import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { toBase64String } from '@angular/compiler/src/output/source_map';
import { LabelValue, State, Document } from 'src/app/models/tasks.model';
import { v4 as uuid } from 'uuid';
import { FileService } from 'src/app/service/shared.service';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-helpersection',
  templateUrl: './helpersection.component.html',
  styleUrls: ['./helpersection.component.css']
})
export class HelpersectionComponent implements OnInit, OnChanges {


  @Input() messageOut: string;
  @Input() tags: any;
  @Input() assignedTaskDdetails: State;
  @Input() documentsForState: any;
  @Input() taskStatusSourceList: LabelValue[];
  @Input() isInProcess:boolean = false;
  @Output() updateEvent = new EventEmitter();
  @Output() saveEvent = new EventEmitter();
  @Output() archiveEvent = new EventEmitter();
  @Output() escalateEvent = new EventEmitter();
  @Output() reserveEvent = new EventEmitter();
  @Output() closeEvent = new EventEmitter();
  @Output() docUploadEvent = new EventEmitter();
  @Output() docRemoveEvent = new EventEmitter();

  taskTags: any[] = [];
  tagsArr: string[] = []
  message: string;
  showTags: boolean = false;
  remarks: string;
  emitObject: any = {}
  showRemarks: boolean = true;
  taskStatus: string;

  //updateMessage:string = "Updating task will move the process ahead, you can tag the task before updating. Are you sure you want to update?";
  //archiveMessage:string = "Your changes won't apply. The case will be archived and the associated process will be closed!!";
  sectionName: string;

  constructor(
    private fileService: FileService,
    private snackBar: MatSnackBar
  ) { }


  ngOnInit() {
    this.startTask();
  }
  ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
    if (changes && changes.messageOut && changes.messageOut.previousValue != changes.messageOut.currentValue) {
      this.startTask();
    }
  }
  startTask() {
    if (this.messageOut === "updateTask") {
      this.sectionName = "Submit Case"
      this.showTags = false;
      this.message = "Submitting the case will move the process ahead, you can tag the task before updating. Are you sure you want to update?"
    }
    else if (this.messageOut == "archiveTask") {
      this.showTags = false;
      this.sectionName = "Archive Case"
      this.message = "Your changes won't apply. The case will be archived and the associated process will be closed!!"
    }
    else if (this.messageOut == "escalateTask") {
      this.showTags = false;
      this.sectionName = "Escalate Case"
      this.message = "Escalating the task will assign it to your team leader, you can add tags to the task before escalating."
    }
    else if (this.messageOut == "reserveTask") {
      this.showTags = false;
      this.showRemarks = false;
      this.sectionName = "Reserve Case"
      this.message = "Reserving the task will move the task in your bucket and your SLA will start after moving it!!"
    }
    else if (this.messageOut == "saveTask") {
      this.showRemarks = true;
      this.message = "Save Draft will save your current details of the task.";
      if (this.taskStatusSourceList && this.taskStatusSourceList.length > 0) {
        this.message = this.message + " Select status before you save."
      }
      this.sectionName = "Save Case";
    }
  }
  updateTask() {
    let tag: any;
    for (tag of this.taskTags) {
      this.tagsArr.push(tag.display);
    }
    this.emitObject.tags = this.tagsArr;
    this.emitObject.remarks = this.remarks;
    if(this.taskStatusSourceList && this.taskStatusSourceList.length > 0 && !this.taskStatus)
    {
      this.snackBar.open('Please Select Task status', "", {
        duration: 5000
      })
    }
    else
    {
    this.emitObject.taskStatus = this.taskStatus;
    this.updateEvent.emit(this.emitObject);
    }
  }

  saveTask() {
    this.isInProcess = true;
    this.emitObject.remarks = this.remarks; 
    this.emitObject.taskStatus = this.taskStatus;
    this.saveEvent.emit(this.emitObject);
  }

  archiveTask() {
    let tag: any;
    for (tag of this.taskTags) {
      this.tagsArr.push(tag.display);
    }
    this.emitObject.tags = this.tagsArr;
    this.emitObject.remarks = this.remarks;
    this.emitObject.taskStatus = this.taskStatus;
    this.archiveEvent.emit(this.emitObject);
  }

  escalateTask() {
    let tag: any;
    for (tag of this.taskTags) {
      this.tagsArr.push(tag.display);
    }
    this.emitObject.tags = this.tagsArr;
    this.emitObject.remarks = this.remarks;
    this.emitObject.taskStatus = this.taskStatus;
    this.escalateEvent.emit(this.emitObject);
  }

  reserveTask() {
    let tag: any;
    for (tag of this.taskTags) {
      this.tagsArr.push(tag.display);
    }
    this.reserveEvent.emit(this.tagsArr);
  }

  closeSection() {
    this.closeEvent.emit();
  }

  getRemarksDocuments(stateInstanace: State) {
    const remarksDocs: Document[] = [];
    if (this.documentsForState && this.documentsForState[stateInstanace._id]) {
      for (let doc of this.documentsForState[stateInstanace._id]) {
        if (doc.documentType && doc.documentType == "USER_REMARKS") {
          remarksDocs.push(doc);
        }
      }
    }
    return remarksDocs;
  }

  onNewRemarksDocuments(stateInstanace: State) {
    const newDoc = new Document();
    newDoc.stateInstanceId = stateInstanace._id;
    newDoc.flowInstanceId = stateInstanace.stateMachineInstanceModelId;
    newDoc.documentType = "USER_REMARKS";
    newDoc.documentName = uuid();
    if (!this.documentsForState[stateInstanace._id]) {
      this.documentsForState[stateInstanace._id] = [];
    }
    this.documentsForState[stateInstanace._id].push(newDoc);
  }

  onDocumentUploadForTask(event: Event, document: Document) {
    let map = {};
    map["event"] = event;
    map["document"] = document;
    this.docUploadEvent.emit(map);
  }
  hideDoc:any[] =[];
  onRemoveDocument(document: Document,index) {
    this.hideDoc[index]= true 
    this.docRemoveEvent.emit(document);
  }

  downloadFile(doc: Document) {
    if (doc && doc.downloadFileUrl) {
      if (doc.downloadFileUrl.startsWith('http')) {
        window.open(doc.downloadFileUrl, '_blank');
      } else {
        this.fileService.download(doc.downloadFileUrl);
      }
    }
  }

  showDocumentRemarksAttachments() {
    if (this.documentsForState && this.documentsForState[this.assignedTaskDdetails._id] && this.documentsForState[this.assignedTaskDdetails._id].length > 0 && !this.assignedTaskDdetails.disableUITaskOperations) {
      for(let doc of this.documentsForState[this.assignedTaskDdetails._id]) {
        if (doc.documentType && doc.documentType == "USER_REMARKS") {
          return true;
        }
      }
    }
    return false;
  }
}
