import { v4 as uuid } from 'uuid';
import { Subject } from "rxjs";
import { Component, OnInit, TemplateRef, Output, EventEmitter, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router, Data } from '@angular/router';

//services
import { CommonService } from '../service/common.service';
import { TaskService } from '../service/task.service';
import { FetchUserService, AllocateTaskToUser } from '../service/userhierarchy.service'
import { StateService } from '../service/state.service';
import { UniversalUser, FileService, TagsService, GraphService, CommunicationService, EntiySharingService } from '../service/shared.service';

//models
import { State, DataPoint, GraphObject, TaskDecision, Document, TimelineStateAuditData, TaskTags, TaskFilter, StateStage, StateModel, DocumentSubSet } from '../models/tasks.model';
import { CommonSearchModel } from '../models/shared.model';
import { UserHierarchy, User } from '../models/user.model'
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import * as moment from 'moment';
import { state } from '@angular/animations';
import { SplitAreaDirective, SplitComponent } from 'angular-split';
import { EntityService } from '../service/entity.service';

import { DataModel } from '../models/datamodel.model';
import { Entity } from '../models/datamodel.model';
import deepEqual from 'deep-equal';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';


@Component({
  selector: 'app-task',
  templateUrl: './task.component.html',
  styleUrls: ['./task.component.scss']
})
export class TaskComponent implements OnInit {
  @ViewChild('split', { static: false }) split: SplitComponent
  @ViewChild('area1', { static: false }) area1: SplitAreaDirective
  @ViewChild('area2', { static: false }) area2: SplitAreaDirective

  modalRef: BsModalRef;
  ortBy = '';
  sortOrder = 'asc';
  filterQuery = '';
  showFilter: boolean = false;
  showStages: boolean = false;

  pageNumber: any;
  assignedTaskPageNumber: number;
  unassignedTaskPageNumber: number;
  assignedTaskPageNumberTeam: number;
  flaggedTaskPageNumber: number;
  fetchRecords: any;
  userId: string;
  progressBarFlag: boolean = false;
  graphObjects = new Map();
  assignedStategraphObject: GraphObject;
  assignedStategraphObjectTeam: GraphObject;
  actionMap: any;
  fieldKeyMap: any;
  responseError: string;
  taskDecision: TaskDecision;
  arrayTableHeaders = {};
  timelineStates: TimelineStateAuditData[] = [];
  selectedTimeLineState: TimelineStateAuditData;
  statusList: any;
  optionList: any[];
  taskTags: any[] = [];
  allProcessStages: any = {};
  selectedProcessStages: any = {};
  activeGraphObjectList: GraphObject[] = [];
  taskTagsObject: TaskTags;
  selectedTab: string;
  searchTerm: string;
  dataPoints: DataPoint[];
  selectedState: State;
  graphObject: GraphObject;

  responseErrorFields:any[]=[];


  assignedStates: State[];
  tempAssignedStates: State[];
  assignedTaskDdetails: State;
  assignedStateTabclass = {};
  assignedTaskActionButtonEnabled = {};


  unassignedStates: State[];
  unassignedTaskDdetails: State;
  unassignedStateTabclass = {};
  unassignedTaskActionButtonEnabled = {};


  assignedStatesTeam: State[];
  tempAssignedStatesTeam: State[];
  assignedTaskDetailsTeam: State;
  assignedStateTabclassTeam = {};
  assignedTaskActionButtonEnabledTeam = {};

  archivedStates: State[];
  tempArchivedStates: State[];
  archivedTaskDdetails: State;
  archivedStateTabclass = {};
  archivedTaskActionButtonEnabled = {};

  currentSelectedTaskDetails: State;

  loadingUnassigned: boolean = false;
  loadingAssigned: boolean = false;
  loadingAssignedTeam: boolean = false;
  loadingArchived: boolean = false;
  stagesFetched: boolean = false;

  showLoaderOverlay = {
    taskListing: true,
    mainScreen: true,
  }


  //filters
  processFilter: any[];
  tagFilter: any[];
  groupFilter: any[];
  taskFilter: TaskFilter;
  showTagFilter: boolean = false;

  processOptions: any[] = [];
  filterGroups: any[] = []
  dropdownSettings: IDropdownSettings;

  //documents
  documentsForState = {};
  documentsToBeUploaded = [];
  docLengthForState:number =0;

  //tabs
  message_type: string = "Assigned";
  TAB_ASSIGNED = 'ASSIGNED';
  TAB_UNASSIGNED = 'UNASSIGNED';
  TAB_TEAM_ASSIGNED = "ASSIGNED_TEAM";
  TAB_ARCHIVED = "ARCHIVED";

  TABLINKS_ACTIVE = "block active";
  TABLINKS = "block"
  assignedCount: number;
  unassignedCount: number;
  currentFolderTaskCount: number;
  personalFetched = false;
  personalTeamFetched = false;
  groupFetched = false;
  archiveFetched = false;
  isToogle: boolean = true;
  viewSpecificState: boolean = false;
  showMain: boolean = true;

  //users
  userName: string;
  users: UserHierarchy[] = [];
  peers: any[] = [];
  userHierarchy: UserHierarchy;
  tempUser: User;
  allocatedAssignedTaskToUserId: string;
  allocatedUnAssignedTaskToUserId: string;
  userChildren: UserHierarchy[];

  //UI Flags
  box: number;
  bulkBox: string = "";

  //helper section
  messageOut: string;

  dropdownList: any[];
  selectedItems: any[];

  //records
  recordsMessage: string = "";
  records: boolean = true;

  // Bulk Tasks
  selectedBulkTasks: any[] = [];
  bulkEditTemplate: boolean = false;

  // @Output() userOutput = new EventEmitter<UserHierarchy[]>();
  public options = [
    { display: 'Self', value: 'ASSIGNED', checked:true },
    { display: 'Group', value: 'UNASSIGNED', checked:false },
    { display: 'Archived', value: 'ARCHIVED', checked:false },
  ];

  viewEntity: any = null;
  selectedDataModelId: string;
  //selectedDataModel:any = null;
  selectedDataModel: DataModel = new DataModel();

  finalDataModel: any = new DataModel();
  associatedEntityValid: boolean = true;
  entityMap: any = {};
  machineIds: any = {};
  entityAssociatedWithTask: boolean = false;
  associatedEntityName: string;
  associatedEntityId: string;
  selectedTabIndex: number = 0;
  entityTabClicked: boolean = false;

  stateId: String = null;
  customerId:string;


  constructor(private commonService: CommonService,
    private communicationService: CommunicationService,
    private taskService: TaskService,
    private universalUser: UniversalUser,
    private stateService: StateService,
    private fetchUserService: FetchUserService,
    private allocateTaskToUser: AllocateTaskToUser,
    private modalService: BsModalService,
    private fileService: FileService,
    private tagService: TagsService,
    private graphService: GraphService,
    private route: ActivatedRoute,
    private router: Router,
    private entityService: EntityService,
    private entitySharingService: EntiySharingService,
    private snackBar: MatSnackBar,

  ) {

    this.unassignedStates = [];
    this.assignedStates = [];
    this.assignedStatesTeam = [];
    this.archivedStates = [];
    this.tempAssignedStates = [];
    this.assignedStateTabclass = {};
    this.userChildren = [];
    this.selectedProcessStages = {};
    this.machineIds = {};
    this.assignedTaskDdetails = new State();
    this.unassignedTaskDdetails = new State();
    this.assignedTaskDetailsTeam = new State();
    this.archivedTaskDdetails = new State();
    this.assignedTaskActionButtonEnabled = {};
    this.assignedStategraphObject = new GraphObject();
    this.assignedStategraphObjectTeam = new GraphObject();
    this.taskDecision = new TaskDecision();
    this.userHierarchy = new UserHierarchy();
    this.selectedTimeLineState = new TimelineStateAuditData();
    this.taskTagsObject = new TaskTags();
    this.taskFilter = new TaskFilter();
    this.currentSelectedTaskDetails = new State();
    this.selectedState = new State();
    this.graphObject = new GraphObject();
    // this.selectedDataModel = new DataModel();
  }

  ngOnInit() {

    this.communicationService.setAssocEntityDetails(null);
    this.selectedTab = this.TAB_ASSIGNED;
    this.pageNumber = 0
    this.assignedTaskPageNumber = 0;
    this.unassignedTaskPageNumber = 0;
    this.assignedTaskPageNumberTeam = 0;
    this.flaggedTaskPageNumber = 0;
    this.fetchRecords = 10;

    this.userId = this.universalUser.getUser()._id;
    this.userName = this.universalUser.getUser().username;
    this.getPeers();
    this.getUserChildren();
    this.getUserList();
    this.getParentUser();
    this.fetchGraphs();
    this.getGroups();
  }

  updateEntityValues(event) {
    this.finalDataModel = event;
  }

  isEntityFormValid(event) {
    this.associatedEntityValid = event;
  }

  // ---------------------- Ui functionality 
  displayBox(vdata: number = 0, operation?: string) {
    if (vdata == 1) {
      if (operation === 'update') {
        this.messageOut = "updateTask";
      }
      else if (operation === 'archive') {
        this.messageOut = "archiveTask";
      }
      else if (operation === 'escalate') {
        this.messageOut = "escalateTask";
      }
      else if (operation === 'reserve') {
        this.messageOut = "reserveTask";
      }
      else if (operation === 'save') {
        this.messageOut = 'saveTask';
      }
    }
    if (vdata == 8 && this.timelineStates.length == 0) {
      this.getTimeline(this.currentSelectedTaskDetails);
    }
    if (vdata == 19) {
      this.getAssociatedEntityData();
    }
    this.box = vdata;
    this.toggle(false);
  }


  getAssociatedEntityData() {
    this.communicationService.setAssocEntityDetails(this.currentSelectedTaskDetails);
    let taskDetails = this.currentSelectedTaskDetails;
    let entityId = null;
    let entityName = null;
    if (taskDetails.parameters['_associatedEntityId'] && taskDetails.parameters['_associatedEntityName']) {
      this.entityAssociatedWithTask = true;
      this.associatedEntityId = taskDetails.parameters['_associatedEntityId'];
      entityName = taskDetails.parameters['_associatedEntityName'];
      this.associatedEntityName = entityName;

    }
    //for backward compatibility
    else if (taskDetails.source != undefined && taskDetails.source != null && taskDetails.source.length > 0) {
      this.entityAssociatedWithTask = true;
      let pos = taskDetails.source.map(item => item.referenceType).indexOf('ENTITY');
      if (pos != null && pos != undefined && pos >= 0) {
        this.associatedEntityId = taskDetails.source[pos]['referenceId'];
        entityName = taskDetails.source[pos]['referenceEntityName'];
        this.associatedEntityName = entityName;
      }
    }
    if (this.associatedEntityId != null && this.associatedEntityName != null) {
      this.entityAssociatedWithTask = true;
      this.communicationService.setAssocEntityDetails(this.currentSelectedTaskDetails);
    }
    else {
      this.entityAssociatedWithTask = false;
    }
    if (this.entityAssociatedWithTask) {
      this.getAssociatedEntity();
    }

  }


  getAssociatedEntity() {

    this.viewEntity = null;
    this.selectedDataModel = null;
    if (this.entityAssociatedWithTask) {
      this.entityTabClicked = true;
      this.entityService.getEntity(this.associatedEntityName, this.associatedEntityId).subscribe(entity => {
        if (entity) {
          this.viewEntity = entity;
          let dataModel = new DataModel();
          dataModel._id = entity.datamodelId;
          this.selectedDataModel = (dataModel);
        }
      });
    }
  }


  displayforBulkBox(vdata: string) {
    this.bulkBox = vdata;
  }
  // for toggle the left side box
  toggleBox(): boolean {
    return this.commonService.toggleBlock;
  }
  toggle(showTaskList) {
    this.isToogle = showTaskList;
    if (showTaskList == true) {
      this.commonService.toggleBlock = !this.commonService.toggleBlock;
      this.box = 0;
    } else if (showTaskList == false) {
      this.commonService.toggleBlock = false;

    }
  }
  openSection() {
    this.toggle(true);
  }
  closeSection() {
    this.toggle(true);
  }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: 'modal-dialog modal-md modal-dialog-centered featureList', backdrop: 'static', keyboard: false });
  }
  // ---------------------- Ui functionality END
  
  filterDropDownSettings() {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }

  getStateById(stateId: string) {
    this.taskService.getStateById(stateId).subscribe(state => {
      if (state) {
        let states = [];
        states.push(state);
        this.setFirstAssignedTaskValues(states, true);
      }
    });
  }

  goToTaskList() {
    if (this.communicationService.getAssocEntityDetails() != null && this.communicationService.getAssocEntityDetails() != undefined && !this.customerId) {
      this.toggle(true);
      this.router.navigateByUrl('/add-activities');
    }
    else if(this.customerId){
      this.toggle(true);
      this.router.navigate(['/customer-details'], { queryParams: { customerId: this.customerId } });
    }
    else {
      this.toggle(true);
      this.router.navigateByUrl('/workspace2/listing');
    }

  }

  getGroups() {
    this.fetchUserService.fetchUserGroups()
      .subscribe(userGroups => {
        if (userGroups && userGroups.length > 0) {
          for (let group of userGroups) {
            this.filterGroups.push({ display: group, value: group })
          }
        }
      });
  }


  getUserList() {
    this.fetchUserService.fetchChildUsers(this.userId)
      .subscribe(userList => {
        if (userList && userList.length > 0) {
          this.users = userList;
        }
      });
  }

  getPeers() {
    this.fetchUserService.getPeers()
      .subscribe(peers => {
        if (peers && peers.length > 0) {
          this.peers = peers;
        }
      });
  }

  getParentUser() {
    this.fetchUserService.getUserHierarchy(this.userId)
      .subscribe(userHierarchyObject => {
        if (userHierarchyObject) {
          this.userHierarchy = userHierarchyObject;
          this.allocatedAssignedTaskToUserId = this.userHierarchy._id;
        }
      });
  }

  fetchRecordsFor(tabName: string, existingRecords: State[]) {
    this.records = true;
    let status = null;
    let folder = null;
    let subStatus = null;

    if (!tabName || tabName == null || tabName.trim().length == 0) {
      return;
    } else if (tabName === this.TAB_ASSIGNED) {
      this.currentFolderTaskCount = this.assignedCount;
      this.message_type = "Assigned";
      if (this.personalFetched) {
        return;
      } else {
        this.personalFetched = true;
        status = 'ACTIVE';
        folder = 'Personal';
      }
    } else if (tabName === this.TAB_UNASSIGNED) {
      this.currentFolderTaskCount = this.unassignedCount;
      this.message_type = "Unassigned";
      if (this.groupFetched) {
        return;
      } else {
        this.groupFetched = true;
        status = 'ACTIVE';
        folder = 'Group';
      }
    } else if (tabName === this.TAB_ARCHIVED) {
      this.currentFolderTaskCount = this.unassignedCount;
      this.message_type = "Archived";
      if (this.archiveFetched) {
        return;
      } else {
        this.archiveFetched = true;
        status = 'CLOSED';
        folder = 'Personal';
      }
    }

    else {
      return;
    }

    if (existingRecords == null) {
      existingRecords = [];
    }

    if (tabName == this.TAB_ASSIGNED || tabName == this.TAB_UNASSIGNED || tabName == this.TAB_ARCHIVED) {
      this.showLoaderOverlay.taskListing = true;
      this.taskService.getStatesByStatusAndFolder(status, folder, 0, this.fetchRecords)
        .subscribe(states => {
          if (states) {
            this.showLoaderOverlay.taskListing = false;
          }
          for (let state of states) {
            state = this.setTimeDataFields(state);
            let nowTime = moment(new Date());
            let stateTime = moment(state.slaEndTimeLong);
            if (stateTime.diff(nowTime, "seconds") < 0) {
              state.overdue = true;
            } else {
              state.overdue = false;
            }
            existingRecords.push(state);
          }

          if (tabName === this.TAB_ASSIGNED) {
            this.setFirstAssignedTaskValues(existingRecords);
            this.tempAssignedStates = JSON.parse(JSON.stringify(existingRecords));
          } else if (tabName === this.TAB_UNASSIGNED) {
            this.setFirstUnAssignedTaskValues(existingRecords);
          } else if (tabName === this.TAB_ARCHIVED) {
            this.setFirstArchivedTaskValues(existingRecords);
          }


          // this.progressBarFlag = false;
        }, error => {
          this.progressBarFlag = false;
          this.showLoaderOverlay.taskListing = false;
        });
    }

  }

  transformTime(date: Date, minutes: any, operation: string) {
    if (operation == "add") {
      date.setMinutes(date.getMinutes() + minutes)
    }
    else if (operation == "subtract") {
      date.setMinutes(date.getMinutes() - minutes)
    }
    return date.getTime();
  }

  loadMore(status: string, type: string): void {

    this.progressBarFlag = true;
    this.loadingAssigned = true;
    this.loadingUnassigned = true;
    this.pageNumber = this.pageNumber + 1;
    this.showLoaderOverlay.taskListing = true;
    if (status != "FLAGGED") {
      if (type == "Personal") {
        this.assignedTaskPageNumber = this.assignedTaskPageNumber + 1;
        this.taskService.getStatesByStatusAndFolder(status, type, this.assignedTaskPageNumber, this.fetchRecords)
          .subscribe(states => {
            if (states != null && states.length == 0) {
              this.showLoaderOverlay.taskListing = false;
              this.openSnackBar("error", "Cases are not available", "Close")
              //new showAlertModal("No more data available");
            }
            if (type == 'Personal') {
              if (this.assignedStates.length == 0) {
                let statesArr = [];
                for (let state of states) {
                  statesArr.push(this.setTimeDataFields(state));
                }
                this.assignedStates = statesArr;
                this.setFirstAssignedTaskValues(statesArr);

              }
              else {
                let statesArr = [];
                for (let state of states) {
                  this.assignedStateTabclass[state._id] = "block";
                  this.assignedTaskActionButtonEnabled[state._id] = true;
                  statesArr.push(this.setTimeDataFields(state));
                  this.setGraphObjects(state);
                }
                this.assignedStates = this.assignedStates.concat(statesArr);
                this.tempAssignedStates = this.tempAssignedStates.concat(JSON.parse(JSON.stringify(statesArr)));
              }

              this.loadingAssigned = false;
              this.showLoaderOverlay.taskListing = false;
            }
            if (!this.loadingUnassigned && !this.loadingAssigned) {

              //this.baThemeSpinner.hide();
            }
            this.progressBarFlag = false;
          }, error => {
            this.loadingUnassigned = false;
            this.progressBarFlag = false;
            if (!this.loadingUnassigned && !this.loadingAssigned) {

              //this.baThemeSpinner.hide();
            }
          });
      }
      else if (type == "Team") {
        this.assignedTaskPageNumberTeam = this.assignedTaskPageNumberTeam + 1;
        this.taskService.getStatesBySubStatusAndFolder(this.TAB_ASSIGNED, status, 0, this.fetchRecords, "TEAM")
          .subscribe(states => {
            if (states != null && states.length == 0) {
              this.showLoaderOverlay.taskListing = false;
              this.openSnackBar("error", "Cases are not available", "Close")
              //new showAlertModal("No more data available");
            }
            if (this.assignedStatesTeam.length == 0) {
              let statesArr = [];
              for (let state of states) {
                statesArr.push(this.setTimeDataFields(state));
              }
              this.setFirstAssignedTeamTaskValues(statesArr);
              this.assignedStatesTeam = statesArr;

            }
            else {
              let statesArr = [];
              for (let state of states) {
                this.assignedStateTabclassTeam[state._id] = "block";
                this.assignedTaskActionButtonEnabledTeam[state._id] = true;
                statesArr.push(this.setTimeDataFields(state));
                this.setGraphObjects(state);
              }
              this.assignedStatesTeam = this.assignedStatesTeam.concat(statesArr);
              this.tempAssignedStatesTeam = this.tempAssignedStatesTeam.concat(JSON.parse(JSON.stringify(statesArr)));
            }


          });
      }
      else {
        this.unassignedTaskPageNumber = this.unassignedTaskPageNumber + 1;
        this.taskService.getStatesByStatusAndFolder(status, type, this.unassignedTaskPageNumber, this.fetchRecords)
          .subscribe(states => {
            if (states != null && states.length == 0) {
              this.showLoaderOverlay.taskListing = false;
              this.openSnackBar("error", "Cases are not available", "Close")
              // new showAlertModal("No more data available");
            }
            if (type == 'Group') {
              if (this.unassignedStates.length == 0) {
                let statesArr = [];
                for (let state of states) {
                  statesArr.push(this.setTimeDataFields(state));
                }
                this.setFirstUnAssignedTaskValues(statesArr);
                this.unassignedStates = statesArr;
              }
              else {
                let statesArr = []
                for (let state of states) {
                  this.unassignedStateTabclass[state._id] = "block";
                  this.unassignedTaskActionButtonEnabled[state._id] = true;
                  statesArr.push(this.setTimeDataFields(state));
                  this.setGraphObjects(state);
                }
                this.unassignedStates = this.unassignedStates.concat(statesArr);
              }

              this.loadingUnassigned = false;
            }

            if (!this.loadingUnassigned && !this.loadingAssigned) {
              this.progressBarFlag = false;
              //this.baThemeSpinner.hide();
            }
            this.progressBarFlag = false;
          }, error => {
            this.loadingUnassigned = false;
            if (!this.loadingUnassigned && !this.loadingAssigned) {
              this.progressBarFlag = false;
              //this.baThemeSpinner.hide();
            }
            this.progressBarFlag = false;
          });
      }

    }

  }
  resetAll() {
    this.taskFilter.groups = [];
    this.taskFilter.processes = [];
    this.taskFilter.tags = [];
    this.finalDataModel = new DataModel();
    this.selectedDataModel = new DataModel();
    if (this.selectedTab == this.TAB_ASSIGNED) {
      this.personalFetched = false;
      this.assignedStates = [];
      this.fetchRecordsFor(this.selectedTab, this.assignedStates);
    }
    else if (this.selectedTab == this.TAB_UNASSIGNED) {
      this.groupFetched = false;
      this.unassignedStates = [];
      this.fetchRecordsFor(this.selectedTab, this.unassignedStates);
    }
    else if (this.selectedTab == this.TAB_ARCHIVED) {
      this.archiveFetched = false;
      this.archivedStates = [];
      this.fetchRecordsFor(this.selectedTab, this.archivedStates);
    }
    else if (this.selectedTab == this.TAB_TEAM_ASSIGNED) {
      this.personalTeamFetched = false;
      this.tempAssignedStates = [];
      this.fetchRecordsForTeam(this.TAB_ASSIGNED, this.assignedStatesTeam);
    }
  }

  // set Time DataFields For Ui

  setTimeDataFields(state: State) {
    if (state.slaEndTimeLong) {
      //let currentTime = this.transformTime(new Date(),330,'add');
      let currentTime = new Date().getTime();
      // state.startTimeLong =  this.transformTime(new Date(state.startTimeLong),330,'subtract');

      //state.slaEndTimeLong = this.transformTime(new Date(state.slaEndTimeLong),330,'subtract');
      //for SLA time
      if (state.slaEndTimeLong) {
        state.slaTime = (state.slaEndTimeLong - state.startTimeLong) / (1000 * 60 * 60);
      }

      //for remaining time
      if (state.slaEndTimeLong && currentTime < state.slaEndTimeLong) {
        state.remainingTime = (state.slaEndTimeLong - currentTime) / (1000 * 60 * 60);
      }
      return state;
    }
    return state;

  }

  decideStagesForState(s: State) {
    this.selectedProcessStages = {};
    if (this.allProcessStages[s.machineType] && this.allProcessStages[s.machineType].length > 0 && s.processStageCdList != null && s.processStageCdList.length > 0) {
      this.selectedProcessStages.stages = this.allProcessStages[s.machineType];
      this.selectedProcessStages.currentStage = s.processStageCdList[0];
      this.showStages = true;
    }
    else {
      this.showStages = false;
    }
  }

  getDataPointConfigForGraph() {
    let co = new CommonSearchModel();
    co.searchParams = []
    co.returnFields = ["dataPointConfigurationList", "states"];
    return co;
  }

  setFirstAssignedTaskValues(states: any, stateView?: boolean) {
    this.selectedTabIndex = 0;
    this.entityTabClicked = false;
    if (states != null && states.length > 0) {
      if (stateView) {
        this.toggle(true);
      }
      this.assignedStateTabclass[states[0]._id] = "block active";
      this.getTaskTags(states[0].machineType);
      let co = this.getDataPointConfigForGraph();
      this.stateService.getDataPointconfiguration(co, states[0].stateMachineInstanceModelId)
        .subscribe(
          response => {
            if (response) {
              this.showLoaderOverlay.mainScreen = false;
            }
            this.graphObject = response;
            this.graphObjects.set(states[0].stateMachineInstanceModelId, this.getSortedDatPointGraphObject(response));
            this.assignedTaskDdetails = states[0];
            this.currentSelectedTaskDetails = this.assignedTaskDdetails;
            this.getAssociatedEntityData();
            this.decideStagesForState(this.assignedTaskDdetails);
            this.statusList = this.getStatusList(this.assignedTaskDdetails);
            this.getDocuments(this.assignedTaskDdetails);
            this.loadingAssigned = false;
            if (!this.loadingUnassigned && !this.loadingAssigned) {
              this.progressBarFlag = false;
              // this.baThemeSpinner.hide();
            }
            this.assignedTaskActionButtonEnabled[this.assignedTaskDdetails._id] = true;
            this.assignedStategraphObject = response;
            /* for (let state of states) {
              if (this.assignedTaskDdetails != state) {
                this.assignedStateTabclass[state._id] = "block";
                this.setGraphObjects(state);
                this.assignedTaskActionButtonEnabled[state._id] = true;
              }
            } */
          },
          error => {
            this.loadingAssigned = false;
            this.progressBarFlag = false;
            this.showLoaderOverlay.mainScreen = false;

          }
        )
    } else {
      this.records = false;
      this.progressBarFlag = false;
    }
  }


  setFirstArchivedTaskValues(states: any) {
    this.selectedTabIndex = 0;
    this.entityTabClicked = false;
    if (states != null && states.length > 0) {
      this.archivedStateTabclass[states[0]._id] = "block active";
      let co = this.getDataPointConfigForGraph();
      this.stateService.getDataPointconfiguration(co, states[0].stateMachineInstanceModelId)
        .subscribe(
          response => {
            if (response) {
              this.showLoaderOverlay.mainScreen = false;
            }
            this.graphObject = response;
            this.graphObjects.set(states[0].stateMachineInstanceModelId, this.getSortedDatPointGraphObject(response));
            this.archivedTaskDdetails = states[0];
            this.currentSelectedTaskDetails = this.archivedTaskDdetails;
            this.getAssociatedEntityData();
            this.decideStagesForState(this.archivedTaskDdetails);
            this.selectedProcessStages = this.allProcessStages.processStageCdList;
            this.statusList = this.getStatusList(this.archivedTaskDdetails);
            this.getDocuments(this.archivedTaskDdetails);
            this.loadingAssigned = false;
            if (!this.loadingUnassigned && !this.loadingAssigned) {
              this.progressBarFlag = false;
              // this.baThemeSpinner.hide();
            }
            this.archivedTaskActionButtonEnabled[this.archivedTaskDdetails._id] = true;
            //this.assignedStategraphObject = response;
            /*  for (let state of states) {
               if (this.archivedTaskDdetails != state) {
                 this.archivedStateTabclass[state._id] = "block";
                 this.setGraphObjects(state);
                 this.archivedTaskActionButtonEnabled[state._id] = true;
               }
             } */
          },
          error => {
            this.loadingAssigned = false;
            this.progressBarFlag = false;
            this.showLoaderOverlay.mainScreen = false;

          }
        )
    } else {
      this.records = false;
      this.progressBarFlag = false;
    }
  }

  setFirstUnAssignedTaskValues(states) {
    this.selectedTabIndex = 0;
    this.entityTabClicked = false;
    if (states != null && states.length > 0) {
      this.unassignedStateTabclass[states[0]._id] = "block active";
      let co = this.getDataPointConfigForGraph();
      this.stateService.getDataPointconfiguration(co, states[0].stateMachineInstanceModelId)
        .subscribe(
          response => {
            if (response) {
              this.showLoaderOverlay.mainScreen = false;
            }
            this.graphObject = response;
            this.graphObjects.set(states[0].stateMachineInstanceModelId, this.getSortedDatPointGraphObject(response));
            this.unassignedTaskDdetails = states[0];
            this.currentSelectedTaskDetails = this.unassignedTaskDdetails;
            this.getAssociatedEntityData();
            this.decideStagesForState(this.unassignedTaskDdetails);
            this.selectedProcessStages = this.unassignedTaskDdetails.processStageCdList
            this.statusList = this.getStatusList(this.assignedTaskDdetails);
            this.getDocuments(this.unassignedTaskDdetails);
            this.loadingUnassigned = false;
            if (!this.loadingUnassigned && !this.loadingAssigned) {
              this.progressBarFlag = false;
              // this.baThemeSpinner.hide();
            }
            this.unassignedTaskActionButtonEnabled[this.unassignedTaskDdetails._id] = true;
            /*  for (let state of states) {
               if (this.unassignedTaskDdetails != state) {
                 this.unassignedStateTabclass[state._id] = "block";
                 this.setGraphObjects(state);
                 this.unassignedTaskActionButtonEnabled[state._id] = true;
               }
             } */
          },
          error => {
            this.loadingUnassigned = false;
            this.progressBarFlag = false;
            this.showLoaderOverlay.mainScreen = false;

          }
        )
    } else {
      this.records = false;
      this.progressBarFlag = false;
    }
  }



  getSortedDatPointGraphObject(graphObject: GraphObject) {
    if (graphObject != null && graphObject.dataPointConfigurationList != null && graphObject.dataPointConfigurationList.length > 0) {
      const dataPointsConfig = JSON.parse(JSON.stringify(graphObject.dataPointConfigurationList));
      dataPointsConfig.sort(function (a: DataPoint, b: DataPoint) {
        return a.sequence > b.sequence ? 1 : a.sequence ? -1 : 0
      });
      graphObject.dataPointConfigurationList = dataPointsConfig;

    }
    return graphObject;
  }


  setGraphObjects(state: State) {
    if (!this.graphObjects.get(state.stateMachineInstanceModelId) || this.graphObjects.get(state.stateMachineInstanceModelId) != null) {
      let co = this.getDataPointConfigForGraph();
      this.stateService.getDataPointconfiguration(co, state.stateMachineInstanceModelId)
        .subscribe(
          response => {
            this.graphObjects.set(state.stateMachineInstanceModelId, this.getSortedDatPointGraphObject(response));

          },
          error => {

          }
        )
    }
    else {
      this.graphObjects.set(state.stateMachineInstanceModelId, null);
    }

  }

  getGraphObject(stateMachineInstanceModelId: string) {
    return this.graphObjects.get(stateMachineInstanceModelId);
  }
  onSelectTask(state: State, taskType) {
    this.selectedTabIndex = 0;
    this.entityTabClicked = false;
    this.showLoaderOverlay.mainScreen = true;
    this.getTaskTags(state.machineType);

    switch (taskType) {
      case 'personal':
        // console.log(state)
        this.onPersonalAssignedSubjectSelect(state);
        break;
      case 'team':
        this.onPersonalAssignedTeamSubjectSelect(state);
        break;
      case 'unassigned':
        this.onPersonalUnAssignedSubjectSelect(state);
        break;
      case 'archived':
        this.onArchivedSubjectSelect(state);
        break;
      default:
        break;
    }
  }



  onPersonalAssignedSubjectSelect(state: State) {
    this.associatedEntityValid = true;
    this.timelineStates = [];
    this.assignedTaskDdetails = state;
    let co = this.getDataPointConfigForGraph();
    this.stateService.getDataPointconfiguration(co, state.stateMachineInstanceModelId)
      .subscribe(
        response => {
          this.graphObject = response;
          this.graphObjects.set(state.stateMachineInstanceModelId, this.getSortedDatPointGraphObject(response));

          this.showLoaderOverlay.mainScreen = false;
        });
    this.currentSelectedTaskDetails = this.assignedTaskDdetails;
    this.assignedTaskActionButtonEnabled[this.assignedTaskDdetails._id] = true;

    this.getAssociatedEntityData();
    this.selectedProcessStages = this.assignedTaskDdetails.processStageCdList;
    this.decideStagesForState(this.assignedTaskDdetails);
    this.getDocuments(this.assignedTaskDdetails);
    for (let asgnState of this.assignedStates) {
      this.assignedStateTabclass[asgnState._id] = this.TABLINKS;
    }
    this.assignedStateTabclass[state._id] = this.TABLINKS_ACTIVE;
    this.assignedStategraphObject = this.graphObjects.get(state.stateMachineInstanceModelId);
    this.graphObject = this.assignedStategraphObject;
  }

  onPersonalUnAssignedSubjectSelect(state: State) {
    this.timelineStates = [];
    this.unassignedTaskDdetails = state;
    let co = this.getDataPointConfigForGraph();
    this.stateService.getDataPointconfiguration(co, state.stateMachineInstanceModelId)
      .subscribe(
        response => {
          this.graphObject = response;
          this.graphObjects.set(state.stateMachineInstanceModelId, this.getSortedDatPointGraphObject(response));

          this.showLoaderOverlay.mainScreen = false;
        });
    this.currentSelectedTaskDetails = this.unassignedTaskDdetails;
    this.unassignedTaskActionButtonEnabled[this.unassignedTaskDdetails._id] = true;
    this.getAssociatedEntityData();
    this.selectedProcessStages = this.unassignedTaskDdetails.processStageCdList;
    this.decideStagesForState(this.unassignedTaskDdetails);
    this.getDocuments(this.unassignedTaskDdetails);
    for (let unasgnState of this.unassignedStates) {
      this.unassignedStateTabclass[unasgnState._id] = this.TABLINKS;
    }
    this.unassignedStateTabclass[state._id] = this.TABLINKS_ACTIVE;
    this.graphObject = this.graphObjects.get(state.stateMachineInstanceModelId);

  }

  onArchivedSubjectSelect(state: State) {
    this.timelineStates = [];
    this.archivedTaskDdetails = state;
    let co = this.getDataPointConfigForGraph();
    this.stateService.getDataPointconfiguration(co, state.stateMachineInstanceModelId)
      .subscribe(
        response => {
          this.graphObject = response;
          this.graphObjects.set(state.stateMachineInstanceModelId, this.getSortedDatPointGraphObject(response));

          this.showLoaderOverlay.mainScreen = false;
        });
    this.currentSelectedTaskDetails = this.archivedTaskDdetails;
    this.getAssociatedEntityData();
    this.selectedProcessStages = this.archivedTaskDdetails.processStageCdList;
    this.decideStagesForState(this.archivedTaskDdetails);
    this.getDocuments(this.archivedTaskDdetails);
    for (let archState of this.archivedStates) {
      this.archivedStateTabclass[archState._id] = this.TABLINKS;
    }
    this.archivedStateTabclass[state._id] = this.TABLINKS_ACTIVE;
    this.graphObject = this.graphObjects.get(state.stateMachineInstanceModelId);
  }


  getBusinessKeysWithoutTable(selectedTask: State) {
    let dataPoints: DataPoint[];
    dataPoints = [];
    if (this.graphObjects.get(selectedTask.stateMachineInstanceModelId) != null && this.graphObjects.get(selectedTask.stateMachineInstanceModelId).dataPointConfigurationList != null && this.graphObjects.get(selectedTask.stateMachineInstanceModelId).dataPointConfigurationList.length > 0) {
      const dataPointsConfig = this.graphObjects.get(selectedTask.stateMachineInstanceModelId).dataPointConfigurationList;
      for (let data of dataPointsConfig) {
        if (!data.businessKeyFlag) {
          if (data.dataType == "ARRAY" && data.childdataPoints != null && data.childdataPoints.length > 0) {

          }
          else {
            if (!this.toBeDisplayOrNot(data, selectedTask)) {
              dataPoints.push(data);
            }
          }

        }
      }
    }
    return dataPoints;
  }

  toBeDisplayOrNot(dataPoint: DataPoint, selectedTask: State) {
    if (this.graphObjects.get(selectedTask.stateMachineInstanceModelId) != null && this.graphObjects.get(selectedTask.stateMachineInstanceModelId).dataPointConfigurationList != null && this.graphObjects.get(selectedTask.stateMachineInstanceModelId).dataPointConfigurationList.length > 0) {
      if (this.graphObjects.get(selectedTask.stateMachineInstanceModelId).states) {
        for (let state of this.graphObjects.get(selectedTask.stateMachineInstanceModelId).states) {
          if (state.stateCd == selectedTask.stateCd && state.dataPointAccessList) {
            for (let dataAccess of state.dataPointAccessList) {
              //console.log(dataAccess.dataPointName + " " + dataAccess.hide);
              if (dataAccess.dataPointName != null && dataPoint.dataPointName != null && dataAccess.dataPointName == dataPoint.dataPointName) {
                return dataAccess.hide;
              }
            }
          }
        }
      }
    }
    return false;
  }

  isDataPointDisabled(dataPoint: DataPoint, selectedTask: State) {
    if (this.graphObjects != null && this.graphObjects.get(selectedTask.stateMachineInstanceModelId) != null && this.graphObjects.get(selectedTask.stateMachineInstanceModelId).states) {
      for (let state of this.graphObjects.get(selectedTask.stateMachineInstanceModelId).states) {
        if (state.stateCd == selectedTask.stateCd && state.dataPointAccessList) {
          for (let dataAccess of state.dataPointAccessList) {
            //console.log(dataAccess.dataPointName + " " + dataAccess.hide);
            if (dataAccess.dataPointName != null && dataPoint.dataPointName != null && dataAccess.dataPointName == dataPoint.dataPointName) {
              return !dataAccess.writeAccess;
            }
          }
        }
      }
    }
    return false;
  }


  getLatestStateInstance(state: State) {
    this.progressBarFlag = true;
    this.unassignedTaskActionButtonEnabled[state._id] = false;
    this.stateService.getStateInstanceById(state._id)
      .subscribe(
        response => {
          this.progressBarFlag = false;
          if (response && response.assignedUserId) {
            this.removedUnAssignedTask(state);
            // new showModal("errorReserveTaskModal");
          }
          else {
            this.progressBarFlag = false;
            this.reserveUnassignedTask(state);
          }
        },
        error => {
          this.progressBarFlag = false;
          // new showAlertModal("Error", "error to reserve task")
        }
      )
  }

  reserveUnassignedTask(state: State) {
    this.unassignedTaskActionButtonEnabled[state._id] = false;
    this.allocateTaskToUser.allocateTask(this.userId, state._id, "Reserve")
      .subscribe(updatedState => {
        this.toggle(true);
        this.unassignedTaskActionButtonEnabled[state._id] = false;
        this.removedUnAssignedTask(state);
        if (this.assignedStates.length == 0) {
          this.assignedStateTabclass[state._id] = "block active";
        }
        else {
          this.assignedStateTabclass[updatedState._id] = "block";
        }
        this.assignedTaskActionButtonEnabled[updatedState._id] = true;
        this.setGraphObjects(updatedState);
        this.assignedStates.push(updatedState);
        this.tempAssignedStates.push(JSON.parse(JSON.stringify(updatedState)));
        this.commonService.fetchNotifications(this.userId);
        this.resetAll();

      }
        ,
        error => {
          this.unassignedTaskActionButtonEnabled[state._id] = false;
          //new showAlertModal("Error", "error to reserve task")
        }
      );

  }




  checkForTags(emitedObject: any, type: string) {
    if (emitedObject.tags != null && emitedObject.tags.length > 0) {
      this.saveTaskTags(emitedObject.tags);
      if (type === "Reserve") {
        this.unassignedTaskDdetails.taskTags = emitedObject.tags;
      }
      else {
        this.assignedTaskDdetails.taskTags = emitedObject.tags;
      }
    }
    if (emitedObject.remarks != null) {
      this.assignedTaskDdetails.taskRemarks = emitedObject.remarks;
    }
    if (emitedObject.taskStatus) {
      this.assignedTaskDdetails.taskStatus = emitedObject.taskStatus;
    }
    if (type === "Update" || type === "Flagged") {
      this.updateProcessFlow(this.currentSelectedTaskDetails, 'ASSIGNED', false, true);
    }
    if (type === "UpdateDirect") {
      this.updateProcessFlow(this.currentSelectedTaskDetails, 'ASSIGNED', false, false);
    }
    else if (type === "Save") {
      this.updateProcessFlow(this.currentSelectedTaskDetails, 'ASSIGNED', true);
    }
    else if (type === "Archive") {
      this.archiveAssignedTask();
    }
    else if (type === "Escalate") {
      this.escalateAssignedTask();
    }
    else if (type === "Reserve") {
      this.getLatestStateInstance(this.unassignedTaskDdetails);
    }
    else if (type === "Allocate_Assigned") {
      this.allocateAssignedTask();
    }
    else if (type === "Allocate_Unassigned") {
      this.allocateUnAssignedTask();
    }

  }
  checkForBulkTags(emitedObject: any, type: string) {
    if (type === "Allocate_Bulk") {
      this.allocateBulkTask();
    }
  }
  updateProcessFlow(state: State, type: string, save: boolean = false, toogle: boolean = false) {
    if (toogle) {
      this.toggle(toogle);
    }
    if (this.documentsForState && this.documentsForState[state._id] && this.documentsForState[state._id].length > 0) {
      if (this.validateDocuments(state)) {
        this.assignedTaskActionButtonEnabled[state._id] = false;
        if (this.documentsToBeUploaded && this.documentsToBeUploaded.length > 0) {
          if (save)
            this.uploadDocumentForTask(state, this.TAB_ASSIGNED, true);
          else
            this.uploadDocumentForTask(state, this.TAB_ASSIGNED);
        }
        else {
          this.checkEntityAndSaveTask(state, save);
        }
      }
    }
    else {
      this.checkEntityAndSaveTask(state, save);
    }
  }

  updateDoc(event) {
    // console.log(event)
    this.checkEntityAndSaveTask(this.currentSelectedTaskDetails, false);
  }
  saveAssignedTask(state: State) {
    let documents: DocumentSubSet[] = [];
    this.showLoaderOverlay.mainScreen = true;
    if (this.documentsForState[state._id].length > 0) {
      for (let doc of this.documentsForState[state._id]) {
        let documentsSubset = new DocumentSubSet();
        documentsSubset.documentName = doc.documentName;
        documentsSubset.documentType = doc.documentType;
        documentsSubset.fileName = doc.fileName;
        documentsSubset.mandatory = doc.mandatory;
        documentsSubset.status = doc.status;
        documents.push(documentsSubset);
      }
    }
    this.stateService.save(state, documents, state._id)
      .subscribe(
        state => {
          if (state) {
            this.showLoaderOverlay.mainScreen = false;
          }
          this.assignedTaskDdetails = state;
          this.currentSelectedTaskDetails = state;
          this.getAssociatedEntityData();
          this.saveDocumentsForState(state);
        });
  }

  saveDocumentsForState(state: State) {
    if (!this.documentsForState || !this.documentsForState[state._id])
      return;
    for (let i = 0; i < this.documentsForState[state._id].length; i++) {
      this.stateService.saveDocument(this.documentsForState[state._id][i])
        .subscribe(
          document => {
            this.documentsForState[state._id][i] = document;
          },
          error => {
            console.log(error);
          }
        )
    }
  }


  removeUiFieldsFromState(state: State) {
    delete state["overdueString"]
    return state;
  }

  checkEntityAndSaveTask(state?: State, save?: boolean) {
    if (this.entityAssociatedWithTask && this.entityTabClicked) {
      const entity = new Entity();
      entity.fields = this.finalDataModel.fields;
      entity.label = this.finalDataModel.label;
      entity.name = this.finalDataModel.name;
      entity.version = this.finalDataModel.version;
      entity.process = this.finalDataModel.process;
      let map = {};
      this.entityMap = this.entitySharingService.getEntityMap(entity.fields);
      if (this.viewEntity != null) {
        this.entityMap["_id"] = this.viewEntity._id;
        this.entityMap["createdAt"] = this.viewEntity.createdAt;
        this.entityMap["createdBy"] = this.viewEntity.createdBy;
        this.entityMap["_entityName"] = this.viewEntity._entityName;
      }

      this.entityService.saveEntity(entity, this.entityMap)
        .subscribe(
          entity => {
            if (entity) {
              if (save) {
                this.saveAssignedTask(state)
              }
              else {
                this.updateAssignedTask(state);
              }
              // this.snackBar.open(entity._entityName.toString() + ' has been created successfully', "Undo", {
              //   duration: 5000
              // })
            }
          },
          error => {
            this.snackBar.open('Something went wrong while saving the associated entity, please try again in sometime', "Undo", {
              duration: 5000
            })
          }
        );

    }
    else if (!this.entityAssociatedWithTask || !this.entityTabClicked) {
      if (save) {
        this.saveAssignedTask(state);
      }
      else {
        this.updateAssignedTask(state);
      }
    }
  }


  updateAssignedTask(state: State) {

    this.showLoaderOverlay.mainScreen = true;

    this.stateService.update(state.machineType, state.entityId, state['parameters'], state.taskStatus, state.taskRemarks, this.documentsForState[state._id], state._id)
      .subscribe(
        response => {
          this.assignedTaskActionButtonEnabled[state._id] = true;
          if (response) {
           
            this.showLoaderOverlay.mainScreen = false;
            
            const errorState: State = response;
            this.responseError = "";
            this.responseErrorFields=[];
            if (errorState.errorMessageMap && Object.keys(errorState.errorMessageMap).length > 0) {
              
              for (const key in errorState.errorMessageMap) {
                if (key && errorState.errorMessageMap[key]) {
                  const errorList: string[] = errorState.errorMessageMap[key];
                  if (this.fieldKeyMap && this.fieldKeyMap[key]) {
                    this.responseError += `${this.fieldKeyMap[key]}<br>`;
                    
                    this.responseErrorFields.push(this.fieldKeyMap[key])
                  }
                  for (const error of errorList) {
                    if (error.indexOf("!!-#-!!") > -1) {
                      this.responseError += error.split("!!-#-!!")[1] + '<br>';
                      this.responseErrorFields.push(error.split("!!-#-!!")[0])
                    } else {
                      this.responseError += ` ${error}, <br>`;
                    }
                  } 
                  setTimeout(() => {
                    this.responseError = "";
                    this.responseErrorFields=[];
                  }, 3000);
                }
              }
              //state = errorState;
              this.progressBarFlag = false;
              return;
            }
            else {
              this.progressBarFlag = false;
              //new showModal('successModal');
              this.assignedTaskActionButtonEnabled[state._id] = true;
              this.removedAssignedTask(state);
            }

          }

          else {
            this.progressBarFlag = false;
            //new showModal('successModal');
            this.assignedTaskActionButtonEnabled[state._id] = true;
            this.removedAssignedTask(state);
          }
        },
        error => {
          this.progressBarFlag = false;
          this.showLoaderOverlay.mainScreen = false; 
          //new showModal('Error in updating process');
        }
      );
  }



  removedAssignedTask(state: State) {
    let index = this.assignedStates.indexOf(state);
    if (index != -1) {
      this.assignedStates.splice(index, 1);
      if (this.assignedStates.length > 0) {
        if (index != this.assignedStates.length) {
          const displayState = this.assignedStates[index];
          this.assignedStateTabclass[displayState._id] = this.TABLINKS_ACTIVE;
          this.assignedTaskDdetails = displayState;
        }
        else {
          const displayState = this.assignedStates[index - 1];
          this.assignedStateTabclass[displayState._id] = this.TABLINKS_ACTIVE;
          this.assignedTaskDdetails = displayState;
        }
      }
      else {
        this.assignedTaskDdetails = null;
      }
    }
    this.resetAll();
  }

  removedUnAssignedTask(state: State) {
    let index = this.unassignedStates.indexOf(state);
    if (index != -1) {
      this.unassignedStates.splice(index, 1);
      this.openSnackBar("success", "Task is Reserved Successfully", "Close")
      if (this.unassignedStates.length > 0) {
        if (index != this.unassignedStates.length) {
          const displayState = this.unassignedStates[index];
          this.unassignedStateTabclass[displayState._id] = this.TABLINKS_ACTIVE;
          this.unassignedTaskDdetails = displayState;
        }
        else {
          const displayState = this.unassignedStates[index - 1];
          this.unassignedStateTabclass[displayState._id] = this.TABLINKS_ACTIVE;
          this.unassignedTaskDdetails = displayState;
        }
      }
      else {
        this.unassignedTaskDdetails = null;
        this.loadMore('ACTIVE', 'Group')
      }

    }
    this.resetAll();
  }


  validateDocuments(stateInstance: State) {
    if (stateInstance && this.documentsForState && this.documentsForState[stateInstance._id]) {
      for (let doc of this.documentsForState[stateInstance._id]) {
        if (doc.mandatory) {
          if (!doc.userFileName) {
            // new showAlertModal('Error', doc.documentType + " is mandatory");
            return false;
          }
          if (!doc.status) {
            //new showAlertModal('Error', doc.documentType + " status is mandatory");
            return false;
          }
        }
      }
    }
    return true;
  }
  modifyEntity(currentTask) {
    this.communicationService.setAssocEntityDetails(currentTask);
    this.router.navigate(['/add-activities'], { queryParams: { edit: 'assoc' } });
  }
  checkforManualStateEntity() {
    if (this.currentSelectedTaskDetails != null && this.currentSelectedTaskDetails != undefined && this.currentSelectedTaskDetails.parameters != undefined) {
      if (this.currentSelectedTaskDetails.parameters['_associatedEntityId'] != null && this.currentSelectedTaskDetails.parameters['_associatedEntityId'] != undefined) {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      return false;
    }
  }

  archiveAssignedTask() {
    this.assignedTaskActionButtonEnabled[this.assignedTaskDdetails._id] = false;
    let archivedState: State = null;
    for (let state of this.tempAssignedStates) {
      if (state._id == this.assignedTaskDdetails._id) {
        archivedState = state
      }
    }
    if (archivedState != null) {
      this.stateService.saveArchivedState(archivedState)
        .subscribe(State => {
          if (State) {
            this.openSnackBar("success", "Task got Archived Successfully", "Close")
          }
          this.assignedTaskActionButtonEnabled[this.assignedTaskDdetails._id] = true;
          this.removedAssignedTask(this.assignedTaskDdetails);
          this.toggle(true);


          //new closeModal('archiveTaskWarningModal');
          //new showModal('assignedTaskArchiveSuccessModal');
        },
          error => {
            this.openSnackBar("error", "Task is not Archived, try again", "Close")
            this.assignedTaskActionButtonEnabled[this.assignedTaskDdetails._id] = true;

            //new showAlertModal('Error', "error to archived selected task");
          }
        );
    }
    else {
      //new showAlertModal("Error", "unable to archived selected task")
    }

  }


  onUserSelectAssignedTask(user) {
    this.allocatedAssignedTaskToUserId = user.userId;
  }

  onUserSelectUnAssignedTask(user) {

    this.allocatedUnAssignedTaskToUserId = user.userId;

  }


  allocateAssignedTask() {
    this.assignedTaskActionButtonEnabled[this.assignedTaskDdetails._id] = false
    // console.log(this.allocatedAssignedTaskToUserId)
    if (this.allocatedAssignedTaskToUserId != null && this.allocatedAssignedTaskToUserId.length > 0) {
      this.allocateTaskToUser.allocateTask(this.allocatedAssignedTaskToUserId, this.assignedTaskDdetails._id, "Allocate")
        .subscribe(any => {
          if (any) {
            this.openSnackBar("success", "Task got Allocated Successfully", "Close")
          }
          this.assignedTaskActionButtonEnabled[this.assignedTaskDdetails._id] = true;
          this.commonService.fetchNotifications(this.userId);
          this.removedAssignedTask(this.assignedTaskDdetails);
          this.toggle(true);
        },
          error => {
            this.openSnackBar("error", "Task is not allocated, try again", "Close")
            // new closeModal('assginedUserModal');
            //new showModal('error in allocate to team ');
          }
        )
    }
    else {
      // new closeModal('assginedUserModal');
      // new showModal('userNotSelected');
      this.assignedTaskActionButtonEnabled[this.assignedTaskDdetails._id] = true;
    }

  }
  // Bulk Allocate
  allocateBulkTask() {
    if (this.allocatedAssignedTaskToUserId != null && this.allocatedAssignedTaskToUserId.length > 0 && this.selectedBulkTasks.length > 0) {
      let payload = {
        "taskList": [],
        "type": "Allocate"
      };
      for (let i = 0; i < this.selectedBulkTasks.length; i++) {
        const element = this.selectedBulkTasks[i];
        let tmp = { stateId: element._id, userId: this.allocatedAssignedTaskToUserId }
        payload.taskList.push(tmp);
        let index = this.assignedStates.map(item => item._id).indexOf(element._id);
        if (index != -1) {
          this.assignedStates.splice(index, 1);
          // if (this.assignedStates.length > 0) {
          //   if (index != this.assignedStates.length) {
          //     const displayState = this.assignedStates[index];
          //     this.assignedStateTabclass[displayState._id] = this.TABLINKS_ACTIVE;
          //     this.assignedTaskDdetails = displayState;
          //   }
          //   else {
          //     const displayState = this.assignedStates[index - 1];
          //     this.assignedStateTabclass[displayState._id] = this.TABLINKS_ACTIVE;
          //     this.assignedTaskDdetails = displayState;
          //   }
          // }
          // else {
          //   this.assignedTaskDdetails = null;
          // }

        }
      }

      this.allocateTaskToUser.allocateBulkTask(payload)
        .subscribe(any => {
          if (any) {
            this.openSnackBar("success", "Task got Allocated Successfully", "Close");
          }
          this.assignedTaskActionButtonEnabled[this.assignedTaskDdetails._id] = true;
          this.commonService.fetchNotifications(this.userId);
          this.selectedBulkTasks = [];
          this.bulkEditTemplate = false;
          this.allocatedAssignedTaskToUserId = null;
          this.resetAll();

        },
          (error) => {
            this.openSnackBar("error", "Task is not allocated, try again", "Close")
            // new closeModal('assginedUserModal');
            //new showModal('error in allocate to team ');
            console.log(error)
          }
        )



    }
    else {
      alert("Please select at least one task and the user to allocate the tasks to");
    }
  }

  allocateUnAssignedTask() {
    this.unassignedTaskActionButtonEnabled[this.unassignedTaskDdetails._id] = false
    if (this.allocatedUnAssignedTaskToUserId != null && this.allocatedUnAssignedTaskToUserId.length > 0) {
      this.allocateTaskToUser.allocateTask(this.allocatedUnAssignedTaskToUserId, this.unassignedTaskDdetails._id, "Allocate")
        .subscribe(any => {
          if (any) {
            this.openSnackBar("success", "Task got Allocated Successfully", "Close")
          }
          this.unassignedTaskActionButtonEnabled[this.unassignedTaskDdetails._id] = true;
          this.commonService.fetchNotifications(this.userId);
          this.removedUnAssignedTask(this.unassignedTaskDdetails);
          this.toggle(true);
        },
          error => {
            this.openSnackBar("error", "Task is not allocated, try again", "Close");
            // new closeModal('unassginedUserModal');
            // new showModal('error in allocate to team ');
          }
        )
      this.removedUnAssignedTask(this.unassignedTaskDdetails);
    }
    else {
      // new closeModal('unassginedUserModal');
      // new showModal('userNotSelected');
      this.unassignedTaskActionButtonEnabled[this.unassignedTaskDdetails._id] = true;
    }

  }

  escalateAssignedTask() {
    this.assignedTaskActionButtonEnabled[this.assignedTaskDdetails._id] = false
    // console.log(this.allocatedAssignedTaskToUserId)
    if (this.allocatedAssignedTaskToUserId != null && this.allocatedAssignedTaskToUserId.length > 0) {
      this.allocateTaskToUser.allocateTask(this.allocatedAssignedTaskToUserId, this.assignedTaskDdetails._id, "Escalate")
        .subscribe(any => {
          this.assignedTaskActionButtonEnabled[this.assignedTaskDdetails._id] = true;
          this.removedAssignedTask(this.assignedTaskDdetails);
          this.commonService.fetchNotifications(this.userId);
          this.toggle(true);
        },
          error => {
            // new closeModal('assginedUserModal');
            //new showModal('error in allocate to team ');
          }
        )
    }
    else {
      // new closeModal('assginedUserModal');
      // new showModal('userNotSelected');
      this.assignedTaskActionButtonEnabled[this.assignedTaskDdetails._id] = true;
    }

  }

  getDocuments(stateInstane: State) {
    if (Object.keys(this.documentsForState).length > 0 && this.documentsForState[stateInstane._id]) {
      return this.documentsForState[stateInstane._id];
    }
    this.documentsForState[stateInstane._id] = [];
    this.stateService.getDocumentsForState(stateInstane)
      .subscribe(documents => {
        if (documents) {
          this.documentsForState[stateInstane._id] = documents;
          this.docLengthForState = documents.length;
        }
      },
        error => {

        }
      )
    // this.getTimeline(stateInstane);
  }

  //  --------------------- Document Section
  onNewDocumentAdd(stateInstanace: State) {
    const newDoc = new Document();
    newDoc.stateInstanceId = stateInstanace._id;
    newDoc.flowInstanceId = stateInstanace.stateMachineInstanceModelId;
    newDoc.documentType = "OTHER";
    newDoc.documentName = uuid();
    newDoc.status = "PENDING";
    if (!this.documentsForState[stateInstanace._id]) {
      this.documentsForState[stateInstanace._id] = [];
    }
    this.documentsForState[stateInstanace._id].push(newDoc);

  }
  onRemoveDocument(stateInstanace: State, document: Document) {
    this.deleteDocumentFromServer(stateInstanace, document);
  }
  removeDoc(document: Document) {
    this.onRemoveDocument(this.assignedTaskDdetails, document);
  }

  deleteDocumentFromServer(stateInstanace: State, document: Document) {
    this.fileService.delete(document.url)
      .subscribe(
        response => {
          this.deleteDocumentForState(stateInstanace, document);
        },
        error => {
          this.responseError = "Error while removing document. Please try again later";
        }
      )
  }

  deleteDocumentForState(stateInstanace: State, document: Document) {
    if (!document._id)
      return;
    this.stateService.deleteDocument(document)
      .subscribe(
        response => {
          if (stateInstanace && document && this.documentsForState && this.documentsForState[stateInstanace._id]) {
            let index = this.documentsForState[stateInstanace._id].indexOf(document)
            if (index > -1) {
              this.documentsForState[stateInstanace._id].splice(index, 1);
              this.saveAssignedTask(stateInstanace);
            }
          }
        },
        error => {
          this.responseError = "Error while removing document. Please try again later";
        }
      )
  }

  isUploadedDocumentValid(document: Document, fileType: string) {
    if (document && document.allowedFileTypes && document.allowedFileTypes.length > 0) {
      for (let allowfileType of document.allowedFileTypes) {
        if (fileType.match(allowfileType)) {
          return true;
        }
      }
      return false
    }
    return true;
  }
  uploadDoc(map: any) {
    let event: any = map.event;
    let doc: Document = map.document;
    this.onDocumentUploadForTask(event, doc, this.assignedTaskDdetails, true);
  }

  onDocumentUploadForTask(event, document: Document, state: State, save: boolean = false) {
    var documentName = document.documentName;
    if (document.documentName == null || document.documentName.trim().length == 0) {
      documentName = uuid();
      document.documentName = documentName;
    }
    const fileInputForm = new FormData();
    const file: File = event.target.files[0];
    if (!this.isUploadedDocumentValid(document, file.type)) {
      // new showAlertModal("Error", document.documentType + " invalid file type");
    }
    fileInputForm.append('file', file, file.name);
    var uploadFileName = uuid();
    if (file.name.split(".").length >= 2) {
      uploadFileName = uploadFileName + "." + file.name.split(".")[file.name.split(".").length - 1];
    }
    fileInputForm.append("fileName", uploadFileName);
    fileInputForm.append("functionInstanceName", "FLOW");
    fileInputForm.append("entityType", state.machineType);
    fileInputForm.append("entityRef", state._id);
    fileInputForm.append("documentName", documentName);
    fileInputForm.append("stateInstanceId", state._id);
    document.userFileName = file.name;
    document.fileName = uploadFileName;
    document.uploadTime = new Date();
    this.documentsToBeUploaded.push(fileInputForm);
    this.uploadDocumentForTask(state, "ASSIGNED", true);
  }
  uploadDocumentForTask(state: State, tabType: string, save: boolean = false) {
    let numberOfDocs = this.documentsToBeUploaded.length;

    for (let inputDoc of this.documentsToBeUploaded) {
      this.fileService.upload(inputDoc)
        .subscribe(
          response => {
            if (response && response["url"] && response["fileName"]) {
              for (let doc of this.documentsForState[state._id]) {
                if (doc.stateInstanceId == inputDoc.get("stateInstanceId") && doc.documentName == inputDoc.get("documentName")) {
                  doc.url = response["url"];
                  doc.fileName = inputDoc.get("fileName");
                  doc.downloadFileUrl = response["downloadFileUrl"];
                  doc.fullDataUrl = response["fullDataUrl"];
                  doc.fullFileUrl = response["fullFileUrl"];
                  this.docLengthForState++;
                }
              }
            }
            numberOfDocs--;
            if (numberOfDocs == 0) {
              if ((tabType == this.TAB_ASSIGNED || this.TAB_TEAM_ASSIGNED) && !save) {
                this.checkEntityAndSaveTask(state, save)
              }
              else if (save) {
                this.checkEntityAndSaveTask(state, save);
              }
            }
          }, (error) => {
            if (error && error.error && error.error.error && error.error.error.message) {
              this.snackBar.open(error.error.error.message, "Dismiss", {
                duration: 10000
              });
            }
          })
    }

  }

  //  --------------------- Document Section END
  getTimeline(state: State) {
    this.timelineStates = [];
    this.stateService.getTimelineForFlow(state.stateMachineInstanceModelId)
      .subscribe(timelineStates => {
        if (timelineStates) {
          this.timelineStates = timelineStates;
        }
      });
  }

  timelineSelect(timeLineState: TimelineStateAuditData) {
    this.selectedTimeLineState = timeLineState;
  }

  changeSelection(TAB_VALUE: string) {

    this.selectedTab = TAB_VALUE;
    if (TAB_VALUE === this.TAB_UNASSIGNED) {
      this.fetchRecordsFor(this.TAB_UNASSIGNED, this.unassignedStates)
    }
    else if (TAB_VALUE === this.TAB_ASSIGNED) {
      this.fetchRecordsFor(this.TAB_ASSIGNED, this.assignedStates)
    }
    else if (TAB_VALUE === this.TAB_TEAM_ASSIGNED) {
      this.fetchRecordsForTeam(this.TAB_ASSIGNED, this.assignedStatesTeam)
    }
    else if (TAB_VALUE === this.TAB_ARCHIVED) {
      this.fetchRecordsFor(this.TAB_ARCHIVED, this.archivedStates)
    }
    this.showFilter = false;
  }

  getStatusList(selectedTask: State) {
    if (this.graphObjects.get(selectedTask.stateMachineInstanceModelId) != null && this.graphObjects.get(selectedTask.stateMachineInstanceModelId).states) {
      for (let state of this.graphObjects.get(selectedTask.stateMachineInstanceModelId).states) {
        if (state.stateCd && selectedTask.stateCd && state.stateCd == selectedTask.stateCd && state.statusList) {
          return state.statusList;
        }
      }
    }
    return [];
  }

  getBusinessKeysWithTable(selectedTask: State) {
    //console.log(this.assignedTaskDdetails);
    let arrayDataPoints: DataPoint[];
    arrayDataPoints = [];
    if (this.graphObjects.get(selectedTask.stateMachineInstanceModelId) != null) {
      const dataPointsConfig = this.graphObjects.get(selectedTask.stateMachineInstanceModelId).dataPointConfigurationList;
      for (let data of dataPointsConfig) {
        if (data != null && !data.businessKeyFlag) {
          if (data.dataType == "ARRAY" && selectedTask['parameters'] != null && selectedTask['parameters'][data.dataPointName] != null && selectedTask['parameters'][data.dataPointName].length > 0 && typeof selectedTask['parameters'][data.dataPointName] != "string") {
            arrayDataPoints.push(data)
          }
          else {

          }

        }
      }
    }
    //console.log("getBusinessKeysWithTable");
    //console.log(selectedTask);
    return arrayDataPoints;
  }

  submitSearch(event) {
    // if (event == 'Enter') {
    this.showFilter = false; this.filterQuery = this.searchTerm;
    // }
  }
  getValueForArraydatatype(dataPoint: DataPoint, state: State) {
    let values = []
    if (state['parameters'][dataPoint.dataPointName] != null) {
      for (let d of state['parameters'][dataPoint.dataPointName]) {
        // console.log("d");
        // console.log(d);
        let headerValue = []
        for (let dp of this.getHeaderDataPointsForArrayDataType(dataPoint, state)) {
          headerValue.push(d[dp.dataPointName])
        }
        values.push(headerValue);
      }
    }
    return values;
  }

  getHeaderDataPointsForArrayDataType(dataPoint: DataPoint, state: State) {
    this.arrayTableHeaders = {};
    let headers = [];
    if (dataPoint.childdataPoints != null && dataPoint.childdataPoints.length > 0) {
      for (let childdata of dataPoint.childdataPoints) {
        this.arrayTableHeaders[childdata.dataPointName] = [];
        headers.push(childdata);
      }
    }
    else {
      if (state['parameters'] != null && state['parameters'][dataPoint.dataPointName] != null && state['parameters'][dataPoint.dataPointName].length > 0) {
        for (let key of state['parameters'][dataPoint.dataPointName]) {
          //console.log(key);
          this.arrayTableHeaders[key] = [];
          const newDataPoint = new DataPoint();
          newDataPoint.dataPointName = key;
          newDataPoint.dataPointLabel = key
          headers.push(newDataPoint);
        }
      }
    }
    //console.log(headers)
    return headers;

  }

  getMultiSelectAndArrayValue(value) {
    if (!value) {
      return null;
    }
    if (value instanceof Array) {
      return value.join(", ")
    }
    return value;
  }

  //Team view ---------------
  getUserChildren(): void {
    this.fetchUserService.getUserChildren()
      .subscribe(userChildren => {
        if (userChildren && userChildren.length > 0) {
          this.userChildren = userChildren;
          let index: any;
          for (index in this.options) {
            if (this.options[index].value === "ARCHIVED") {
              this.options.splice(index, 1);
            }
          }
          this.options.push({ display: 'Team', value: 'ASSIGNED_TEAM', checked:false })
          this.options.push({ display: 'Archived', value: 'ARCHIVED', checked:false })
          //this.fetchRecordsFor(this.TAB_ASSIGNED, this.assignedStates);
        }
        else {
          this.progressBarFlag = false;
        }
      });
  }


  fetchRecordsForTeam(tabName: string, existingRecords: State[]) {
    this.records = true;
    let status = null;
    this.message_type = "Assigned_Team";
    if (!tabName || tabName == null || tabName.trim().length == 0) {
      return;
    } else if (tabName === this.TAB_ASSIGNED) {
      if (this.personalTeamFetched) {
        return;
      } else {
        this.personalTeamFetched = true;
        status = 'ACTIVE';
      }
    } else if (tabName === this.TAB_UNASSIGNED) {
      if (this.groupFetched) {
        return;
      } else {
        this.groupFetched = true;
        status = 'ACTIVE';
      }

    } else {
      return;
    }

    if (existingRecords == null) {
      existingRecords = [];
    }

    this.progressBarFlag = true;
    this.showLoaderOverlay.taskListing = true;
    this.taskService.getStatesBySubStatusAndFolder(tabName, status, this.assignedTaskPageNumberTeam, this.fetchRecords, "TEAM")
      .subscribe(states => {
        if (states) {
          this.showLoaderOverlay.taskListing = false;
        }
        for (let state of states) {
          state = this.setTimeDataFields(state);
          existingRecords.push(state);
        }

        if (tabName === this.TAB_ASSIGNED) {
          this.setFirstAssignedTeamTaskValues(existingRecords);
          this.tempAssignedStatesTeam = JSON.parse(JSON.stringify(existingRecords));
        }

        this.progressBarFlag = false;
      }, error => {
        this.progressBarFlag = false;
        this.showLoaderOverlay.taskListing = false;
      });
  }



  setFirstAssignedTeamTaskValues(states: any) {
    if (states != null && states.length > 0) {
      this.assignedStateTabclassTeam[states[0]._id] = "block active";
      let co = this.getDataPointConfigForGraph();
      this.stateService.getDataPointconfiguration(co, states[0].stateMachineInstanceModelId)
        .subscribe(
          response => {
            if (response) {
              this.showLoaderOverlay.mainScreen = false;
            }
            this.graphObject = response;
            this.graphObjects.set(states[0].stateMachineInstanceModelId, this.getSortedDatPointGraphObject(response));
            this.assignedTaskDetailsTeam = states[0];
            this.currentSelectedTaskDetails = this.assignedTaskDetailsTeam;
            this.decideStagesForState(this.assignedTaskDetailsTeam);
            this.statusList = this.getStatusList(this.assignedTaskDetailsTeam);
            this.getDocuments(this.assignedTaskDetailsTeam);
            this.loadingAssigned = false;
            if (!this.loadingUnassigned && !this.loadingAssigned) {
              this.progressBarFlag = false;
              // this.baThemeSpinner.hide();
            }
            this.assignedTaskActionButtonEnabledTeam[this.assignedTaskDetailsTeam._id] = true;
            this.assignedStategraphObjectTeam = response;
            /* for (let state of states) {
              if (this.assignedTaskDetailsTeam != state) {
                this.assignedStateTabclassTeam[state._id] = "block";
                this.setGraphObjects(state);
                this.assignedTaskActionButtonEnabledTeam[state._id] = true;
              }
            } */
          },
          error => {
            this.loadingAssigned = false;
            this.progressBarFlag = false;
            this.showLoaderOverlay.mainScreen = false;
            // console.log("graph object not found");
          }
        )
    } else {
      this.records = false;
      this.progressBarFlag = false;
    }
  }

  onPersonalAssignedTeamSubjectSelect(state: State) {
    this.assignedTaskDetailsTeam = state;
    let co = this.getDataPointConfigForGraph();
    this.stateService.getDataPointconfiguration(co, state.stateMachineInstanceModelId)
      .subscribe(
        response => {
          this.graphObject = response;
          this.graphObjects.set(state.stateMachineInstanceModelId, this.getSortedDatPointGraphObject(response));

          this.showLoaderOverlay.mainScreen = false;
        });
    this.currentSelectedTaskDetails = this.assignedTaskDetailsTeam;
    this.selectedProcessStages = this.assignedTaskDetailsTeam.processStageCdList;
    this.decideStagesForState(this.assignedTaskDetailsTeam);
    this.getDocuments(this.assignedTaskDetailsTeam);
    for (let asgnState of this.assignedStatesTeam) {
      this.assignedStateTabclassTeam[asgnState._id] = this.TABLINKS;
    }
    this.assignedStateTabclassTeam[state._id] = this.TABLINKS_ACTIVE;
    this.assignedStategraphObjectTeam = this.graphObjects.get(state.stateMachineInstanceModelId);
    this.graphObject = this.assignedStategraphObjectTeam;
  }



  // -------------------- tags implementation ----------- //

  getTaskTags(machineType: string) {
    this.taskTags = [];
    this.tagService.getTags(machineType)
      .subscribe(taskTag => {
        this.taskTagsObject = taskTag;
        if (taskTag) {
          for (let key of Object.keys(this.taskTagsObject.tags)) {
            this.taskTags.push(key);
          }
        }
        else {
          this.showTagFilter = false;
        }
      }, error => {

      });
  }

  emitState(state: State) {
    this.assignedTaskDdetails = state;
  }

  saveTaskTags(tags: any[]) {
    this.assignedTaskDdetails.taskTags = tags;
    this.tagService.saveTaskTags(tags, this.assignedTaskDdetails.machineType)
      .subscribe(taskTag => {
        if (taskTag != null) {
          // console.log(taskTag);
        }
      }, error => {

      });
  }


  // filter //

  fetchGraphs(): void {
    let commonsearchModel = new CommonSearchModel();
    commonsearchModel.searchParams = [{ "statusCd": "ACTIVE" }];
    commonsearchModel.returnFields = ["machineType", "machineLabel", "masterStages"];
    this.graphService.fetch(commonsearchModel)
      .subscribe(graphObjects => {
        // console.log(graphObjects);
        this.activeGraphObjectList = graphObjects;
        if (this.activeGraphObjectList) {
          let graphs: string[] = []
          for (let graph of this.activeGraphObjectList) {
            graphs.push(graph.machineType);
            this.processOptions.push({ display: graph.machineLabel, value: graph.machineType })
            let stageMap = {};
            if (graph.masterStages && graph.masterStages.length > 0) {
              const graphStages: StateStage[] = graph.masterStages;
              graphStages.sort(function (a: StateStage, b: StateStage) {
                return a.order > b.order ? 1 : a.order ? -1 : 0
              });
              let stages = [];
              for (let masterStage of graphStages) {
                stages.push(masterStage.stage);
              }
              if (!this.machineIds[graph.machineType]) {
                this.machineIds[graph.machineType] = graph._id;
              }
              if (!this.allProcessStages[graph.machineType]) {
                this.allProcessStages[graph.machineType] = stages;
              }
            }
          }
          let entityId = null;
          this.route.queryParams.subscribe(params => {
            entityId = params["entityId"];
            this.customerId = params["customerId"];
          })
      

          if (entityId != null) {
            let stateId = entityId;
            if (stateId) {
              this.viewSpecificState = true;
              this.getStateById(stateId);
            }
          }
          else {
            this.fetchRecordsFor(this.TAB_ASSIGNED, this.assignedStates);
            // console.log("empty")
          }
        }
      });
  }


  //filter implementation
  filter() {

    if (this.showFilter == false) {
      this.showFilter = true;
    }
    else if (this.showFilter == true) {
      this.taskFilter = new TaskFilter();
      if (this.selectedTab == this.TAB_ASSIGNED) {
        this.personalFetched = false;
        this.assignedStates = [];
        this.fetchRecordsFor(this.selectedTab, this.assignedStates);
      }
      else if (this.selectedTab == this.TAB_UNASSIGNED) {
        this.groupFetched = false;
        this.unassignedStates = [];
        this.fetchRecordsFor(this.selectedTab, this.unassignedStates);
      }
      else if (this.selectedTab == this.TAB_ARCHIVED) {
        this.archiveFetched = false;
        this.archivedStates = [];
        this.fetchRecordsFor(this.selectedTab, this.archivedStates);
      }
      else if (this.selectedTab == this.TAB_TEAM_ASSIGNED) {
        this.personalTeamFetched = false;
        this.tempAssignedStates = [];
        this.fetchRecordsForTeam(this.TAB_ASSIGNED, this.assignedStatesTeam);
      }
      this.showTagFilter = false;
      this.showFilter = false;
    }
  }
  expandForBulk() {
    this.bulkEditTemplate = !this.bulkEditTemplate;
  }
  changeProcessSelection(process: string) {
    this.showTagFilter = true;
    this.getTaskTags(process);
    //remove for multi select
    this.taskFilter.processes = [];

    this.taskFilter.processes.push(process);
    if (this.selectedTab == this.TAB_ASSIGNED || this.selectedTab == this.TAB_UNASSIGNED || this.selectedTab == this.TAB_ARCHIVED) {
      this.fetchRecordsForFilter([]);
    }
    else if (this.selectedTab.trim() == this.TAB_TEAM_ASSIGNED.trim()) {
      this.fetchRecordsForTeamFilter(this.TAB_ASSIGNED, []);
    }
    this.showFilter = false
  }

  addGroupsToFilter() {
    for (let group of this.groupFilter) {
      if (!this.taskFilter.groups.includes(group.value)) {
        this.taskFilter.groups.push(group.value);
      }
    }
    if (this.selectedTab == this.TAB_ASSIGNED || this.selectedTab == this.TAB_UNASSIGNED || this.selectedTab == this.TAB_ARCHIVED) {
      this.fetchRecordsForFilter([])
    }
    else if (this.selectedTab == this.TAB_TEAM_ASSIGNED) {
      this.fetchRecordsForTeamFilter(this.TAB_ASSIGNED, [])
    }
    this.showFilter = false
  }

  addTagsToFilter() {
    for (let tag of this.tagFilter) {
      if (!this.taskFilter.tags.includes(tag.value)) {
        this.taskFilter.tags.push(tag.value);
      }
    }
    if (this.selectedTab == this.TAB_ASSIGNED || this.selectedTab == this.TAB_UNASSIGNED || this.selectedTab == this.TAB_ARCHIVED) {
      this.fetchRecordsForFilter([])
    }
    else if (this.selectedTab == this.TAB_TEAM_ASSIGNED) {
      this.fetchRecordsForTeamFilter(this.TAB_ASSIGNED, [])
    }
    this.showFilter = false
  }

  onSelectAllProcess(event) {

  }
  hideAfterAt(str) {
    return str.split("@")[0]
  }

  fetchRecordsForFilter(existingRecords) {
    this.records = true;
    let status = null;
    let folder = null;
    let subStatus = null;

    if (!this.selectedTab || this.selectedTab == null || this.selectedTab.trim().length == 0) {
      return;
    } else if (this.selectedTab === this.TAB_ASSIGNED) {
      this.currentFolderTaskCount = this.assignedCount;
      this.message_type = "Assigned";
      status = 'ACTIVE';
      folder = 'Personal';
      this.assignedTaskDdetails = new State();
      this.assignedStates = []

    } else if (this.selectedTab === this.TAB_UNASSIGNED) {
      this.currentFolderTaskCount = this.unassignedCount;
      this.message_type = "Unassigned";
      status = 'ACTIVE';
      folder = 'Group';
      this.unassignedTaskDdetails = new State();
      this.unassignedStates = []

    } else if (this.selectedTab === this.TAB_ARCHIVED) {
      this.currentFolderTaskCount = this.unassignedCount;
      this.message_type = "Archived";
      status = 'CLOSED';
      folder = 'Personal';
      this.archivedTaskDdetails = new State();
      this.archivedStates = []

    }

    else {
      return;
    }

    if (existingRecords == null) {
      existingRecords = [];
    }
    if (this.selectedTab == this.TAB_ASSIGNED || this.selectedTab == this.TAB_UNASSIGNED || this.selectedTab == this.TAB_ARCHIVED) {
      this.showLoaderOverlay.taskListing = true;
      this.taskService.getStatesByStatusAndFolderAndFilter(status, folder, 0, this.fetchRecords, this.taskFilter)
        .subscribe(states => {
          if (states) {
            this.showLoaderOverlay.taskListing = false;
          }
          for (let state of states) {
            state = this.setTimeDataFields(state);
            existingRecords.push(state);
          }

          if (this.selectedTab === this.TAB_ASSIGNED) {
            this.setFirstAssignedTaskValues(existingRecords);
            this.tempAssignedStates = JSON.parse(JSON.stringify(existingRecords));
            this.assignedStates = this.tempAssignedStates;
          } else if (this.selectedTab === this.TAB_UNASSIGNED) {
            this.setFirstUnAssignedTaskValues(existingRecords);
            let tempUnassignedStates = JSON.parse(JSON.stringify(existingRecords));
            this.unassignedStates = tempUnassignedStates;
          } else if (this.selectedTab === this.TAB_ARCHIVED) {
            this.setFirstArchivedTaskValues(existingRecords);
            this.tempArchivedStates = JSON.parse(JSON.stringify(existingRecords));
            this.archivedStates = this.tempArchivedStates;
          }


          // this.progressBarFlag = false;
        }, error => {
          this.progressBarFlag = false;
          this.showLoaderOverlay.taskListing = false;
        });
    }

  }



  fetchRecordsForTeamFilter(tabName: string, existingRecords: State[]) {
    this.records = true;
    let status = null;
    if (!tabName || tabName == null || tabName.trim().length == 0) {
      return;
    } else if (tabName === this.TAB_ASSIGNED) {
      this.message_type = "Assigned_Team";
      status = 'ACTIVE';
      this.assignedTaskDetailsTeam = new State();
      this.assignedStatesTeam = [];
    } else {
      return;
    }

    if (existingRecords == null) {
      existingRecords = [];
    }

    this.progressBarFlag = true;
    this.showLoaderOverlay.taskListing = true;
    this.taskService.getStatesBySubStatusAndFolderFilter(tabName, status, this.assignedTaskPageNumberTeam, this.fetchRecords, "TEAM", this.taskFilter)
      .subscribe(states => {
        if (states) {
          this.showLoaderOverlay.taskListing = false;
        }
        for (let state of states) {
          state = this.setTimeDataFields(state);
          existingRecords.push(state);
        }
        if (tabName === this.TAB_ASSIGNED) {
          this.setFirstAssignedTeamTaskValues(existingRecords);
          this.tempAssignedStatesTeam = JSON.parse(JSON.stringify(existingRecords));
          this.assignedStatesTeam = this.tempAssignedStatesTeam;
        }

        this.progressBarFlag = false;
      }, error => {
        this.progressBarFlag = false;
        this.showLoaderOverlay.taskListing = false;
      });
  }

  noRecordsFound() {
    if (this.records == false) {
      this.recordsMessage = "No Records Found";
    }
    return this.recordsMessage;
  }

  openSnackBar(type, message, action) {
    let config: MatSnackBarConfig = new MatSnackBarConfig();
    config.verticalPosition = 'bottom';
    config.horizontalPosition = 'center';
    config.duration = 6500;
    if (type === 'error') {
      config.panelClass = ['errorMsg']
    }
    else {
      config.panelClass = ['success']
    }
    this.snackBar.open(message, action, config)
  }
}