import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Application } from '../models/application.model';
import { commonKeys } from '../models/constants';
import { User } from '../models/user.model';
import { ApplicationService } from '../service/application.service';
import { AuthService } from '../service/auth.service';
import { UniversalUser, WhiteLabel } from '../service/shared.service';
import { PreferenceService } from '../service/user.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import { EnvironmentData } from '../service/environments.service';
import { NgxCaptchaService } from '@binssoft/ngx-captcha';
import * as moment from 'moment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  mySlideOptions: OwlOptions = {
    items: 1,
    dots: true,
    nav: false,
    loop: true,
    autoplay: true,
    autoplayHoverPause: true,
    navText: ['<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>'],

  };

  mySlideImages = [];
  loginForm: FormGroup;
  forgotPassForm: FormGroup;
  isSubmitted = false;
  showLogin: boolean = true;
  userName: AbstractControl;
  passWord: AbstractControl;
  user: User = new User();
  returnUrl: string;
  oauthClients: any = [];
  redirect_uri_oauth: string;
  token: string;
  setAutoHide: boolean = true;
  autoHide: number = 2000;
  logoUrl: string;
  isWhiteLabel: boolean=false;
  showPassword = false
  pwdType = 'password'
  imageType = "assets/images/passwordEye.svg";
  @ViewChild('loginLoader',{static:true}) loginLoader: ElementRef;
  inlineCheckbox1:any;
  bsModalRef:BsModalRef
  commpanyIdentifier:string = '';
  // Capthcha config
  captchaStatus:boolean = false;
  captchaConfig:any = {
    type: 1, // 1 or 2 or 3 or 4
    length:5,
    cssClass:'captchacustom',
    back: {
      stroke:"#2F9688",
      solid:"#f2efd2"
    } ,
    font:{
      color:"#000000",
      size:"30px",
      weight:"bold"
    }
  };
  
  constructor(private router: Router,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private universalUser: UniversalUser,
    private prefService: PreferenceService,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private whiteLabel: WhiteLabel,
    private applicationService: ApplicationService,
    private modalService: BsModalService,
    public sanitizer: DomSanitizer,
    private environmentData: EnvironmentData,
    private captchaService:NgxCaptchaService
  ) {
    this.redirect_uri_oauth = window.location.origin + "/login";
    this.captchaStatus = false;
  }
  ngOnDestroy(): void {
    this.captchaService.setCaptchaStatus(null)
  }

  ngOnInit() {
    this.universalUser.setTimeZone();
    this.getOauthClients();
    this.route.queryParamMap.subscribe(params => {
      if (params.get('token')) {
        this.token = params.get('token');
      }
    });
    if (this.token) {
      this.OauthLogin();
    }

    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
      // termsAndConditions: ['',Validators.required]
    });
    this.forgotPassForm = this.formBuilder.group({
      resetEmail: ['', Validators.required],
    });
    setTimeout(() => {
      this.loginLoader.nativeElement.classList.add('displayNone');
    }, 3000);
    this.captchaService.captchStatus.subscribe((status)=>{
      this.captchaStatus= status;
      if(status == null){
        this.captchaStatus= false;
      }
      if (status == false) {
        this.openSnackBar("Please enter the correct Captcha code and click on Check to verify");
      } else  if (status == true) {
        // alert("Captcha Verified");
      }
    });
  }

  get formControls() { return this.loginForm.controls; }
  get forgotFormControls() { return this.forgotPassForm.controls; }

  login() {

    this.isSubmitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    this.user.username = this.loginForm.value['email'];
    this.user.password = this.loginForm.value['password'];
    if(this.captchaStatus == false){
      this.openSnackBar("Please enter the correct Captcha code and click on Check to verify");
    }else{
      this.authenticate();
    }
  }
  toggleShow() {

    this.showPassword = !this.showPassword
    this.pwdType = this.showPassword? 'text' : 'password'
    this.imageType = this.showPassword ? 'assets/images/eyeSlash.svg':'assets/images/passwordEye.svg'
    

 }//toggleShow
  forgotPass() {

    this.isSubmitted = true;
    if (this.forgotPassForm.invalid) {
      return;
    }
    this.user.username = this.forgotPassForm.value['resetEmail'];
    this.resetPass();
  }

  authenticate() {
    this.authService.authenticate(this.user).subscribe(
      (user: User) => {
        this.user = user;
        this.prefService.getUserPref().subscribe(
          (response) => {
            let sortedByDateRes = response.sort(function (a, b) {
              return b.lastUpdatedAtLong - a.lastUpdatedAtLong;
            });
            let uniqueResult = sortedByDateRes.filter(function (a) {
              var key = a.screen + '|' + a.identifier;
              if (!this[key]) {
                this[key] = true;
                return true;
              }
            }, Object.create(null));
            this.universalUser.setUserPref(uniqueResult)
          },
          (error) => { }
        );
        this.universalUser.setUser(user, true);
        this.openSnackBar("You are successfully logged In");
        if (this.user.changePwdOnLogin) {
          this.router.navigate(["/profile"], { queryParams: { resetPass: 'true' } });
          return;
        }
        this.getSubscribedApplications();
        this.getCompanyAccountInfo();
      },
      error => {
        if (error && error.error) {
          this.openSnackBar(error.error.message);
        }
      });
  }
  getCompanyAccountInfo(){
    this.authService.getCompanyAccount(this.user.companyId).subscribe(
      response=>{
        this.universalUser.setcompanyAccount(response)
      },
      error=>{}
    );
  }
  getSubscribedApplications() {
    this.applicationService.getSelectedApplication().subscribe(
      (selectedApplication: Application) => {
        if (selectedApplication) {
          const navigateToPiForm = !(selectedApplication.applicationWizardDetails &&
            selectedApplication.applicationWizardDetails.wizardCompleted);

          if (navigateToPiForm && this.universalUser.displayPiForms()) {
            selectedApplication.alreadyAccessed = true;
            this.applicationService.saveApplication(selectedApplication);
            this.returnUrl = "/pi-forms";
          } else if (this.route.snapshot.queryParams[commonKeys.returnUrl]) {
            this.returnUrl = this.route.snapshot.queryParams[commonKeys.returnUrl];
          } else {
            this.returnUrl = "/custom-dashboard";
          }
        } else {
          if (this.route.snapshot.queryParams[commonKeys.returnUrl]) {
            this.returnUrl = this.route.snapshot.queryParams[commonKeys.returnUrl];
          } else if (this.universalUser.displayBusinessDashboard()) {
            this.returnUrl = "/businessoutlook";
          } else {
            this.returnUrl = "/dashboard";
          }
        }

        this.router.navigate([this.returnUrl]);
      },
      (error) => {
        if (this.route.snapshot.queryParams[commonKeys.returnUrl]) {
          this.returnUrl = this.route.snapshot.queryParams[commonKeys.returnUrl];
        } else if (this.universalUser.displayBusinessDashboard()) {
          this.returnUrl = "/businessoutlook";
        } else {
          this.returnUrl = "/dashboard";
        }

        this.router.navigate([this.returnUrl]);
      }
    );
  }

  resetPass() {
    this.authService.forgotPassword(this.user)
      .subscribe(
        user => {
          this.openSnackBar("New password has been mailed to your registerd email id.");
          this.showLogin = true
        },
        error => {
          if (error.error.message)
            this.openSnackBar(error.error.message, "errorMsg");
          else
            this.openSnackBar("Something went wrong. Please tray again later", "errorMsg");
        }
      );
  }

  

  //Oauth

  getOauthClients() {
    let arr = window.location.hostname.split(".");
    let host = window.location.host;
    if (arr.length > 0) {
      let whiteLabelHostName = host;
      this.commpanyIdentifier = arr[0];
      // this.commpanyIdentifier = 'teamc';
     /*  "ultragas", "ultragas.piflow.automatapi.xyz" */
     if(this.commpanyIdentifier.indexOf(this.environmentData.getEnvData().subdomain) == -1  ){
        // this.authService.getClients("teamc", "teamc.piflow-dev.automatapi.com").subscribe(client => {
        this.authService.getClients(this.commpanyIdentifier,whiteLabelHostName).subscribe(client => {
          if (client) {
            // return;
            this.whiteLabel.setWhiteLabel(this.isWhiteLabel);
            // For theming
            if(client.whiteLabel){
              this.authService.getLogoByDomain(this.commpanyIdentifier).subscribe(
                (response)=>{
                  this.whiteLabel.setLogoUrl(response)
                },
                (error)=>{}
              );
              let slideArr:any[] = [];
              for (let index = 1; index < 2; index++) {
                this.authService.getSlideByDomain(this.commpanyIdentifier,index).subscribe(
                  (response)=>{
                    if(index == 1 && response){
                      this.mySlideImages = [];
                    }
                    let tmpUrl = URL.createObjectURL(response);
                    slideArr.push(tmpUrl);
                    this.mySlideImages.push(
                      { image: this.sanitizer.bypassSecurityTrustResourceUrl(tmpUrl), htmlContent: "" }
                    )
                  },
                  (error)=>{
                    // console.log(error)
                  }
                ); 
              }
              
              if(client["whiteLabel"]['uiProperties'] && client["whiteLabel"]['uiProperties']['theme']){
                let cssFile = "./assets/theme/" + client["whiteLabel"]['uiProperties']['theme'] + '.css';
                this.loadcssfile(cssFile);
              }
              // if(client["whiteLabel"]['uiProperties'] && client["whiteLabel"]['uiProperties']['companyLogo']){
              //   // this.addFont(this.companyDetails['uiProperties']['companyLogo']);
              // }
              if(client["whiteLabel"]['uiProperties'] && client["whiteLabel"]['uiProperties']['fontFamily']){
                this.addFont(client["whiteLabel"]['uiProperties']['fontFamily']);
              }
              this.whiteLabel.setTheme(client);
            }
            if (client['whiteLabel'] && client['whiteLabel'] && client['whiteLabel']["companyName"]) {
              // this.logoUrl = client["whiteLabel"]["logoUrl"];
              this.isWhiteLabel = true;
              // this.whiteLabel.setLogoUrl(this.logoUrl);
              this.whiteLabel.setWhiteLabel(this.isWhiteLabel);
              // delete client["whiteLabel"];
            }
            for (const key in client) {
              if (Object.prototype.hasOwnProperty.call(client, key)) {
                const element = client[key];
                if(key != 'whiteLabel'){
                  let map = {};
                  map["name"] = key;
                  map["value"] = element;
                  this.oauthClients.push(map);
                }
              }
            }
          }
        });
      }else{
        this.mySlideImages = [
          { image: this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/AutomatapiLogin.svg'), htmlContent: "<p> Automated Case Management and Intuitive Workboards </p>'" },
          { image: this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/CXO.svg'), htmlContent: "<p> Realtime CXO Dashboard and Customer One view </p>'" },
         
         ]
         this.whiteLabel.setLogoUrl('https://www.automatapi.com/assets/images/logo.svg');
        //  this.addFont('Outfit');
         this.loadcssfile("./assets/theme/none.css")
      }
    }
  }

  getLogoUrl() {
      if(this.whiteLabel.getLogoUrl()){
        // console.log(this.whiteLabel.getLogoUrl())
        return this.sanitizer.bypassSecurityTrustUrl(this.whiteLabel.getLogoUrl());
      }else{
        return(this.sanitizer.bypassSecurityTrustResourceUrl('https://www.automatapi.com/assets/images/logo.svg'))
      }
  }
  getSlideImages() {
    return this.mySlideImages;
   
  }

  OauthLogin() {
    this.authService.authenticateOauth(this.token)
      .subscribe(
        user => {
          this.universalUser.setXAuthToken(this.token);
          this.user = user;
          this.universalUser.setUser(user, true);          
          window.history.replaceState(null, null, window.location.origin);
          if (this.route.snapshot.queryParams[commonKeys.returnUrl]) {
            this.returnUrl = this.route.snapshot.queryParams[commonKeys.returnUrl];
          }
          else if (this.universalUser.displayBusinessDashboard()) {
            this.returnUrl = "/businessoutlook";
          }
          else {
            this.returnUrl = "/dashboard";
          }
          this.router.navigate([this.returnUrl]);
        },
        error => {

        }
      );
  }
  
  openSnackBar(message, panelClass?: string) {
    let config: MatSnackBarConfig = new MatSnackBarConfig();
    config.verticalPosition = 'top';
    config.horizontalPosition = 'center';
    config.duration = this.setAutoHide ? this.autoHide : 0;
    if (!panelClass || panelClass == null) {
      if (message === 'Username is invalid' || message === 'Bad credentials') {
        config.panelClass = ['errorMsg']
      }
      else {

        config.panelClass = ['success']
      }
    }
    else {
      config.panelClass = [panelClass];
    }

    this.snackBar.open(message, "", config);
  }
  loadcssfile(filename) {
    var fileref = document.createElement("link")
    fileref.setAttribute("rel", "stylesheet")
    fileref.setAttribute("type", "text/css")
    fileref.setAttribute("href", filename);
    fileref.setAttribute("id", "loadedCssFile");
    document.getElementsByTagName("head")[0].appendChild(fileref)
  }
  addCss:boolean = false;
  addFont(fontName){
    var fileref = document.createElement("link")
    fileref.setAttribute("rel", "stylesheet")
    fileref.setAttribute("href", "https://fonts.googleapis.com/css2?family=" + fontName + ":wght@400;700&display=swap")
    document.getElementsByTagName("head")[0].appendChild(fileref);
    this.addCss = true;
    let text = `body{font-family:'` + fontName.replace("+", " ") +`',sans-serif !important}`;
    var styleref = document.createElement("style");
    let divName = ("id" + (new Date().getTime()));
    styleref.setAttribute("id", divName);
    document.getElementsByTagName("head")[0].appendChild(styleref);
    document.getElementById(divName).innerHTML = (text);
  }
  openModal(divName) {
    this.bsModalRef = this.modalService.show(divName)
  }

  closeModal() {
    this.bsModalRef.hide()
  }
}
