import { Component, OnInit,Input,Output,EventEmitter } from '@angular/core';
import { State } from '../../models/tasks.model'


@Component({
  selector: 'app-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.css']
})
export class TagComponent implements OnInit {

  @Input() tags:any[];
  @Input() totalTags:any[];
  @Output() closeEvent = new EventEmitter();
  @Output() flagEvent = new EventEmitter();
  @Output() flagUpdateEvent = new EventEmitter();


  taskTags:any[]=[];
  tagsArr:string[] = [];

  constructor() { }

  ngOnInit() {
    if(this.tags!=null && this.tags.length>0)
    {
      this.tagsArr = this.tags;
      this.taskTags = this.tags;
    }
    else
    {
      this.taskTags = [];
      this.tagsArr = [];
    }
   
  }

  tagTask(){
    let tag:any;
    for(tag of this.taskTags){
      if(!this.totalTags.includes(tag))
      {
        this.tagsArr.push(tag.display);
      }
    }
    // this.flagEvent.emit(this.tagsArr);
    this.flagEvent.emit(this.taskTags);
  }

  tagAndUpdate(){
    this.flagUpdateEvent.emit(this.taskTags);
  }

  closeSection(){
    this.closeEvent.emit();
  }

}
