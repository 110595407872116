import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { Observable, Observer, Subject } from 'rxjs';
import { CommonService } from 'src/app/service/common.service';
import { FileService, SnackBarService } from 'src/app/service/shared.service';
import { StateService } from 'src/app/service/state.service';
import { ImagepdfviewerComponent } from 'src/app/shared/imagepdfviewer/imagepdfviewer.component';
import { ocrComponent } from 'src/app/shared/ocr-comp/ocrComp.component';
import { v4 as uuid } from 'uuid';
import { Document, State } from '../../models/tasks.model';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit, OnChanges {
  @Input() documentsForState: any;
  @Input() documentsForEntity: any;
  @Input() canvasWidth: any;
  @Input() canvasHeight: any;

  @Input() assignedTaskDdetails: State;
  @Input() associatedEntity: any;
  @Input() disableAdd: boolean;
  @Input() displayActivatedDocument: boolean;

  @Output() addDocumentEvent = new EventEmitter();
  @Output() removeDocumentEvent = new EventEmitter<any>();
  @Output() uploadDocumentEvent = new EventEmitter<any>();
  @Output() updatedDocumentEvent = new EventEmitter<any>();
  @Output() saveStateTrigger = new EventEmitter<any>();
  @Output() closeEvent = new EventEmitter();
  @Output() docLength = new EventEmitter<number>();
  @Output() showFull = new EventEmitter<any>();
  dataFillModeFlag: boolean = false;

  @ViewChild('imageContainer', { static: false }) wrapper: ElementRef;
  @ViewChild('mainContainer', { static: false }) mainWrapper: ElementRef;

  fileUploadPrompt: Subject<any>;
  fileList: Observable<any[]>;
  bsModalRef: BsModalRef;
  isExpanded: boolean = false;
  loaded: boolean = false;
  currentDocument: any;
  currentDocumentIndex: any;

  public _canvasDim;
  get canvasDim() {
    return this._canvasDim;
  }

  constructor(
    private modalService: BsModalService,
    private fileService: FileService,
    private stateService: StateService,
    private snackBarService: SnackBarService,
    private commonService: CommonService
  ) { }

  ngOnInit() {
    this.updateDocList([]);

    let fileList = [];
    if (this.documentsForState) {
      fileList = this.documentsForState[this.assignedTaskDdetails._id];
      if (this.documentsForEntity && this.associatedEntity && this.documentsForEntity[this.associatedEntity._id] && this.documentsForState[this.assignedTaskDdetails._id]) {
        fileList = this.documentsForState[this.assignedTaskDdetails._id].concat(this.documentsForEntity[this.associatedEntity._id]);
      }
    }
    // if (this.documentsForEntity && this.associatedEntity && this.associatedEntity._id) {
    //   // this.expandSection(this.documentsForEntity[this.associatedEntity._id]);
    //   this.documentsForEntity = (JSON.parse(JSON.stringify(this.documentsForEntity)))
    // }
  }

  ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
    if(changes.assignedTaskDdetails && changes.assignedTaskDdetails.currentValue != undefined){
      this.assignedTaskDdetails = changes.assignedTaskDdetails.currentValue;
    }
    if(changes.associatedEntity && changes.associatedEntity.currentValue != undefined){
      this.associatedEntity = changes.associatedEntity.currentValue;
    }
    setTimeout(() => {
      
      if (this.associatedEntity && changes.documentsForEntity && changes.documentsForEntity.currentValue != undefined) {
        this.loaded = true;
        let entDocList = [];
        this.documentsForEntity = changes.documentsForEntity.currentValue[this.associatedEntity._id];
        if (this.documentsForEntity && this.documentsForEntity.length > 0) {
          for (let index = 0; index < this.documentsForEntity.length; index++) {
            const dc = this.documentsForEntity[index];
            if (Array.isArray(dc.value) && dc.value.length > 0) {
              for (let i = 0; i < dc.value.length; i++) {
                const innerDoc = dc.value[i];
                innerDoc['source'] = dc.source;
                if (!innerDoc.userFileName) {
                  innerDoc.userFileName = innerDoc.fileName;
                }
                innerDoc.source = dc.source;
                innerDoc.uploadTimeLong = dc.uploadTimeLong;
                entDocList.push(innerDoc);
              }
            } else {
              if (!dc.value.userFileName) {
                dc.value.userFileName = dc.value.fileName;
              }
              dc.value.source = dc.source;
              dc.value.uploadTimeLong = dc.uploadTimeLong;
              entDocList.push(dc.value)
            }
          }
        }
        this.updateDocList(entDocList);
        this.updateCanvasDim();
      }
      if (this.assignedTaskDdetails && changes.documentsForState && changes.documentsForState.currentValue != undefined) {
        this.loaded = true;
        this.documentsForState = changes.documentsForState.currentValue;
        if(this.documentsForState && this.documentsForState[this.assignedTaskDdetails._id] && this.documentsForState[this.assignedTaskDdetails._id].length>0){
          this.updateDocList(this.documentsForState[this.assignedTaskDdetails._id]);
          this.updateCanvasDim();
        }
      }
      if (changes.displayActivatedDocument && changes.displayActivatedDocument.currentValue != undefined) {
        if (this.displayActivatedDocument == true && this.documentsForState[this.assignedTaskDdetails._id][0]) {
          this.expandSection(this.documentsForState[this.assignedTaskDdetails._id][0], 0);
        }
      }
    }, 1000);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.updateCanvasDim();
  }

  private updateCanvasDim() {
    const el = this.mainWrapper && this.mainWrapper.nativeElement ? this.mainWrapper.nativeElement : null;

    // if (el && (el.offsetWidth !== this._canvasDim.width || el.offsetHeight !== this._canvasDim.height)) {
    if (el) {
      const newDim = { width: el.offsetWidth - 2, height: el.offsetWidth + 40 };
      setTimeout(() => this._canvasDim = newDim, 0);
      // }
    } else {
      const newDim = { width: this.canvasWidth, height: this.canvasHeight };
      setTimeout(() => this._canvasDim = newDim, 0);

    }
  }

  closeSection() {
    this.showFull.emit(false);
    this.closeEvent.emit();
  }

  expandSection(document, documentIndex) {
    if (document.fileName == undefined) {
      document.fileName = document.userFileName
    }
    this.isExpanded = true;
    this.currentDocument = document;
    this.currentDocumentIndex = documentIndex;
    this.showFull.emit(true);
  }

  updateDoc(event) {
    this.updatedDocumentEvent.emit(event)
  }

  onDocumentUploadForTask(event: any) {
    let map = {};
    map["event"] = event.event;
    map["document"] = event.document
    this.uploadDocumentEvent.emit(map);
  }

  onNewRemarksDocuments(stateInstanace: State) {
    const newDoc = new Document();
    newDoc.stateInstanceId = stateInstanace._id;
    newDoc.flowInstanceId = stateInstanace.stateMachineInstanceModelId;
    newDoc.documentType = "USER_REMARKS";
    newDoc.documentName = uuid();
    if (!this.documentsForState[stateInstanace._id]) {
      this.documentsForState[stateInstanace._id] = [];
    }
    this.documentsForState[stateInstanace._id].push(newDoc);
  }

  getRemarksDocuments(stateInstanace: State) {
    const remarksDocs: Document[] = [];
    if (this.documentsForState && this.documentsForState[stateInstanace._id]) {
      for (let doc of this.documentsForState[stateInstanace._id]) {
        if (doc.documentType && doc.documentType == "USER_REMARKS") {
          remarksDocs.push(doc);
        }
      }
    }
    return remarksDocs;
  }

  getOtherThanRemarksDocuments(stateInstanace: State) {
    const docs: Document[] = [];
    if (this.fileList) {
      this.fileList.subscribe(baseList => {
        for (let index = 0; index < baseList.length; index++) {
          const doc = baseList[index];
          if (doc.documentType && doc.documentType != "USER_REMARKS") {
            doc['source'] = "Process";
          }
          if (!doc.fileName) {
            doc.fileName = doc.userFileName;
          }
          if (doc.uploadTimeLong == undefined && doc.uploadTime == undefined && doc.value != undefined) {
            doc.uploadTimeLong = doc.fileCreatedOn;
          }
          if (!doc.piExtractCode) {
            doc['piExtractCode'] = "";
          }
          docs.push(doc)
        }
        return docs;
      })
    }
    return docs;
  }

  openModalWithComponent(document) {
    const config: ModalOptions = {
      backdrop: 'static',
      keyboard: false,
      animated: false,
      ignoreBackdropClick: true,
      initialState: {
        doc: document,
        title: 'Document Title'
      }
    };
    this.bsModalRef = this.modalService.show(ImagepdfviewerComponent, config);
    this.bsModalRef.content.closeBtnName = 'Close';
  }

  setFileUploadPrompt(fileUploadPrompt: Subject<any>) {
    this.fileUploadPrompt = fileUploadPrompt;
  }

  onNewDocumentAdd() {
    const newDoc = new Document();
    newDoc.stateInstanceId = this.assignedTaskDdetails._id;
    newDoc.flowInstanceId = this.assignedTaskDdetails.stateMachineInstanceModelId;
    newDoc.documentType = "OTHER";
    newDoc.documentName = uuid();
    newDoc.status = "PENDING";
    if (!this.documentsForState[this.assignedTaskDdetails._id]) {
      this.documentsForState[this.assignedTaskDdetails._id] = [];
    }
    this.documentsForState[this.assignedTaskDdetails._id].push(newDoc);

    this.isExpanded = true;
    this.currentDocument = newDoc;

    setTimeout(() => {
      if (this.fileUploadPrompt) {
        this.fileUploadPrompt.next();
      }
    }, 100);
  }
  closeUploadSection() {
    this.isExpanded = false;
    if (!this.currentDocument.downloadFileUrl) {
      let pos = this.documentsForState[this.assignedTaskDdetails._id].map(item => item._id).indexOf(null)
      this.documentsForState[this.assignedTaskDdetails._id].splice(pos, 1)
    }
    this.showFull.emit(false)
  }
  onRemoveDocument(document: Document) {
    this.fileService.delete(document.url)
      .subscribe(
        response => {
          this.deleteDocumentForState(document);
        },
        error => {
          this.snackBarService.openSnackBar('Error while removing document. Please try again later.', 'error');
        }
      )
  }

  deleteDocumentForState(document: Document) {
    if (!document || !document._id) {
      return;
    }

    this.stateService.deleteDocument(document)
      .subscribe(
        response => {
          if (this.assignedTaskDdetails && document && this.documentsForState && this.documentsForState[this.assignedTaskDdetails._id]) {
            let index = this.documentsForState[this.assignedTaskDdetails._id].indexOf(document);
            if (index > -1) {
              this.documentsForState[this.assignedTaskDdetails._id].splice(index, 1);
              // this.saveAssignedTask(this.assignedTaskDdetails);
              this.removeDocumentEvent.emit();
              this.saveStateTrigger.emit();
            }
          }
        },
        error => {
          this.snackBarService.openSnackBar('Error while removing document. Please try again later.', 'error');
        }
      )
  }
  openOCR(ocrFileValue: any) {
    const config: ModalOptions = {
      backdrop: 'static',
      keyboard: false,
      animated: false,
      ignoreBackdropClick: true,
      class: 'modal-lg width80percent',
      initialState: {
        doc: ocrFileValue,
        allFields: this.associatedEntity.fields,
        title: 'Document Title',
        entityView: true,
        ocrFile: true
      }
    };
    this.bsModalRef = this.modalService.show(ocrComponent, config);
    this.bsModalRef.content.closeBtnName = 'Close';
    this.bsModalRef.content.event.subscribe(res => {
      const div = document.querySelector('body');
      div.classList.remove('modal-open');
      for (let i = 1; i <= this.modalService.getModalsCount(); i++) {
        this.modalService.hide(i)
      }
    });
  }
  toggleDataFillMode() {
    this.dataFillModeFlag = !this.dataFillModeFlag;
    this.commonService.toggleDataFillMode(this.dataFillModeFlag);
  }
  navigateTo(direction) {
    if (direction == 'next') {
      if (this.currentDocumentIndex == this.getOtherThanRemarksDocuments(this.assignedTaskDdetails).length - 1) {
        this.snackBarService.openSnackBar('No More Documents to display', 'error');
      } else {
        this.currentDocumentIndex = this.currentDocumentIndex + 1;
      }
    }
    if (direction == 'prev') {
      if (this.currentDocumentIndex == 0) {
        this.snackBarService.openSnackBar('No More Documents to display', 'error');
      } else {
        this.currentDocumentIndex = this.currentDocumentIndex - 1;
      }
    }

    this.expandSection(this.getOtherThanRemarksDocuments(this.assignedTaskDdetails)[this.currentDocumentIndex], this.currentDocumentIndex);
  }

  updateDocList(list: any[]) {
    let newList = [];
    if (this.fileList) {
      this.fileList.subscribe(response => {
        newList = list
      })
    }
    if (list && list.length > 0) {
      newList = newList.concat(list);
      this.fileList = new Observable<any>((observer: Observer<any>) => {
        observer.next(newList)
      });
    }
  }
}
