import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatPaginator, MatSnackBar, MatSort, MatTable, MatTableDataSource } from '@angular/material';
import { DataModel, EntitySearchCriteria, LinkageModel } from 'src/app/models/datamodel.model';
import { DataModelService } from 'src/app/service/datamodel.service';
import { EntityService } from 'src/app/service/entity.service';

@Component({
  selector: 'app-linked-entity-table',
  templateUrl: './linked-entity-table.component.html',
  styleUrls: ['./linked-entity-table.component.css']
})
export class LinkedEntityTableComponent implements OnChanges {

    @Input()
    linkageModel: LinkageModel;

    childDataModel: DataModel;
    childEntityList: any[];
    childEntityMap: any;

    otherColumns: any[];
    displayedColumns: any;
    sortedDiaplayColumns: any[];
    columnList: any[];
    notNeededFields: string[];
    businessKeyField: string;

    entityMaterialList: MatTableDataSource<any>;
    paginator: MatPaginator;
    sort: MatSort;

    constructor(
        private dataModelService: DataModelService,
        private entityService: EntityService
    ) {
        this.childEntityList = [];
        this.childEntityMap = {};

        this.displayedColumns = [];
        this.sortedDiaplayColumns = [];
        this.columnList = [];
        this.notNeededFields = [];
        this.businessKeyField = null;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.linkageModel && changes.linkageModel.isFirstChange() && this.linkageModel) {
            this.fetchChildDataModel(this.linkageModel.childDataModelName);
        }
    }

    fetchChildDataModel(dataModelName: string) {
        this.dataModelService.getDataModelByName(dataModelName).subscribe(
            childDataModel => {
                if (childDataModel) {
                    this.childDataModel = childDataModel;
                    this.sortedDiaplayColumns = childDataModel.fields.sort((a, b) => { return a.sortOrder - b.sortOrder }).map(item => item.name);

                    const unwantedFieldTypes: string[] = ['LOOKUP_REFERENCE', 'DYNAMIC_TABLE', 'FILE_SECURED', 'FILE', 'MODEL', 'HTML_VIEWER'];

                    for (let field of childDataModel.fields) {
                        if (field.hide || unwantedFieldTypes.includes(field.type)) {
                            this.notNeededFields.push(field.name);
                        }
                        if (field.businessKey == true) {
                            this.businessKeyField = field.name;
                        }
                    }

                    this.entityService.getEntityCommonFields().subscribe(
                        response=>{
                            this.notNeededFields = this.notNeededFields.concat(response);
                            this.fetchLinkedChildEntities(this.childDataModel.name);
                        },
                        error=>{
                            console.log(error)
                            this.fetchLinkedChildEntities(this.childDataModel.name);
                        }
                    )


                    
                }
            }
        );
    }

    fetchLinkedChildEntities(dataModelName: string) {
        if (this.linkageModel && this.linkageModel.childMetadataList && this.linkageModel.childMetadataList.length > 0) {
            const idList: string[] = [];

            for (const childMetadata of this.linkageModel.childMetadataList) {
                idList.push(childMetadata.entityId);
            }

            const entitySearchCriteria: EntitySearchCriteria = new EntitySearchCriteria();
            entitySearchCriteria.criteria['_id'] = { '$in': idList };

            this.otherColumns = [];

            this.entityService.getParameterizedEntityList(dataModelName, 0, idList.length, entitySearchCriteria).subscribe(
                entityList => {
                    if (entityList && entityList.length > 0) {
                        for (const entity of entityList) {
                            for (const key in entity) {
                                if (entity.hasOwnProperty(key)) {
                                    if (key.toLowerCase().indexOf('url') > -1) {
                                        entity[key] = "<a href='" + entity[key] + "' target='_blank'>Link</a>"
                                    }
                                }
                            }

                            this.childEntityList.push(entity);
                            this.childEntityMap[entity._id] = entity;
                        }

                        this.entityMaterialList = new MatTableDataSource(this.childEntityList);
                        this.paginator = this.entityMaterialList.paginator;
                        this.sort = this.entityMaterialList.sort;

                        let notNeeded = ['companyId',  'statusCd', '_entityName', '_id', 'datamodelId', '_lookup_reference_id', '_lookup_reference_label', 'flowId','lastKnownPage', 'pageProgress', 'pageFinished', '_carouselLastKnownIndex', '_carouselProgress', '_carouselFinished', '_lastKnownVideoDuration', '_videoFinished', '_parentDataModelName', '_parentId', '_linkageId', 'createdAt','updatedAt','createdBy','updatedBy'];
                        if (this.notNeededFields.length > 0) {
                            notNeeded = notNeeded.concat(this.notNeededFields);
                        }

                        this.displayedColumns = [];
                        for (const key in this.childEntityList[0]) {
                            let pos = notNeeded.indexOf(key);

                            if (pos == -1) {
                                let keyPosInDatamodel = this.childDataModel.fields.map(item=>item.name).indexOf(key);
                                if (keyPosInDatamodel >-1) {
                                    this.otherColumns.push({
                                        "name": key,
                                        "label": this.childDataModel.fields[keyPosInDatamodel].label
                                    });
                                }else{
                                    this.otherColumns.push({
                                        "name": key,
                                        "label": this.capitalize(key)
                                    });
                                }
        
                                if (key.toLowerCase() != 'description') {
                                    this.displayedColumns.push(key)
                                }
                                
                            }
                        }

                        let sorting = [];
                        for (let index = 0; index < this.sortedDiaplayColumns.length; index++) {
                            const dispEl = this.sortedDiaplayColumns[index];
                            if (this.displayedColumns.indexOf(dispEl) > -1) {
                                sorting[index] = this.displayedColumns[this.displayedColumns.indexOf(dispEl)];
                                this.displayedColumns.splice(this.displayedColumns.indexOf(dispEl), 1)
                            }
                        }

                        this.displayedColumns = sorting.concat(this.displayedColumns);
                        this.displayedColumns = this.displayedColumns.filter((el) => {
                            return el != null;
                        });
                        // this.displayedColumns.push('createdAt', 'updatedAt');

                        for (let index = 0; index < this.displayedColumns.length; index++) {
                            const element = this.displayedColumns[index];
                            this.columnList[index] = {
                                "value": true,
                                "label": element
                            }
                        }

                        this.columnList = [...this.columnList];
                    }
                }
            );
        }
    }
    showHiddenFields:boolean = false;
    getDisplayedColumns() {
        this.displayedColumns = [];
        for (let index = 0; index < this.columnList.length; index++) {
            const element = this.columnList[index];
            if (element.value == true) {
                this.displayedColumns.push(element.label)
            }
        }
        
        // if(this.showHiddenFields == true && this.childDataModel){
        //     for (let i = 0; i < this.childDataModel.fields.length; i++) {
        //         const hiddenElement = this.childDataModel.fields[i];
        //         if (hiddenElement.hide == true) {
                    
        //             if(this.otherColumns.map(item=>item.name).indexOf(hiddenElement) == -1){
        //                 this.otherColumns.push({
        //                     "name": hiddenElement.name,
        //                     "label": hiddenElement.label
        //                 });
        //             }
        //             this.displayedColumns.push(hiddenElement.name)
        //         }
        //     }
        // }
        return this.displayedColumns;
    }

    capitalize(text): string {
        if (typeof text !== 'string') return '';

        let tempStr = text.charAt(0).toUpperCase() + text.slice(1);
        return tempStr.split(/(?<![A-Z])(?=[A-Z])/).join(" ");
    }

    formatDate(str, key) {
        if (this.capitalize(key).search("Date") == -1) {
            if (str == undefined) {
                return ("-")
            } else {
                if (str.length > 20) {
                    return ("<div class='ellipsisExpand'>" + str + "</div>")
                } else {
                    return (str)
                }
            }
        } else {
            return this.convertDate(str)
        }
    }
    convertDate(inputFormat) {
        function pad(s) { return (s < 10) ? '0' + s : s; }
        var d = new Date(inputFormat)
        return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('/')
    }
    toggleSystemFields(){

    }
}