import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalize'
})
export class CapitalizePipe implements PipeTransform {
  transform(s: any): any {
    if (typeof s !== 'string') return s;
    let tempStr = s.charAt(0).toUpperCase() + s.slice(1);
    return tempStr.split(/(?<![A-Z])(?=[A-Z])/).join(" ");
  }
}

@Pipe({
  name: 'searchfilter'
})
export class SearchFilterPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if (!items) return [];
    if (!searchText) return items;
  
    return items.filter(item => {
      return Object.keys(item).some(key => {
        return String(item[key]).toLowerCase().includes(searchText.toLowerCase());
      });
    });
   }
}

@Pipe({ name: 'tzDate' })
export class TzDatePipe implements PipeTransform {
  // adding a default format in case you don't want to pass the format
  // then 'yyyy-MM-dd' will be used
  transform(date: Date | string, format: string = 'yyyy-MM-dd'): string {
    let tz = window.localStorage.getItem('timezone');
    if(tz){
      if(date){
      date = new Date(date);
      return new DatePipe('en-US').transform(date, format,tz);
      }else{
        return "-";
      }
    }else{
      date = new Date(date);  // if orginal type was a string
      date.setDate(date.getDate());
      return new DatePipe('en-US').transform(date, format);
    }
  }
}
