import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ImageViewerModule } from '@hallysonh/ngx-imageviewer';

import { MatPaginatorModule } from '@angular/material';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { AngularMaterialModule } from '../material.module';

import { UniversalFilterPipe } from './universal-data-filter.pipe';
import { ImagepdfviewerComponent } from './imagepdfviewer/imagepdfviewer.component';
import { InlineDocViewerComponent } from './inline-doc-viewer/inline-doc-viewer.component';
import { AmchartGraphsComponent } from './amchart-graph/amchart-graph.component';
import { EscapeScriptPipe } from './escape-script.pipe';
import { AdhocEmailComponent } from './adhocemail/adhocemail.component';
import { MetricStatComponent } from './metric-stat/metric-stat.component';
import { DataTableComponent } from './data-table/data-table.component';
import { DataTableModule } from 'angular-6-datatable';
import { PieChartDashboardComponent } from './pie-chart-dashboard/pie-chart-dashboard.component';
import { BarChartDashboardComponent } from './bar-chart-dashboard/bar-chart-dashboard.component';
import { LineChartDashboardComponent } from './line-chart-dashboard/line-chart-dashboard.component';

import { ComponentLoaderComponent } from './component-loader/component-loader.component';
import { NgxDatatableModule } from '@tusharghoshbd/ngx-datatable';
import { FunnelChartDashboardComponent } from './funnel-chart-dashboard/funnel-chart-dashboard.component';
import { SpeedometerChartDashboardComponent } from './speedometer-chart-dashboard/speedometer-chart-dashboard.component';
import { TemplatesearchPipe } from './templatesearch.pipe';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TagInputModule } from 'ngx-chips';
import { ocrComponent } from './ocr-comp/ocrComp.component';
import { ocrFieldTrackComponent } from './ocr-fieldTrack/ocr-fieldTrack.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { USPSearchComponent } from './usp-search/usp-search.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { RouterModule } from '@angular/router';
import { DateRangePickerComponent } from './daterangepicker';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { SingleMailComponent } from './single-mail/single-mail.component';
import { RatingCompComponent } from './rating-comp/rating-comp.component';
import { TimeCounterComponent } from './timecounter/timecounter.component';
import { ShortenNamePipe } from './shorten-name.pipe';
import { NotesForEntityComponent } from './notes-for-entity/notes-for-entity.component';
import { DateDifferenceComponent } from './datedifference/datedifference.component';
import { TruncateTitlePipe } from './truncateTitle-name.pipe';
import { LinkedEntityTableComponent } from './linked-entity-table/linked-entity-table.component';
import { ocrFieldTrackLLMComponent } from './ocr-fieldTrack-llm/ocr-fieldTrack-llm.component';
import { TzDatePipe } from './custompipes.pipe';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ImageViewerModule,
    DataTableModule,
    NgxDatatableModule,
    AngularEditorModule,
    TagInputModule,
    TooltipModule.forRoot(),
    NgbModule,
    NgSelectModule,
    RouterModule,
    NgxDaterangepickerMd.forRoot(),
    AngularMaterialModule,
    MatPaginatorModule,
    MatButtonModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatSnackBarModule,
    MatTabsModule
  ],
  declarations: [
    UniversalFilterPipe,
    TzDatePipe,
    ImagepdfviewerComponent,
    ocrComponent,
    ocrFieldTrackComponent,
    ocrFieldTrackLLMComponent,
    InlineDocViewerComponent,
    AmchartGraphsComponent,
    EscapeScriptPipe,
    AdhocEmailComponent,
    MetricStatComponent,
    DataTableComponent,
    PieChartDashboardComponent,
    BarChartDashboardComponent,
    LineChartDashboardComponent,
    ComponentLoaderComponent,
    FunnelChartDashboardComponent,
    SpeedometerChartDashboardComponent,
    TemplatesearchPipe,
    USPSearchComponent,
    DateRangePickerComponent,
    SingleMailComponent,
    RatingCompComponent,
    TimeCounterComponent,
    ShortenNamePipe,
    TruncateTitlePipe,
    NotesForEntityComponent,
    DateDifferenceComponent,
    LinkedEntityTableComponent,
  ],
  exports: [
    InlineDocViewerComponent,
    AmchartGraphsComponent,
    EscapeScriptPipe,
    MetricStatComponent,
    DataTableComponent,
    PieChartDashboardComponent,
    BarChartDashboardComponent,
    LineChartDashboardComponent,
    ComponentLoaderComponent,
    FunnelChartDashboardComponent,
    SpeedometerChartDashboardComponent,
    ocrFieldTrackComponent,
    ocrFieldTrackLLMComponent,
    USPSearchComponent,
    DateRangePickerComponent,
    SingleMailComponent,
    RatingCompComponent,
    TimeCounterComponent,
    UniversalFilterPipe,
    TzDatePipe,
    ShortenNamePipe,
    TruncateTitlePipe,
    NotesForEntityComponent,
    CommonModule,
    DateDifferenceComponent,
    LinkedEntityTableComponent,
  ],
  entryComponents:[NotesForEntityComponent],
  providers: [
    EscapeScriptPipe
  ]
})
export class SharedModule { }
