import * as AWS from 'aws-sdk/global';
import * as S3 from 'aws-sdk/clients/s3';
import * as FileSaver from 'file-saver';
import { saveAs as importedSaveAs } from "file-saver";
import { AngularEditorConfig } from '@kolkov/angular-editor';

import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControlOptions, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IMyDpOptions } from 'mydatepicker';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { DataModel, Field, LinkageModel } from 'src/app/models/datamodel.model';
import { CommonSearchModel } from 'src/app/models/shared.model';
import { DataModelService } from 'src/app/service/datamodel.service';
import { EntityService } from 'src/app/service/entity.service';
import { CommunicationService, FileService, EntiySharingService, UniversalUser, DataSharingService } from 'src/app/service/shared.service';
import { v4 as uuid } from 'uuid';
import { commonKeys, DependencyExpressionActions } from '../../models/constants';
import { ImagepdfviewerComponent } from '../../shared/imagepdfviewer/imagepdfviewer.component';
import { Data, AccessControlRequest } from 'src/app/models/helper.model';
import { first } from 'rxjs/operators';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { DynamicFormModalComponent } from '../dynamic-form-modal/dynamic-form-modal.component';
import { EntityRefModalComponent } from '../entity-ref-modal/entity-ref-modal.component';
import { NgModel } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

import { State } from 'src/app/models/tasks.model';
import { ocrComponent } from 'src/app/shared/ocr-comp/ocrComp.component';
import { ocrFieldTrackComponent } from 'src/app/shared/ocr-fieldTrack/ocr-fieldTrack.component';
import { AccessControlService } from 'src/app/service/helper.service';
import { FunctionConfig } from 'src/app/models/functionConfig.model';




@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss']
})
export class DynamicFormComponent implements OnInit, OnChanges {

  @Input() model;
  @Input() viewEntity;
  @Input() form: FormGroup;
  @Input() manualStateEntityAction;
  @Input() state: State;
  @Input() parentClass;
  @Input() onTaskScreen: boolean = false;
  @Input() taskSelectedDataModel: Subject<DataModel> = new Subject<DataModel>();
  @Input() gridOptionsInputs;
  @Input() parentTableFields;
  @Input() parentWidth = 100;
  @Input() showDeleteOption: boolean = true;
  @Input() baseDocumentId: string;
  @Output() emitted: EventEmitter<any> = new EventEmitter();
  @Output() finalEmit: EventEmitter<any> = new EventEmitter();
  @Output() emittedinner: EventEmitter<any> = new EventEmitter();
  @Output() emitOpenDoc: EventEmitter<any> = new EventEmitter();
  @Output() emitOpenOcr: EventEmitter<any> = new EventEmitter();
  @Output() isEntityFormValid: EventEmitter<boolean> = new EventEmitter();
  @Output() emitcloseEnity: EventEmitter<any> = new EventEmitter();
  @Output() validationErrors: EventEmitter<any> = new EventEmitter();
  gridOpts = { isgrid: false, gridIndex: 0 };

  private subscription: Subscription;
  // dataModelList: any[] = [];
  fieldDatamodel: DataModel;
  selectedDataModel: DataModel;
  selectedDataModelFieldMap: Map<string, Field> = new Map();
  // form: FormGroup;
  boolean: any[];
  showFieldsMap: any = {};
  placeHolderText: string;
  DataModelfields: any = {};
  showSave: boolean = true;
  showSubmit: boolean = true;
  showFields: boolean = true;
  lookupEntityList: any[];
  lookupRefEntityList: any[];
  entityModels: any[];
  bsModalRef: BsModalRef;
  modalOpen: boolean = false;
  entityRefField: Field = new Field();
  dialogRef: MatDialogRef<DynamicFormModalComponent>;
  showSubEntitySection: boolean = false;
  subEntityToBePassed: any;
  showEntityRefSection: boolean = false;
  EntityRefToBePassed: any;
  entityRefs: any[];
  p: number[] = [];
  arrayforTime = {};

  listCallInProcess: boolean = false;
  mvelPayloadMap: any = {};

  errorMap: any = {};
  warningMap: any = {};
  fileMap: any = {};

  viewingNestedEntity: boolean = false;
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '600',
    minHeight: '400',
    maxHeight: '600',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    uploadWithCredentials: false,                    //Image Upload Can we make this work@Parth
    uploadUrl: '',                                   //Your backend must return imageUrl.   | https://github.com/kolkov/angular-editor/issues/5  
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    sanitize: false,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      [
        // 'undo',
        // 'redo',
        // 'strikeThrough',
        // 'subscript',
        // 'superscript',
        // 'indent',
        // 'outdent',
        // 'insertUnorderedList',
        // 'insertOrderedList',
        // 'heading',
        // 'fontName',
        'customClasses',
        'insertImage',
        'insertVideo',
        // 'toggleEditorMode',
        // 'link',
        // 'unlink',
        // 'insertHorizontalRule',
        // 'removeFormat',
        // 'fontSize',
        // 'textColor',
        // 'backgroundColor',
      ],
      []
    ]
  };
  
  public mytime: Date = new Date();
  currentYear: any = this.mytime.getUTCFullYear();
  currentPrevDate: any = this.mytime.getUTCDate() + 1;
  currentFutDate: any = this.mytime.getUTCDate() - 1;
  currentMonth: any = this.mytime.getUTCMonth() + 1; //months from 1-12
  public myDatePickerOptions: IMyDpOptions = {
    dateFormat: 'dd-mm-yyyy'
  };
  public myPreviousDatePickerOptions: IMyDpOptions = {
    disableSince: { year: this.currentYear, month: this.currentMonth, day: this.currentPrevDate },
    dateFormat: 'dd-mm-yyyy'
  };
  public myFutureDatePickerOptions: IMyDpOptions = {
    disableUntil: { year: this.currentYear, month: this.currentMonth, day: this.currentFutDate },
    dateFormat: 'dd-mm-yyyy'
  };

  renderLookup: boolean = false;
  parentDataModelName: string = "";
  childDataModelName: string = "";
  stateInstanceId: string = null;
  entityDeleteConfirmationMessage: string = null;
  allowToDelete: boolean;

  selectedDocumentFieldToBeDelete: Field = null;
  selectedDocumentFieldToBeDeleteIndex: number;

  bsModelDelete: BsModalRef;
  validationErrorList:any[] = [];
  validationErrorTypes:any[] =[];

  linkageModelList: LinkageModel[];

  constructor(
    private router: Router,
    private communicationService: CommunicationService,
    private dataModelService: DataModelService,
    private entityService: EntityService,
    private fileService: FileService,
    private universalService: UniversalUser,
    private snackBar: MatSnackBar,
    private cd: ChangeDetectorRef,
    private modalService: BsModalService,
    private entiySharingService: EntiySharingService,
    public dialog: MatDialog,
    private sanitizer: DomSanitizer,
    private dataSharingService: DataSharingService,
    private universalUser: UniversalUser,
    private accessControlService: AccessControlService

  ) {
    this.placeHolderText = commonKeys.entityDefaultPlaceHolder;
    this.lookupEntityList = [];
    this.lookupRefEntityList = [];
    this.linkageModelList = [];
    this.entityModels = [];
    this.boolean = [true, false];
    this.selectedDataModel = new DataModel();
    this.form = new FormGroup({});
    //this.dialogRef = new MatDialogRef(null,null);
  }

  datapointList: any[] = [];

  ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
    if (changes.gridOptionsInputs && changes.gridOptionsInputs.currentValue != undefined) {
      this.gridOpts = (changes.gridOptionsInputs.currentValue);
    }
    if (changes.model && changes.model.previousValue != undefined) {
      if (changes.model.previousValue != changes.model.currentValue && changes.model.currentValue._id) {
        this.createEntityModal(changes.model.currentValue._id);
      }
    }
    if (changes.viewEntity) {
      if (changes.viewEntity.currentValue) {
        this.viewEntity = changes.viewEntity.currentValue;
      }
    }
    if (changes.parentTableFields && changes.parentTableFields.currentValue != undefined) {
      let parentField = changes.parentTableFields.currentValue;
      let tmppos = this.dataSharingService.piExtractResponse.map(item => item.businessObjectCode).indexOf(parentField.piExtractCode);
      if (tmppos > -1) {
        let selectedPagenumber = this.dataSharingService.piExtractResponse[tmppos]['extractedData'].map(item => item.pageNo).indexOf(parentField.piExtractPageNo)
        if (this.dataSharingService.piExtractResponse[tmppos]['extractedData'][selectedPagenumber]['dataPoints'].map(itm => itm.key).indexOf(parentField.piExtractKey) > -1) {
          this.datapointList = this.dataSharingService.piExtractResponse[tmppos]['extractedData'][selectedPagenumber]['dataPoints'][this.dataSharingService.piExtractResponse[tmppos]['extractedData'][selectedPagenumber]['dataPoints'].map(itm => itm.key).indexOf(parentField.piExtractKey)]
        }
      }
    }
  }

  getDatePickerOptions(field: Field) {
    if (field.dateTypeName == 'PastAndCurrentDate') {
      return this.myPreviousDatePickerOptions;
    }
    else if (field.dateTypeName == 'FutureAndCurrentDate') {
      return this.myFutureDatePickerOptions;
    }
    else {
      return this.myDatePickerOptions;
    }
  }

  addModel(field) {
    let index;
    if (field.value && field.value.length > 0) {
      index = field.value.length;
    }
    else {
      field.value = [];
      index = 0;
    }
    field["currentIndex"] = index;
    this.fetchDataModel(field.modelName).subscribe(model => {
      field.value[index] = model;
      this.openentityUpdateModal(field, index);
    });

  }

  removeModel(field, i) {
    if (field.value.length > 0) {
      field.value.splice(i, 1);
    }
  }

  removeSubEntity(field) {
    field.value = null;
  }

  getUniqueIndex(field) {
    if (field.currentIndex) {
      return field.currentIndex;
    }
    else {
      return 0;
    }

  }

  updateInnerModelList(field, event, index) {
    field.value[index] = event;
  }

  ngOnInit() {
    this.communicationService.setAssocEntityDetails(null);
    if (this.model && this.model.viewEntity == null && this.model.viewEntity == undefined && !this.model.viewEntity) {
      if (this.model.modelName && !this.model._id) {
        this.getDataModelList(this.model.modelName);
      }
      else {
        this.createEntityModal(this.model._id);
      }
    }
    else {
      if (this.model && this.model.type == "MODEL" && !this.model.list) {
        if (this.model.value) {
          this.selectedDataModel = this.model.value;
        }
      }
      else {
        this.selectedDataModel = this.model;
      }
      this.createForm();
    }
    this.setAccessOfUserForDataModel(this.selectedDataModel)
  }

  onChanges(): void {
    this.form.valueChanges.subscribe(val => {
      this.emitted.emit(this.selectedDataModel);
      this.showSubEntitySection = false;
      for (let key of Object.keys(val)) {
        this.DataModelfields[key] = val[key];
      }
      this.isEntityFormValid.emit(this.checkFormisValid(this.DataModelfields));
    });
  }

  onFieldChange() {
    if (this.selectedDataModel && this.selectedDataModel.fields) {
      for (const field of this.selectedDataModel.fields) {
        if (field && this.form && this.form.controls && this.form.controls[field.name]) {
          this.form.controls[field.name].valueChanges.subscribe(value => {

            if (field.value != value) {
              let shouldRunMVEL = false;

              if (field.dependencyExpressionList) {
                for (const dependencyExp of field.dependencyExpressionList) {
                  if (dependencyExp && dependencyExp.expression && dependencyExp.triggerEvent && dependencyExp.triggerEvent == 'ON_CHANGE') {
                    shouldRunMVEL = true;
                    break;
                  }
                }
              }

              setTimeout(() => {
                if (shouldRunMVEL) {
                  let payloadToEvaluateMVEL = this.getPayloadToEvaluateMVEL();
                  payloadToEvaluateMVEL['onChange'] = true;
                  payloadToEvaluateMVEL['fields'] = [field.name];
                  this.getMVELResult(payloadToEvaluateMVEL);
                }
              }, 10);
            }
          });
        }

        // ----------------------- remove
        // if(field.type == 'TEXT'){
        //   field.ocrMeta = {
        //     "fileName" : "HAWB 38369_Page0.pdf",
        //     "pageNumber": 1
        //   }
        // }


        // Remove End
      }
    }
  }

  isErrorMapEmpty() {
    return !this.errorMap || Object.keys(this.errorMap).length == 0;
  }

  checkFormisValid(formValue?: any) {
    if (!this.isErrorMapEmpty()) {
      return false;
    }

    let valid = [];
    if (this.selectedDataModel.fields) {
      for (let field of this.selectedDataModel.fields) {
        if (field.mandatory) {
          if ((formValue && formValue[field.name]) || field.value != null && field.value != "" && field.value != undefined ) {
            valid.push("true");
          }
          else {
            if (field.dependencyExpression) {
              if (!this.showFieldsMap[field.name + "*" + field.sortOrder]) {
                valid.push("true");
              }
              else {
                valid.push("false");
              }
            }
            else {
              valid.push("false");
            }
          }
        }
        else {
          valid.push("true");
        }
      }
      if (valid.includes("false")) {
        return false;
      }
      else {
        return true;
      }
    }
    else {
      return false;
    }

  }


  saveEntity(type: string) {
    let map = {};
    let validationArr = this.entiySharingService.checkValidation(this.selectedDataModel);
    if (validationArr.length == 0 && !validationArr.includes(false)) {
      map["type"] = type;
      map["model"] = this.selectedDataModel;
      this.finalEmit.emit(map);
    }
  }

  onDeleteEntity(templateRef: TemplateRef<any>) {
    if (this.viewEntity.stateInstanceId) {
      this.entityDeleteConfirmationMessage = "Entity is attached to process. You can not delete it";
    }
    else {
      this.allowToDelete = true;
      this.entityDeleteConfirmationMessage = "Are you sure you want to delete?";
    }
    this.bsModelDelete = this.modalService.show(templateRef, { class: 'modal-dialog modal-md modal-dialog-centered' });
  }

  onEntityDeleteCancel() {
    this.bsModelDelete.hide();
  }

  onEntityDeleteConfrimation() {
    this.entityService.deleteEntityById(this.selectedDataModel.name, this.viewEntity._id)
      .subscribe(
        response => {
          this.bsModelDelete.hide();
          this.router.navigateByUrl('/activities');
        },
        error => {

        }
      )
  }



  conditionForField(field: Field, index?: number) {
    if (!field.hide && field.name != commonKeys.entityLookUpRefId) {
      if (field.dependencyExpression) {

        const exp = field.dependencyExpression.replace(/datamodel/g, commonKeys.dependencyExpressionReplacer);
        const result = this.evaluateExpression(exp);
        if (result) {
          this.showFieldsMap[field.name + "*" + field.sortOrder] = true;
          if (field["previousValue"] != null && field["previousValue"] != undefined) {
            field.value = field["previousValue"];
            field["previousValue"] = null;
          }
        }
        else {
          this.showFieldsMap[field.name + "*" + field.sortOrder] = false;
          if (field.value != null && field.value != undefined) {
            field["previousValue"] = field.value;
            field.value = null;
          }
        }
        return result;
      }
      else {
        return true;
      }

    }
    else {
      return false;
    }
  }

  evaluateExpression(expression: string) {
    if (expression && expression.trim().length > 0) {
      const result = eval(expression);
      return result;
    }
  }

  checkForRefrenceField(field: Field) {
    return field.disable || field.name == commonKeys.entityLookUpRefId;
  }

  toFormGroup(fields: Field[]) {
    const group: any = {};

    // Deliberately written 'String' & not 'string' since field.type has datatype 'String'
    const onBlurFieldTypes: String[] = [
      'TEXT',
      'TEXTAREA',
      'PASSWORD',
      'SECURED',
      'INT',
      'FLOAT',
      'HTML_VIEWER'
    ];

    fields.forEach(field => {
      let required = false;
      required = field.mandatory;
      for (const validator of field.validators) {
        if (validator.name === 'Required') {
          required = true;
        }
      }

      let shouldUpdateOnBlur: boolean = false;
      if (field.dependencyExpressionList && !field.list && onBlurFieldTypes.includes(field.type)) {
        for (const dependencyExp of field.dependencyExpressionList) {
          if (dependencyExp && dependencyExp.expression && dependencyExp.triggerEvent && dependencyExp.triggerEvent == 'ON_CHANGE') {
            shouldUpdateOnBlur = true;
            break;
          }
        }
      }

      let fieldValue = field.value != null && field.value != undefined ? field.value : '';

      let formControlOptions: AbstractControlOptions = {
        'updateOn': shouldUpdateOnBlur ? 'blur' : 'change'
      };

      if (required) {
        formControlOptions['validators'] = [Validators.required];
      }

      group[field.name] = new FormControl(fieldValue, formControlOptions);
    });

    return new FormGroup(group);
  }

  compareKeys(a, b) {
    var aKeys = Object.keys(a).sort();
    var bKeys = Object.keys(b).sort();
    return JSON.stringify(aKeys) === JSON.stringify(bKeys);
  }


  getDataModelList(modelName) {
    const commonsearchModel = new CommonSearchModel();
    commonsearchModel.searchParams = [{ 'statusCd': 'ACTIVE' }];
    commonsearchModel.returnFields = ['_id', 'name'];
    this.subscription = this.dataModelService.getDataModelList(commonsearchModel)
      .subscribe(list => {
        if (list.length > 0) {
          let pos = list.map(item => item.name).indexOf(modelName);
          let currentDataModel = list[pos];
          this.createEntityModal(currentDataModel._id);
        }
      });
  }

  createEntityModal(dataModelId?: string) {
    this.showFieldsMap = {};
    if (dataModelId) {
      this.subscription = this.dataModelService.getDataModel(dataModelId)
        .subscribe(
          datamodel => {
            if (datamodel) {
              if (this.viewEntity != null) {
                datamodel["parent"] = true;
                this.insertDataForView(datamodel);
              }
              else {
                this.selectedDataModel = datamodel;
                this.setAccessOfUserForDataModel(this.selectedDataModel);
                this.createForm();
              }
            }
          });
    }
  }
  fields = {};

  returnLabels(eachItem, index) {
    // This is for returning the Label and Name of the subentity(model)
    if (this.fields[eachItem.label] == undefined) {
      if (!this.p[index]) this.p[index] = 1;
      this.fields[eachItem.label] = [];

      if (eachItem.value && eachItem.value.length > 0) {
        if (eachItem.value[0]) {
          let tmp = eachItem.value[0].fields.sort((a, b) => { return a.sortOrder > b.sortOrder ? 1 : a.sortOrder ? 0 : -1 })
          for (let i = 0; i < tmp.length; i++) {
            const element = tmp[i];
            if (element.type != 'LOOKUP_REFERENCE' && element.type != 'MODEL') {
              this.fields[eachItem.label].push({
                name: element.name,
                label: element.label
              })
            }
          }
          return true;
        }
      }
      setTimeout(() => {

      }, 1000);

    }
    // return true;
  }

  getDisplayValueForNestedEntityTable(record, key) {
    const keyIndex = record.fields.map(rec => rec.name).indexOf(key);
    if (keyIndex > -1 && record.fields[keyIndex] && record.fields[keyIndex].value) {
      if (record.fields[keyIndex].value.date) {
        const date = record.fields[keyIndex].value.date;
        return date['day'] + '/' + date['month'] + '/' + date['year'];
      } else if (typeof record.fields[keyIndex].value === 'string') {
        return record.fields[keyIndex].value.substr(0, 255);
      } else {
        return record.fields[keyIndex].value;
      }
    } else {
      return "";
    }
  }
  getColData(fieldValue, type) {
    if (fieldValue != undefined) {
      let tmpOutput = "<th style='background-color: #DDEFEF; border: none; color: #336B6B; padding: 10px; text-align: left; text-shadow: 1px 1px 1px #fff;white-space: nowrap;'>Actions</th>"

      let fieldArr = fieldValue.fields.sort((a, b) => {
        return a.sortOrder - b.sortOrder
      })
      if (type == 'heading') {
        for (let i = 0; i < fieldArr.length; i++) {
          const element = fieldArr[i];
          if (element.type != 'MODEL') {
            tmpOutput += "<th style='background-color: #DDEFEF; border: none; color: #336B6B; padding: 10px; text-align: left; text-shadow: 1px 1px 1px #fff;white-space: nowrap;'>" + element.label + " </th>"
          }
        }
        // tmpOutput += "<th style='background-color: #DDEFEF; border: none; color: #336B6B; padding: 10px; text-align: left; text-shadow: 1px 1px 1px #fff;white-space: nowrap;'>Actions</th>";
      } else {
        for (let i = 0; i < fieldArr.length; i++) {
          const element = fieldArr[i];
          tmpOutput += "<td style='border-bottom: solid 1px #DDEFEF;    border-right: solid 1px #DDEFEF;    color: #333;    padding: 10px;    text-shadow: 1px 1px 1px #fff;    white-space: normal;'>" + element.value.value + "</td>"
        }
      }
      return this.sanitizer.bypassSecurityTrustHtml(tmpOutput);
    }
  }
  getEntityRefLabel(entityRefField, type) {
    if (entityRefField.fields && entityRefField.fields.length > 0) {
      let pos = entityRefField.fields.map(item => item.name).indexOf("_lookup_reference_label");
      if(entityRefField &&  entityRefField.fields && entityRefField.fields[pos] && entityRefField.fields[pos].value) {
        return entityRefField.fields[pos].value
      }
    }
    return null
  }
  resetTableFields(divid) {
    let trRows = document.getElementById(divid).getElementsByClassName("tabRow");
    for (let index = 0; index < trRows.length; index++) {
      const element = trRows[index];
      element.textContent = ""
    }
    //
  }
  getPageNumber(p, i, index) {
    if (p[index] == undefined) { p[index] = 1 }
    return (p[index] - 1) * 10 + i + 1;
    this.cd.detectChanges();
  }
  // Entity and datamodel join, Fill in entitydata, insert data
  insertDataForView(selectedDatamodel: any, viewEntity: any = null, refrenceField: boolean = false) {
    if (viewEntity == null) {
      viewEntity = this.viewEntity;
    }
    if (viewEntity && viewEntity._id) {
      this.entityService.getValidationForEntityId(viewEntity._id).subscribe(
        response=>{
          if(selectedDatamodel["parent"]){
            this.validationErrorList = (response);
            this.validationErrorTypes = Array.from(new Set(response.map(item=>item.type)));
            this.errorsByType = this.validationErrorList.filter(item=>item.type == 'ERROR');
          }
        },
        error=>{
          console.log(error)
        }
      );
    }
    selectedDatamodel.viewEntity = true;
    if(selectedDatamodel.fields){
      selectedDatamodel.fields.forEach((field, index) => {
        // for lOOKUP fIELDS
        for (let key of Object.keys(viewEntity)) {
          if (field.name == key) {
            if (field.type == "DATE" || field.type == "DATETIME") {
              if (viewEntity[key]) {
                let date = new Date(viewEntity[key]);
                field.value = this.gateDateToDisplay(field, date)
              }
            }
            else if (field.type == "MODEL" && field.list) {
              viewEntity[field.name].forEach((val, index) => {
                this.fetchDataModel(field.modelName).subscribe(model => {
                  field["viewEntity"] = true;
                  {
                    this.insertDataForView(model, val);
                    if (field.value == undefined) {
                      field.value = [];
                    }
                    field.value[index] = model;
                  }
                });
              });
            }
            else if (field.type == "MODEL" && !field.list) {
              field["viewEntity"] = true;
              this.fetchDataModel(field.modelName).subscribe(model => {
                this.insertDataForView(model, viewEntity[key]);
                field.value = model;
              });

            }
            // else if (field.type == "SINGLE_SELECT" || field.type == "MULTI_SELECT") {
            //   field.value = viewEntity[key];
            //   field.inputSource.sort();
            // }
            else if (field.type == "LOOKUP_REFERENCE" && field.list) {
              if (viewEntity[field.name]) {
                this.fetchDataModel(field.referenceModel).subscribe(model => {
                  field["viewEntity"] = true;
                  if (Array.isArray(viewEntity[field.name])) {
                    viewEntity[field.name].forEach((val, index) => {
                      let modelCopy = JSON.parse(JSON.stringify(model))
                      this.fetchRefrenceEntity(field.referenceModel, val).subscribe(entity => {
                        this.insertDataForView(modelCopy, entity);
                        if (field.listvalue == undefined) {
                          field.listvalue = [];
                        }
                        if (field.value == undefined) {
                          field.value = [];
                        }
                        let map = {};
                        map["id"] = entity[commonKeys.entityLookUpRefId];
                        map["name"] = entity[commonKeys.entityLookUpRefLabel];
                        field.value.push(map);
                        modelCopy["lookupEntityRefMap"] = map;
                        modelCopy[commonKeys.entityLookUpRefLabel] = entity[commonKeys.entityLookUpRefLabel];
                        field.listvalue[index] = modelCopy;
                        this.createForm();
                      });
                    });
                  }
                });

              }
            }
            else if (field.type == "LOOKUP_REFERENCE" && !field.list) {
              field["viewEntity"] = true;
              field.value = viewEntity[key];
              this.fetchDataModel(field.referenceModel).subscribe(model => {
                this.fetchRefrenceEntity(field.referenceModel, field.value).subscribe(entity => {
                  this.insertDataForView(model, entity);
                  model["lookupEntityRefId"] = entity[commonKeys.entityLookUpRefId];
                  field["actualValue"] = entity[commonKeys.entityLookUpRefId];
                  field.value = model;
                });
              });
            } else if(field.type == 'USER' || field.type == 'GROUP'){
              field.value = viewEntity[key];
              this.getRefList(field,selectedDatamodel);
            } else if(field.type == 'TEXTAREA' && field.uiProperties.useDHTML){
              field.value = this.nl2br(viewEntity[key]);
            }else if(field.type == 'FLOAT'){
              field.value =parseFloat((viewEntity[key])).toFixed(2);
            } else {
              field.value = viewEntity[key];
            }
            field._id = viewEntity['_id'];
            selectedDatamodel.fields.splice(index, 1, field);
          }
        }
      });
    }
    if (selectedDatamodel["parent"]) {
      this.selectedDataModel = selectedDatamodel;
      this.setAccessOfUserForDataModel(this.selectedDataModel);
      this.createForm();
    }
  }
  compareObjects(o1: any, o2: any) {
    if (o2 != undefined) {
      if (o1.id == o2.lookupEntityRefId || o1.id == o2.actualValue)
        return true;
      else return false
    }
  }

  compareObjectsList(o1: any, o2: any) {
    if (o2 != undefined) {
      return o1 && o2 ? o1.id === o2.id : o1 === o2;
    }
  }

  fetchRefrenceEntity(modelName: any, id: any): Observable<any> {
    const subject = new Subject<any>();
    this.subscription = this.entityService.getEntityLookupRef(modelName, id)
      .subscribe(entity => {
        if (entity) {
          subject.next(entity);
        }
      });
    return subject.asObservable();
  }


  createForm() {
    if (this.selectedDataModel && this.selectedDataModel.fields) {
      for (let field of this.selectedDataModel.fields) {
        this.selectedDataModelFieldMap.set(field.name, field);
      }
      for (let field of this.selectedDataModel.fields) {
        if (field.type == commonKeys.entityLookup) {
          this.getLookupEntitiesList(field);
        }
        else if (field.type == commonKeys.entityLookupReference) {
          this.getRefLookupEntitiesList(field);
        }
      }
      //this.selectedDataModel.fields = this.selectedDataModel.fields.filter(field => !field.hide);
      this.selectedDataModel.fields.forEach((field, index) => {

        if (field.dependencyExpression) {
          this.showFieldsMap[field.name + "*" + field.sortOrder] = false;
        }
        else {
          this.showFieldsMap[field.name] = true;
        }
        field.value = this.decodeEntities(field.value);

        // for lOOKUP fIELDS
        if(field.type == 'USER' || field.type == 'GROUP'){
          this.getRefList(field,this.selectedDataModel);
        }
        // if (field.uiProperties.useDHTML == true) {
        //   field.value = (this.decodeEntities(field.value))
        // }
      });
      let _this = this;
      this.selectedDataModel.fields.sort(function (a: Field, b: Field) {
        if (_this.selectedDataModel.groups && _this.selectedDataModel.groups.length>0) {
          if (a.groupName === b.groupName) {
            // Price is only important when cities are the same
            return a.sortOrder - b.sortOrder;
         }
         return a.groupName > b.groupName ? 1 : -1;
        }else{ 
          return a.sortOrder > b.sortOrder ? 1 : a.sortOrder ? -1 : 0
        }
      });

      this.selectedDataModel.groups.forEach((group,index)=>{
        group.uiProperties.iscollapsed = false;
      });
      
      this.emitted.emit(this.selectedDataModel);
      this.showSubEntitySection = false;
      this.form = this.toFormGroup(this.selectedDataModel.fields);
      this.onChanges();
      this.showFields = true;

      setTimeout(() => {  // To avoid first on change when the form is created
        this.runAllDependencyExpressions();
        this.onFieldChange();
      }, 10);

      this.fetchLinkageModels();
    }
  }

  fetchLinkageModels() {
    if (this.viewEntity && this.viewEntity['_id'] && this.viewEntity['_id'].trim().length > 0) {
      this.dataModelService.getLinkageModelByParentId(this.viewEntity['_id']).subscribe(
        (linkageModelList: LinkageModel[]) => {
          if (linkageModelList && linkageModelList.length > 0) {
            for (const linkageModel of linkageModelList) {
              if (linkageModel && linkageModel.childMetadataList) {
                const tempChildMetadataList = [];

                for (const childMetadata of linkageModel.childMetadataList) {
                  if (childMetadata && childMetadata.entityId && childMetadata.statusCd
                      && !['DELETED', 'DELINKED'].includes(childMetadata.statusCd)) {
                    tempChildMetadataList.push(childMetadata);
                  }
                }

                if (tempChildMetadataList.length > 0) {
                  linkageModel.childMetadataList = tempChildMetadataList;
                  this.linkageModelList.push(linkageModel);
                }
              }
            }
          }
        }
      );
    }
  }

  toggleCollapse(group){
    group.uiProperties.iscollapsed = (!group.uiProperties.iscollapsed);
  }
  fetchDataModel(modelName): Observable<DataModel> {
    const subject = new Subject<DataModel>();
    const commonsearchModel = new CommonSearchModel();
    commonsearchModel.searchParams = [{ 'statusCd': 'ACTIVE' }];
    commonsearchModel.returnFields = ['_id', 'name'];
    this.subscription = this.dataModelService.getDataModelList(commonsearchModel)
      .subscribe(list => {
        if (list.length > 0) {
          let pos = list.map(item => item.name).indexOf(modelName);
          if (list[pos]) {
            this.subscription = this.dataModelService.getDataModel(list[pos]._id)
              .subscribe(
                datamodel => {
                  if (datamodel) {
                    subject.next(datamodel);
                  }
                }
              );
          }
        }
      });
    return subject.asObservable();
  }

  checkForSubmit() {
    if (!this.checkForCreate() && !this.checkForUpdate()) {
      return false;
    }
    if (!this.manualStateEntityAction) {
      if (this.viewEntity) {
        if (this.viewEntity.statusCd == "DRAFT" && this.selectedDataModel.process && this.selectedDataModel.process.toString().length > 0) {
          return true;
        }
        else {
          return false;
        }
      }
      else {
        if (this.selectedDataModel.process && this.selectedDataModel.process.toString().length > 0) {
          return true;
        }
        else {
          return false;
        }
      }
    }
    else {
      return false;
    }

  }

  checkForCreate() {
    if (this.viewEntity == null || !this.viewEntity._id) {
      if (this.selectedDataModel && this.selectedDataModel.subEntity) {
        return true;
      }
      if (this.selectedDataModel.allowCreate && this.checkForSave()) {
        return true;
      }
    }
    return false;
  }

  checkForUpdate() {
    if (this.selectedDataModel && this.selectedDataModel.subEntity) {
      return true;
    }
    if (this.viewEntity && this.viewEntity._id) {
      if (this.selectedDataModel.allowUpdate && this.checkForSave()) {
        return true;
      }
    }
    return false;
  }

  checkForSave() {
    if (!this.manualStateEntityAction) {
      if (this.viewEntity) {
        if (this.viewEntity.statusCd == "DRAFT") {
          return true;
        }
        else {
          return false;
        }
      }
      else {
        return true;
      }
    }
    else {
      return false
    }


  }

  checForNavigateToTask() {
    if (this.viewEntity != undefined && this.viewEntity["stateInstanceId"] != null && this.viewEntity["stateInstanceId"] != undefined) {
      this.stateInstanceId = this.viewEntity["stateInstanceId"];
      return true;
    }
    else {
      return false;
    }
  }


  getLookupEntitiesList(field: Field) {
    const commonsearchModel = new CommonSearchModel();
    commonsearchModel.searchParams = [{}];
    commonsearchModel.returnFields = [];

    if (!field.parentAttributeCode || field.parentAttributeCode.trim().length == 0) {
      if (field.lookupType) {
        commonsearchModel.searchParams = [{
          lookupType: field.lookupType
        }];
      }
      this.subscription = this.entityService.getLookupEntities(field.referenceModel, commonsearchModel)
        .subscribe(lookupList => {
          for (let lookupEntity of lookupList) {
            this.lookupEntityList.push(lookupEntity);
          }
          field.lookupEntityList = lookupList;
        });
    } else {
      const parentField = this.selectedDataModelFieldMap.get(field.parentAttributeCode);

      if (parentField && parentField.value) {
        let tempSearchParam = {};
        tempSearchParam["parentValueCode"] = parentField.value;
        if (field.lookupType) {
          tempSearchParam["lookupType"] = field.lookupType;
        }
        commonsearchModel.searchParams = [tempSearchParam];

        this.subscription = this.entityService.getLookupEntities(field.referenceModel, commonsearchModel)
          .subscribe(lookupList => {
            for (let lookupEntity of lookupList) {
              this.lookupEntityList.push(lookupEntity);
            }
            field.lookupEntityList = lookupList;
          });
      }
    }
  }

  onLookupSelect(changedField: Field) {
    let childFields: Field[] = [];

    for (let field of this.selectedDataModel.fields) {
      if (field.parentAttributeCode && field.parentAttributeCode == changedField.name) {
        const childField = field;
        childField.value = "";
        childField.lookupEntityList = [];
        childFields.push(childField);
        this.onLookupSelect(field);
      }
    }

    if (changedField && changedField.value && childFields) {
      for (const childField of childFields) {
        const commonsearchModel = new CommonSearchModel();
        let tempSearchParam = {};
        tempSearchParam["parentValueCode"] = changedField.value;
        if (childField.lookupType) {
          tempSearchParam["lookupType"] = childField.lookupType;
        }
        commonsearchModel.searchParams = [tempSearchParam];
        commonsearchModel.returnFields = [];
        this.subscription = this.entityService.getLookupEntities(childField.referenceModel, commonsearchModel)
          .subscribe(lookupList => {
            for (let lookupEntity of lookupList) {
              this.lookupEntityList.push(lookupEntity);
            }
            childField.lookupEntityList = lookupList;
          });
      }
    }
  }

  getRefLookupEntitiesList(field: Field) {
    const commonsearchModel = new CommonSearchModel();
    commonsearchModel.searchParams = [{}];
    commonsearchModel.returnFields = [commonKeys.entityLookUpRefLabel, commonKeys.entityLookUpRefId];
    this.subscription = this.entityService.getRefLookupEntities(field.referenceModel, commonsearchModel)
      .subscribe(lookupList => {
        let lookupRefEntityList = [];
        for (let lookupEntity of lookupList) {
          if (lookupEntity[commonKeys.entityLookUpRefId] != null && lookupEntity[commonKeys.entityLookUpRefId] != undefined) {
            let map = {};
            map["id"] = lookupEntity[commonKeys.entityLookUpRefId];
            map["name"] = lookupEntity[commonKeys.entityLookUpRefLabel];
            lookupRefEntityList.push(map);
          }
        }
        field.lookupEntityList = lookupRefEntityList;
      });
  }

  gateDateToDisplay(field: Field, date: Date) {
    this.arrayforTime[field.name] = date;
    field.timeValue = date;
    let value = {
      date: {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate()
      }
    };
    return value;
  }
  isValid(field: Field) {
    let required = false;
    for (const validator of field.validators) {
      if (validator.name === 'Required') {
        required = true;
      }
    }
    if (field.mandatory || required) {
      if (field.value != null && field.value != "" && field.value != undefined) {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      return true;
    }
  }

  downloadFromS3(field: Field, credResponse: any, url: string) {
    const bucket = new S3(
      {
        accessKeyId: credResponse.accessKey,
        secretAccessKey: credResponse.secretKey,
        sessionToken: credResponse.sessionToken,
        region: 'ap-south-1'

      }
    );
    const key = this.universalService.getUser().companyId + url.replace("/api/downloadDataFile", "").replace("/api/downloadData", "").replace("/null/", "/");
    const params = {
      Bucket: credResponse.bucket,
      Key: key
    };
    field.downloadProgressBar = true;
    bucket.getObject(params).on('httpDownloadProgress', (progress) => {
      field.downloadProgressPercentage = Math.round((progress.loaded * 100) / progress.total);


    }).send((err, data) => {
      if (err) {
        field.downloadProgressBar = false;
        this.snackBar.open("There was an error downloading your file", "Dismiss", {
          duration: 10000
        });
        return false;
      }
      const blob = new Blob([data.Body as BlobPart], { type: data.ContentType });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      let urlParts = url.split("/");
      link.download = urlParts && urlParts.length > 0 && urlParts[urlParts.length - 1] ? urlParts[urlParts.length - 1] : 'temp';
      field.downloadProgressBar = false;
      link.click();
      return true;
    })
  }

  uploadOnAWSS3(field: Field, file: File, credResponse: any) {
    const contentType = file.type;
    const key: string = this.universalService.getUser().companyId + credResponse.uploadUrls.url.replace("/api/downloadData", "").replace("/null/", "/");
    const bucket = new S3(
      {
        accessKeyId: credResponse.accessKey,
        secretAccessKey: credResponse.secretKey,
        sessionToken: credResponse.sessionToken,
        region: 'ap-south-1'

      }
    );
    const params = {
      Bucket: credResponse.bucket,
      Key: key,
      Body: file,
      ContentType: contentType
    };
    if (field.publicFile) {
      params["ACL"] = 'public-read';
    }
    const fetchVideoUrl: boolean = true ? file.type && file.type == "video/mp4" : false;
    field.uploadProgressBar = true;
    bucket.upload(params).on('httpUploadProgress', (evt) => {
      field.uploadProgressPercentage = Math.round((evt.loaded * 100) / evt.total);
    })
      .send((err, data) => {
        if (err) {
          field.uploadProgressBar = false;
          this.snackBar.open("There was an error uploading your file", "Dismiss", {
            duration: 10000
          });
          return false;

        }
        field.uploadProgressBar = false;
        this.onFileSuccessfullyUpload(field, credResponse.uploadUrls, fetchVideoUrl);

        return true;
      })
  }

  onDocumentUpload(event, field: Field) {
    for (let index = 0; index < event.target.files.length; index++) {
      let validSize = true;
      let sizeInBytes: number = event.target.files[index].size;
      if (field.validationExpression != undefined && field.validationExpression && field.validationExpression.length > 0) {
        validSize = this.checkSizeValidation(field.validationExpression, sizeInBytes);
      }
      if (!validSize) {
        let errorMessage = '';
        if (field.errorMessage.length > 0) {
          errorMessage = field.errorMessage;
        }
        else {
          errorMessage = "File size exceeded, size limit is " + field.validationExpression;
        }
        this.snackBar.open(errorMessage, "Dismiss", {
          duration: 10000
        });
        return;
      }

      if (validSize) {
        let uploadedFile = event.target.files[index];
        this.form.controls[field.name].setValue(uploadedFile ? uploadedFile.name : '');
        const file: File = event.target.files[index];
        this.getAWSCreds(field, file);

        event.target.value = '';
      }
      else {

      }

    }

  }

  getAWSCreds(field: Field, file: File) {
    const functionInstanceName: string = field.functionInstanceNameForFile ? field.functionInstanceNameForFile : "API";
    const entityType: string = "ACTIVITY_" + this.selectedDataModel.name;
    if (!this.baseDocumentId) {
      this.baseDocumentId = uuid();
    }
    const entityRef = this.baseDocumentId + "_" + field.name;
    const body = { fileName: file.name, functionInstanceName: functionInstanceName, entityType: entityType, entityRef: entityRef };
    field.loader = true;
    this.fileService.getAWSCreds(body)
      .subscribe(
        response => {
          field.loader = false;
          const errorMsg = this.validateFileWithfunctionConfig(response.functionConfig, file);
          if (errorMsg) {
            this.snackBar.open(errorMsg, "Dismiss", {
              duration: 5000
            });
            return;
          }
          if (!response.s3Allowed) {
            this.uploadViaDataService(field, file, functionInstanceName, entityType, entityRef);
          }
          else {
            this.uploadOnAWSS3(field, file, response);
          }

        },
        error => {

        }
      )
  }

  validateFileWithfunctionConfig(functionConfig: FunctionConfig, file: File) {
    if (!functionConfig) {
      return null;
    }
    if (functionConfig.allowedFileSize) {
      let allowedFileSizeStr = functionConfig.allowedFileSize.toUpperCase();
      allowedFileSizeStr = allowedFileSizeStr.replace("B", "");
      let allowedFileSizeInBytes = 0;
      if (allowedFileSizeStr.includes("K"))
          allowedFileSizeInBytes = Number(allowedFileSizeStr.replace("K", "")) * 1000
      if (allowedFileSizeStr.includes("M"))
          allowedFileSizeInBytes = Number(allowedFileSizeStr.replace("M", "").replace("B", "")) * 1000 * 1000
      if (allowedFileSizeStr.includes("G"))
          allowedFileSizeInBytes = Number(allowedFileSizeStr.replace("G", "").replace("B", "")) * 1000 * 1000 * 1000
      if (allowedFileSizeInBytes == 0) {
        return "Invalid file size configuration " + functionConfig.allowedFileSize;
      }
      if (file.size > allowedFileSizeInBytes) {
        return "Max file size " + allowedFileSizeStr + " allow.";
      }
    }
    if (functionConfig.allowedFileTypes) {
      const fileTypeParts = file.type.split('/');
      const fileExtension = fileTypeParts[fileTypeParts.length - 1].toLowerCase();
      const index = functionConfig.allowedFileTypes.indexOf(fileExtension);
      if (index == -1) {
        return "Allowed file types are " + functionConfig.allowedFileTypes.join(", ");
      }
    }
    return null;
  }

  uploadViaDataService(field: Field, file: File, functionInstanceName: string, entityType: string, entityRef: string) {
    const fileInputForm = new FormData();
    fileInputForm.append('file', file, file.name);
    var uploadFileName = uuid();
    fileInputForm.append("fileName", file.name);
    fileInputForm.append("functionInstanceName", functionInstanceName);
    fileInputForm.append("entityType", entityType);
    fileInputForm.append("entityRef", entityRef);
    if (field.value != undefined && field.value != null && field.value.vimeoVideoUrl != undefined) {
      fileInputForm.append("vimeoUri", field.value.vimeoVideoUrl);
    }
    if (field.publicFile) {
      fileInputForm.append("publicRead", field.publicFile.toString());
    }
    let videoPayload: any = this.getVideoNameAndDescription(this.selectedDataModel.fields);
    fileInputForm.append("videoName", videoPayload.name);
    fileInputForm.append("videoDescription", videoPayload.description);
    this.uploadFile(fileInputForm, field);
  }

  getVideoNameAndDescription(fields: Field[]) {
    let videoPayload = {}
    for (let f of fields) {
      if (f.name.toLowerCase() == "name") {
        videoPayload["name"] = f.value;
      }
      if (f.name.toLowerCase() == "description") {
        videoPayload["description"] = f.value;
      }
    }
    return videoPayload;
  }

  checkSizeValidation(validationRegex: string, size: number) {
    let configuredSize = validationRegex.match(/\d+/);
    let actualSize = null;
    if (validationRegex.toLowerCase().includes("m")) {
      actualSize = size / 1024 / 1024;
    }
    else if (validationRegex.toLowerCase().includes("k")) {
      actualSize = size / 1024;
    }
    if (configuredSize.length > 0 && !isNaN(Number(configuredSize[0]))) {
      if (actualSize < +configuredSize) {
        return true;
      }
      else {
        return false;
      }
    }

  }

  onFileSuccessfullyUpload(field: Field, uploadResponse: any, fetchVideoUrl?: boolean) {
    const uploadExtractedResponse: any = {};
    uploadExtractedResponse.url = uploadResponse["url"];
    uploadExtractedResponse.fileName = uploadResponse["fileName"];
    uploadExtractedResponse.downloadFileUrl = uploadResponse["downloadFileUrl"];
    uploadExtractedResponse.fullDataUrl = uploadResponse["fullDataUrl"];
    uploadExtractedResponse.fullFileUrl = uploadResponse["fullFileUrl"];
    uploadExtractedResponse.fileCreatedOn = uploadResponse["fileCreatedOn"];
    if (uploadResponse["publicURL"]) {
      uploadExtractedResponse.publicURL = uploadResponse["publicURL"]
    }
    if (uploadResponse["vimeoVideoUrl"]) {
      uploadExtractedResponse.vimeoVideoUrl = uploadResponse["vimeoVideoUrl"];
    }
    // if (field.list == true) {
    //   if (field.value == undefined) {
    //     field.value = [];
    //   }
    //   field.value.push([uploadExtractedResponse]);
    // } else {
    //   field.value = uploadExtractedResponse;
    // }
    if (fetchVideoUrl) {
      field.loader = true;
      const requestBody: any = { url: uploadExtractedResponse.url }
      this.fileService.getVimeoVideoLink(requestBody)
        .subscribe(
          response => {
            if (response) {
              if (!response.videoStreamingAllowed) {
                this.setUploadResponse(field, uploadResponse);
              }
              else {
                this.setUploadResponse(field, uploadResponse, response.vimeoVideoUrl);
              }
            }
            field.loader = false;

          },
          error => {

          }
        )
    } else {
      this.setUploadResponse(field, uploadResponse);
    }

  }

  setUploadResponse(field: Field, uploadResponse: any, videoUrl?: string) {
    const uploadExtractedResponse: any = {};
    uploadExtractedResponse.url = uploadResponse["url"];
    uploadExtractedResponse.fileName = uploadResponse["fileName"];
    uploadExtractedResponse.downloadFileUrl = uploadResponse["downloadFileUrl"];
    uploadExtractedResponse.fullDataUrl = uploadResponse["fullDataUrl"];
    uploadExtractedResponse.fullFileUrl = uploadResponse["fullFileUrl"];
    uploadExtractedResponse.interfaceUrl = uploadResponse["interfaceUrl"];
    uploadExtractedResponse.fileCreatedOn = uploadResponse["fileCreatedOn"];
    if (uploadResponse["publicURL"]) {
      uploadExtractedResponse.publicURL = uploadResponse["publicURL"]
    }
    if (videoUrl) {
      uploadExtractedResponse.vimeoVideoUrl = videoUrl;
    }
    if (uploadResponse["vimeoVideoUrl"]) {
      uploadExtractedResponse.vimeoVideoUrl = uploadResponse["vimeoVideoUrl"];
    }

    if (field.list == true) {
      if (field.value == undefined) {
        field.value = [];
      }
      if (field.value && field.value.length > 0 && field.value[0].length > 0) {
        const tempFiles = [];
        for (let f of field.value) {
          tempFiles.push(f[0]);
        }
        field.value = tempFiles;        
      }
      field.value.push(uploadExtractedResponse);
    } else {
      field.value = uploadExtractedResponse;
    }
    this.isEntityFormValid.emit(this.checkFormisValid());
  }

  uploadFile(formData: FormData, field: Field) {
    this.fileService.upload(formData)
      .subscribe(
        response => {
            if (response && response["url"] && response["fileName"]) {
              this.onFileSuccessfullyUpload(field, response);
            }
          },
          error => {
            if (error.error && error.error.error && error.error.error.message) {
              this.snackBar.open(error.error.error.message, "Dismiss", {
                duration: 5000
              });
              return;
            }
          }
        );
  }

  viewFile(fieldValue) {
    this.fileService.download(fieldValue.downloadFileUrl, true).subscribe(
      objectUrl => {
        if (objectUrl) {
          var type = (objectUrl.split(';')[0]).replace("data:", "");
          fetch(objectUrl)
            .then(res => res.blob())
            .then(blob => {
              // const file = new File([blob], this.doc.fileName,{ type: type })
              // return (file);
            })
          // this.downloadFileObjectUrl = objectUrl;
        }
      }
    );
  }
  openModalWithComponent(doc: any, index?: number) {
    const config: ModalOptions = {
      backdrop: 'static',
      keyboard: false,
      animated: false,
      ignoreBackdropClick: true,
      class: 'modal-lg',
      initialState: {
        doc: doc,
        title: 'Document Title',
        entityView: true

      }
    };
    this.bsModalRef = this.modalService.show(ImagepdfviewerComponent, config);
    this.bsModalRef.content.closeBtnName = 'Close';
    this.bsModalRef.content.event.subscribe(res => {
      for (let i = 1; i <= this.modalService.getModalsCount(); i++) {
        this.modalService.hide(i)
      }
      const div =  document.querySelector('body');
        div.classList.remove('modal-open');
    });

  }
  openModalForOCRFieldTrack(ocrField: any, allFields, parentField?, gridIndex?) {
    const config: ModalOptions = {
      backdrop: 'static',
      keyboard: false,
      animated: false,
      ignoreBackdropClick: true,
      class: 'modal-lg width80percent',
      initialState: {
        field: ocrField,
        allFields: allFields,
        title: 'Document Title',
        entityView: true,
      }
    }
    if (this.onTaskScreen == true) {
      let fieldsToShowOcr = {};
      if (parentField) {
        ocrField.piExtractPageNo = parentField.piExtractPageNo;
        ocrField.piExtractForFileFieldName = parentField.piExtractForFileFieldName;
        ocrField.piExtractCode = parentField.piExtractCode;
        ocrField.gridIndex = gridIndex;
        fieldsToShowOcr = {
          field: ocrField,
          allFields: this.selectedDataModel.fields
        }

      } else {
        fieldsToShowOcr = {
          field: ocrField,
          allFields: allFields
        }
      }
      this.emitOpenOcr.emit(fieldsToShowOcr);
    } else {
      this.bsModalRef = this.modalService.show(ocrFieldTrackComponent, config);
      this.bsModalRef.content.closeBtnName = 'Close';
      this.bsModalRef.content.event.subscribe(res => {
        const div = document.querySelector('body');
        div.classList.remove('modal-open');
        for (let i = 1; i <= this.modalService.getModalsCount(); i++) {
          this.modalService.hide(i)
        }
      });
    }
  }
  trackTableRow(fieldsToHighlight, ocrField, dataModel) {
    let allFields = dataModel.fields;
    const config: ModalOptions = {
      backdrop: 'static',
      keyboard: false,
      animated: false,
      ignoreBackdropClick: true,
      class: 'modal-lg width80percent',
      initialState: {
        field: ocrField,
        allFields: allFields,
        tableFields: fieldsToHighlight,
        title: 'Document Title',
        entityView: true,
      }
    }
    if (this.onTaskScreen == true) {
      let fieldsToShowOcr = {};
      fieldsToShowOcr = {
        tableFields: fieldsToHighlight,
        field: ocrField,
        allFields: allFields
      }
      this.emitOpenOcr.emit(fieldsToShowOcr);
    } else {
      this.bsModalRef = this.modalService.show(ocrFieldTrackComponent, config);
      this.bsModalRef.content.closeBtnName = 'Close';
      this.bsModalRef.content.event.subscribe(res => {
        const div = document.querySelector('body');
        div.classList.remove('modal-open');
        for (let i = 1; i <= this.modalService.getModalsCount(); i++) {
          this.modalService.hide(i)
        }
      });
    }
  }
  openentityUpdateModal(item, index?, viewingNestedEntity?): void {
    if (viewingNestedEntity == undefined) {
      this.viewingNestedEntity = false;
    }
    else {
      this.viewingNestedEntity = viewingNestedEntity;
    }
    item["listvalue"] = item.value;
    const initialState = {
      entityItem: item,
      title: item.label,
      index: index,
      showFieldsMap: this.showFieldsMap,
      event: item.value,
      modalId: new Date().getTime(),
      viewingNestedEntity: this.viewingNestedEntity
    };
    const config: ModalOptions = {
      backdrop: 'static',
      keyboard: false,
      animated: false,
      ignoreBackdropClick: true,
      class: 'modal-lg width80percent',
      initialState: initialState
    }

    this.currTimeForZindex++;
    this.subEntityToBePassed = initialState;
    this.showSubEntitySection = true;
    // this.dialogRef = this.dialog.open(DynamicFormModalComponent, {
    //   data: initialState
    // });
  }
  getValueConfidence(field) {
    if (this.datapointList && this.datapointList['value']) {

      for (let index = 0; index < this.datapointList['value'].length; index++) {
        const element = this.datapointList['value'][index];
        if (typeof field.value == 'object') {
          if (element.map(item => item._id).indexOf(field.value._id) > -1) {
            let tmpfield = element[element.map(item => item._id).indexOf(field.value._id)];
            if (tmpfield.valueConfidence > 90) {
              return "greenValConf";
            } else if (tmpfield.valueConfidence > 70 && tmpfield.valueConfidence < 70) {
              return "amberValConf";
            } else {
              return "redValConf";
            }
          }
        } else {

        }

      }
    } else {
      if((this.selectedDataModel['uiProperties'].genericLLM)){ // This is for backwards compatibility V2 LLM
        let dataPOintPos = this.dataSharingService.piExtractResponse.map(item => item.key).indexOf(field._id+".name");
        if(dataPOintPos > -1){
          let tmpfield = this.dataSharingService.piExtractResponse[dataPOintPos];
          if (tmpfield.valueConfidence > 90) {
            return "greenValConf";
          } else if (tmpfield.valueConfidence > 70 && tmpfield.valueConfidence < 70) {
            return "amberValConf";
          } else {
            return "redValConf";
          }
        }
      }else{
        let tmppos = this.dataSharingService.piExtractResponse.map(item => item.key).indexOf((field._id + "." + field.name));
        
        if (tmppos > -1) {
          let currFieldFromPiextractResponse = this.dataSharingService.piExtractResponse[tmppos];
          let pgNoPos = currFieldFromPiextractResponse.pageNo;
          // let pgNoPos = this.dataSharingService.piExtractResponse[tmppos].extractedData.map(item => item.pageNo).indexOf(field.piExtractPageNo)
          if (pgNoPos > -1) {
            field.piExtractPageNo = currFieldFromPiextractResponse.pageNo;
            if (currFieldFromPiextractResponse.valueConfidence > 90) {
              return "greenValConf";
            } else if (currFieldFromPiextractResponse.valueConfidence > 70 && currFieldFromPiextractResponse.valueConfidence < 70) {
              return "amberValConf";
            } else {
              return "redValConf";
            }
          }
          
        }

      }
    }
    return "";
  }
  onSubmitOfSubEntity(result) {
    if (result.data) {
      this.viewingNestedEntity = false;
      if (!result.data["list"]) {
        this.updateInnerModel(result.data["field"], result.data["event"]);
        this.cd.detectChanges();
      } else {
        this.updateInnerModelList(result.data["field"], result.data["event"], result.data["index"])
        this.cd.detectChanges();
      }
      this.emitted.emit(this.selectedDataModel);
      this.emitcloseEnity.emit(result);
    }
    this.showSubEntitySection = false;
  }
  closeRefEntityentity(event) {
    this.EntityRefToBePassed = null;
    this.showEntityRefSection = false;
  }
  currTimeForZindex = 0;
  openDialogEnityRef(field: Field): void {
    const initialState = {
      data: field,
    };

    this.EntityRefToBePassed = initialState;
    this.showEntityRefSection = true;
    this.currTimeForZindex++;
    // const dialogRef = this.dialog.open(EntityRefModalComponent, {
    //   width: '1000px',
    //   data: field
    // });

    // dialogRef.afterClosed().subscribe(result => {
    // });
  }
  openSubEntity(event) {
    this.EntityRefToBePassed = null;
    this.showEntityRefSection = false;
    this.currTimeForZindex++;
    let tmpSubEntity = {}
    // tmpSubEntity.name = event.entityItem.referenceModel;    
    this.fetchDataModel(event.entityItem.referenceModel).subscribe(model => {
      this.fetchRefrenceEntity(event.entityItem.referenceModel, event.entityItem.value[event.index].id).subscribe(entity => {
        this.insertDataForView(model, entity);
        model["lookupEntityRefId"] = entity[commonKeys.entityLookUpRefId];
        tmpSubEntity["actualValue"] = entity[commonKeys.entityLookUpRefId];
        tmpSubEntity['value'] = model;
        this.openentityUpdateModal(tmpSubEntity['value'], event.index, true)
      });
    });


    // this.openentityUpdateModal(tmpSubEntity,event.index,true)
  }
  closeModal(modalId?: number) {
    this.modalService.hide(modalId);
  }

  onDocumentDeleteCancel() {
    this.selectedDocumentFieldToBeDelete = null;
    this.bsModelDelete.hide();
  }

  onDocumentDeleteConfrimation() {
    const field = this.selectedDocumentFieldToBeDelete;
    var docValue = field.value;
    if (field.list) {
      if (docValue[this.selectedDocumentFieldToBeDeleteIndex].length == undefined) {
        docValue = docValue[this.selectedDocumentFieldToBeDeleteIndex];
      }
      else {
        docValue = docValue[this.selectedDocumentFieldToBeDeleteIndex][0]
      }
      // docValue = docValue[0];
    }

    let vimeoId = null
    if (docValue && docValue.vimeoVideoUrl != undefined && docValue.vimeoVideoUrl.length > 0) {
      vimeoId = docValue.vimeoVideoUrl.match(/\d+/)[0];
    }
    this.fileService.delete(docValue.url, vimeoId)
      .subscribe(
        response => {
          if (this.selectedDocumentFieldToBeDeleteIndex != undefined) {
            let index = this.selectedDataModel.fields.indexOf(field)
            if (index != -1) {
              this.selectedDataModel.fields[index].value.splice(this.selectedDocumentFieldToBeDeleteIndex, 1);
            }
          } else {
            let index = this.selectedDataModel.fields.indexOf(field)
            if (index != -1) {
              this.selectedDataModel.fields[index].value = undefined;
            }
          }
          this.form = this.toFormGroup(this.selectedDataModel.fields);

          this.onChanges();

          setTimeout(() => {  // To avoid first on change when the form is created
            this.runAllDependencyExpressions();
            this.onFieldChange();
          }, 10);
          this.bsModelDelete.hide();

        },
        error => {

        }
      )

  }

  onRemoveDocument(templateRef: TemplateRef<any>, field: Field, ind?: number) {
    this.selectedDocumentFieldToBeDelete = field;
    this.selectedDocumentFieldToBeDeleteIndex = ind;
    this.bsModelDelete = this.modalService.show(templateRef, { class: 'modal-dialog modal-md modal-dialog-centered' });
  }

  getFileName(field: Field) {
    return this.decodeEntities(field.value.fileName);
  }

  updateInnerModel(field, event) {
    field.value = event;
    this.emitted.emit(this.selectedDataModel);
    this.showSubEntitySection = false;
  }

  onBooleanChange(event, field?: Field) {

  }

  //Autocomplete

  selectEvent(item, field) {
    // do something with selected item
    field.value = item;
    this.emitted.emit(this.selectedDataModel);
    this.showSubEntitySection = false;
  }

  onSelectChange(item, field) {
    if (!field.list && field["entityRefValue"] != item.source.value.name) {
      field["actualValue"] = item.source.value.id;
      field["viewEntity"] = true;
      field["entityRefValue"] = item.source.value.name;
      this.fetchDataModel(field.referenceModel).subscribe(model => {
        this.fetchRefrenceEntity(field.referenceModel, field["actualValue"]).subscribe(entity => {
          this.insertDataForView(model, entity);
          model["lookupEntityRefId"] = entity[commonKeys.entityLookUpRefId]
          field.value = model;
        });
      });
    }
  }

  onSelectChangeList(item, field: any) {
    field["actualValue"] = [];
    field.listvalue = [];
    this.fetchDataModel(field.referenceModel).subscribe(model => {
      field["viewEntity"] = true;
      field.value.forEach((val, index) => {
        let modelCopy = JSON.parse(JSON.stringify(model))
        this.fetchRefrenceEntity(field.referenceModel, val.id).subscribe(entity => {
          this.insertDataForView(modelCopy, entity);
          field["actualValue"].push(val.id);
          modelCopy[commonKeys.entityLookUpRefLabel] = entity[commonKeys.entityLookUpRefLabel];
          modelCopy["lookupEntityRefId"] = entity[commonKeys.entityLookUpRefId];
          field.listvalue[index] = modelCopy;
        });
      });
    });
  }

  onChangeSearch(event) {
    // fetch remote data from here

    //this.emitted.emit(this.selectedDataModel);
  }

  onFocused(e) {
    // do something when input is focused
  }

  onDateChanged(event) {
    if (event) {
      // this.selectedAgent.uiComponent.startTime = event["jsdate"];
    }
  }

  onTimeChanged(event, field) {
    if (field.value && field.value['date']) {
      let temp = field.value['date']
      let currentTimeRef = new Date(event)
      temp['hour'] = currentTimeRef.getHours();
      temp['min'] = currentTimeRef.getMinutes();
      // this.selectedDataModel.fields.forEach((oldField, index) => {
      //   if (oldField.name == field.name) {
      //     oldField.value = "";
      //     oldField.value = temp;
      //   }
      // });
    }
  }

  closeDialogSave(field?: any) {
    let validationArr = this.entiySharingService.checkValidation(this.selectedDataModel);
    // if (validationArr.length == 0 && !validationArr.includes(false)) {
    //   this.selectedDataModel["dialogRef"].close({ event: 'close', data: this.selectedDataModel["map"] });
    // }
    if (this.gridOpts && this.gridOpts.isgrid != true) {
      this.snackBar.open("Record Saved", "Dismiss", {
        duration: 3000
      });
    }
    this.onSubmitOfSubEntity({ event: 'close', data: this.selectedDataModel["map"], index: 97865678 });
    this.showSubEntitySection = false;
  }

  closeDialog(field?: any) {
    this.selectedDataModel["map"] = {};
    // this.selectedDataModel["dialogRef"].close({ event: 'close', data: false });
    this.onSubmitOfSubEntity({ event: 'close', data: false, index: 97865678 })
    this.showSubEntitySection = false;
  }

  pageChange(event) {
  }

  runAllDependencyExpressions() {
    let payloadToEvaluateMVEL = this.getPayloadToEvaluateMVEL();
    payloadToEvaluateMVEL['onChange'] = false;

    if (this.selectedDataModel && this.selectedDataModel.fields) {
      for (const field of this.selectedDataModel.fields) {
        if (field && field.dependencyExpressionList && field.dependencyExpressionList.length > 0) {
          if (!payloadToEvaluateMVEL['fields']) {
            payloadToEvaluateMVEL['fields'] = [];
          }

          payloadToEvaluateMVEL['fields'].push(field.name);
        }
      }
    }


    if (payloadToEvaluateMVEL && payloadToEvaluateMVEL['fields'] && payloadToEvaluateMVEL['fields'].length > 0) {
      this.getMVELResult(payloadToEvaluateMVEL);
    }
  }

  getMVELResult(payloadToEvaluaetMVEL) {
    this.subscription = this.entityService.evaluateMvel(this.selectedDataModel.name, payloadToEvaluaetMVEL)
      .subscribe(
        mvelResult => {
          let recursiveCallFields = {};

          if (mvelResult && Object.keys(mvelResult).length > 0) {
            for (const key of Object.keys(mvelResult)) {
              if (key && mvelResult[key] && Object.keys(mvelResult[key]).length > 0) {
                const field = this.selectedDataModelFieldMap.get(key);

                if (field) {
                  for (const action of Object.keys(mvelResult[key])) {
                    if (action && mvelResult[key][action]) {
                      const actionResult = mvelResult[key][action]['result'];
                      const actionMessage = mvelResult[key][action]['message'];

                      if (action == DependencyExpressionActions.ENABLE.toString()) {
                        field.disable = !actionResult;
                      } else if (action == DependencyExpressionActions.SHOW.toString()) {
                        field.hide = !actionResult;
                      } else if (action == DependencyExpressionActions.MANDATORY.toString()) {
                        field.mandatory = actionResult;

                        if (actionResult) {
                          this.form.controls[field.name].setValidators(Validators.required);
                        } else {
                          this.form.controls[field.name].clearValidators();
                        }
                      } else if (action == DependencyExpressionActions.ERROR.toString()) {
                        if (actionResult && actionMessage) {
                          this.errorMap[key] = actionMessage;
                        } else {
                          delete this.errorMap[key];
                        }
                      } else if (action == DependencyExpressionActions.WARNING.toString()) {
                        if (actionResult && actionMessage) {
                          this.warningMap[key] = actionMessage;
                        } else {
                          delete this.warningMap[key];
                        }
                      } else if (action == DependencyExpressionActions.VALUE.toString()) {
                        if (field.type == commonKeys.entityLookupReference) {
                          field['actualValue'] = actionResult;
                        } else if (field.type == "DATE" || field.type == "DATETIME") {
                          let date = new Date(actionResult);
                          field.value = this.gateDateToDisplay(field, date);
                        } else {
                          if (actionResult != null) {
                            field.value = actionResult;
                          }

                        }

                        let shouldRunMVEL = false;

                        if (field.dependencyExpressionList) {
                          for (const dependencyExp of field.dependencyExpressionList) {
                            if (dependencyExp && dependencyExp.expression && dependencyExp.triggerEvent && dependencyExp.triggerEvent == 'ON_CHANGE') {
                              shouldRunMVEL = true;
                              break;
                            }
                          }
                        }

                        if (shouldRunMVEL) {
                          recursiveCallFields[field.name] = true;
                        }
                      }
                    }
                  }
                }

              }
            }
            this.isEntityFormValid.emit(this.checkFormisValid());
          }

          if (recursiveCallFields && Object.keys(recursiveCallFields).length > 0) {
            let payloadToEvaluateMVEL = this.getPayloadToEvaluateMVEL();
            payloadToEvaluateMVEL['onChange'] = true;
            payloadToEvaluateMVEL['fields'] = Object.keys(recursiveCallFields);
            this.getMVELResult(payloadToEvaluateMVEL);
          }
        }
      );
  }

  getPayloadToEvaluateMVEL() {
    this.mvelPayloadMap = {};

    for (let field of this.selectedDataModel.fields) {
      this.mvelPayloadMap = this.createMap(field, this.mvelPayloadMap);
    }

    if (this.viewEntity) {
      if (this.viewEntity['_id']) {
        this.mvelPayloadMap['_id'] = this.viewEntity['_id'];
      }
      if (this.viewEntity['companyId']) {
        this.mvelPayloadMap['companyId'] = this.viewEntity['companyId'];
      }
      if (this.viewEntity['createdAt']) {
        this.mvelPayloadMap['createdAt'] = this.viewEntity['createdAt'];
      }
      if (this.viewEntity['createdBy']) {
        this.mvelPayloadMap['createdBy'] = this.viewEntity['createdBy'];
      }
      if (this.viewEntity['updatedAt']) {
        this.mvelPayloadMap['updatedAt'] = this.viewEntity['updatedAt'];
      }
      if (this.viewEntity['updatedBy']) {
        this.mvelPayloadMap['updatedBy'] = this.viewEntity['updatedBy'];
      }
      if (this.viewEntity['stageCd']) {
        this.mvelPayloadMap['stageCd'] = this.viewEntity['stageCd'];
      }
      if (this.viewEntity['statusCd']) {
        this.mvelPayloadMap['statusCd'] = this.viewEntity['statusCd'];
      }
      if (this.viewEntity['datamodelId']) {
        this.mvelPayloadMap['datamodelId'] = this.viewEntity['datamodelId'];
      }
      if (this.viewEntity['_entityName']) {
        this.mvelPayloadMap['_entityName'] = this.viewEntity['_entityName'];
      }
    }
    return {
      'payload': this.mvelPayloadMap,
      'state': this.state
    };
  }

  createMap(field: Field, existingPayload?: any) {
    let payload = existingPayload ? existingPayload : {};

    if (field && field.type) {
      if (field.type == "MODEL" && field.value) {
        if (field.list) {
          let innerPayloadList = [];

          for (let innerModel of field.value) {
            if (innerModel && innerModel.value && innerModel.value.fields) {
              let innerPayload = {};

              for (let innerField of innerModel.value.fields) {
                innerPayload = this.createMap(innerField, innerPayload);
              }

              if (innerPayload && Object.keys(innerPayload).length > 0) {
                innerPayloadList.push(innerPayload);
              }
            }
          }

          if (innerPayloadList && innerPayloadList.length > 0) {
            payload[field.name] = innerPayloadList;
          }
        } else {
          if (field.value.fields) {
            let innerPayload = {};

            for (let innerField of field.value.fields) {
              innerPayload = this.createMap(innerField, innerPayload);
            }

            if (innerPayload && Object.keys(innerPayload).length > 0) {
              payload[field.name] = innerPayload;
            }
          }
        }
      } else if (field.type == commonKeys.entityLookupReference && field['actualValue']) {
        payload[field.name] = this.getFieldValue(field, field['actualValue']);
      } else if ((field.type == "DATE" || field.type == "DATETIME") && field.value) {
        payload[field.name] = this.getFieldValue(field, this.getMillis(field['value']));
      } else if (field.value) {
        payload[field.name] = this.getFieldValue(field, field['value']);
      }
    }

    return payload;
  }

  getMillis(date: any) {
    let dt = new Date();
    dt.setFullYear(date.date['year']);
    dt.setMonth(+date.date['month'] - 1);
    dt.setDate(date.date['day']);

    if (date.date['hour'] && date.date['min']) {
      dt.setHours(date.date['hour']);
      dt.setMinutes(date.date['min']);
    }

    return dt.getTime();
  }

  getFieldValue(field: Field, value: any) {
    let tempField: Field = JSON.parse(JSON.stringify(field));
    let tempValue: Field = JSON.parse(JSON.stringify(value));

    if (tempField.validationExpression) {
      return this.validateRegex(tempField, tempValue);
    } else {
      return tempValue;
    }
  }

  validateRegex(field: Field, value: any, showError?: boolean) {
    if (field.validationExpression && field.validationExpression.length > 0) {
      const matchValues = (value+"").match(field.validationExpression);
      if (!matchValues || matchValues.length == 0) {
        if (showError) {
          var errorMsg = "Invalid input for field :: " + field.name;
          if (field.errorMessage) {
            errorMsg = field.errorMessage;
          }
          this.snackBar.open(errorMsg, "Dismiss", {
            duration: 5000
          });
        }

        return null;
      } else {
        var regexExp = new RegExp(field.validationExpression, "g");
        if (regexExp) {
          var matches = regexExp.exec(value);
          if (matches && matches.length > 0 && value && value.length > 0 && matches[0] != null && matches[0].length > 0) {
            return matches[0];
          } else {
            return null;
          }
        }
      }
    }

    return value;
  }
  removeSelectValue(field: Field) {
    field.value = null;
  }

  navigateToTask() {
    this.router.navigate(['/task'], { queryParams: { entityId: this.stateInstanceId } });
    // this.router.navigate(['/task', this.stateInstanceId]);
  }

  downloadFile(field: Field, i?: any) {
    if (field.value) {
      var docValue = field.value;
      if (field.list && field.value.length > 0) {
        docValue = field.value[i];
        if (docValue instanceof Array && docValue[0]) {
          docValue = docValue[0];
        }
      }
      if (docValue.downloadFileUrl.startsWith("http")) {
        window.open(docValue.downloadFileUrl, "_blank");
        return;
      }
      const body = { functionInstanceName: "API" };
      field.loader = true
      this.fileService.getAWSCreds(body)
        .subscribe(
          response => {
            field.loader = false;
            if (!response.s3Allowed) {
              this.fileService.download(docValue.downloadFileUrl);
            }
            else {
              if(docValue.url){
              this.downloadFromS3(field, response, docValue.url);
              }else{
                this.downloadFromS3(field, response, docValue.downloadFileUrl);
              }
            }
          },
          error => { }
        )
    }
  }
  videoURL(url) {
    let videoId = url.match(/\d+/);
    if (!isNaN(Number(videoId))) {
      url = "https://player.vimeo.com/video/" + videoId[0];
      return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
  }
  decodeEntities(encodedString) {
    if (encodedString && typeof encodedString == "string") {
      var translate_re = /&(nbsp|amp|quot|lt|gt);/g;
      var translate = {
        "nbsp": " ",
        "amp": "&",
        "quot": "\"",
        "lt": "<",
        "gt": ">"
      };
      return encodedString.replace(translate_re, function (match, entity) {
        return translate[entity];
      }).replace(/&#(\d+);/gi, function (match, numStr) {
        var num = parseInt(numStr, 10);
        return String.fromCharCode(num);
      });
    }
    return encodedString;
  }
  @ViewChild('editor', { static: false }) editor: any;
  onPaste(event: ClipboardEvent, field, form: FormGroup) {

    let contentToPaste = "";
    if (event.clipboardData.getData('text')) {
      contentToPaste = event.clipboardData.getData('text/html');
    } else {
      contentToPaste = event.clipboardData.getData('text');
    }
    this.editor.focus();
    if (!field.value)
      field.value = "";
    if (contentToPaste == "") {
      let items = (event.clipboardData).items;
      for (let index in items) {
        let item = items[index];
        if (item.kind === 'file') {
          event.preventDefault();
          let strPlace = "{{" + new Date().getTime() + "_ReplaceString}}";
          this.editor.editorService.insertHtml(strPlace);
          let blob = item.getAsFile();
          let reader = new FileReader();
          reader.onload = function (fileEvent) {
            let valuePositionSplitArray = field.value.split(strPlace);
            if (valuePositionSplitArray[1] == undefined) valuePositionSplitArray.push("");
            let newValue = valuePositionSplitArray[0] + "<img src=" + fileEvent.target["result"] + ">" + valuePositionSplitArray[1];
            newValue.replace("undefined", "");
            field.value = newValue
            form.controls[field.name].setValue(field.value);
          }; // data url!
          reader.readAsDataURL(blob);
        }
      }
    } else {
      event.preventDefault();
      let strPlace = "{{" + new Date().getTime() + "_ReplaceString}}";
      this.editor.editorService.insertHtml(strPlace);
      let valuePositionSplitArray = field.value.split(strPlace);
      if (valuePositionSplitArray[1] == undefined) valuePositionSplitArray.push("");
      let newValue = valuePositionSplitArray[0] + contentToPaste + valuePositionSplitArray[1];
      newValue.replace("undefined", "");
      field.value = newValue
      form.controls[field.name].setValue(field.value);
    }
  }

  getUserFileName(item) {
    if (item && item.length == undefined) {
      return this.getUserFileNameFromMap(item);
    }
    if (item && item[0]) {
      return this.getUserFileNameFromMap(item[0]);      
    }
    return "-";
  }

  getUserFileNameFromMap(item: any) {
    if (item.userFileName) {
      return this.decodeEntities(item.userFileName);
    }
    return this.decodeEntities(item.fileName);
  }

  updateIfGrid() {
    if (this.gridOpts && this.gridOpts.isgrid == true) {
      this.closeDialogSave();
    }
  }
  deleteFromGrid(gridList, index) {
    gridList.splice(index, 1);
    // this.saveEntity('save');
    this.removeSubEntity(gridList[index]);
  }

  setAccessOfUserForDataModel(dataModel: DataModel) {
    if (!dataModel || !dataModel.name) {
      return;
    }
    if (dataModel.subEntity) {
      dataModel.allowCreate = true;
      dataModel.allowUpdate = true;
      dataModel.allowDelete = true;
      return;
    }
    let accessControlReq = new AccessControlRequest();
    accessControlReq.componentType = "ENTITY";
    accessControlReq.componentName = dataModel.name;
    accessControlReq.userId = this.universalUser.getUser()._id;
    this.accessControlService.getUserAccess(accessControlReq)
      .subscribe(
        accessControl => {
          if (!accessControl || accessControl.operationAccessList == null || accessControl.operationAccessList.length == 0) {
            dataModel.allowCreate = true;
            dataModel.allowUpdate = true;
            dataModel.allowDelete = true;            
          }
          else {
            if (accessControl.operationAccessList) {
              for(let operation of accessControl.operationAccessList) {
                if (operation.operationName == "Create" && operation.enabled) {
                  dataModel.allowCreate = true;                
                }
                if (operation.operationName == "Update" && operation.enabled) {
                  dataModel.allowUpdate = true;                
                }
                if (operation.operationName == "Delete" && operation.enabled) {
                  dataModel.allowDelete = true;                
                }
                if (operation.operationName == "View" && operation.enabled) {
                  dataModel.allowView = true;                
                }
              }
            }
          }
          
      });
  }
  userList:any[]=[];
  groupList:any[]=[];
  getRefList(field:Field,selectedDatamodel){
    let datmodelName = selectedDatamodel.name;
    if (field.type == 'USER' && datmodelName) {
      this.dataModelService.getDataModelLookupByType(datmodelName,field.type,field.name).subscribe(
        response=>{
          this.userList = response;
        }
      );
    }
    if (field.type == 'GROUP' && datmodelName) {
      this.dataModelService.getDataModelLookupByType(datmodelName,field.type,field.name).subscribe(
        response=>{
          this.groupList = response;
        }
      );
    }
  }
  checkChecked(field,parentFormGroup,val){
    const formControlValue = field.value;
    if(typeof formControlValue == 'boolean' || !formControlValue){
      return false;
    }else{
      let valArr =formControlValue;
      if(valArr.indexOf(val) > -1) return true;
    }
    return false;
  }
  onCheckChange(event,field,parentFormGroup:FormGroup) {
    const formControlValue = field.value;
    let valArr =[];
    // console.log(typeof formControlValue)
    if(typeof formControlValue == 'boolean' || !formControlValue) {field.value=[];}else{valArr = field.value;}
    let pos = valArr.indexOf(event.target.value);
    if (pos == -1) {
      valArr.push(event.target.value);
    }else{
      valArr.splice(pos,1);
    }
    field.value=valArr;
  }
  getHtmlContent(htmlDoc:any)
  {
    return this.sanitizer.bypassSecurityTrustHtml(htmlDoc);
  }
  nl2br (str, is_xhtml?) {
    var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
  }
  checkServerValidations(field:Field){
    let pos = this.validationErrorList.map(item=> item.fieldName).indexOf(field.name);
    if(pos>-1){
      return {
        type:this.validationErrorList[pos].type,
        message:this.validationErrorList[pos].message
      };
    }else{
      return {
        type:"",message:""
      };
    }
  }
  fieldToHighlight(highlightField){
    let element = document.getElementById(highlightField.fieldName);
    if (element) {
      document.getElementById(highlightField.fieldName).focus();
    } 
  }
  getErrorCountByType(errorType){
    return this.validationErrorList.filter(itm=>itm.type == errorType).length;
  }
  errPanelName='ERROR';
  errorsByType:any[] =[];
  showErrorPanel(errorTypes){
    if(this.errPanelName == errorTypes){
      this.errPanelName = '';
    }else{
      this.errPanelName = errorTypes;
      this.errorsByType = this.validationErrorList.filter(item=>item.type == errorTypes)
    }
    
  }
  fieldsInGroup(group,fields){
    let fieldsInGroup = []
    for (let index = 0; index < fields.length; index++) {
      const field = fields[index];

      if(field.groupName == group.name){

        if(field.hide==false){
          fieldsInGroup.push(field)
        }

      }
    }
    return fieldsInGroup.length
  }
}
