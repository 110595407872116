import { v4 as uuid } from 'uuid';
import * as Stomp from 'stompjs';
import * as SockJS from 'sockjs-client';
import { Subject, Observable, from } from "rxjs";
import { Component, OnInit, TemplateRef, Output, EventEmitter, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router, Data } from '@angular/router';

//services
import { CommonService } from '../../service/common.service';
import { TaskService } from '../../service/task.service';
import { FetchUserService, AllocateTaskToUser } from '../../service/userhierarchy.service'
import { StateService } from '../../service/state.service';
import { UniversalUser, FileService, TagsService, GraphService, CommunicationService, EntiySharingService } from '../../service/shared.service';

//models
import { State, DataPoint, GraphObject, TaskDecision, Document, TimelineStateAuditData, TaskTags, TaskFilter, StateStage, StateModel, DocumentSubSet, Task, TASK_FOLDER, SearchableDataPointResponse } from '../../models/tasks.model';
import { CommonSearchModel } from '../../models/shared.model';
import { UserHierarchy, User } from '../../models/user.model'
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import * as moment from 'moment';
import { state } from '@angular/animations';
import { SplitAreaDirective, SplitComponent } from 'angular-split';
import { EntityService } from '../../service/entity.service';
import { DataModelService } from '../../service/datamodel.service';
import { DataModel } from '../../models/datamodel.model';
import { Entity } from '../../models/datamodel.model';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { TaskActivity } from '../../models/task-activity.model';
import { JSONParser } from '@amcharts/amcharts4/core';
import { EnvironmentData } from 'src/app/service/environments.service';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-separate-task-listing',
  templateUrl: './separate-task-listing.component.html',
  styleUrls: ['./separate-task-listing.component.css']
})
export class SeparateTaskListingComponent implements OnInit {
  @ViewChild('split', { static: false }) split: SplitComponent
  @ViewChild('area1', { static: false }) area1: SplitAreaDirective
  @ViewChild('area2', { static: false }) area2: SplitAreaDirective

  modalRef: BsModalRef;
  ortBy = '';
  sortOrder = 'asc';
  filterQuery = '';
  showFilter: boolean = false;
  showStages: boolean = false;
  leftPaneWidth = 65;
  pageNumber: any;
  assignedTaskPageNumber: number;
  unassignedTaskPageNumber: number;
  assignedTaskPageNumberTeam: number;
  flaggedTaskPageNumber: number;
  fetchRecords: any;
  userId: string;
  progressBarFlag: boolean = false;
  graphObjects = new Map();
  assignedStategraphObject: GraphObject;
  assignedStategraphObjectTeam: GraphObject;
  actionMap: any;
  fieldKeyMap: any;
  responseError: string;
  taskDecision: TaskDecision;
  arrayTableHeaders = {};
  timelineStates: TimelineStateAuditData[] = [];
  selectedTimeLineState: TimelineStateAuditData;
  statusList: any;
  optionList: any[];
  taskTags: any[] = [];
  allProcessStages: any = {};
  selectedProcessStages: any = {};
  activeGraphObjectList: GraphObject[] = [];
  taskTagsObject: TaskTags;
  selectedTab: string;
  showSubTabs = 'ASSIGNED';
  searchTerm: string;
  dataPoints: DataPoint[];
  selectedState: State;
  graphObject: GraphObject;
  companyDetails:any;
  showTopBar:boolean = true;

  allTasks: Task[];
  assignedActiveTasks: Task[];
  assignedClosedTasks: Task[];
  groupActiveTasks: Task[];
  teamActiveTasks: Task[];
  teamClosedTasks: Task[];
  peersActiveTasks: Task[];

  // total count
  allTasksCount: number = 0;
  assignedActiveTasksCount: number = 0;
  assignedClosedTasksCount: number = 0;
  groupActiveTasksCount: number = 0;
  teamActiveTasksCount: number = 0;
  teamClosedTasksCount: number = 0;
  peersActiveTasksCount: number = 0;

  // first time set
  firstTimeAssignedActiveTasks: boolean = true;
  firstTimeAssignedClosedTasks: boolean = false;
  firstTimeGroupActiveTasks: boolean = false;
  firstTimeTeamActiveTasks: boolean = false;
  firstTimeTeamClosedTasks: boolean = false;

  // progress tasks
  progressTasks: Task[];

  assignedTaskDdetails: State;
  assignedStateTabclass = {};
  assignedTaskActionButtonEnabled = {};


  unassignedTaskDdetails: State;
  unassignedStateTabclass = {};
  unassignedTaskActionButtonEnabled = {};


  assignedTaskDetailsTeam: State;
  assignedStateTabclassTeam = {};
  assignedTaskActionButtonEnabledTeam = {};

  tempArchivedStates: State[];
  archivedTaskDdetails: State;
  archivedStateTabclass = {};
  archivedTaskActionButtonEnabled = {};

  currentSelectedTaskDetails: State;
  currentSelectedTask: Task;

  loadingUnassigned: boolean = false;
  loadingAssigned: boolean = false;
  loadingAssignedTeam: boolean = false;
  loadingArchived: boolean = false;
  stagesFetched: boolean = false;

  showLoaderOverlay = {
    taskListing: true,
    mainScreen: true,
    taskBar: false
  }


  //filters
  processFilter: any[];
  tagFilter: any[];
  groupFilter: any[];
  taskFilter: TaskFilter;
  showTagFilter: boolean = false;
  allTaskFilter: TaskFilter;
  assignedActiveTaskFilter: TaskFilter;
  assignedClosedTaskFilter: TaskFilter;
  groupActiveTaskFilter: TaskFilter;
  teamActiveTaskFilter: TaskFilter;
  teamClosedTaskFilter: TaskFilter;
  peersActiveTaskFilter: TaskFilter;

  allTaskFilterQuery: string = "";
  assignedActiveTaskFilterQuery: string = "";
  assignedClosedTaskFilterQuery: string = "";
  groupActiveTaskFilterQuery: string = "";
  teamActiveTaskFilterQuery: string = "";
  teamClosedTaskFilterQuery: string = "";
  peersActiveTaskFilterQuery: string = "";


  processOptions: any[] = [];
  searchableDataPoints: SearchableDataPointResponse[] = [];
  filterGroups: any[] = [];
  groupNames: string[] = [];
  dropdownSettings: IDropdownSettings;

  //documents
  documentsForState = {};
  documentsToBeUploaded = [];
  docLengthForState: number = 0;

  showEmailSonar:boolean=false;
  //tabs
  TAB_ASSIGNED = 'ASSIGNED';
  TAB_UNASSIGNED = 'UNASSIGNED';
  TAB_TEAM_ASSIGNED = "ASSIGNED_TEAM";
  TAB_ARCHIVED = "ARCHIVED";

  TABLINKS_ACTIVE = "block active";
  TABLINKS = "block"
  assignedCount: number;
  unassignedCount: number;
  currentFolderTaskCount: number;
  personalFetched = false;
  personalTeamFetched = false;
  groupFetched = false;
  archiveFetched = false;
  isToogle: boolean = true;
  viewSpecificState: boolean = false;
  showMain: boolean = true;

  //users
  userName: string;
  users: UserHierarchy[] = [];
  peers: any[] = [];
  userHierarchy: UserHierarchy;
  tempUser: User;
  allocatedAssignedTaskToUserId: string;
  allocatedUnAssignedTaskToUserId: string;
  allocatedTeamActiveTaskToUserId: string;
  userChildren: UserHierarchy[];

  //UI Flags
  box: number;
  bulkBox: string = "";

  //helper section
  messageOut: string;

  dropdownList: any[];
  selectedItems: any[];

  //records
  recordsMessage: string = "";
  records: boolean = true;

  // Bulk Tasks
  selectedBulkTasks: any[] = [];
  bulkEditTemplate: boolean = false;

  // @Output() userOutput = new EventEmitter<UserHierarchy[]>();
  FOLDER_OPTION_ALL = "ALL";
  FOLDER_OPTION_ASSIGNED = "ASSIGNED";
  FOLDER_OPTION_GROUP = "GROUP";
  FOLDER_OPTION_TEAM = "TEAM";
  FOLDER_OPTION_PEERS = "PEERS";
  FOLDER_OPTION_ASSIGNED_ACTIVE = "ASSIGNED_ACTIVE";
  FOLDER_OPTION_ASSIGNED_CLOSED = "ASSIGNED_CLOSED";
  FOLDER_OPTION_TEAM_ACTIVE = "TEAM_ACTIVE";
  FOLDER_OPTION_TEAM_CLOSED = "TEAM_CLOSED";
  public options = [
    { display: 'Self', value: this.FOLDER_OPTION_ASSIGNED },
    { display: 'Group', value: this.FOLDER_OPTION_GROUP },
    // { display: 'Archived', value: 'ARCHIVED' },
  ];
  selectedFolderName: string = this.FOLDER_OPTION_ASSIGNED_ACTIVE;
  parentSelectedFolderName: string = this.FOLDER_OPTION_ASSIGNED;
  selectedFolderNameForAssignedTask: string = this.FOLDER_OPTION_ASSIGNED_ACTIVE;
  selectedFolderNameForTeamTask: string = this.FOLDER_OPTION_TEAM_ACTIVE;


  viewEntity: any = null;
  selectedDataModelId: string;
  //selectedDataModel:any = null;
  selectedDataModel: DataModel = new DataModel();

  finalDataModel: any = new DataModel();
  associatedEntityValid: boolean = true;
  entityMap: any = {};
  machineIds: any = {};
  entityAssociatedWithTask: boolean = false;
  associatedEntityName: string;
  associatedEntityId: string;
  selectedTabIndex: number = 0;
  entityTabClicked: boolean = false;

  stateId: String = null;

  // task ref types
  TASK_REF_TYPE_CONVERSE = "CONVERSE"
  TASK_REF_TYPE_PROCESS = "PROCESS"

  responseErrorFields: any[] = [];
  customQuery: any;
  checkboxAll =false;
  // query params
  taskType: string = null;
  startTime: string = null;
  endTime: string = null;

  // task types
  TASK_TYPE_TEAM_PENDING = "TEAM_PENDING";
  TASK_TYPE_TEAM_UNASSIGNED = "TEAM_UNASSIGNED";
  TASK_TYPE_TEAM_OVERDUE = "TEAM_OVERDUE";
  TASK_TYPE_TEAM_ESCALATED = "TEAM_ESCALATED";
  TASK_TYPE_TEAM_RESERVED = "TEAM_RESERVED";
  TASK_TYPE_TEAM_COMPLETED = "TEAM_COMPLETED";
  TASK_TYPE_PENDING = "PENDING";
  TASK_TYPE_UNASSIGNED = "UNASSIGNED";
  TASK_TYPE_OVERDUE = "OVERDUE";
  TASK_TYPE_ESCALATED = "ESCALATED";
  TASK_TYPE_RESERVED = "RESERVED";
  TASK_TYPE_COMPLETED = "COMPLETED";
  customerId:string;

  // websocket
  stompClient: any = null;

  constructor(private commonService: CommonService,
    private communicationService: CommunicationService,
    private taskService: TaskService,
    private universalUser: UniversalUser,
    private stateService: StateService,
    private fetchUserService: FetchUserService,
    private allocateTaskToUser: AllocateTaskToUser,
    private modalService: BsModalService,
    private fileService: FileService,
    private tagService: TagsService,
    private graphService: GraphService,
    private route: ActivatedRoute,
    private router: Router,
    private entityService: EntityService,
    private entitySharingService: EntiySharingService,
    private snackBar: MatSnackBar,
    private dataModelService: DataModelService,
    private environmentData: EnvironmentData,
    private titleService: Title
  ) {
    this.titleService.setTitle("Workbench - Classic");
    this.assignedStateTabclass = {};
    this.userChildren = [];
    this.selectedProcessStages = {};
    this.machineIds = {};
    this.assignedTaskDdetails = new State();
    this.unassignedTaskDdetails = new State();
    this.assignedTaskDetailsTeam = new State();
    this.archivedTaskDdetails = new State();
    this.assignedTaskActionButtonEnabled = {};
    this.assignedStategraphObject = new GraphObject();
    this.assignedStategraphObjectTeam = new GraphObject();
    this.taskDecision = new TaskDecision();
    this.userHierarchy = new UserHierarchy();
    this.selectedTimeLineState = new TimelineStateAuditData();
    this.taskTagsObject = new TaskTags();
    this.taskFilter = new TaskFilter();
    this.currentSelectedTaskDetails = new State();
    this.selectedState = new State();
    this.graphObject = new GraphObject();
    // this.selectedDataModel = new DataModel();
    this.allTaskFilter = new TaskFilter();
    this.assignedActiveTaskFilter = new TaskFilter();
    this.assignedClosedTaskFilter = new TaskFilter();
    this.groupActiveTaskFilter = new TaskFilter();
    this.teamActiveTaskFilter = new TaskFilter();
    this.teamClosedTaskFilter = new TaskFilter();
    this.peersActiveTaskFilter = new TaskFilter();
    this.currentSelectedTask = null;
    this.allTasks = [];
    this.assignedActiveTasks = [];
    this.assignedClosedTasks = [];
    this.groupActiveTasks = [];
    this.teamActiveTasks = [];
    this.teamClosedTasks = [];
    this.peersActiveTasks = [];
    this.progressTasks = [];
    this.customQuery = this.router.getCurrentNavigation() ? this.router.getCurrentNavigation().extras.state : null;
  }

  ngOnInit() {
    this.communicationService.setAssocEntityDetails(null);
    this.selectedTab = this.TAB_ASSIGNED;
    this.pageNumber = 0
    this.assignedTaskPageNumber = 0;
    this.unassignedTaskPageNumber = 0;
    this.assignedTaskPageNumberTeam = 0;
    this.flaggedTaskPageNumber = 0;
    this.fetchRecords = 10;

    this.userId = this.universalUser.getUser()._id;
    this.companyDetails = this.universalUser.getCompanyDetails();
    if(this.companyDetails!=null && this.companyDetails['allowCustom']==true){
      if (this.companyDetails['flags']['showallOnWorkspaceByDefault'] == false) {
        this.onFolderSelection(this.FOLDER_OPTION_ALL);
        this.showTopBar = false;
      }
      
    }
    this.userName = this.universalUser.getUser().username;
    if(localStorage.getItem('filterDetails')){
      let filterDetails = JSON.parse(localStorage.getItem('filterDetails'));
      this.parentSelectedFolderName = localStorage.getItem("parentSelectedFolderName");
      this.selectedFolderName = localStorage.getItem("selectedFolderName");
      this.setFilterOnReturn(filterDetails,this.selectedFolderName);
      localStorage.removeItem("filterDetails");
      localStorage.removeItem("selectedFolderName");
      localStorage.removeItem("parentSelectedFolderName");
    }else{
      this.initTaskFilter(this.allTaskFilter, TASK_FOLDER.ALL, "ACTIVE");
      this.initTaskFilter(this.assignedActiveTaskFilter, TASK_FOLDER.Personal, "ACTIVE");
      this.initTaskFilter(this.assignedClosedTaskFilter, TASK_FOLDER.Personal, "CLOSED");
      this.initTaskFilter(this.groupActiveTaskFilter, TASK_FOLDER.Group, "ACTIVE");
      this.initTaskFilter(this.teamActiveTaskFilter, TASK_FOLDER.Team, "ACTIVE");
      this.initTaskFilter(this.teamClosedTaskFilter, TASK_FOLDER.Team, "CLOSED");
      this.initTaskFilter(this.peersActiveTaskFilter, TASK_FOLDER.Peers, "ACTIVE");
    }
    var validTaskType: boolean = false;
    var taskId: string = null;
    if (this.route.snapshot.queryParamMap) {
      if (this.route.snapshot.queryParamMap.get("taskType")) {
        this.taskType = this.route.snapshot.queryParamMap.get("taskType");
        this.startTime = this.route.snapshot.queryParamMap.get("startTime") ? this.route.snapshot.queryParamMap.get("startTime").replace(" ", "+") : null;
        this.endTime = this.route.snapshot.queryParamMap.get("endTime") ? this.route.snapshot.queryParamMap.get("endTime").replace(" ", "+") : null;
        validTaskType = this.initTaskFilterWithTaskType();
      }
      if (this.route.snapshot.queryParamMap.get("taskId")) {
        taskId = this.route.snapshot.queryParamMap.get("taskId");
        this.fetchRecordsForTaskId(taskId);
      }
    }

    // this.connectToWebSocket();
    this.getPeers();
    this.getUserChildren();
    this.getUserList();
    this.getParentUser();
    this.fetchGraphs();
    this.getAllTaskTags();
    if (!validTaskType && taskId == null) {
      switch (this.selectedFolderName) {
        case this.FOLDER_OPTION_ALL:
          this.fetchRecordsForAllTasks();
        break;
        case this.FOLDER_OPTION_GROUP:
        this.fetchRecordsForGroupActiveTasks()
        break;
        case this.FOLDER_OPTION_PEERS:
          this.fetchRecordsForPeersActiveTasks();
        break;
        case this.FOLDER_OPTION_ASSIGNED:
          
          this.fetchRecordsForAssignedActiveTasks();
        break;
        case this.FOLDER_OPTION_ASSIGNED_ACTIVE:
          this.selectedFolderNameForAssignedTask = this.FOLDER_OPTION_ASSIGNED_ACTIVE;
          this.fetchRecordsForAssignedActiveTasks();
        break;
        case this.FOLDER_OPTION_ASSIGNED_CLOSED:
          this.selectedFolderNameForAssignedTask = this.FOLDER_OPTION_ASSIGNED_CLOSED;
          this.fetchRecordsForAssignedClosedTasks()
        break;
        case this.FOLDER_OPTION_TEAM_ACTIVE:
          this.selectedFolderNameForTeamTask = this.FOLDER_OPTION_TEAM_ACTIVE;
          this.fetchRecordsForTeamActiveTasks()
        break;
        case this.FOLDER_OPTION_TEAM_CLOSED:
          this.selectedFolderNameForTeamTask = this.FOLDER_OPTION_TEAM_CLOSED;
          this.fetchRecordsForTeamClosedTasks();
        break;
        default:
          this.fetchRecordsForAllTasks();
          break;
      }
      
    }
    this.getGroups();
    // this.selectForAllTasks(true);
  }

  getCurrentPageNumber(){
    switch (this.selectedFolderName) {
      case this.FOLDER_OPTION_ALL:
        return this.allTaskFilter.pageNo + 1
      break;
      case this.FOLDER_OPTION_GROUP:
        return this.groupActiveTaskFilter.pageNo + 1
      break;
      case this.FOLDER_OPTION_PEERS:
        return this.peersActiveTaskFilter.pageNo + 1
      break;
      case this.FOLDER_OPTION_ASSIGNED_ACTIVE:
        return this.assignedActiveTaskFilter.pageNo + 1
      break;
      case this.FOLDER_OPTION_ASSIGNED_CLOSED:
        return this.assignedClosedTaskFilter.pageNo + 1
      break;
      case this.FOLDER_OPTION_TEAM_ACTIVE:
        return this.teamActiveTaskFilter.pageNo + 1
      break;
      case this.FOLDER_OPTION_TEAM_CLOSED:
        return this.teamClosedTaskFilter.pageNo + 1
      break;
      default:
        return '-'
        break;
    }
  }
  ngOnDestroy(): void {
    this.setFilterOnDestroy();
    this.disconnectFromWebSocket();
  }

  connectToWebSocket() {
    //console.log("Initialize WebSocket Connection");
    const envData = this.environmentData.getEnvData();
    const webSocketEndPoint: string = envData.rootUrl + "/flow/websocket/newtask";
    let ws = new SockJS(webSocketEndPoint);
    this.stompClient = Stomp.over(ws);
    const _this = this;
    this.stompClient.connect({username: this.universalUser.getUser().username}, function (frame) {
        _this.stompClient.subscribe("/user/queue/newtask/active", (receivedEvent) => {
          //console.log("on message received ", receivedEvent);
          if (receivedEvent && receivedEvent.body) {
            const body = JSON.parse(receivedEvent.body);
            if (body.folderName) {
              _this.startAutoRefresh(body.folderName);
            }
          }
            
        });
        _this.stompClient.reconnect_delay = 2000;
    }, this.errorCallBack);
  }

  errorCallBack(error) {
    console.log("errorCallBack -> " + error)
    setTimeout(() => {
        this.connectToWebSocket();
    }, 5000);
  }

  disconnectFromWebSocket() {
    if (this.stompClient !== null) {
      this.stompClient.disconnect();
    }
  }


  initTaskFilterWithTaskType(task?: Task) {
    // this.selectedFolderNameForAssignedTask
    // this.selectedFolderNameForTeamTask
    if (this.taskType == this.TASK_TYPE_PENDING || this.taskType == this.TASK_TYPE_RESERVED) {
      this.parentSelectedFolderName = this.FOLDER_OPTION_ASSIGNED;
      this.selectedFolderName = this.FOLDER_OPTION_ASSIGNED_ACTIVE;
      this.selectedFolderNameForAssignedTask = this.selectedFolderName;
      if (this.startTime && this.endTime) {
        this.assignedActiveTaskFilter.fromTime = new Date(this.startTime);
        this.assignedActiveTaskFilter.toTime = new Date(this.endTime);
      }
      if (task) {
        this.appendTasks([task], "assignedActiveTasks");
        // this.setFirstAssignedActiveTask();
      }
      this.fetchRecordsForAssignedActiveTasks();
      return true;
    }
    if (this.taskType == this.TASK_TYPE_TEAM_PENDING || this.taskType == this.TASK_TYPE_TEAM_RESERVED) {
      this.parentSelectedFolderName = this.FOLDER_OPTION_TEAM;
      this.selectedFolderName = this.FOLDER_OPTION_TEAM_ACTIVE;
      this.selectedFolderNameForTeamTask = this.selectedFolderName;
      if (this.startTime && this.endTime) {
        this.teamActiveTaskFilter.fromTime = new Date(this.startTime);
        this.teamActiveTaskFilter.toTime = new Date(this.endTime);
      }
      if (task) {
        this.appendTasks([task], "teamActiveTasks");
        // this.setFirstTeamActiveTask();
      }
      this.fetchRecordsForTeamActiveTasks();
      return true;
    }
    if (this.taskType == this.TASK_TYPE_UNASSIGNED || this.taskType == this.TASK_TYPE_TEAM_UNASSIGNED) {
      this.parentSelectedFolderName = this.FOLDER_OPTION_GROUP;
      this.selectedFolderName = this.FOLDER_OPTION_GROUP;
      this.selectedFolderNameForTeamTask = this.selectedFolderName;
      if (this.startTime && this.endTime) {
        this.groupActiveTaskFilter.fromTime = new Date(this.startTime);
        this.groupActiveTaskFilter.toTime = new Date(this.endTime);
      }
      if (task) {
        this.appendTasks([task], "groupActiveTasks");
        // this.setFirstGroupActiveTask();
      }
      this.fetchRecordsForGroupActiveTasks();
      return true;
    }
    if (this.taskType == this.TASK_TYPE_OVERDUE) {
      this.assignedActiveTaskFilter.overdue = true;
      this.parentSelectedFolderName = this.FOLDER_OPTION_ASSIGNED;
      this.selectedFolderName = this.FOLDER_OPTION_ASSIGNED_ACTIVE;
      this.selectedFolderNameForAssignedTask = this.selectedFolderName;
      if (task) {
        this.appendTasks([task], "assignedActiveTasks");
        // this.setFirstAssignedActiveTask();
      }
      this.fetchRecordsForAssignedActiveTasks();
    }
    if (this.taskType == this.TASK_TYPE_TEAM_OVERDUE) {
      this.teamActiveTaskFilter.overdue = true;
      this.parentSelectedFolderName = this.FOLDER_OPTION_TEAM;
      this.selectedFolderName = this.FOLDER_OPTION_TEAM_ACTIVE;
      this.selectedFolderNameForTeamTask = this.selectedFolderName;
      if (task) {
        this.appendTasks([task], "teamActiveTasks");
        // this.setFirstTeamActiveTask();
      }
      this.fetchRecordsForTeamActiveTasks();
      return true;
    }
    if (this.taskType == this.TASK_TYPE_ESCALATED) {
      this.assignedActiveTaskFilter.escalatedTask = true;
      if (this.startTime && this.endTime) {
        this.assignedActiveTaskFilter.fromTime = new Date(this.startTime);
        this.assignedActiveTaskFilter.toTime = new Date(this.endTime);
      }
      this.parentSelectedFolderName = this.FOLDER_OPTION_ASSIGNED;
      this.selectedFolderName = this.FOLDER_OPTION_ASSIGNED_ACTIVE;
      this.selectedFolderNameForAssignedTask = this.selectedFolderName;
      if (task) {
        this.appendTasks([task], "assignedActiveTasks");
        // this.setFirstAssignedActiveTask();
      }
      this.fetchRecordsForAssignedActiveTasks();
      return true;
    }
    if (this.taskType == this.TASK_TYPE_TEAM_ESCALATED) {
      this.teamActiveTaskFilter.escalatedTask = true;
      if (this.startTime && this.endTime) {
        this.teamActiveTaskFilter.fromTime = new Date(this.startTime);
        this.teamActiveTaskFilter.toTime = new Date(this.endTime);
      }
      this.parentSelectedFolderName = this.FOLDER_OPTION_TEAM;
      this.selectedFolderName = this.FOLDER_OPTION_TEAM_ACTIVE;
      this.selectedFolderNameForTeamTask = this.selectedFolderName;
      if (task) {
        this.appendTasks([task], "teamActiveTasks");
        // this.setFirstTeamActiveTask();
      }
      this.fetchRecordsForTeamActiveTasks();
      return true;
    }
    if (this.taskType == this.TASK_TYPE_COMPLETED) {
      this.parentSelectedFolderName = this.FOLDER_OPTION_ASSIGNED;
      this.selectedFolderName = this.FOLDER_OPTION_ASSIGNED_CLOSED;
      this.selectedFolderNameForAssignedTask = this.selectedFolderName;
      if (task) {
        this.appendTasks([task], "assignedClosedTasks");
        // this.setFirstAssignedClosedTask();
      }
      this.fetchRecordsForAssignedClosedTasks();
      return true;
    }
    if (this.taskType == this.TASK_TYPE_TEAM_COMPLETED) {
      this.parentSelectedFolderName = this.FOLDER_OPTION_TEAM;
      this.selectedFolderName = this.FOLDER_OPTION_TEAM_CLOSED;
      this.selectedFolderNameForTeamTask = this.selectedFolderName;
      if (task) {
        this.appendTasks([task], "teamClosedTasks");
        // this.setFirstTeamClosedTask();
      }
      this.fetchRecordsForTeamClosedTasks();
      return true;
    }
    return false;
  }

  initTaskFilter(taskFilter: TaskFilter, folder: string, statusCd: string, reset?: boolean, pageNo?: number) {
    if (!taskFilter || taskFilter == null)
    {
      taskFilter = new TaskFilter();
    }
    if (reset!= undefined && reset == true) {
      taskFilter = new TaskFilter();
    }
    taskFilter.folder = folder;
    taskFilter.statusCd = statusCd;
    if (pageNo) {
    taskFilter.pageNo = pageNo;
    } else {
      taskFilter.pageNo = 0;
    }
    taskFilter.pageSize = 10;
    taskFilter.processes = [];
    taskFilter.sortBy = "startTime";
    taskFilter.sortDir = -1;
    return taskFilter;
  }

  updateEntityValues(event) {
    this.finalDataModel = event;
  }

  isEntityFormValid(event) {
    this.associatedEntityValid = event;
  }

  // ---------------------- Ui functionality 
  displayBox(vdata: number = 0, operation?: string) {
    if (vdata == 1) {
      if (operation === 'update') {
        this.messageOut = "updateTask";
      }
      else if (operation === 'archive') {
        this.messageOut = "archiveTask";
      }
      else if (operation === 'escalate') {
        this.messageOut = "escalateTask";
      }
      else if (operation === 'reserve') {
        this.messageOut = "reserveTask";
      }
      else if (operation === 'save') {
        this.messageOut = 'saveTask';
      }
    }
    if (vdata == 8 && this.timelineStates.length == 0) {
      this.getTimeline(this.currentSelectedTaskDetails);
    }
    if (vdata == 19) {
      this.getAssociatedEntityData();
    }
    this.box = vdata;
    this.toggle(false);
  }


  getAssociatedEntityData(stateInstanace?: State) {
    const currentSelectedTaskDetails = stateInstanace ? stateInstanace : this.currentSelectedTaskDetails;
    // this.communicationService.setAssocEntityDetails(currentSelectedTaskDetails);
    let taskDetails = currentSelectedTaskDetails;
    let entityId = null;
    let entityName = null;
    if (taskDetails && taskDetails.parameters['_associatedEntityId'] && taskDetails.parameters['_associatedEntityName']) {
      this.entityAssociatedWithTask = true;
      this.associatedEntityId = taskDetails.parameters['_associatedEntityId'];
      entityName = taskDetails.parameters['_associatedEntityName'];
      this.associatedEntityName = entityName;

    }
    //for backward compatibility
    else if (taskDetails && taskDetails.source != undefined && taskDetails.source != null && taskDetails.source.length > 0) {
      this.entityAssociatedWithTask = true;
      let pos = taskDetails.source.map(item => item.referenceType).indexOf('ENTITY');
      if (pos != null && pos != undefined && pos >= 0) {
        this.associatedEntityId = taskDetails.source[pos]['referenceId'];
        entityName = taskDetails.source[pos]['referenceEntityName'];
        this.associatedEntityName = entityName;
      }
    }
    if (this.associatedEntityId != null && this.associatedEntityName != null) {
      this.entityAssociatedWithTask = true;
      // this.communicationService.setAssocEntityDetails(currentSelectedTaskDetails);
    }
    else {
      this.entityAssociatedWithTask = false;
    }
    if (this.entityAssociatedWithTask) {
      this.getAssociatedEntity();
    }

  }


  getAssociatedEntity() {

    this.viewEntity = null;
    this.selectedDataModel = null;
    if (this.entityAssociatedWithTask) {
      this.entityTabClicked = true;
      this.entityService.getEntity(this.associatedEntityName, this.associatedEntityId).subscribe(entity => {
        if (entity) {
          this.viewEntity = entity;
          let dataModel = new DataModel();
          // dataModel._id = entity.datamodelId;
          // this.selectedDataModel = (dataModel);
          this.getSelectedDatamodel(entity.datamodelId)
        }
      });
    }
  }


  getSelectedDatamodel(datamodelId:string) {
    this.dataModelService.getDataModel(datamodelId)
      .subscribe(
        datamodel => {
          if (datamodel) {
           this.selectedDataModel = datamodel;
          }
        });
  }


  displayforBulkBox(vdata: string) {
    this.bulkBox = vdata;
  }
  // for toggle the left side box
  toggleBox(): boolean {
    return this.commonService.toggleBlock;
  }
  toggle(showTaskList) {
    this.isToogle = showTaskList;
    if (showTaskList == true) {
      this.commonService.toggleBlock = !this.commonService.toggleBlock;
      this.box = 0;
      this.leftPaneWidth = 100;
    } else if (showTaskList == false) {
      this.commonService.toggleBlock = false;
      this.leftPaneWidth = 65;
    }
  }
  openSection() {
    this.toggle(true);
  }
  closeSection() {
    this.toggle(true);
  }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: 'modal-dialog modal-md modal-dialog-centered featureList', backdrop: 'static', keyboard: false });
  }
  // ---------------------- Ui functionality END

  filterDropDownSettings() {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }

  getStateById(stateId: string) {
    this.taskService.getStateById(stateId).subscribe(state => {
      if (state) {
        let states = [];
        states.push(state);
        // this.setFirstAssignedTaskValues(states, true);
      }
    });
  }

  goToTaskList() {
    if (this.communicationService.getAssocEntityDetails() != null && this.communicationService.getAssocEntityDetails() != undefined && !this.customerId) {
      this.toggle(true);
      this.router.navigateByUrl('/add-activities');
    }
    else if(this.customerId){
      this.toggle(true);
      this.router.navigate(['/customer-details'], { queryParams: { customerId: this.customerId } });
    }
    else {
      this.toggle(true);
      this.router.navigateByUrl('/task');
    }

  }

  getGroups() {
    this.fetchUserService.fetchUserGroups()
      .subscribe(userGroups => {
        if (userGroups && userGroups.length > 0) {
          for (let group of userGroups) {
            this.filterGroups.push({ value: group, label: group })
          }
          this.groupNames = userGroups;
        }
      });
  }


  getUserList() {
    this.fetchUserService.fetchChildUsers(this.userId)
      .subscribe(userList => {
        if (userList && userList.length > 0) {
          this.users = userList;
        }
      });
  }

  getPeers() {
    this.fetchUserService.getPeers()
      .subscribe(peers => {
        if (peers && peers.length > 0) {
          this.peers = peers;
          if (!this.universalUser.getUser().restrictPeersAllocation) {
            if (!this.userChildren || this.userChildren.length == 0) {
              this.options.push({ display: 'Team', value: this.FOLDER_OPTION_PEERS })
            }
          }
        }
      });
  }

  getParentUser() {
    this.fetchUserService.getUserHierarchy(this.userId)
      .subscribe(userHierarchyObject => {
        if (userHierarchyObject) {
          this.userHierarchy = userHierarchyObject;
          this.allocatedAssignedTaskToUserId = this.userHierarchy._id;
        }
      });
  }

  fetchRecordsForTaskId(taskId: string) {
    this.taskService.getBy_id(taskId)
      .subscribe(
        task => {
          if (!task) {
            this.openSnackBar("error", "Requested case not found in sytem", "close");
            this.setTaskDetailsFromTask(task);
            return;
          }
          if (!this.userChildren || this.userChildren.length == 0) {
            this.fetchUserService.getUserChildren()
              .subscribe(
                response => {
                  if (response && response.length > 0) {
                    this.userChildren = response;
                  }
                  this.setTaskDetailsFromTask(task);
                }
              )
          }
          else {
            this.setTaskDetailsFromTask(task);
          }
        },
        error => {

        }
      )
  }

  setTaskDetailsFromTask(task: Task) {
    this.taskType = this.getTaskTypeFromTask(task);
    const validTaskType: boolean = this.initTaskFilterWithTaskType(task);
    if (!validTaskType) {
      this.fetchRecordsForAssignedActiveTasks();
    }
  }

  getTaskTypeFromTask(task: Task) {
    if (!task)
      return null;
    if (task.assignedUserId && task.assignedUserId.length > 0) {
      if (this.universalUser.getUser()._id == task.assignedUserId) {
        if (task.statusCd == "ACTIVE") {
          return this.TASK_TYPE_PENDING;
        }
        if (task.statusCd == "CLOSED" || task.statusCd == "ARCHIVE") {
          return this.TASK_TYPE_COMPLETED;
        }
        return null;
      }
      if (!this.userChildren || this.userChildren.length == 0) {
        this.openSnackBar("error", "Your are not allow to view requested case", "close");
        return null;
      }
      for (let children of this.userChildren) {
        if (children.userId == task.assignedUserId) {
          if (task.statusCd == "ACTIVE") {
            return this.TASK_TYPE_TEAM_PENDING;
          }
          if (task.statusCd == "CLOSED" || task.statusCd == "ARCHIVE") {
            return this.TASK_TYPE_TEAM_COMPLETED;
          }
          return null
        }
      }
      this.openSnackBar("error", "Your are not allow to view requested case", "close");
      return null;
    }
    if (task.assignedUserGroupCd && task.assignedUserGroupCd.length > 0) {
      if (!this.groupNames || this.groupNames.length == 0) {
        this.openSnackBar("error", "Your are not allow to view requested case", "close");
        return null;
      }
      if (this.groupNames.indexOf(task.assignedUserGroupCd) != -1) {
        return this.TASK_TYPE_UNASSIGNED;
      }
      this.openSnackBar("error", "Your are not allow to view requested case", "close");
      return null;
    }
    this.openSnackBar("error", "Your are not allow to view requested case", "close");
    return null;
  }

  fetchRecordsForAssignedActiveTasks(loadMore?: boolean) {
    this.fetchCountForAssignedActiveTaskFilter();
    this.fetchTasksForTaskFilter(this.assignedActiveTaskFilter, this.assignedActiveTasks)
      .subscribe(
        response => {
          this.appendTasks(response, "assignedActiveTasks");
          if (!loadMore && this.assignedActiveTasks && this.assignedActiveTasks.length > 0){}
            // this.setFirstAssignedActiveTask();

        },
        error => {

        }
      )
  }

  fetchRecordsForAssignedClosedTasks(loadMore?: boolean) {
    this.fetchCountForAssignedClosedTaskFilter();
    this.fetchTasksForTaskFilter(this.assignedClosedTaskFilter, this.assignedClosedTasks)
      .subscribe(
        response => {
          this.appendTasks(response, "assignedClosedTasks");
          if (!loadMore && this.assignedClosedTasks && this.assignedClosedTasks.length > 0){}
            // this.setFirstAssignedClosedTask();
        }
      )
  }

  fetchRecordsForGroupActiveTasks(loadMore?: boolean) {
    this.fetchCountForGroupActiveTaskFilter();
    this.fetchTasksForTaskFilter(this.groupActiveTaskFilter, this.groupActiveTasks)
      .subscribe(
        response => {
          this.appendTasks(response, "groupActiveTasks");
          if (!loadMore && this.groupActiveTasks && this.groupActiveTasks.length > 0) {
            // this.setFirstGroupActiveTask();
          }
        },
        error => {

        }
      )
  }

  fetchRecordsForTeamActiveTasks(loadMore?: boolean) {
    this.fetchCountForTeamActiveTaskFilter();
    this.fetchTasksForTaskFilter(this.teamActiveTaskFilter, this.teamActiveTasks)
      .subscribe(
        response => {
          this.appendTasks(response, "teamActiveTasks");
          if (!loadMore && this.teamActiveTasks && this.teamActiveTasks.length > 0) {
            // this.setFirstTeamActiveTask();
          }
        },
        error => {

        }
      )
  }

  fetchRecordsForTeamClosedTasks(loadMore?: boolean) {
    this.fetchCountForTeamClosedTaskFilter();
    this.fetchTasksForTaskFilter(this.teamClosedTaskFilter, this.teamClosedTasks)
      .subscribe(
        response => {
          this.appendTasks(response, "teamClosedTasks");
          if (!loadMore && this.teamClosedTasks && this.teamClosedTasks.length > 0) {
            // this.setFirstTeamClosedTask();
          }
        }
      )
  }

  fetchRecordsForPeersActiveTasks(loadMore?: boolean) {
    this.fetchCountForPeersActiveTaskFilter();
    this.fetchTasksForTaskFilter(this.peersActiveTaskFilter, this.peersActiveTasks)
      .subscribe(
        response => {
          this.appendTasks(response, "peersActiveTasks");
          if (!loadMore && this.peersActiveTasks && this.peersActiveTasks.length > 0) {
            // this.setFirstTeamClosedTask();
          }
        }
      )
  }

  fetchRecordsForAllTasks(loadMore?: boolean) {
    this.fetchCountForAllTaskFilter();
    this.fetchTasksForTaskFilter(this.allTaskFilter, this.allTasks)
      .subscribe(
        response => {
          this.appendTasks(response, "allTasks");
          
        }
      )
  }

  fetchTasksForTaskFilter(taskFilter: TaskFilter, tasks: Task[]): Observable<Task[]> {
    const subject = new Subject<Task[]>();
    if (!tasks)
      tasks = [];
    this.showLoaderOverlay.taskListing = true;
    this.taskService.getTaskByTaskFilter(taskFilter)
      .subscribe(
        response => {
          subject.next(response)
          this.showLoaderOverlay.taskListing = false;
        },
        error => {
          this.showLoaderOverlay.taskListing = false;
          subject.error(error);
        }
      )
    return subject.asObservable();
  }


  fetchCountForAssignedActiveTaskFilter(withShareObj?: boolean) {
    if (withShareObj && this.communicationService.assignedActiveTaskFilter) {
      this.assignedActiveTaskFilter = this.communicationService.assignedActiveTaskFilter;
    }
    this.taskService.getCountByTaskFilter(this.assignedActiveTaskFilter)
      .subscribe (
        response => {
          this.assignedActiveTasksCount = response;
        },
        error => {

        }
      )
  }

  fetchCountForAssignedClosedTaskFilter(withShareObj?: boolean) {
    if (withShareObj && this.communicationService.assignedClosedTaskFilter) {
      this.assignedClosedTaskFilter = this.communicationService.assignedClosedTaskFilter;
    }
    this.taskService.getCountByTaskFilter(this.assignedClosedTaskFilter)
      .subscribe (
        response => {
          this.assignedClosedTasksCount = response;
        }
      )
  }

  fetchCountForGroupActiveTaskFilter(withShareObj?: boolean) {
    if (withShareObj && this.communicationService.groupActiveTaskFilter) {
      this.groupActiveTaskFilter = this.communicationService.groupActiveTaskFilter;
    }
    this.taskService.getCountByTaskFilter(this.groupActiveTaskFilter)
      .subscribe (
        response => {
          this.groupActiveTasksCount = response;
        }
      )
  }

  fetchCountForTeamActiveTaskFilter(withShareObj?: boolean) {
    if (withShareObj && this.communicationService.teamActiveTaskFilter) {
      this.teamActiveTaskFilter = this.communicationService.teamActiveTaskFilter;
    }
    this.taskService.getCountByTaskFilter(this.teamActiveTaskFilter)
      .subscribe (
        response => {
          this.teamActiveTasksCount = response;
        }
      )
  }

  fetchCountForTeamClosedTaskFilter(withShareObj?: boolean) {
    if (withShareObj && this.communicationService.teamClosedTaskFilter) {
      this.teamClosedTaskFilter = this.communicationService.teamClosedTaskFilter;
    }
    this.taskService.getCountByTaskFilter(this.teamClosedTaskFilter)
      .subscribe (
        response => {
          this.teamClosedTasksCount = response;
        }
      )
  }

  fetchCountForPeersActiveTaskFilter(withShareObj?: boolean) {
    if (withShareObj && this.communicationService.peersActiveTaskFilter) {
      this.peersActiveTaskFilter = this.communicationService.peersActiveTaskFilter;
    }
    this.taskService.getCountByTaskFilter(this.peersActiveTaskFilter)
      .subscribe (
        response => {
          this.peersActiveTasksCount = response;
        }
      )
  }

  fetchCountForAllTaskFilter(withShareObj?: boolean) {
    if (withShareObj && this.communicationService.allTaskFilter) {
      this.allTaskFilter = this.communicationService.allTaskFilter;
    }
    this.taskService.getCountByTaskFilter(this.allTaskFilter)
      .subscribe (
        response => {
          this.allTasksCount = response;
        }
      )
  }


  transformTime(date: Date, minutes: any, operation: string) {
    if (operation == "add") {
      date.setMinutes(date.getMinutes() + minutes)
    }
    else if (operation == "subtract") {
      date.setMinutes(date.getMinutes() - minutes)
    }
    return date.getTime();
  }


  // set Time DataFields For Ui

  setTimeDataFields(state: State) {
    if (state.slaEndTimeLong) {
      //let currentTime = this.transformTime(new Date(),330,'add');
      let currentTime = new Date().getTime();
      // state.startTimeLong =  this.transformTime(new Date(state.startTimeLong),330,'subtract');

      //state.slaEndTimeLong = this.transformTime(new Date(state.slaEndTimeLong),330,'subtract');
      //for SLA time
      if (state.slaEndTimeLong) {
        state.slaTime = (state.slaEndTimeLong - state.startTimeLong) / (1000 * 60 * 60);
      }

      //for remaining time
      if (state.slaEndTimeLong && currentTime < state.slaEndTimeLong) {
        state.remainingTime = (state.slaEndTimeLong - currentTime) / (1000 * 60 * 60);
      }
      return state;
    }
    return state;

  }

  decideStagesForState(s: State) {
    this.selectedProcessStages = {};
    if (this.allProcessStages[s.machineType] && this.allProcessStages[s.machineType].length > 0 && s.processStageCdList != null && s.processStageCdList.length > 0) {
      this.selectedProcessStages.stages = this.allProcessStages[s.machineType];
      this.selectedProcessStages.currentStage = s.processStageCdList[0];
      this.showStages = true;
    }
    else {
      this.showStages = false;
    }
  }

  getDataPointConfigForGraph() {
    let co = new CommonSearchModel();
    co.searchParams = []
    co.returnFields = ["dataPointConfigurationList", "states"];
    return co;
  }

  setFirstAssignedActiveTask() {
    if (!this.assignedActiveTasks || this.assignedActiveTasks.length == 0) {
      return;
    }
    this.assignedActiveTasks[0].cssClass = this.TABLINKS_ACTIVE;
    this.setAssignedActiveTaskDetails(this.assignedActiveTasks[0]);

  }

  setFirstAssignedClosedTask() {
    if (!this.assignedClosedTasks || this.assignedClosedTasks.length == 0) {
      return;
    }
    this.assignedClosedTasks[0].cssClass = this.TABLINKS_ACTIVE;
    this.setAssignedClosedTaskDetails(this.assignedClosedTasks[0]);

  }

  setFirstGroupActiveTask() {
    if (!this.groupActiveTasks || this.groupActiveTasks.length == 0) {
      return;
    }
    this.groupActiveTasks[0].cssClass = this.TABLINKS_ACTIVE;
    this.setGroupActiveTaskDetails(this.groupActiveTasks[0]);
  }

  setFirstTeamActiveTask() {
    if (!this.teamActiveTasks || this.teamActiveTasks.length == 0) {
      return;
    }
    this.teamActiveTasks[0].cssClass = this.TABLINKS_ACTIVE;
    this.setTeamActiveTaskDetails(this.teamActiveTasks[0]);
  }

  setFirstTeamClosedTask() {
    if (!this.teamClosedTasks || this.teamClosedTasks.length == 0) {
      return;
    }
    this.teamClosedTasks[0].cssClass = this.TABLINKS_ACTIVE;
    this.setTeamClosedTaskDetails(this.teamClosedTasks[0]);
  }


  setFirstAssignedTaskValues(states: any, stateView?: boolean) {
    this.selectedTabIndex = 0;
    this.entityTabClicked = false;
    if (states != null && states.length > 0) {
      if (stateView) {
        this.toggle(true);
      }
      this.assignedStateTabclass[states[0]._id] = "block active";
      this.getTaskTags(states[0].machineType);
      let co = this.getDataPointConfigForGraph();
      this.stateService.getDataPointconfiguration(co, states[0].stateMachineInstanceModelId)
        .subscribe(
          response => {
            if (response) {
              this.showLoaderOverlay.mainScreen = false;
            }
            this.graphObject = response;
            this.graphObjects.set(states[0].stateMachineInstanceModelId, this.getSortedDatPointGraphObject(response));
            this.assignedTaskDdetails = states[0];
            this.currentSelectedTaskDetails = this.assignedTaskDdetails;
            this.getAssociatedEntityData();
            this.decideStagesForState(this.assignedTaskDdetails);
            this.statusList = this.getStatusList(this.assignedTaskDdetails);
            this.getDocuments(this.assignedTaskDdetails);
            this.loadingAssigned = false;
            if (!this.loadingUnassigned && !this.loadingAssigned) {
              this.progressBarFlag = false;
              // this.baThemeSpinner.hide();
            }
            this.assignedTaskActionButtonEnabled[this.assignedTaskDdetails._id] = true;
            this.assignedStategraphObject = response;
            /* for (let state of states) {
              if (this.assignedTaskDdetails != state) {
                this.assignedStateTabclass[state._id] = "block";
                this.setGraphObjects(state);
                this.assignedTaskActionButtonEnabled[state._id] = true;
              }
            } */
          },
          error => {
            this.loadingAssigned = false;
            this.progressBarFlag = false;
            this.showLoaderOverlay.mainScreen = false;

          }
        )
    } else {
      this.records = false;
      this.progressBarFlag = false;
    }
  }


  setFirstArchivedTaskValues(states: any) {
    this.selectedTabIndex = 0;
    this.entityTabClicked = false;
    if (states != null && states.length > 0) {
      this.archivedStateTabclass[states[0]._id] = "block active";
      let co = this.getDataPointConfigForGraph();
      this.stateService.getDataPointconfiguration(co, states[0].stateMachineInstanceModelId)
        .subscribe(
          response => {
            if (response) {
              this.showLoaderOverlay.mainScreen = false;
            }
            this.graphObject = response;
            this.graphObjects.set(states[0].stateMachineInstanceModelId, this.getSortedDatPointGraphObject(response));
            this.archivedTaskDdetails = states[0];
            this.currentSelectedTaskDetails = this.archivedTaskDdetails;
            this.getAssociatedEntityData();
            this.decideStagesForState(this.archivedTaskDdetails);
            this.selectedProcessStages = this.allProcessStages.processStageCdList;
            this.statusList = this.getStatusList(this.archivedTaskDdetails);
            this.getDocuments(this.archivedTaskDdetails);
            this.loadingAssigned = false;
            if (!this.loadingUnassigned && !this.loadingAssigned) {
              this.progressBarFlag = false;
              // this.baThemeSpinner.hide();
            }
            this.archivedTaskActionButtonEnabled[this.archivedTaskDdetails._id] = true;
            //this.assignedStategraphObject = response;
            /*  for (let state of states) {
               if (this.archivedTaskDdetails != state) {
                 this.archivedStateTabclass[state._id] = "block";
                 this.setGraphObjects(state);
                 this.archivedTaskActionButtonEnabled[state._id] = true;
               }
             } */
          },
          error => {
            this.loadingAssigned = false;
            this.progressBarFlag = false;
            this.showLoaderOverlay.mainScreen = false;

          }
        )
    } else {
      this.records = false;
      this.progressBarFlag = false;
    }
  }

  setFirstUnAssignedTaskValues(states) {
    this.selectedTabIndex = 0;
    this.entityTabClicked = false;
    if (states != null && states.length > 0) {
      this.unassignedStateTabclass[states[0]._id] = "block active";
      let co = this.getDataPointConfigForGraph();
      this.stateService.getDataPointconfiguration(co, states[0].stateMachineInstanceModelId)
        .subscribe(
          response => {
            if (response) {
              this.showLoaderOverlay.mainScreen = false;
            }
            this.graphObject = response;
            this.graphObjects.set(states[0].stateMachineInstanceModelId, this.getSortedDatPointGraphObject(response));
            this.unassignedTaskDdetails = states[0];
            this.currentSelectedTaskDetails = this.unassignedTaskDdetails;
            this.getAssociatedEntityData();
            this.decideStagesForState(this.unassignedTaskDdetails);
            this.selectedProcessStages = this.unassignedTaskDdetails.processStageCdList
            this.statusList = this.getStatusList(this.assignedTaskDdetails);
            this.getDocuments(this.unassignedTaskDdetails);
            this.loadingUnassigned = false;
            if (!this.loadingUnassigned && !this.loadingAssigned) {
              this.progressBarFlag = false;
              // this.baThemeSpinner.hide();
            }
            this.unassignedTaskActionButtonEnabled[this.unassignedTaskDdetails._id] = true;
            /*  for (let state of states) {
               if (this.unassignedTaskDdetails != state) {
                 this.unassignedStateTabclass[state._id] = "block";
                 this.setGraphObjects(state);
                 this.unassignedTaskActionButtonEnabled[state._id] = true;
               }
             } */
          },
          error => {
            this.loadingUnassigned = false;
            this.progressBarFlag = false;
            this.showLoaderOverlay.mainScreen = false;

          }
        )
    } else {
      this.records = false;
      this.progressBarFlag = false;
    }
  }



  getSortedDatPointGraphObject(graphObject: GraphObject) {
    if (graphObject != null && graphObject.dataPointConfigurationList != null && graphObject.dataPointConfigurationList.length > 0) {
      const dataPointsConfig = JSON.parse(JSON.stringify(graphObject.dataPointConfigurationList));
      dataPointsConfig.sort(function (a: DataPoint, b: DataPoint) {
        return a.sequence > b.sequence ? 1 : a.sequence ? -1 : 0
      });
      graphObject.dataPointConfigurationList = dataPointsConfig;

    }
    return graphObject;
  }


  setGraphObjects(state: State) {
    if (!this.graphObjects.get(state.stateMachineInstanceModelId) || this.graphObjects.get(state.stateMachineInstanceModelId) != null) {
      let co = this.getDataPointConfigForGraph();
      this.stateService.getDataPointconfiguration(co, state.stateMachineInstanceModelId)
        .subscribe(
          response => {
            this.graphObjects.set(state.stateMachineInstanceModelId, this.getSortedDatPointGraphObject(response));
          },
          error => {
          }
        )
    }
    else {
      this.graphObjects.set(state.stateMachineInstanceModelId, null);
    }
  }

  getGraphObject(stateMachineInstanceModelId: string) {
    return this.graphObjects.get(stateMachineInstanceModelId);
  }

 

  appendTasks(tasks: Task[], tasksType: string, appendAtBegining?: boolean) {
    if (!tasks || tasks.length == 0) {
      this.openSnackBar("error", "Cases are not available", "Close");
    }else{
      this.pageNumber++;
      if (this.pageNumber == 0) {
        this.pageNumber++;
      }
    }
    if (this.currentSelectedTask) {
      for (let index = 0; index < tasks.length; index++) {
        if (tasks[index]._id == tasks[index]._id) {
          tasks.splice(index, 1);
        }
      }
    }
    // let tmpTasks = JSON.parse(JSON.stringify(this[tasksType])) // When we want to append to current task list
    let tmpTasks = []; // When we are showing page wise
    for (let task of tasks) {
      if (task.slaEndTime) {
        let nowTime = moment(new Date());
        let stateTime = moment(task.slaEndTimeLong);
        if (this.selectedFolderName == this.FOLDER_OPTION_ASSIGNED_ACTIVE || this.selectedFolderName == this.FOLDER_OPTION_GROUP || this.selectedFolderName == this.FOLDER_OPTION_TEAM_ACTIVE) {
          if (stateTime.diff(nowTime, "seconds") < 0) {
            task.overdue = true;
          } else {
            task.overdue = false;
          }
        }
        if (task.allocatedById && task.allocatedById.trim().length > 0) {
          this.fetchUserService.getUser(task.allocatedById)
            .subscribe (
              response => {
                if (response) {
                  if (response.name) {
                    task.allocatedByDisplayUsername = response.name;
                  }
                  else {
                    task.allocatedByDisplayUsername = response.username;
                  }
                }
              }
            )
        }
        if (task.escalatedById && task.escalatedById.trim().length > 0) {
          if (task.escalatedById = "Process") {
            task.escalatedByDisplayUsername = "System";
          }
          else {
            this.fetchUserService.getUser(task.escalatedById)
            .subscribe (
              response => {
                if (response) {
                  if (response.name) {
                    task.escalatedByDisplayUsername = response.name;
                  }
                  else {
                    task.escalatedByDisplayUsername = response.username;
                  }
                }
              }
            )
          }
          
        }

      }

      task.cssClass = this.TABLINKS;
      if (appendAtBegining) {
        tmpTasks.unshift(task);
      }
      else {
        tmpTasks.push(task);
      }
    }
    this[tasksType] = tmpTasks;
    this.communicationService.currentTaskList = tmpTasks;
  }

  removeTask(task: Task, tasksType: string) {
    let tmpTasks = JSON.parse(JSON.stringify(this[tasksType]))
    const index = tmpTasks.map(item => item._id).indexOf(task._id);
    if (index != -1) {
      tmpTasks.splice(index, 1);
      this.currentSelectedTask = null;
      this.currentSelectedTaskDetails = null;
    }
    this[tasksType] = tmpTasks;
  }

  isSonarOnEmailNeedToBeDisplay() {

    // This function opens the email ta automatically but looks weird .Hence showing sonar instead
    if (this.currentSelectedTask && this.currentSelectedTask.source && this.currentSelectedTask.source.length > 0) {
      for(let task of this.currentSelectedTask.source) {
        if (task.referenceType && task.referenceType == "Email") {
          return true;
        }
      }
    }
    return false;
  }

  setCurrentTaskDetailsForStateInstance(task: Task, stateInstanace: State) {
    this.showLoaderOverlay.mainScreen = true;
    this.stateService.getDataPointconfiguration(this.getDataPointConfigForGraph(), stateInstanace.stateMachineInstanceModelId)
      .subscribe(
        response => {
          this.graphObject = response;
          this.graphObjects.set(stateInstanace.stateMachineInstanceModelId, this.getSortedDatPointGraphObject(response));

          this.showLoaderOverlay.mainScreen = false;
        });
    this.currentSelectedTaskDetails = stateInstanace;
    this.currentSelectedTask = task;
    this.getAssociatedEntityData();
    this.decideStagesForState(this.currentSelectedTaskDetails);
    this.statusList = this.getStatusList(this.currentSelectedTaskDetails);
    this.getDocuments(this.currentSelectedTaskDetails);
  }

  setAssignedActiveTaskDetails(task: Task) {
    if (!task) {
      this.currentSelectedTask = null;
      this.currentSelectedTaskDetails = null;
      return;
    }

    this.showLoaderOverlay.mainScreen = true;
    this.currentSelectedTask = null;
    this.currentSelectedTaskDetails = null;
    if (this.isTaskStillInProgress(task)) {
      this.openSnackBar("error", "Case is still in progress. Please wait for some time to complete performed action.", "Close")
      this.showLoaderOverlay.taskBar = true;
      return;
    }
    this.showLoaderOverlay.taskBar = false;
    if (task.refType == this.TASK_REF_TYPE_PROCESS) {
      this.stateService.getStateInstanceById(task.refId)
        .subscribe(
          response => {
            this.showLoaderOverlay.mainScreen = false;
            this.validateAssignedActiveTaskWithStateAndSetDetails(task, response);

          },
          error => {
            this.showLoaderOverlay.mainScreen = false;
          }
        )
    }

  }

  validateAssignedActiveTaskWithStateAndSetDetails(task: Task, stateInstanace: State) {
    if (stateInstanace.statusCd != "ACTIVE") {
      this.openSnackBar("error", "Case " + task.stateCd + " - " + task.businessKey + " has been updated by system", "Close");
      this.removeTask(task, "assignedActiveTasks");
      return false;
    }
    if (stateInstanace.assignedUserId && stateInstanace.assignedUserId != this.userId) {
      this.openSnackBar("error", "Case " + task.stateCd + " - " + task.businessKey + " refreshed and now assigned to user " + stateInstanace.assignedUserDisplayName, "Close");
      this.removeTask(task, "assignedActiveTasks");
      return false;
    }
    if (!stateInstanace.assignedUserId && stateInstanace.assignedUserGroupCd) {
      this.openSnackBar("error", "Case " + task.stateCd + " - " + task.businessKey + " refreshed and now assigned to group " + stateInstanace.assignedUserGroupCd, "Close");
      this.removeTask(task, "assignedActiveTasks");
      return false;;
    }
    this.setCurrentTaskDetailsForStateInstance(task, stateInstanace);
    this.firstTimeAssignedActiveTasks = false;
    this.currentSelectedTask.enableUpdate = true;
    return true;
  }

  setAssignedClosedTaskDetails(task: Task) {
    if (!task) {
      this.currentSelectedTask = null;
      this.currentSelectedTaskDetails = null;
      return;
    }
    if (task.refType == this.TASK_REF_TYPE_PROCESS) {
      this.stateService.getStateInstanceById(task.refId)
        .subscribe(
          response => {
            this.validateAssignedClosedTaskWithStateAndSetDetails(task, response);

          },
          error => {

          }
        )
    }

  }

  validateAssignedClosedTaskWithStateAndSetDetails(task: Task, stateInstanace: State) {
    if (stateInstanace.statusCd != "ARCHIVE" && stateInstanace.statusCd != "CLOSED") {
      this.openSnackBar("error", "Case " + task.stateCd + " - " + task.businessKey + " has been updated by system", "Close");
      this.removeTask(task, "assignedClosedTasks");
      return false;
    }
    this.setCurrentTaskDetailsForStateInstance(task, stateInstanace);
    return true;
  }

  setGroupActiveTaskDetails(task: Task) {
    if (!task) {
      this.currentSelectedTask = null;
      this.currentSelectedTaskDetails = null;
      return;
    }
    if (task.refType == this.TASK_REF_TYPE_PROCESS) {
      this.stateService.getStateInstanceById(task.refId)
        .subscribe(
          response => {
            this.validateGroupActiveTaskWithStateAndSetDetails(task, response);

          },
          error => {

          }
        )
    }
  }

  validateGroupActiveTaskWithStateAndSetDetails(task: Task, stateInstanace: State) {
    if (stateInstanace.statusCd != "ACTIVE") {
      this.openSnackBar("error", "Case " + task.stateCd + (task.businessKey ? " - " + task.businessKey : "") + " has been updated by system", "Close");
      this.removeTask(task, "groupActiveTasks");
      return false;
    }
    this.setCurrentTaskDetailsForStateInstance(task, stateInstanace);
    this.currentSelectedTask.enableReserve = true;
    return true;
  }

  setTeamActiveTaskDetails(task: Task) {
    if (!task) {
      this.currentSelectedTask = null;
      this.currentSelectedTaskDetails = null;
      return;
    }
    if (task.refType == this.TASK_REF_TYPE_PROCESS) {
      this.stateService.getStateInstanceById(task.refId)
        .subscribe(
          response => {
            this.validateTeamActiveTaskWithStateAndSetDetails(task, response);

          },
          error => {

          }
        )
    }
  }

  validateTeamActiveTaskWithStateAndSetDetails(task: Task, stateInstanace: State) {
    if (stateInstanace.statusCd != "ACTIVE") {
      this.openSnackBar("error", "Case " + task.stateCd + (task.businessKey ? " - " + task.businessKey : "") + " has been updated by system", "Close");
      this.removeTask(task, "teamActiveTasks");
      return false;
    }
    this.setCurrentTaskDetailsForStateInstance(task, stateInstanace);
    return true;
  }

  setTeamClosedTaskDetails(task: Task) {
    if (!task) {
      this.currentSelectedTask = null;
      this.currentSelectedTaskDetails = null;
      return;
    }
    if (task.refType == this.TASK_REF_TYPE_PROCESS) {
      this.stateService.getStateInstanceById(task.refId)
        .subscribe(
          response => {
            this.validateTeamClosedTaskWithStateAndSetDetails(task, response);

          },
          error => {

          }
        )
    }
  }

  validateTeamClosedTaskWithStateAndSetDetails(task: Task, stateInstanace: State) {
    if (stateInstanace.statusCd != "CLOSED") {
      this.openSnackBar("error", "Case " + task.stateCd + (task.businessKey ? " - " + task.businessKey : "") + " has been updated by system", "Close");
      this.removeTask(task, "teamClosedTasks");
      return false;
    }
    this.setCurrentTaskDetailsForStateInstance(task, stateInstanace);
    return true;
  }

  setPeerActiveTaskDetails(task: Task) {
    if (!task) {
      this.currentSelectedTask = null;
      this.currentSelectedTaskDetails = null;
      return;
    }
    if (task.refType == this.TASK_REF_TYPE_PROCESS) {
      this.stateService.getStateInstanceById(task.refId)
        .subscribe(
          response => {
            this.validatePeerActiveTaskWithStateAndSetDetails(task, response);

          },
          error => {

          }
        )
    }
  }

  setAllTaskDetails(task: Task) {
    if (!task) {
      this.currentSelectedTask = null;
      this.currentSelectedTaskDetails = null;
      return;
    }
    if (task.refType == this.TASK_REF_TYPE_PROCESS) {
      this.stateService.getStateInstanceById(task.refId)
        .subscribe(
          response => {
            this.validateAllTaskWithStateAndSetDetails(task, response);

          },
          error => {

          }
        )
    }
  }

  validatePeerActiveTaskWithStateAndSetDetails(task: Task, stateInstanace: State) {
    if (stateInstanace.statusCd != "ACTIVE") {
      this.openSnackBar("error", "Case " + task.stateCd + (task.businessKey ? " - " + task.businessKey : "") + " has been updated by system", "Close");
      this.removeTask(task, "peersActiveTasks");
      return false;
    }
    this.setCurrentTaskDetailsForStateInstance(task, stateInstanace);
    return true;
  }

  validateAllTaskWithStateAndSetDetails(task: Task, stateInstanace: State) {
    this.setCurrentTaskDetailsForStateInstance(task, stateInstanace);
    return true;
  }

  setShareValues() {
    this.communicationService.assignedActiveTasks = this.assignedActiveTasks;
    this.communicationService.assignedActiveTasksCount = this.assignedActiveTasksCount;
    this.communicationService.assignedActiveTaskFilter = this.assignedActiveTaskFilter;

    this.communicationService.assignedClosedTasks = this.assignedClosedTasks;
    this.communicationService.assignedClosedTasksCount = this.assignedClosedTasksCount;
    this.communicationService.assignedClosedTaskFilter = this.assignedClosedTaskFilter;

    this.communicationService.groupActiveTasks = this.groupActiveTasks;
    this.communicationService.groupActiveTasksCount = this.groupActiveTasksCount;
    this.communicationService.groupActiveTaskFilter = this.groupActiveTaskFilter;

    this.communicationService.teamActiveTasks = this.teamActiveTasks;
    this.communicationService.teamActiveTasksCount = this.teamActiveTasksCount;
    this.communicationService.teamActiveTaskFilter = this.teamActiveTaskFilter;

    this.communicationService.teamClosedTasks = this.teamClosedTasks;
    this.communicationService.teamClosedTasksCount = this.teamClosedTasksCount;
    this.communicationService.teamClosedTaskFilter = this.teamClosedTaskFilter;

    this.communicationService.peersActiveTasks = this.peersActiveTasks;
    this.communicationService.peersActiveTasksCount = this.peersActiveTasksCount;
    this.communicationService.peersActiveTaskFilter = this.peersActiveTaskFilter;
  }

  onAssignedActiveTaskSubjectSelect(task: Task) {
    this.setShareValues();
    localStorage.setItem("filterDetails",JSON.stringify(this.assignedActiveTaskFilter));
    localStorage.setItem("selectedFolderName",this.selectedFolderName);
    localStorage.setItem("parentSelectedFolderName",this.parentSelectedFolderName);
    this.router.navigate([ '/task' ], { queryParams: { 'entityId': task._id, 'parentSelectedFolderName': this.parentSelectedFolderName,"selectedFolderName": this.selectedFolderName} })
    // this.setAssignedActiveTaskDetails(task);

  }

  onAssignedClosedTaskSubjectSelect(task: Task) {
    this.setShareValues();
    localStorage.setItem("filterDetails",JSON.stringify(this.assignedClosedTaskFilter));
    localStorage.setItem("selectedFolderName",this.selectedFolderName);
    localStorage.setItem("parentSelectedFolderName",this.parentSelectedFolderName);
    this.router.navigate([ '/task' ], { queryParams: { 'entityId': task._id, 'parentSelectedFolderName': this.parentSelectedFolderName,"selectedFolderName": this.selectedFolderName } })
    // if (task.refType == this.TASK_REF_TYPE_PROCESS) {
    //   this.setAssignedClosedTaskDetails(task);
    // }
    
  }

  onGroupActiveTaskSubjectSelect(task: Task) {
    this.setShareValues();
    this.router.navigate([ '/task' ], { queryParams: { 'entityId': task._id, 'parentSelectedFolderName': this.parentSelectedFolderName,"selectedFolderName": this.selectedFolderName} })
    localStorage.setItem("filterDetails",JSON.stringify(this.groupActiveTaskFilter));
    localStorage.setItem("selectedFolderName",this.selectedFolderName);
    localStorage.setItem("parentSelectedFolderName",this.parentSelectedFolderName);
    // if (task.refType == this.TASK_REF_TYPE_PROCESS) {
    //   this.setGroupActiveTaskDetails(task);
    // }
  }

  onTeamActiveTaskSubjectSelect(task: Task) {
    this.setShareValues();
    this.router.navigate([ '/task' ], { queryParams: { 'entityId': task._id, 'parentSelectedFolderName': this.parentSelectedFolderName,"selectedFolderName": this.selectedFolderName} })
    localStorage.setItem("filterDetails",JSON.stringify(this.teamActiveTaskFilter));
    localStorage.setItem("selectedFolderName",this.selectedFolderName);
    localStorage.setItem("parentSelectedFolderName",this.parentSelectedFolderName);
    // if (task.refType == this.TASK_REF_TYPE_PROCESS) {
    //   this.setTeamActiveTaskDetails(task);
    // }
  }

  onTeamClosedTaskSubjectSelect(task: Task) {
    this.setShareValues();
    this.router.navigate([ '/task' ], { queryParams: { 'entityId': task._id, 'parentSelectedFolderName': this.parentSelectedFolderName,"selectedFolderName": this.selectedFolderName} });
    localStorage.setItem("filterDetails",JSON.stringify(this.teamClosedTaskFilter));
    localStorage.setItem("selectedFolderName",this.selectedFolderName);
    localStorage.setItem("parentSelectedFolderName",this.parentSelectedFolderName);
    // if (task.refType == this.TASK_REF_TYPE_PROCESS) {
    //   this.setTeamClosedTaskDetails(task);
    // }
  }

  onPeersActiveTaskSubjectSelect(task: Task) {
    this.setShareValues();
    this.router.navigate([ '/task' ], { queryParams: { 'entityId': task._id, 'parentSelectedFolderName': this.parentSelectedFolderName, "selectedFolderName": this.selectedFolderName } } );
    localStorage.setItem("filterDetails",JSON.stringify(this.peersActiveTaskFilter));
    localStorage.setItem("selectedFolderName",this.selectedFolderName);
    localStorage.setItem("parentSelectedFolderName",this.parentSelectedFolderName);
  }

  onAllTaskSubjectSelect(task: Task) {
    this.router.navigate([ '/task' ], { queryParams: { 'entityId': task.refId } } );
    localStorage.setItem("filterDetails",JSON.stringify(this.peersActiveTaskFilter));
    localStorage.setItem("selectedFolderName",this.selectedFolderName);
    localStorage.setItem("parentSelectedFolderName",this.parentSelectedFolderName);

  }



  getBusinessKeysWithoutTable(selectedTask: State) {
    let dataPoints: DataPoint[];
    dataPoints = [];
    if (this.graphObjects.get(selectedTask.stateMachineInstanceModelId) != null && this.graphObjects.get(selectedTask.stateMachineInstanceModelId).dataPointConfigurationList != null && this.graphObjects.get(selectedTask.stateMachineInstanceModelId).dataPointConfigurationList.length > 0) {
      const dataPointsConfig = this.graphObjects.get(selectedTask.stateMachineInstanceModelId).dataPointConfigurationList;
      for (let data of dataPointsConfig) {
        if (!data.businessKeyFlag) {
          if (data.dataType == "ARRAY" && data.childdataPoints != null && data.childdataPoints.length > 0) {

          }
          else {
            if (!this.toBeDisplayOrNot(data, selectedTask)) {
              dataPoints.push(data);
            }
          }

        }
      }
    }
    return dataPoints;
  }

  toBeDisplayOrNot(dataPoint: DataPoint, selectedTask: State) {
    if (this.graphObjects.get(selectedTask.stateMachineInstanceModelId) != null && this.graphObjects.get(selectedTask.stateMachineInstanceModelId).dataPointConfigurationList != null && this.graphObjects.get(selectedTask.stateMachineInstanceModelId).dataPointConfigurationList.length > 0) {
      if (this.graphObjects.get(selectedTask.stateMachineInstanceModelId).states) {
        for (let state of this.graphObjects.get(selectedTask.stateMachineInstanceModelId).states) {
          if (state.stateCd == selectedTask.stateCd && state.dataPointAccessList) {
            for (let dataAccess of state.dataPointAccessList) {
              if (dataAccess.dataPointName != null && dataPoint.dataPointName != null && dataAccess.dataPointName == dataPoint.dataPointName) {
                return dataAccess.hide;
              }
            }
          }
        }
      }
    }
    return false;
  }

  isDataPointDisabled(dataPoint: DataPoint, selectedTask: State) {
    if (this.graphObjects != null && this.graphObjects.get(selectedTask.stateMachineInstanceModelId) != null && this.graphObjects.get(selectedTask.stateMachineInstanceModelId).states) {
      for (let state of this.graphObjects.get(selectedTask.stateMachineInstanceModelId).states) {
        if (state.stateCd == selectedTask.stateCd && state.dataPointAccessList) {
          for (let dataAccess of state.dataPointAccessList) {
            if (dataAccess.dataPointName != null && dataPoint.dataPointName != null && dataAccess.dataPointName == dataPoint.dataPointName) {
              return !dataAccess.writeAccess;
            }
          }
        }
      }
    }
    return false;
  }

  reserveGroupTask(state: State) {
    this.addToProgressTasks();
    this.stateService.getStateInstanceById(state._id)
      .subscribe(
        response => {
          if (response && response.assignedUserId) {
            this.openSnackBar("error", "Case has been updated from background", "Close");
            this.removeFromProgressTasksByStateInstanceId(state._id);
            this.removeFromGroupActiveTasksByStateInstanceId(state._id);
            return;
          }
          else {
            this.reserveGroupStateInstance(state);
          }
        }
      )
  }

  reserveGroupStateInstance(state: State) {
    this.showLoaderOverlay.taskBar = true;
    this.allocateTaskToUser.allocateTask(this.userId, state._id, "Reserve")
      .subscribe(updatedState => {
        this.showLoaderOverlay.taskBar = false;
        this.toggle(true);
        this.removeFromProgressTasksByStateInstanceId(state._id, "RESERVE");
        this.removeFromGroupActiveTasksByStateInstanceId(state._id);
        this.moveGroupTaskToAssignedActiveTask(updatedState);
      },
        error => {

        }
      )
  }

  moveToTeamClosedTask(state: State) {
    this.showLoaderOverlay.taskBar = true;
    this.taskService.getTaskByRefTypeAndRefId(this.TASK_REF_TYPE_PROCESS, state._id)
      .subscribe(
        response => {
          this.appendTasks([response], "teamClosedTasks", true);
          if (this.selectedFolderName == this.FOLDER_OPTION_TEAM_ACTIVE && this.teamClosedTasks.length == 0) {
            this.currentSelectedTask = null;
            this.currentSelectedTaskDetails = null;
          }
          this.showLoaderOverlay.taskBar = false;
        },
        error => {

        }
      )
  }

  moveAssignedActiveToAssignedClosedTask(state: State) {
    this.showLoaderOverlay.taskBar = true;
    this.taskService.getTaskByRefTypeAndRefId(this.TASK_REF_TYPE_PROCESS, state._id)
      .subscribe(
        response => {
          this.appendTasks([response], "assignedClosedTasks", true);
          if (this.selectedFolderName == this.FOLDER_OPTION_ASSIGNED_ACTIVE && this.assignedActiveTasks.length == 0) {
            this.currentSelectedTask = null;
            this.currentSelectedTaskDetails = null;
          }
          this.showLoaderOverlay.taskBar = false;
        },
        error => {

        }
      )
  }

  moveGroupTaskToAssignedActiveTask(state: State) {
    this.showLoaderOverlay.taskBar = true;
    this.taskService.getTaskByRefTypeAndRefId(this.TASK_REF_TYPE_PROCESS, state._id)
      .subscribe(
        response => {
          this.appendTasks([response], "assignedActiveTasks", true);
          if (this.selectedFolderName == this.FOLDER_OPTION_GROUP && this.groupActiveTasks.length == 0) {
            this.currentSelectedTask = null;
            this.currentSelectedTaskDetails = null;
          }
          this.showLoaderOverlay.taskBar = false;
        },
        error => {

        }
      )
  }



  checkForTags(emitedObject: any, type: string) {
    this.messageShown = false;
    if (emitedObject.tags != null && emitedObject.tags.length > 0) {
      this.saveTaskTags(emitedObject.tags);
      if (type === "Reserve") {
        this.currentSelectedTaskDetails.taskTags = emitedObject.tags;
      }
      else {
        this.currentSelectedTaskDetails.taskTags = emitedObject.tags;
      }
    }
    if (emitedObject.remarks != null) {
      this.currentSelectedTaskDetails.taskRemarks = emitedObject.remarks;
    }
    if (emitedObject.taskStatus) {
      this.currentSelectedTaskDetails.taskStatus = emitedObject.taskStatus;
    }
    if (type === "Update" || type === "Flagged") {
      this.updateProcessFlow(this.currentSelectedTaskDetails, 'ASSIGNED', false, true);
    }
    if (type === "UpdateDirect") {
      this.updateProcessFlow(this.currentSelectedTaskDetails, 'ASSIGNED', false, false);
    }
    else if (type === "Save") {
      this.updateProcessFlow(this.currentSelectedTaskDetails, 'ASSIGNED', true);
    }
    else if (type === "Archive") {
      this.archiveAssignedActiveTask();
    }
    else if (type === "Escalate") {
      this.escalateAssignedActiveTask();
      // this.escalateAssignedTask();
    }
    else if (type === "Reserve") {
      this.reserveGroupTask(JSON.parse(JSON.stringify(this.currentSelectedTaskDetails)));

    }
    else if (type === "Allocate_Assigned") {
      this.allocateAssignedActiveTask();
      // this.allocateAssignedTask();
    }
    else if (type === "Allocate_Unassigned") {
      this.allocateGroupActiveTask();
      // this.allocateUnAssignedTask();
    }
    else if (type == "Allocate_Team_Active") {
      this.allocateTeamActiveTask();
    }

  }

  updateProcessFlow(state: State, type: string, save: boolean = false, toogle: boolean = false) {
    if (toogle) {
      this.toggle(toogle);
    }
    if (this.documentsForState && this.documentsForState[state._id] && this.documentsForState[state._id].length > 0) {
      if (this.validateDocuments(state)) {
        this.assignedTaskActionButtonEnabled[state._id] = false;
        if (this.documentsToBeUploaded && this.documentsToBeUploaded.length > 0) {
          if (save)
            this.uploadDocumentForTask(state, true);
          else
            this.uploadDocumentForTask(state);
        }
        else {
          this.checkEntityAndSaveTask(state, save);
        }
      }
    }
    else {
      this.checkEntityAndSaveTask(state, save);
    }
  }

  updateDoc(document: Document) {
    // this.taskService.saveTaskActivity(TaskActivity.getFileDetailsUpdatedActivity(this.assignedTaskDdetails, document.documentName));
    this.checkEntityAndSaveTask(this.currentSelectedTaskDetails, false);
  }
  saveAssignedTask(state: State) {
    state = JSON.parse(JSON.stringify(state));
    this.addToProgressTasksByState(state);
    let documents: DocumentSubSet[] = [];
    this.showLoaderOverlay.mainScreen = true;
    if (this.documentsForState[state._id].length > 0) {
      for (let doc of this.documentsForState[state._id]) {
        let documentsSubset = new DocumentSubSet();
        documentsSubset.documentName = doc.documentName;
        documentsSubset.documentType = doc.documentType;
        documentsSubset.fileName = doc.fileName;
        documentsSubset.mandatory = doc.mandatory;
        documentsSubset.status = doc.status;
        documents.push(documentsSubset);
      }
    }
    this.stateService.save(state, documents, state._id)
      .subscribe(
        state => {
          if (state) {
            this.showLoaderOverlay.mainScreen = false;

            if (this.currentSelectedTaskDetails != null && state._id == this.currentSelectedTaskDetails._id) {
              this.currentSelectedTaskDetails = state;
              this.getAssociatedEntityData();
            }
            this.saveDocumentsForState(state);
            this.removeFromProgressTasksByStateInstanceId(state._id);

          }
        });
  }

  saveDocumentsForState(state: State) {
    if (!this.documentsForState || !this.documentsForState[state._id])
      return;
    for (let i = 0; i < this.documentsForState[state._id].length; i++) {
      this.stateService.saveDocument(this.documentsForState[state._id][i])
        .subscribe(
          document => {
            this.documentsForState[state._id][i] = document;
          },
          error => {
            
          }
        )
    }
  }


  removeUiFieldsFromState(state: State) {
    delete state["overdueString"]
    return state;
  }

  checkEntityAndSaveTask(state?: State, save?: boolean) {
    if (this.entityAssociatedWithTask && this.entityTabClicked) {
      const entity = new Entity();
      entity.fields = this.finalDataModel.fields;
      entity.label = this.finalDataModel.label;
      entity.name = this.finalDataModel.name;
      entity.version = this.finalDataModel.version;
      entity.process = this.finalDataModel.process;
      let map = {};
      this.entityMap = this.entitySharingService.getEntityMap(entity.fields);
      if (this.viewEntity != null) {
        this.entityMap["_id"] = this.viewEntity._id;
        this.entityMap["createdAt"] = this.viewEntity.createdAt;
        this.entityMap["createdBy"] = this.viewEntity.createdBy;
        this.entityMap["_entityName"] = this.viewEntity._entityName;
      }

      this.entityService.saveEntity(entity, this.entityMap)
        .subscribe(
          entity => {
            if (entity) {
              if (save) {
                this.saveAssignedTask(state)
              }
              else {
                this.updateAssignedTask(state);
              }
              // this.snackBar.open(entity._entityName.toString() + ' has been created successfully', "Undo", {
              //   duration: 5000
              // })
            }
          },
          error => {
            this.snackBar.open('Something went wrong while saving the associated entity, please try again in sometime', "Undo", {
              duration: 5000
            })
          }
        );

    }
    else if (!this.entityAssociatedWithTask || !this.entityTabClicked) {
      if (save) {
        this.saveAssignedTask(state);
      }
      else {
        this.updateAssignedTask(state);
      }
    }
  }



  updateAssignedTask(state: State) {
    // TODO: Save task before submitting it

    this.showLoaderOverlay.mainScreen = true;
    state = JSON.parse(JSON.stringify(state));
    this.addToProgressTasksByState(state);

    this.stateService.update(state.machineType, state.entityId, state['parameters'], state.taskStatus, state.taskRemarks, this.documentsForState[state._id], state._id)
      .subscribe(
        response => {
          this.assignedTaskActionButtonEnabled[state._id] = true;
          if (response) {

            this.showLoaderOverlay.mainScreen = false;
            const errorState: State = response;
            this.responseError = "";
            this.responseErrorFields = [];
            if (response._id && state._id || (errorState.errorMessageMap && Object.keys(errorState.errorMessageMap).length > 0)) {
              this.removeFromProgressTasksByStateInstanceId(state._id);
              this.showLoaderOverlay.mainScreen = false;
              this.progressBarFlag = false;
              return;
            }
            else {
              this.removeFromProgressTasksByStateInstanceId(state._id, "UPDATE");
              this.removeFromAssignedActiveTasksByStateInstanceId(state._id);
              this.showLoaderOverlay.mainScreen = false;
              this.progressBarFlag = false;
              //new showModal('successModal');
              this.assignedTaskActionButtonEnabled[state._id] = true;
              //this.removedAssignedTask(state);
            }

          }

          else {
            this.removeFromProgressTasksByStateInstanceId(state._id);
            this.removeFromAssignedActiveTasksByStateInstanceId(state._id);

            this.progressBarFlag = false;
            //new showModal('successModal');
            this.assignedTaskActionButtonEnabled[state._id] = true;
            //this.removedAssignedTask(state);
          }
        },
        error => {
          this.progressBarFlag = false;
          /*  this.showLoaderOverlay.mainScreen = false; */
          //new showModal('Error in updating process');
        }
      );
  }




  validateDocuments(stateInstance: State) {
    if (stateInstance && this.documentsForState && this.documentsForState[stateInstance._id]) {
      for (let doc of this.documentsForState[stateInstance._id]) {
        if (doc.mandatory) {
          if (!doc.userFileName) {
            // new showAlertModal('Error', doc.documentType + " is mandatory");
            return false;
          }
          if (!doc.status) {
            //new showAlertModal('Error', doc.documentType + " status is mandatory");
            return false;
          }
        }
      }
    }
    return true;
  }
 
  checkforManualStateEntity() {
    if (this.currentSelectedTaskDetails != null && this.currentSelectedTaskDetails != undefined && this.currentSelectedTaskDetails.parameters != undefined) {
      if (this.currentSelectedTaskDetails.parameters['_associatedEntityId'] != null && this.currentSelectedTaskDetails.parameters['_associatedEntityId'] != undefined) {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      return false;
    }
  }

  archiveTeamActiveTask() {
    this.addToProgressTasks();
    this.showLoaderOverlay.taskBar = true;
    const state: State = JSON.parse(JSON.stringify(this.currentSelectedTaskDetails));
    state.archivedBy = this.universalUser.getUser()._id;
    this.stateService.saveArchivedState(state)
      .subscribe(
        response => {
          this.showLoaderOverlay.taskBar = false;
          this.openSnackBar("success", "Case got closed Successfully", "Close");
          this.removeFromProgressTasksByStateInstanceId(state._id, "ARCHIVE");
          this.removeFromTeamActiveTasksByStateInstanceId(state._id);
          this.moveToTeamClosedTask(state);
          this.toggle(true);
        },
        error => {
          this.openSnackBar("error", "Unable to archived case", "Close");
          this.showLoaderOverlay.taskBar = false;
          this.removeFromProgressTasksByStateInstanceId(state._id);
        }
      )
  }

  archiveAssignedActiveTask() {
    this.currentSelectedTask.enableUpdate = true;
    this.addToProgressTasks();
    this.showLoaderOverlay.taskBar = true;
    const state: State = JSON.parse(JSON.stringify(this.currentSelectedTaskDetails));
    this.stateService.saveArchivedState(state)
      .subscribe(
        response => {
          this.showLoaderOverlay.taskBar = false;
          this.openSnackBar("success", "Case got closed Successfully", "Close");
          this.removeFromProgressTasksByStateInstanceId(state._id, "ARCHIVE");
          this.removeFromAssignedActiveTasksByStateInstanceId(state._id);
          this.moveAssignedActiveToAssignedClosedTask(response);
          this.toggle(true);
        },
        error => {
          this.openSnackBar("error", "Unable to archived case", "Close");
          this.showLoaderOverlay.taskBar = false;
          this.removeFromProgressTasksByStateInstanceId(state._id);
        }
      )
  }



  onUserSelectAssignedTask(user) {
    this.allocatedAssignedTaskToUserId = user.userId;
  }

  onUserSelectUnAssignedTask(user) {

    this.allocatedUnAssignedTaskToUserId = user.userId;
    
  }

  onUserSelectTeamActiveTask(user) {
    this.allocatedTeamActiveTaskToUserId = user.userId
  }

  allocateTeamActiveTask() {
    if (this.allocatedTeamActiveTaskToUserId) {
      this.addToProgressTasks();
      this.currentSelectedTask.enableUpdate = false;
      this.showLoaderOverlay.mainScreen = true;
      const state: State = JSON.parse(JSON.stringify(this.currentSelectedTaskDetails));
      this.allocateTaskToUser.allocateTask(this.allocatedTeamActiveTaskToUserId, state._id, "Allocate")
        .subscribe(
          response => {
            if (response) {
              this.openSnackBar("success", "Case got allocated successfully", "Close");
              this.commonService.fetchNotifications(this.userId);
              this.removeFromProgressTasksByStateInstanceId(state._id, "ALLOCATE");
              this.removeFromTeamActiveTasksByStateInstanceId(state._id);
              this.toggle(true);
            }
          },
          error => {
            this.openSnackBar("error", "Error while allocating a case", "Close");
            this.removeFromProgressTasksByStateInstanceId(state._id);
          }
        )
    }
  }

  allocateAssignedActiveTask() {
    if (this.allocatedAssignedTaskToUserId) {
      this.addToProgressTasks();
      this.currentSelectedTask.enableUpdate = false;
      this.showLoaderOverlay.mainScreen = true;
      const state: State = JSON.parse(JSON.stringify(this.currentSelectedTaskDetails));
      this.allocateTaskToUser.allocateTask(this.allocatedAssignedTaskToUserId, state._id, "Allocate")
        .subscribe(
          response => {
            if (response) {
              this.openSnackBar("success", "Case got allocated successfully", "Close");
              this.commonService.fetchNotifications(this.userId);
              this.removeFromProgressTasksByStateInstanceId(state._id, "ALLOCATE");
              this.removeFromAssignedActiveTasksByStateInstanceId(state._id);
              this.toggle(true);
            }
          },
          error => {
            this.openSnackBar("error", "Error while allocating a case", "Close");
            this.removeFromProgressTasksByStateInstanceId(state._id);
          }
        )
    }


  }


  allocateBulkAssignedActiveTasks(type:string) {
      let selectedTasks = [] 
      if(this.selectedFolderName == this.FOLDER_OPTION_ASSIGNED_ACTIVE){
        selectedTasks = this.assignedActiveTasks;
      }
      else if(this.selectedFolderName == this.FOLDER_OPTION_GROUP){
        selectedTasks = this.groupActiveTasks;
      }
      else if(this.selectedFolderName = this.FOLDER_OPTION_TEAM_ACTIVE){
        selectedTasks = this.teamActiveTasks;
      }
      let payload = { taskList: [], type: type };
      const stateIds = [];
      const entityIds = [];
      for (let task of selectedTasks) {
        if (task.checkForAllocate && stateIds.indexOf(task.refId) == -1) {
          stateIds.push(task.refId);
          if(this.allocatedAssignedTaskToUserId){
            payload.taskList.push({ stateId: task.refId, userId: this.allocatedAssignedTaskToUserId });
          }
          else{
            payload.taskList.push({ stateId: task.refId });
          }
          entityIds.push(task.entityId);
        }
      }
      this.showFilter = false;
      this.showLoaderOverlay.taskListing = true;
      this.showLoaderOverlay.taskBar = true;

      this.communicationService.bulkTaskUpdated = entityIds;
      this.allocateTaskToUser.allocateBulkTask(payload)
        .subscribe(
          response => {
            if (response && response.length > 0) {
              const allocateFailedBusinessKeys: string[] = []
              const successStateIds: string[] = [];
              for (let task of selectedTasks) {
                if (task.checkForAllocate) {
                  if (response.indexOf(task.refId) == -1)
                    allocateFailedBusinessKeys.push(task.businessKey);
                  else
                    successStateIds.push(task.refId);
                }
              }
              if (allocateFailedBusinessKeys && allocateFailedBusinessKeys.length > 0) {
                this.openSnackBar("error", "Unable to allocate tasks " + allocateFailedBusinessKeys.join(", ") + " mail has been sent to administrator.", "Close");
              }
              else {
                this.openSnackBar("success", "Tasks has been allocated successfully", "Close");
              }
              if (successStateIds && successStateIds.length > 0) {
                if(this.selectedFolderName == this.FOLDER_OPTION_ASSIGNED_ACTIVE){
                 this.assignedActiveTasksCount = this.assignedActiveTasksCount - successStateIds.length;
                }
                if(this.selectedFolderName == this.FOLDER_OPTION_TEAM_ACTIVE){
                  this.teamActiveTasksCount = this.teamActiveTasksCount - successStateIds.length;
                }
                for (let stateId of successStateIds) {
                  if(this.selectedFolderName == this.FOLDER_OPTION_ASSIGNED_ACTIVE){
                    this.removeFromAssignedActiveTasksByStateInstanceId(stateId);
                  }
                  if(this.selectedFolderName == this.FOLDER_OPTION_TEAM_ACTIVE){
                    this.removeFromTeamActiveTasksByStateInstanceId(stateId);
                  }
                }
              }
            }
            else {
              this.openSnackBar("error", "Unable to allocate tasks", "Close");
            }
            this.showLoaderOverlay.taskListing = false;
            this.showLoaderOverlay.taskBar = false;


          },
          error => {
            this.openSnackBar("error", "Unable to allocate tasks", "Close");
            this.showLoaderOverlay.taskListing = false;
            this.showLoaderOverlay.taskBar = false;

          }
        )
    }

  updateBulkAssignedActiveTasks(map:any) {
    let selectedTasks = [];

    if(this.selectedFolderName == this.FOLDER_OPTION_ASSIGNED_ACTIVE){
      selectedTasks = this.assignedActiveTasks;
    }
    if(this.selectedFolderName == this.FOLDER_OPTION_TEAM_ACTIVE){
      selectedTasks = this.teamActiveTasks;
    }
      const entityIds = [];
      let machineType = '';
      if(map!=null && map['taskRemarks'] && selectedTasks.length > 0){
        for (let task of selectedTasks) {
          if (task.checkForAllocate && entityIds.indexOf(task.entityId) == -1) {
            entityIds.push(task.entityId);
            machineType = task.machineType;
          }
        }
        if(entityIds.length < 20){
          this.communicationService.bulkTaskUpdated = entityIds;
          this.showFilter = false;
          this.showLoaderOverlay.taskListing = true;
          this.showLoaderOverlay.taskBar = true;
          this.stateService.updateBulkTask(machineType,entityIds,{},map['taskStatus'],map['taskRemarks'])
          .subscribe(
            response => {
              if (response) {
              this.openSnackBar("success", "Tasks will be updated in background", "Close");
              if(this.selectedFolderName == this.FOLDER_OPTION_ASSIGNED_ACTIVE){
                this.assignedActiveTasksCount = this.assignedActiveTasksCount - entityIds.length; 
              }
              if(this.selectedFolderName == this.FOLDER_OPTION_TEAM_ACTIVE){
                this.teamActiveTasksCount = this.teamActiveTasksCount - entityIds.length; 
              }           
              for (let task of selectedTasks) {
                if (entityIds.includes(task.entityId)) {
                  if(this.selectedFolderName == this.FOLDER_OPTION_ASSIGNED_ACTIVE){
                    this.removeFromAssignedActiveTasksByStateInstanceId(task.refId);
                  }
                  if(this.selectedFolderName == this.FOLDER_OPTION_TEAM_ACTIVE){
                    this.removeFromTeamActiveTasksByStateInstanceId(task.refId);
                  }                
                }
              }
              this.currentSelectedTask = null;
              this.currentSelectedTaskDetails = null;
              this.showLoaderOverlay.taskListing = false;
              this.showLoaderOverlay.taskBar = false;
              }
  
            },
            error => {
              this.openSnackBar("error", "Unable to submit and close tasks", "Close");
              this.showLoaderOverlay.taskListing = false;
              this.showLoaderOverlay.taskBar = false;
  
            }
          )
        }
        else{
          this.openSnackBar("error","Can not update more than 20 tasks at one time","Close");
        }
        
      }
  }


  allocateGroupActiveTask() {
    if (this.allocatedUnAssignedTaskToUserId) {
      this.addToProgressTasks();
      const state: State = JSON.parse(JSON.stringify(this.currentSelectedTaskDetails));
      this.allocateTaskToUser.allocateTask(this.allocatedUnAssignedTaskToUserId, state._id, "Allocate")
        .subscribe(
          response => {
            if (response) {
              this.openSnackBar("success", "Case got allocated successfully", "Close");
              this.removeFromProgressTasksByStateInstanceId(state._id, "ALLOCATE");
              this.removeFromGroupActiveTasksByStateInstanceId(state._id);
              this.toggle(true);
            }
          },
          error => {
            this.openSnackBar("error", "Error while allocating case", "Close");
            this.removeFromProgressTasksByStateInstanceId(state._id);
          }
        )
    }
  }


  escalateAssignedActiveTask() {
    this.currentSelectedTask.enableUpdate = false;
    this.showLoaderOverlay.mainScreen = true;
    this.addToProgressTasks();
    const state: State = JSON.parse(JSON.stringify(this.currentSelectedTaskDetails));
    if (this.allocatedAssignedTaskToUserId) {
      this.allocateTaskToUser.allocateTask(this.allocatedAssignedTaskToUserId, state._id, "Escalate")
        .subscribe(
          response => {
            this.openSnackBar("success", "Case escalated successfully", "Close");
            this.removeFromProgressTasksByStateInstanceId(state._id, "ESCALTE");
            this.removeFromAssignedActiveTasksByStateInstanceId(state._id);
            this.showLoaderOverlay.mainScreen = false;
            this.toggle(true);
          },
          error => {
            this.openSnackBar("success", "Error while escalating a case", "Close");
            this.removeFromProgressTasksByStateInstanceId(state._id);
            this.showLoaderOverlay.mainScreen = false;
          }
        )
    }
  }


  getDocuments(stateInstane: State) {
    if (Object.keys(this.documentsForState).length > 0 && this.documentsForState[stateInstane._id]) {
      return this.documentsForState[stateInstane._id];
    }
    this.documentsForState[stateInstane._id] = [];
    this.stateService.getDocumentsForState(stateInstane)
      .subscribe(documents => {
        if (documents) {
          this.documentsForState[stateInstane._id] = documents;
          this.docLengthForState = documents.length;
        }
      },
        error => {

        }
      )
    // this.getTimeline(stateInstane);
  }

  //  --------------------- Document Section
  onNewDocumentAdd(stateInstanace: State) {
    const newDoc = new Document();
    newDoc.stateInstanceId = stateInstanace._id;
    newDoc.flowInstanceId = stateInstanace.stateMachineInstanceModelId;
    newDoc.documentType = "OTHER";
    newDoc.documentName = uuid();
    newDoc.status = "PENDING";
    if (!this.documentsForState[stateInstanace._id]) {
      this.documentsForState[stateInstanace._id] = [];
    }
    this.documentsForState[stateInstanace._id].push(newDoc);

  }
  onRemoveDocument(stateInstanace: State, document: Document) {
    this.deleteDocumentFromServer(stateInstanace, document);
  }
  removeDoc(document: Document) {
    this.docLengthForState--;
    this.onRemoveDocument(this.assignedTaskDdetails, document);
  }

  deleteDocumentFromServer(stateInstanace: State, document: Document) {
    this.fileService.delete(document.url)
      .subscribe(
        response => {
          this.deleteDocumentForState(stateInstanace, document);
        },
        error => {
          this.responseError = "Error while removing document. Please try again later";
        }
      )
  }

  deleteDocumentForState(stateInstanace: State, document: Document) {
    if (!document._id)
      return;
    this.stateService.deleteDocument(document)
      .subscribe(
        response => {
          if (stateInstanace && document && this.documentsForState && this.documentsForState[stateInstanace._id]) {
            let index = this.documentsForState[stateInstanace._id].indexOf(document)
            if (index > -1) {
              this.documentsForState[stateInstanace._id].splice(index, 1);
              this.saveAssignedTask(stateInstanace);
            }
          }
        },
        error => {
          this.responseError = "Error while removing document. Please try again later";
        }
      )
  }

  isUploadedDocumentValid(document: Document, fileType: string) {
    if (document && document.allowedFileTypes && document.allowedFileTypes.length > 0) {
      for (let allowfileType of document.allowedFileTypes) {
        if (fileType.match(allowfileType)) {
          return true;
        }
      }
      return false
    }
    return true;
  }
  uploadDoc(map: any) {
    let event: any = map.event;
    let doc: Document = map.document;
    this.addToProgressTasks();
    const state = JSON.parse(JSON.stringify(this.currentSelectedTaskDetails));
    this.onDocumentUploadForTask(event, doc, state, true);
  }

  onDocumentUploadForTask(event, document: Document, state: State, save: boolean = false) {
    var documentName = document.documentName;
    if (document.documentName == null || document.documentName.trim().length == 0) {
      documentName = uuid();
      document.documentName = documentName;
    }
    const fileInputForm = new FormData();
    const file: File = event.target.files[0];
    if (!this.isUploadedDocumentValid(document, file.type)) {
      // new showAlertModal("Error", document.documentType + " invalid file type");
    }
    fileInputForm.append('file', file, file.name);
    var uploadFileName = uuid();
    if (file.name.split(".").length >= 2) {
      uploadFileName = uploadFileName + "." + file.name.split(".")[file.name.split(".").length - 1];
    }
    fileInputForm.append("fileName", uploadFileName);
    fileInputForm.append("functionInstanceName", "FLOW");
    fileInputForm.append("entityType", state.machineType);
    fileInputForm.append("entityRef", state._id);
    fileInputForm.append("documentName", documentName);
    fileInputForm.append("stateInstanceId", state._id);
    document.userFileName = file.name;
    document.fileName = uploadFileName;
    document.uploadTime = new Date();
    this.documentsToBeUploaded.push(fileInputForm);
    this.uploadDocumentForTask(state, true);
  }
  uploadDocumentForTask(state: State, save: boolean = false) {
    let numberOfDocs = this.documentsToBeUploaded.length;

    for (let inputDoc of this.documentsToBeUploaded) {
      this.fileService.upload(inputDoc)
        .subscribe(
          response => {
            if (response && response["url"] && response["fileName"]) {
              for (let doc of this.documentsForState[state._id]) {
                if (doc.stateInstanceId == inputDoc.get("stateInstanceId") && doc.documentName == inputDoc.get("documentName")) {
                  doc.url = response["url"];
                  doc.fileName = inputDoc.get("fileName");
                  doc.downloadFileUrl = response["downloadFileUrl"];
                  doc.fullDataUrl = response["fullDataUrl"];
                  doc.fullFileUrl = response["fullFileUrl"];
                  this.docLengthForState++;
                }
              }
            }
            numberOfDocs--;
            if (numberOfDocs == 0) {
              if (!save) {
                this.checkEntityAndSaveTask(state, save)
              }
              else if (save) {
                this.checkEntityAndSaveTask(state, save);
              }
            }
          }, (error) => {
            if (error && error.error && error.error.error && error.error.error.message) {
              this.snackBar.open(error.error.error.message, "Dismiss", {
                duration: 10000
              });
            }
          })
    }

  }

  //  --------------------- Document Section END
  getTimeline(state: State) {
    this.timelineStates = [];
    this.stateService.getTimelineForFlow(state.stateMachineInstanceModelId)
      .subscribe(timelineStates => {
        if (timelineStates) {
          this.timelineStates = timelineStates;
        }
      });
  }

  timelineSelect(timeLineState: TimelineStateAuditData) {
    this.selectedTimeLineState = timeLineState;
  }

  getSelectedTask(tasks: Task[]) {
    var foundIndex: number = -1;
    for (let i = 0; i < tasks.length; i++) {
      if (foundIndex == -1 && tasks[i].cssClass == this.TABLINKS_ACTIVE) {
        foundIndex = i;
      }
      else {
        tasks[i].cssClass = this.TABLINKS;
      }
    }
    return tasks[foundIndex];
  }

  selectForAllTasks(value) {
    if (value) {
      this.onFolderSelection(this.FOLDER_OPTION_ALL);
    }
    else {
      this.onFolderSelection(this.FOLDER_OPTION_ASSIGNED_ACTIVE);
    }
  }


  onFolderSelection(folderName: string, resetTasks?: boolean) {
    this.parentSelectedFolderName = folderName;
    if (folderName == this.FOLDER_OPTION_ASSIGNED_ACTIVE || folderName == this.FOLDER_OPTION_ASSIGNED_CLOSED) {
      this.parentSelectedFolderName = this.FOLDER_OPTION_ASSIGNED;
    }
    else if (folderName == this.FOLDER_OPTION_TEAM_ACTIVE || folderName == this.FOLDER_OPTION_TEAM_CLOSED) {
      this.parentSelectedFolderName = this.FOLDER_OPTION_TEAM;
    }
    if (folderName == this.FOLDER_OPTION_ASSIGNED) {
      this.selectedFolderName = this.selectedFolderNameForAssignedTask;
    }
    else if (folderName == this.FOLDER_OPTION_TEAM) {
      this.selectedFolderName = this.selectedFolderNameForTeamTask;
    }
    else {
      this.selectedFolderName = folderName;
    }
    this.pageNumber =1;
    if (this.selectedFolderName == this.FOLDER_OPTION_ASSIGNED_ACTIVE) {
      if (resetTasks) {
        this.assignedActiveTaskFilterQuery = "";
        this.assignedActiveTasks = [];
        this["assignedActiveTasks"] = [];
        this.assignedActiveTaskFilter = this.initTaskFilter(this.assignedActiveTaskFilter, TASK_FOLDER.Personal, "ACTIVE", true,0);
      }
      if (!this.assignedActiveTasks || this.assignedActiveTasks.length == 0) {
        this.currentSelectedTask = null;
        this.currentSelectedTaskDetails = null;
        this.fetchRecordsForAssignedActiveTasks();
      }
      else {
        this.setAssignedActiveTaskDetails(this.getSelectedTask(this.assignedActiveTasks));
      }
    }
    else if (this.selectedFolderName == this.FOLDER_OPTION_ASSIGNED_CLOSED) {
      if (resetTasks) {
        this.assignedClosedTaskFilterQuery = "";
        this.assignedClosedTasks = [];
        this["assignedClosedTasks"] = [];
        this.assignedClosedTaskFilter = this.initTaskFilter(this.assignedClosedTaskFilter, TASK_FOLDER.Personal, "CLOSED", true,0);
      }
      if (!this.assignedClosedTasks || this.assignedClosedTasks.length == 0) {
        this.currentSelectedTask = null;
        this.currentSelectedTaskDetails = null;
        this.fetchRecordsForAssignedClosedTasks();
      }
      else {
        this.setAssignedClosedTaskDetails(this.getSelectedTask(this.assignedClosedTasks));
      }
    }
    else if (this.selectedFolderName == this.FOLDER_OPTION_GROUP) {
      if (resetTasks) {
        this.groupActiveTaskFilterQuery = "";
        this.groupActiveTasks = [];
        this["groupActiveTasks"] = [];
        this.groupActiveTaskFilter = this.initTaskFilter(this.groupActiveTaskFilter, TASK_FOLDER.Group, "ACTIVE", true,0);
      }
      if (!this.groupActiveTasks || this.groupActiveTasks.length == 0) {
        this.currentSelectedTask = null;
        this.currentSelectedTaskDetails = null;
        this.fetchRecordsForGroupActiveTasks();
      }
      else {
        this.setGroupActiveTaskDetails(this.getSelectedTask(this.groupActiveTasks));
      }
    }
    else if (this.selectedFolderName == this.FOLDER_OPTION_TEAM_ACTIVE) {
      if (resetTasks) {
        this.teamActiveTaskFilterQuery = "";
        this.teamActiveTasks = [];
        this["teamActiveTasks"] = [];
        this.teamActiveTaskFilter = this.initTaskFilter(this.teamActiveTaskFilter, TASK_FOLDER.Team, "ACTIVE", true,0);
      }
      if (!this.teamActiveTasks || this.teamActiveTasks.length == 0) {
        this.currentSelectedTask = null;
        this.currentSelectedTaskDetails = null;
        this.fetchRecordsForTeamActiveTasks();
      }
      else {
        this.setTeamActiveTaskDetails(this.getSelectedTask(this.teamActiveTasks));
      }
    }
    else if (this.selectedFolderName == this.FOLDER_OPTION_TEAM_CLOSED) {
      if (resetTasks) {
        this.teamClosedTaskFilterQuery = "";
        this.teamClosedTasks = [];
        this["teamClosedTasks"] = [];
        this.teamClosedTaskFilter = this.initTaskFilter(this.teamClosedTaskFilter, TASK_FOLDER.Team, "CLOSED", true,0);
      }
      if (!this.teamClosedTasks || this.teamClosedTasks.length == 0) {
        this.currentSelectedTask = null;
        this.currentSelectedTaskDetails = null;
        this.fetchRecordsForTeamClosedTasks();
      }
      else {
        this.setTeamClosedTaskDetails(this.getSelectedTask(this.teamClosedTasks));
      }
    }
    else if (this.selectedFolderName == this.FOLDER_OPTION_PEERS) {
      if (resetTasks) {
        this.peersActiveTaskFilterQuery = "";
        this.peersActiveTasks = [];
        this["peersActiveTasks"] = [];
        this.peersActiveTaskFilter = this.initTaskFilter(this.peersActiveTaskFilter, TASK_FOLDER.Peers, "ACTIVE", true,0);
      }
      if (!this.peersActiveTasks || this.peersActiveTasks.length == 0) {
        this.currentSelectedTask = null;
        this.currentSelectedTaskDetails = null;
        this.fetchRecordsForPeersActiveTasks();
      }
      else {
        this.setPeerActiveTaskDetails(this.getSelectedTask(this.peersActiveTasks));
      }
    }
    else if (this.selectedFolderName == this.FOLDER_OPTION_ALL) {
      if (resetTasks) {
        this.allTaskFilterQuery = "";
        this.allTasks = [];
        this["allTasks"] = [];
        this.allTaskFilter = this.initTaskFilter(this.allTaskFilter, null, "ACTIVE", true,0);
      }
      if (!this.allTasks || this.allTasks.length == 0) {
        this.currentSelectedTask = null;
        this.currentSelectedTaskDetails = null;
        this.fetchRecordsForAllTasks();
      }
      else {
        this.setAllTaskDetails(this.getSelectedTask(this.allTasks));
      }
    }
  }

  onAssignedSelection(statusCd?: string) {
    if (!statusCd || statusCd == "ACTIVE") {
      this.selectedFolderName = this.FOLDER_OPTION_ASSIGNED_ACTIVE;
      if (!this.assignedActiveTasks || this.assignedActiveTasks.length == 0) {
        this.fetchRecordsForAssignedActiveTasks();
      }
    }
    if (statusCd && statusCd == "CLOSED") {
      this.selectedFolderName = this.FOLDER_OPTION_ASSIGNED_CLOSED;
      if (!this.assignedClosedTasks || this.assignedClosedTasks.length == 0) {
        this.fetchRecordsForAssignedClosedTasks();
      }
    }
  }

  onGroupSelection() {
    this.selectedFolderName = this.FOLDER_OPTION_GROUP;
    if (!this.groupActiveTasks || this.groupActiveTasks.length == 0) {
      this.fetchRecordsForGroupActiveTasks();
    }
  }

  onTeamSelection(statusCd?: string) {
    if (!statusCd || statusCd == "ACTIVE") {
      if (!this.teamActiveTasks || this.teamActiveTasks.length == 0) {
        this.fetchRecordsForTeamActiveTasks();
      }
    }
    if (statusCd && statusCd == "CLOSED") {
      if (!this.teamClosedTasks || this.teamClosedTasks.length == 0) {
        this.fetchRecordsForTeamClosedTasks();
      }
    }
  }


  getStatusList(selectedTask: State) {
    if (this.graphObjects.get(selectedTask.stateMachineInstanceModelId) != null && this.graphObjects.get(selectedTask.stateMachineInstanceModelId).states) {
      for (let state of this.graphObjects.get(selectedTask.stateMachineInstanceModelId).states) {
        if (state.stateCd && selectedTask.stateCd && state.stateCd == selectedTask.stateCd && state.statusList) {
          return state.statusList;
        }
      }
    }
    return [];
  }

  getBusinessKeysWithTable(selectedTask: State) {
    let arrayDataPoints: DataPoint[];
    arrayDataPoints = [];
    if (this.graphObjects.get(selectedTask.stateMachineInstanceModelId) != null) {
      const dataPointsConfig = this.graphObjects.get(selectedTask.stateMachineInstanceModelId).dataPointConfigurationList;
      for (let data of dataPointsConfig) {
        if (data != null && !data.businessKeyFlag) {
          if (data.dataType == "ARRAY" && selectedTask['parameters'] != null && selectedTask['parameters'][data.dataPointName] != null && selectedTask['parameters'][data.dataPointName].length > 0 && typeof selectedTask['parameters'][data.dataPointName] != "string") {
            arrayDataPoints.push(data)
          }
          else {

          }

        }
      }
    }
    return arrayDataPoints;
  }

  submitSearch(event) {
    // if (event == 'Enter') {
    this.showFilter = false; this.filterQuery = this.searchTerm;
    // }
  }

  setCssClass(tasks: Task[], cssClass: string) {
    if (!tasks)
      return;
    for (let task of tasks) {
      task.cssClass = cssClass;
    }
  }

  onAssignedActiveTaskInput() {
    this.assignedActiveTaskFilterQuery = this.assignedActiveTaskFilter.businessKey;
    this.setCssClass(this.assignedActiveTasks, this.TABLINKS);
    this.currentSelectedTask = null;
    this.currentSelectedTaskDetails = null;

  }

  onAssignedClosedTaskInput() {
    this.assignedClosedTaskFilterQuery = this.assignedClosedTaskFilter.businessKey;
    this.setCssClass(this.assignedClosedTasks, this.TABLINKS);
    this.currentSelectedTask = null;
    this.currentSelectedTaskDetails = null;
  }

  onGroupActiveTaskInput() {
    this.groupActiveTaskFilterQuery = this.groupActiveTaskFilter.businessKey;
    this.setCssClass(this.groupActiveTasks, this.TABLINKS);
    this.currentSelectedTask = null;
    this.currentSelectedTaskDetails = null;
  }

  onTeamActiveTaskInput() {
    this.teamActiveTaskFilterQuery = this.teamActiveTaskFilter.businessKey;
    this.setCssClass(this.teamActiveTasks, this.TABLINKS);
    this.currentSelectedTask = null;
    this.currentSelectedTaskDetails = null;
  }

  onTeamClosedTaskInput() {
    this.teamClosedTaskFilterQuery = this.teamClosedTaskFilter.businessKey;
    this.setCssClass(this.teamClosedTasks, this.TABLINKS);
    this.currentSelectedTask = null;
    this.currentSelectedTaskDetails = null;
  }

  getValueForArraydatatype(dataPoint: DataPoint, state: State) {
    let values = []
    if (state['parameters'][dataPoint.dataPointName] != null) {
      for (let d of state['parameters'][dataPoint.dataPointName]) {
        let headerValue = []
        for (let dp of this.getHeaderDataPointsForArrayDataType(dataPoint, state)) {
          headerValue.push(d[dp.dataPointName])
        }
        values.push(headerValue);
      }
    }
    return values;
  }

  getHeaderDataPointsForArrayDataType(dataPoint: DataPoint, state: State) {
    this.arrayTableHeaders = {};
    let headers = [];
    if (dataPoint.childdataPoints != null && dataPoint.childdataPoints.length > 0) {
      for (let childdata of dataPoint.childdataPoints) {
        this.arrayTableHeaders[childdata.dataPointName] = [];
        headers.push(childdata);
      }
    }
    else {
      if (state['parameters'] != null && state['parameters'][dataPoint.dataPointName] != null && state['parameters'][dataPoint.dataPointName].length > 0) {
        for (let key of state['parameters'][dataPoint.dataPointName]) {
          this.arrayTableHeaders[key] = [];
          const newDataPoint = new DataPoint();
          newDataPoint.dataPointName = key;
          newDataPoint.dataPointLabel = key
          headers.push(newDataPoint);
        }
      }
    }
    return headers;

  }

  getMultiSelectAndArrayValue(value) {
    if (!value) {
      return null;
    }
    if (value instanceof Array) {
      return value.join(", ")
    }
    return value;
  }

  //Team view ---------------
  getUserChildren(): void {
    this.fetchUserService.getUserChildren()
      .subscribe(userChildren => {
        if (userChildren && userChildren.length > 0) {
          this.userChildren = userChildren;
          let index: any;
          for (index in this.options) {
            if (this.options[index].value === "ARCHIVED") {
              this.options.splice(index, 1);
            }
          }
          this.options.push({ display: 'Team', value: this.FOLDER_OPTION_TEAM })
          // this.options.push({ display: 'Archived', value: 'ARCHIVED' })
          //this.fetchRecordsFor(this.TAB_ASSIGNED, this.assignedStates);
        }
        else {
          this.progressBarFlag = false;
        }
      });
  }



  setFirstAssignedTeamTaskValues(states: any) {
    if (states != null && states.length > 0) {
      this.assignedStateTabclassTeam[states[0]._id] = "block active";
      let co = this.getDataPointConfigForGraph();
      this.stateService.getDataPointconfiguration(co, states[0].stateMachineInstanceModelId)
        .subscribe(
          response => {
            if (response) {
              this.showLoaderOverlay.mainScreen = false;
            }
            this.graphObject = response;
            this.graphObjects.set(states[0].stateMachineInstanceModelId, this.getSortedDatPointGraphObject(response));
            this.assignedTaskDetailsTeam = states[0];
            this.currentSelectedTaskDetails = this.assignedTaskDetailsTeam;
            this.decideStagesForState(this.assignedTaskDetailsTeam);
            this.statusList = this.getStatusList(this.assignedTaskDetailsTeam);
            this.getDocuments(this.assignedTaskDetailsTeam);
            this.loadingAssigned = false;
            if (!this.loadingUnassigned && !this.loadingAssigned) {
              this.progressBarFlag = false;
              // this.baThemeSpinner.hide();
            }
            this.assignedTaskActionButtonEnabledTeam[this.assignedTaskDetailsTeam._id] = true;
            this.assignedStategraphObjectTeam = response;
            /* for (let state of states) {
              if (this.assignedTaskDetailsTeam != state) {
                this.assignedStateTabclassTeam[state._id] = "block";
                this.setGraphObjects(state);
                this.assignedTaskActionButtonEnabledTeam[state._id] = true;
              }
            } */
          },
          error => {
            this.loadingAssigned = false;
            this.progressBarFlag = false;
            this.showLoaderOverlay.mainScreen = false;
          }
        )
    } else {
      this.records = false;
      this.progressBarFlag = false;
    }
  }


  // -------------------- tags implementation ----------- //

  getTaskTags(machineType: string) {
    this.taskTags = [];
    this.tagService.getTags(machineType)
      .subscribe(taskTag => {
        this.taskTagsObject = taskTag;
        if (taskTag) {
          for (let key of Object.keys(this.taskTagsObject.tags)) {
            this.taskTags.push({ value: key, label: key });
          }
        }
        else {
          this.showTagFilter = false;
        }
      }, error => {

      });
  }

  getAllTaskTags() {
    this.tagService.getAllTags()
      .subscribe (
        taskTags => {
          
        }
      )
  }

  emitState(state: State) {
    this.assignedTaskDdetails = state;
  }

  saveTaskTags(tags: any[]) {
    this.currentSelectedTaskDetails.taskTags = tags;
    this.tagService.saveTaskTags(tags, this.currentSelectedTaskDetails.machineType)
      .subscribe(taskTag => {
        
      }, error => {

      });
  }


  // filter //

  fetchGraphs(): void {
    let commonsearchModel = new CommonSearchModel();
    commonsearchModel.searchParams = [{ "statusCd": "ACTIVE" }];
    commonsearchModel.returnFields = ["machineType", "machineLabel", "masterStages"];
    this.graphService.fetch(commonsearchModel)
      .subscribe(graphObjects => {
        if (graphObjects) {
          this.showLoaderOverlay.mainScreen = false;
        }
        this.activeGraphObjectList = graphObjects;
        const machineTypes: string[] = [];
        if (this.activeGraphObjectList) {
          let graphs: string[] = []
          for (let graph of this.activeGraphObjectList) {
            graphs.push(graph.machineType);
            this.processOptions.push({ label: graph.machineLabel, value: graph.machineType })
            machineTypes.push(graph.machineType);
            let stageMap = {};
            if (graph.masterStages && graph.masterStages.length > 0) {
              const graphStages: StateStage[] = graph.masterStages;
              graphStages.sort(function (a: StateStage, b: StateStage) {
                return a.order > b.order ? 1 : a.order ? -1 : 0
              });
              let stages = [];
              for (let masterStage of graphStages) {
                stages.push(masterStage.stage);
              }
              if (!this.machineIds[graph.machineType]) {
                this.machineIds[graph.machineType] = graph._id;
              }
              if (!this.allProcessStages[graph.machineType]) {
                this.allProcessStages[graph.machineType] = stages;
              }
            }
          }
          let entityId = null;
          this.route.params.subscribe(params => {
            entityId = params["entityId"]
            this.customerId = params["customerId"];

          })

          if (entityId != null) {
            let stateId = entityId;
            if (stateId) {
              this.viewSpecificState = true;
              this.getStateById(stateId);
            }
          }
          else {
            
          }
        }
        if (machineTypes && machineTypes.length > 0) {
          this.fetchSearchableDataPoints(machineTypes);
        }
      });
  }

  fetchSearchableDataPoints(machineTypes: string[]) {
    this.graphService.fetchSearchableDataPointsByMachineTypes(machineTypes)
      .subscribe(
        response => {
          this.searchableDataPoints = response;
        },
        error => {

        }
      )
  }


  expandForBulk() {
    this.bulkEditTemplate = !this.bulkEditTemplate;
  }


  onSelectAllProcess(event) {

  }
  hideAfterAt(str) {
    return str.split("@")[0]
  }


  noRecordsFound() {
    if (this.records == false) {
      this.recordsMessage = "No Records Found";
    }
    return this.recordsMessage;
  }

  openSnackBar(type, message, action) {
    let config: MatSnackBarConfig = new MatSnackBarConfig();
    config.verticalPosition = 'bottom';
    config.horizontalPosition = 'center';
    config.duration = 6500;
    if (type === 'error') {
      config.panelClass = ['errorMsg']
    }
    else {
      config.panelClass = ['success']
    }
    this.snackBar.open(message, action, config)
  }

  filter() {
    if (this.showFilter == false) {
      this.showFilter = true;
    }
    else if (this.showFilter == true) {
      this.showTagFilter = false;
      this.showFilter = false;
    }
  }

  onAssignedActiveTaskFilter() {
    this.currentSelectedTask = null;
    this.currentSelectedTaskDetails = null;
    this.assignedActiveTasks = [];
    this.assignedActiveTaskFilter.pageNo = 0;
    localStorage.setItem("filterDetails",JSON.stringify(this.assignedActiveTaskFilter));
    localStorage.setItem("selectedFolderName",this.selectedFolderName);
    localStorage.setItem("parentSelectedFolderName",this.parentSelectedFolderName);
    this.fetchRecordsForAssignedActiveTasks();

  }

  onAssignedClosedTaskFilter() {
    this.currentSelectedTask = null;
    this.currentSelectedTaskDetails = null;
    this.assignedClosedTasks = [];
    this.assignedClosedTaskFilter.pageNo = 0;
    localStorage.setItem("filterDetails",JSON.stringify(this.assignedClosedTaskFilter));
    localStorage.setItem("selectedFolderName",this.selectedFolderName);
    localStorage.setItem("parentSelectedFolderName",this.parentSelectedFolderName);
    this.fetchRecordsForAssignedClosedTasks();

  }

  onGroupActiveTaskFilter() {
    this.currentSelectedTask = null;
    this.currentSelectedTaskDetails = null;
    this.groupActiveTasks = [];
    this.groupActiveTaskFilter.pageNo = 0;
    localStorage.setItem("filterDetails",JSON.stringify(this.groupActiveTaskFilter));
    localStorage.setItem("selectedFolderName",this.selectedFolderName);
    localStorage.setItem("parentSelectedFolderName",this.parentSelectedFolderName);
    this.fetchRecordsForGroupActiveTasks();
  }

  onTeamActiveTaskFilter() {
    this.currentSelectedTask = null;
    this.currentSelectedTaskDetails = null;
    this.teamActiveTasks = [];
    this.teamActiveTaskFilter.pageNo = 0;
    localStorage.setItem("filterDetails",JSON.stringify(this.teamActiveTaskFilter));
    localStorage.setItem("selectedFolderName",this.selectedFolderName);
    localStorage.setItem("parentSelectedFolderName",this.parentSelectedFolderName);
    this.fetchRecordsForTeamActiveTasks();
  }

  onTeamClosedTaskFilter() {
    this.currentSelectedTask = null;
    this.currentSelectedTaskDetails = null;
    this.teamClosedTasks = [];
    this.teamClosedTaskFilter.pageNo = 0;
    localStorage.setItem("filterDetails",JSON.stringify(this.teamClosedTaskFilter));
    localStorage.setItem("selectedFolderName",this.selectedFolderName);
    localStorage.setItem("parentSelectedFolderName",this.parentSelectedFolderName);
    this.fetchRecordsForTeamClosedTasks();
  }

  onPeersActiveTaskFilter() {
    this.currentSelectedTask = null;
    this.currentSelectedTaskDetails = null;
    this.peersActiveTasks = [];
    this.peersActiveTaskFilter.pageNo = 0;
    localStorage.setItem("filterDetails",JSON.stringify(this.peersActiveTaskFilter));
    localStorage.setItem("selectedFolderName",this.selectedFolderName);
    localStorage.setItem("parentSelectedFolderName",this.parentSelectedFolderName);
    this.fetchRecordsForPeersActiveTasks();
  }

  onAllTaskFilter() {
    this.currentSelectedTask = null;
    this.currentSelectedTaskDetails = null;
    this.allTasks = [];
    this.allTaskFilter.pageNo = 0;
    localStorage.setItem("filterDetails",JSON.stringify(this.allTaskFilter));
    this.fetchRecordsForAllTasks();
  }

  closeAssignedActiveTaskFilter() {
    if (!this.assignedActiveTasks || this.assignedActiveTasks.length == 0) {
      this.currentSelectedTask = null;
      this.currentSelectedTaskDetails = null;
    }
  }

  closeAssignedClosedTaskFilter() {
    if (!this.assignedClosedTasks || this.assignedClosedTasks.length == 0) {
      this.currentSelectedTask = null;
      this.currentSelectedTaskDetails = null;
    }
  }

  closeGroupActiveTaskFilter() {
    if (!this.groupActiveTasks || this.groupActiveTasks.length == 0) {
      this.currentSelectedTask = null;
      this.currentSelectedTaskDetails = null;
    }
  }

  closeTeamActiveTaskFilter() {
    if (!this.teamActiveTasks || this.teamActiveTasks.length == 0) {
      this.currentSelectedTask = null;
      this.currentSelectedTaskDetails == null;
    }
  }

  closeTeamClosedTaskFilter() {
    if (!this.teamClosedTasks || this.teamClosedTasks.length == 0) {
      this.currentSelectedTask = null;
      this.currentSelectedTaskDetails = null;
    }
  }

  closePeersActiveTaskFilter() {
    if (!this.peersActiveTasks || this.peersActiveTasks.length == 0) {
      this.currentSelectedTask = null;
      this.currentSelectedTaskDetails = null;
    }
  }

  closeAllTaskFilter() {
    if (!this.allTasks || this.allTasks.length == 0) {
      this.currentSelectedTask = null;
      this.currentSelectedTaskDetails = null;
    }
  }

  isTaskStillInProgress(task: Task) {
    if (!this.progressTasks || this.progressTasks.length == 0) {
      return false;
    }
    for (let tempTask of this.progressTasks) {
      if (task._id == tempTask._id) {
        return true;
      }
    }
    return false;
  }

  addToProgressTasks() {
    if (this.currentSelectedTask != null) {
      for (let task of this.progressTasks) {
        if (task._id == this.currentSelectedTask._id) {
          return;
        }
      }
      this.progressTasks.push(JSON.parse(JSON.stringify(this.currentSelectedTask)));
    }
  }

  addToProgressTasksByState(state: State) {
    if (state != null) {
      for (let task of this.progressTasks) {
        if (task.refId == state._id) {
          return;
        }
      }
      for (let task of this.assignedActiveTasks) {
        if (task.refId == state._id) {
          this.progressTasks.push(task);
        }
      }
    }
  }

  removeFromAssignedActiveTasksByStateInstanceId(stateInstanceId: string) {
    if (this.assignedActiveTasks) {
      for (let task of this.assignedActiveTasks) {
        if (task.refId == stateInstanceId) {
          this.removeTask(task, "assignedActiveTasks");
        }
      }
    }
    if (this.selectedFolderName == this.FOLDER_OPTION_ASSIGNED_ACTIVE && (!this.assignedActiveTasks || this.assignedActiveTasks.length == 0)) {
      this.currentSelectedTask = null;
      this.currentSelectedTaskDetails = null;
    }
  }

  removeFromTeamActiveTasksByStateInstanceId(stateInstanceId: string) {
    if (this.teamActiveTasks) {
      for (let task of this.teamActiveTasks) {
        if (task.refId == stateInstanceId) {
          this.removeTask(task, "teamActiveTasks");
        }
      }
    }
    if (this.selectedFolderName == this.FOLDER_OPTION_TEAM_ACTIVE && (!this.teamActiveTasks || this.teamActiveTasks.length == 0)) {
      this.currentSelectedTask = null;
      this.currentSelectedTaskDetails = null;
    }
  }

  removeFromGroupActiveTasksByStateInstanceId(stateInstanceId: string) {
    if (this.groupActiveTasks) {
      for (let task of this.groupActiveTasks) {
        if (task.refId == stateInstanceId) {
          this.removeTask(task, "groupActiveTasks");
          if (this.currentSelectedTask && this.currentSelectedTask._id == task._id) {
            this.currentSelectedTask = null;
            this.currentSelectedTaskDetails = null;
          }
        }
      }
    }
  }

  removeFromProgressTasksByStateInstanceId(stateInstanceId: string, action?: string) {
    if (this.progressTasks) {
      for (let i = 0; i < this.progressTasks.length; i++) {
        if (this.progressTasks[i].refId == stateInstanceId) {
          const completedProgressTask = JSON.parse(JSON.stringify(this.progressTasks[i]))
          this.progressTasks.splice(i, 1);
          if (!action) {
            this.setCurrentTask(completedProgressTask);
          }
          else if (action == "UPDATE") {
            this.setCurrentAssignedActiveTask(completedProgressTask);
          }
          else if (action == "ARCHIVE" || action == "ALLOCATE") {

          }
          else if (action == "RESERVE") {

          }

        }
      }
    }
  }

  setCurrentAssignedActiveTask(updatedTask: Task) {
    if (this.selectedFolderName == this.FOLDER_OPTION_ASSIGNED_ACTIVE) {
      if (this.assignedActiveTasks) {
        for (let task of this.assignedActiveTasks) {
          if (task.cssClass == this.TABLINKS_ACTIVE && updatedTask._id == task._id) {
            this.currentSelectedTask = null;
            this.currentSelectedTaskDetails = null;
          }
        }
      }
    }
  }

  setCurrentTask(completedProgressTask: Task) {
    if (this.selectedFolderName == this.FOLDER_OPTION_ASSIGNED_ACTIVE) {
      for (let task of this.assignedActiveTasks) {
        if (completedProgressTask._id == task._id && task.cssClass == this.TABLINKS_ACTIVE) {
          this.setAssignedActiveTaskDetails(task);
        }
      }
    }
    else if (this.selectedFolderName == this.FOLDER_OPTION_ASSIGNED_CLOSED) {
      for (let task of this.assignedClosedTasks) {
        if (completedProgressTask._id == task._id && task.cssClass == this.TABLINKS_ACTIVE) {
          this.setAssignedClosedTaskDetails(task);
        }
      }
    }
    else if (this.selectedFolderName == this.FOLDER_OPTION_GROUP) {
      for (let task of this.groupActiveTasks) {
        if (completedProgressTask._id == task._id && task.cssClass == this.TABLINKS_ACTIVE) {
          this.setGroupActiveTaskDetails(task);
        }
      }
    }
    else if (this.selectedFolderName == this.FOLDER_OPTION_TEAM_ACTIVE) {
      for (let task of this.teamActiveTasks) {
        if (completedProgressTask._id == task._id && task.cssClass == this.TABLINKS_ACTIVE) {
          this.setTeamActiveTaskDetails(task);
        }
      }
    }
    else if (this.selectedFolderName == this.FOLDER_OPTION_TEAM_CLOSED) {
      for (let task of this.teamClosedTasks) {
        if (completedProgressTask._id == task._id && task.cssClass == this.TABLINKS_ACTIVE) {
          this.setTeamClosedTaskDetails(task);
        }
      }
    }
  }
  messageShown: boolean = false;
  getErrorMsgForTaskDetails(state: State) {
    this.responseError = "";
    this.responseErrorFields = [];
    if (this.messageShown == false) {

      if (state && state.errorMessageMap && Object.keys(state.errorMessageMap).length > 0) {
        for (const key in state.errorMessageMap) {

          if (key && state.errorMessageMap[key]) {
            const errorList: string[] = state.errorMessageMap[key];

            if (this.fieldKeyMap && this.fieldKeyMap[key]) {
              this.responseError += `${this.fieldKeyMap[key]}<br>`;
              this.responseErrorFields.push(this.fieldKeyMap[key])
            }
            for (const error of errorList) {
              if (error.indexOf("!!-#-!!") > -1) {
                this.responseError += error.split("!!-#-!!")[1] + '<br>';
                this.responseErrorFields.push(error.split("!!-#-!!")[0])
              } else {
                this.responseError += ` ${error}, <br>`;
              }
            }
            setTimeout(() => {
              this.responseError = "";
              this.responseErrorFields = [];
              this.messageShown = true;

            }, 3000);
          }
        }
      }
    }

    return this.responseError;
  }

  checkForBulkTags(emitedObject: any, type: string) {
    if (type === "Allocate_Bulk") {
      this.allocateBulkAssignedActiveTasks(type);
    }
  }

  fetchTasksForAutoRefresh(taskFilter: TaskFilter, tasks: Task[]): Observable<Task[]> {
    const subject = new Subject<Task[]>();
    if (!tasks)
      tasks = [];
    this.showLoaderOverlay.taskListing = true;
    this.taskService.getTaskByAutoRefresh(taskFilter)
      .subscribe(
        response => {
          subject.next(response)
          this.showLoaderOverlay.taskListing = false;
        },
        error => {
          this.showLoaderOverlay.taskListing = false;
          subject.error(error);
        }
      )
    return subject.asObservable();
  }

  isTaskOverdue(task: Task) {
    if (!task || task == null)
      return false;
    if (this.selectedFolderName == this.FOLDER_OPTION_ASSIGNED_ACTIVE || this.selectedFolderName == this.FOLDER_OPTION_GROUP || this.selectedFolderName == this.FOLDER_OPTION_TEAM_ACTIVE) {
      let nowTime = moment(new Date());
      let stateTime = moment(task.slaEndTimeLong);
      if (stateTime.diff(nowTime, "seconds") < 0) {
        return true;
      } 
      
    }
    return false;
  }

  appendAutoRefreshTasks(tasks: Task[], tasksType: string, appendAtBegining: boolean, folderName: string) {
    if (!tasks || tasks.length == 0) {
      return;
    }

    let newTaskCount = 0;
    
    for (let task of tasks.reverse()) {
      let tmpTasks = [];
      tmpTasks= JSON.parse(JSON.stringify(this[tasksType]));

      if (task.slaEndTime) {
        task.overdue = this.isTaskOverdue(task);
        
        if (task.allocatedById && task.allocatedById.trim().length > 0) {
          this.fetchUserService.getUser(task.allocatedById)
            .subscribe (
              response => {
                if (response) {
                  if (response.name) {
                    task.allocatedByDisplayUsername = response.name;
                  }
                  else {
                    task.allocatedByDisplayUsername = response.username;
                  }
                }
              }
            );
        }
        if (task.escalatedById && task.escalatedById.trim().length > 0) {
          if (task.escalatedById == "Process") {
            task.escalatedByDisplayUsername = "System";
          }
          else {
            this.fetchUserService.getUser(task.escalatedById)
            .subscribe (
              response => {
                if (response) {
                  if (response.name) {
                    task.escalatedByDisplayUsername = response.name;
                  }
                  else {
                    task.escalatedByDisplayUsername = response.username;
                  }
                }
              }
            );
          }
          
        }

      }

      task.cssClass = this.TABLINKS;

      let duplicateTask = false;
      let insertionIndex = -1;

      for (let taskIndex = 0; taskIndex < tmpTasks.length; taskIndex++) {
        const tempTask = tmpTasks[taskIndex];

        if (tempTask && tempTask._id) {
          if (tempTask._id == task._id) {
            duplicateTask = true;
            break;
          } else if (tempTask.taskPriority != null && tempTask.taskPriority != undefined && task.taskPriority != null && task.taskPriority != undefined) {
            if (task.taskPriority >= tempTask.taskPriority) {
              insertionIndex = taskIndex;
              break;
            }
          }
        }
      }

      if (!duplicateTask) {
        task.newTask = true;
        newTaskCount++;

        if (insertionIndex > -1) {
          tmpTasks.splice(insertionIndex, 0, task);
        } else {
          tmpTasks.push(task);
        }
      }

      this[tasksType] = tmpTasks;
    }

    if (newTaskCount > 0) {
      let message = newTaskCount + (newTaskCount == 1 ? ' new task' : ' new tasks');
      if (folderName == this.FOLDER_OPTION_ASSIGNED_ACTIVE) {
        message = message + " assigned to you."
      }
      else if (folderName == this.FOLDER_OPTION_GROUP) {
        message = message + " allocate to group";
      }
      else if (folderName == this.FOLDER_OPTION_TEAM_ACTIVE) {
        message = message + " allocate to your team";
      }
      this.openSnackBar("success", message, "Close");
    }
  }

  startAutoRefresh(folderName?: string) {
    if (!folderName) {
      return;
    }
    
    if (folderName && [this.FOLDER_OPTION_ASSIGNED_ACTIVE, this.FOLDER_OPTION_TEAM_ACTIVE,
      this.FOLDER_OPTION_GROUP].includes(folderName)) {

      if (folderName == this.FOLDER_OPTION_ASSIGNED_ACTIVE) {
        if (!this.assignedActiveTaskFilter || (this.assignedActiveTaskFilterQuery == "" && this.assignedActiveTaskFilter.isDefaultFilter())) {
          const autoRefreshFilter = this.initTaskFilter(new TaskFilter(), TASK_FOLDER.Personal, "ACTIVE", true);

          this.taskService.getCountByTaskFilter(autoRefreshFilter)
            .subscribe(
              newTaskCount => {
                if (this.assignedActiveTasksCount < newTaskCount) {
                  autoRefreshFilter.pageSize = newTaskCount - this.assignedActiveTasksCount;
                  this.assignedActiveTasksCount = newTaskCount;

                  this.fetchTasksForAutoRefresh(autoRefreshFilter, this.assignedActiveTasks)
                    .subscribe(
                      autoRefreshTask => {
                        this.appendAutoRefreshTasks(autoRefreshTask, "assignedActiveTasks", true, folderName);
                        if (!this.assignedActiveTasks || this.assignedActiveTasks.length == 0) {
                          this.assignedActiveTasks = [];
                        }
                      },
                      error => {

                      }
                    );
                }
              },
              error => {

              }
            );
        }
      }
  
      else if (folderName == this.FOLDER_OPTION_TEAM_ACTIVE) {
        if (!this.teamActiveTaskFilter || (this.teamActiveTaskFilterQuery == "" && this.teamActiveTaskFilter.isDefaultFilter())) {
          const autoRefreshFilter = this.initTaskFilter(new TaskFilter(), TASK_FOLDER.Team, "ACTIVE", true);

          this.taskService.getCountByTaskFilter(autoRefreshFilter)
            .subscribe(
              newTaskCount => {
                if (this.teamActiveTasksCount < newTaskCount) {
                  autoRefreshFilter.pageSize = newTaskCount - this.teamActiveTasksCount;
                  this.teamActiveTasksCount = newTaskCount;

                  this.fetchTasksForAutoRefresh(autoRefreshFilter, this.teamActiveTasks)
                    .subscribe(
                      autoRefreshTask => {
                        this.appendAutoRefreshTasks(autoRefreshTask, "teamActiveTasks", true, folderName);
                        if (!this.teamActiveTasks || this.teamActiveTasks.length == 0) {
                          this.teamActiveTasks = [];
                        }
                      },
                      error => {

                      }
                    );
                }
              },
              error => {

              }
            );
        }
      }
  
      else if (folderName == this.FOLDER_OPTION_GROUP) {
        if (!this.groupActiveTaskFilter || (this.teamActiveTaskFilterQuery == "" && this.groupActiveTaskFilter.isDefaultFilter())) {
          const autoRefreshFilter = this.initTaskFilter(new TaskFilter(), TASK_FOLDER.Group, "ACTIVE", true);

          this.taskService.getCountByTaskFilter(autoRefreshFilter)
            .subscribe(
              newTaskCount => {
                if (this.groupActiveTasksCount < newTaskCount) {
                  autoRefreshFilter.pageSize = newTaskCount - this.groupActiveTasksCount;
                  this.groupActiveTasksCount = newTaskCount;

                  this.fetchTasksForAutoRefresh(autoRefreshFilter, this.groupActiveTasks)
                    .subscribe(
                      autoRefreshTask => {
                        this.appendAutoRefreshTasks(autoRefreshTask, "groupActiveTasks", true, folderName);
                        if (!this.groupActiveTasks || this.groupActiveTasks.length == 0) {
                          this.groupActiveTasks = [];
                        }
                      },
                      error => {

                      }
                    );
                }
              },
              error => {

              }
            );
        }
      }
    }

    // setTimeout(() => {
    //   this.startAutoRefresh();
    // }, 15000);
  }
  prevScreenEntity(folderName,pagenumber){
    if (this.pageNumber > 0) {
      this.pageNumber = this.pageNumber -2;
    }
    switch (folderName) {
      case this.FOLDER_OPTION_ASSIGNED_ACTIVE:
        this.fetchRecordsForAssignedActiveTasks(false);
      break;
      case this.FOLDER_OPTION_ASSIGNED_CLOSED:
        this.fetchRecordsForAssignedClosedTasks(false);
      break;
      case this.FOLDER_OPTION_GROUP:
        this.fetchRecordsForGroupActiveTasks(false);
      break;
      case this.FOLDER_OPTION_TEAM_ACTIVE:
        this.fetchRecordsForTeamActiveTasks(false);
      break;
      case this.FOLDER_OPTION_TEAM_CLOSED:
        this.fetchRecordsForTeamClosedTasks(false);
      break;
      case this.FOLDER_OPTION_PEERS:
        this.fetchRecordsForPeersActiveTasks(false);
      break;
      case this.FOLDER_OPTION_ALL:
        this.fetchRecordsForAllTasks(false);
      break;
    
      default:
      break;
    }
  }
  fetchDataForFetch(folderName){
    switch (folderName) {
      case this.FOLDER_OPTION_ASSIGNED_ACTIVE:
        this.fetchRecordsForAssignedActiveTasks(true);
      break;
      case this.FOLDER_OPTION_ASSIGNED_CLOSED:
        this.fetchRecordsForAssignedClosedTasks(true);
      break;
      case this.FOLDER_OPTION_GROUP:
        this.fetchRecordsForGroupActiveTasks(true);
      break;
      case this.FOLDER_OPTION_TEAM_ACTIVE:
        this.fetchRecordsForTeamActiveTasks(true);
      break;
      case this.FOLDER_OPTION_TEAM_CLOSED:
        this.fetchRecordsForTeamClosedTasks(true);
      break;
      case this.FOLDER_OPTION_PEERS:
        this.fetchRecordsForPeersActiveTasks(true);
      break;
      case this.FOLDER_OPTION_ALL:
        this.fetchRecordsForAllTasks(true);
      break;
    
      default:
      break;
    }
  }


  setFilterOnReturn(filterDetails:any,folderName){
    switch (folderName) {
      case this.FOLDER_OPTION_ASSIGNED_ACTIVE:
        this.assignedActiveTaskFilter = this.getTaskFilterOnreturn(filterDetails);
        this.initTaskFilter(this.allTaskFilter, TASK_FOLDER.ALL, "ACTIVE");
        this.initTaskFilter(this.assignedClosedTaskFilter, TASK_FOLDER.Personal, "CLOSED");
        this.initTaskFilter(this.groupActiveTaskFilter, TASK_FOLDER.Group, "ACTIVE");
        this.initTaskFilter(this.teamActiveTaskFilter, TASK_FOLDER.Team, "ACTIVE");
        this.initTaskFilter(this.teamClosedTaskFilter, TASK_FOLDER.Team, "CLOSED");
        this.initTaskFilter(this.peersActiveTaskFilter, TASK_FOLDER.Peers, "ACTIVE");
      break;
      case this.FOLDER_OPTION_ASSIGNED_CLOSED:
        this.assignedClosedTaskFilter = this.getTaskFilterOnreturn(filterDetails);
        this.initTaskFilter(this.allTaskFilter, TASK_FOLDER.ALL, "ACTIVE");
        this.initTaskFilter(this.assignedActiveTaskFilter, TASK_FOLDER.Personal, "ACTIVE");
        this.initTaskFilter(this.groupActiveTaskFilter, TASK_FOLDER.Group, "ACTIVE");
        this.initTaskFilter(this.teamActiveTaskFilter, TASK_FOLDER.Team, "ACTIVE");
        this.initTaskFilter(this.teamClosedTaskFilter, TASK_FOLDER.Team, "CLOSED");
        this.initTaskFilter(this.peersActiveTaskFilter, TASK_FOLDER.Peers, "ACTIVE");
      break;
      case this.FOLDER_OPTION_GROUP:
        this.groupActiveTaskFilter = this.getTaskFilterOnreturn(filterDetails);
        this.initTaskFilter(this.allTaskFilter, TASK_FOLDER.ALL, "ACTIVE");
        this.initTaskFilter(this.assignedActiveTaskFilter, TASK_FOLDER.Personal, "ACTIVE");
        this.initTaskFilter(this.assignedClosedTaskFilter, TASK_FOLDER.Personal, "CLOSED");
        this.initTaskFilter(this.teamActiveTaskFilter, TASK_FOLDER.Team, "ACTIVE");
        this.initTaskFilter(this.teamClosedTaskFilter, TASK_FOLDER.Team, "CLOSED");
        this.initTaskFilter(this.peersActiveTaskFilter, TASK_FOLDER.Peers, "ACTIVE");
      break;
      case this.FOLDER_OPTION_TEAM_ACTIVE:
        this.teamActiveTaskFilter = this.getTaskFilterOnreturn(filterDetails);
        this.initTaskFilter(this.allTaskFilter, TASK_FOLDER.ALL, "ACTIVE");
        this.initTaskFilter(this.assignedActiveTaskFilter, TASK_FOLDER.Personal, "ACTIVE");
        this.initTaskFilter(this.assignedClosedTaskFilter, TASK_FOLDER.Personal, "CLOSED");
        this.initTaskFilter(this.groupActiveTaskFilter, TASK_FOLDER.Group, "ACTIVE");
        this.initTaskFilter(this.teamClosedTaskFilter, TASK_FOLDER.Team, "CLOSED");
        this.initTaskFilter(this.peersActiveTaskFilter, TASK_FOLDER.Peers, "ACTIVE");
      break;
      case this.FOLDER_OPTION_TEAM_CLOSED:
        this.teamClosedTaskFilter = this.getTaskFilterOnreturn(filterDetails);
        this.initTaskFilter(this.allTaskFilter, TASK_FOLDER.ALL, "ACTIVE");
        this.initTaskFilter(this.assignedActiveTaskFilter, TASK_FOLDER.Personal, "ACTIVE");
        this.initTaskFilter(this.assignedClosedTaskFilter, TASK_FOLDER.Personal, "CLOSED");
        this.initTaskFilter(this.groupActiveTaskFilter, TASK_FOLDER.Group, "ACTIVE");
        this.initTaskFilter(this.teamActiveTaskFilter, TASK_FOLDER.Team, "ACTIVE");
        this.initTaskFilter(this.peersActiveTaskFilter, TASK_FOLDER.Peers, "ACTIVE");
      break;
      case this.FOLDER_OPTION_PEERS:
        this.peersActiveTaskFilter = this.getTaskFilterOnreturn(filterDetails);
        this.initTaskFilter(this.allTaskFilter, TASK_FOLDER.ALL, "ACTIVE");
        this.initTaskFilter(this.assignedActiveTaskFilter, TASK_FOLDER.Personal, "ACTIVE");
        this.initTaskFilter(this.assignedClosedTaskFilter, TASK_FOLDER.Personal, "CLOSED");
        this.initTaskFilter(this.groupActiveTaskFilter, TASK_FOLDER.Group, "ACTIVE");
        this.initTaskFilter(this.teamActiveTaskFilter, TASK_FOLDER.Team, "ACTIVE");
        this.initTaskFilter(this.teamClosedTaskFilter, TASK_FOLDER.Team, "CLOSED");
      break;
      case this.FOLDER_OPTION_ALL:
        this.allTaskFilter = this.getTaskFilterOnreturn(filterDetails);
        this.initTaskFilter(this.assignedActiveTaskFilter, TASK_FOLDER.Personal, "ACTIVE");
        this.initTaskFilter(this.assignedClosedTaskFilter, TASK_FOLDER.Personal, "CLOSED");
        this.initTaskFilter(this.groupActiveTaskFilter, TASK_FOLDER.Group, "ACTIVE");
        this.initTaskFilter(this.teamActiveTaskFilter, TASK_FOLDER.Team, "ACTIVE");
        this.initTaskFilter(this.teamClosedTaskFilter, TASK_FOLDER.Team, "CLOSED");
        this.initTaskFilter(this.peersActiveTaskFilter, TASK_FOLDER.Peers, "ACTIVE");
      break;
    
      default:
      break;
    }
  }


  setFilterOnDestroy(){
    if(this.selectedFolderName && this.parentSelectedFolderName){
      localStorage.setItem("selectedFolderName",this.selectedFolderName);
      localStorage.setItem("parentSelectedFolderName",this.parentSelectedFolderName);
      switch (this.selectedFolderName) {
        case this.FOLDER_OPTION_ASSIGNED_ACTIVE:
          localStorage.setItem("filterDetails",JSON.stringify(this.assignedActiveTaskFilter));
        break;
        case this.FOLDER_OPTION_ASSIGNED_CLOSED:
          localStorage.setItem("filterDetails",JSON.stringify(this.assignedClosedTaskFilter));
        break;
        case this.FOLDER_OPTION_GROUP:
          localStorage.setItem("filterDetails",JSON.stringify(this.groupActiveTaskFilter));
        break;
        case this.FOLDER_OPTION_TEAM_ACTIVE:
          localStorage.setItem("filterDetails",JSON.stringify(this.teamActiveTaskFilter));
        break;
        case this.FOLDER_OPTION_TEAM_CLOSED:
          localStorage.setItem("filterDetails",JSON.stringify(this.teamClosedTaskFilter));
        break;
        case this.FOLDER_OPTION_PEERS:
          localStorage.setItem("filterDetails",JSON.stringify(this.peersActiveTaskFilter));
        break;
        case this.FOLDER_OPTION_ALL:
          localStorage.setItem("filterDetails",JSON.stringify(this.allTaskFilter));
        break;
      
        default:
        break;
      }
    }
    
  }

  getTaskFilterOnreturn(taskFilter: TaskFilter){
    let fromTime:any = null;
    let toTime:any = null;

     let tf = new TaskFilter();
      for (var key in taskFilter) {
          if(key == "toTime"){
            if(taskFilter[key]){
              // toTime = taskFilter[key];
              toTime = new Date(taskFilter[key]);
            }
          }
          else if(key == "fromTime"){
            if(taskFilter[key]){
              // fromTime = taskFilter[key];
              fromTime = new Date(taskFilter[key]);
            }
          }
          else{
            tf[key] = taskFilter[key];
          }
      }
      if(toTime!=null && fromTime!=null){
        tf.toTime = toTime;
        tf.fromTime = fromTime;
      }
      taskFilter = tf;
      return taskFilter;
  }

  
}
