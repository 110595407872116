import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FileService } from 'src/app/service/shared.service';
import { StateService } from 'src/app/service/state.service';
import { v4 as uuid } from 'uuid';
import { Document, State, TimelineStateAuditData } from '../../models/tasks.model';

import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent implements OnInit, OnChanges {

  @Input() timeLineStateAuditData: TimelineStateAuditData[] =[];
  @Input() selectedFolder:string;
  @Input() assignedTaskDdetails: State;
  @Input() documentsForState: any;
  @Input() statusList: any;

  @Output() docUploadEvent = new EventEmitter();
  @Output() docRemoveEvent = new EventEmitter();
  @Output() closeEvent = new EventEmitter();

  FOLDER_OPTION_ASSIGNED_CLOSED = "ASSIGNED_CLOSED";
  FOLDER_OPTION_TEAM_CLOSED = "TEAM_CLOSED";

  loader: boolean = false;
  showAutoStages: boolean = false;
  fileObjectUrl = {};
  tooltipContent = "";
  timelineStates: TimelineStateAuditData[] = [];
  constructor(
    private fileService: FileService,
    private modalService: BsModalService,
    private stateService: StateService,
    private bsModalRef: BsModalRef
  ) { }

  ngOnInit(): void {
    this.getTimeline(this.assignedTaskDdetails)
      
  }
  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes)
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.

    // this.renderDocuments();
  }

  getDownloadUrl(auditIndex: number, documentIndex: number) {
    return this.fileObjectUrl[auditIndex + '' + documentIndex];
  }
  getTimeline(state: State) {
    this.timelineStates = [];
    this.stateService.getTimelineForFlow(state.stateMachineInstanceModelId)
      .subscribe(timelineStates => {
        // console.log(timelineStates)
        if (timelineStates) {
          this.timelineStates = timelineStates;
          this.timeLineStateAuditData = timelineStates
        }
      });
  }
  renderDocuments() {
    if (this.timeLineStateAuditData) {
      for (let auditIndex = 0; auditIndex < this.timeLineStateAuditData.length; auditIndex++) {
        const auditData = this.timeLineStateAuditData[auditIndex];

        if (auditData && auditData.documents) {
          for (let documentIndex = 0; documentIndex < auditData.documents.length; documentIndex++) {
            const document = auditData.documents[documentIndex];

            if (document && document.downloadFileUrl) {
              if (document.downloadFileUrl.startsWith('http')) {
                this.fileObjectUrl[auditIndex + '' + documentIndex] = document.downloadFileUrl;
              } else {
                this.fileService.download(document.downloadFileUrl, true).subscribe(
                  objectUrl => {
                    if (objectUrl) {
                      this.fileObjectUrl[auditIndex + '' + documentIndex] = objectUrl;
                    }
                  }
                );
              }
            }
          }
        }
      }
    }
  }

  onNewRemarksDocuments(stateInstanace: State) {
    const newDoc = new Document();
    newDoc.stateInstanceId = stateInstanace._id;
    newDoc.flowInstanceId = stateInstanace.stateMachineInstanceModelId;
    newDoc.documentType = "USER_REMARKS";
    newDoc.documentName = uuid();
    if (!this.documentsForState[stateInstanace._id]) {
      this.documentsForState[stateInstanace._id] = [];
    }
    this.documentsForState[stateInstanace._id].push(newDoc);
  }

  getRemarksDocuments(stateInstanace: State) {
    const remarksDocs: Document[] = [];
    if (this.documentsForState && this.documentsForState[stateInstanace._id]) {
      for (let doc of this.documentsForState[stateInstanace._id]) {
        if (doc.documentType && doc.documentType == "USER_REMARKS") {
          remarksDocs.push(doc);
        }
      }
    }
    return remarksDocs;
  }

  getOtherThanRemarksDocuments(stateInstanace: State) {
    const docs: Document[] = [];
    if (this.documentsForState && this.documentsForState[stateInstanace._id]) {
      for (let doc of this.documentsForState[stateInstanace._id]) {
        if (doc.documentType && doc.documentType != "USER_REMARKS") {
          docs.push(doc);
        }
      }
    }
    return docs;
  }

  onRemoveDocument(document: Document) {
    this.docRemoveEvent.emit(document);
  }

  onDocumentUploadForTask(event: Event, document: Document) {
    let map = {};
    map["event"] = event;
    map["document"] = document;
    this.docUploadEvent.emit(map);
  }

  closeSection() {
    this.bsModalRef.hide()
    this.closeEvent.emit({ data: "" , res:2133 });
  }

  capAndSpace(string) {
    if (string.toLowerCase() == 'initiate' || string.toLowerCase() == 'init') {
      string = 'Ticket Created'
    }
    return capitalize(string.split(/(?=[A-Z])/).join(" "))
  }

  toggleAutoStages() {
    this.showAutoStages = !this.showAutoStages;
  }

  getFilteredTimeline(timelineList: TimelineStateAuditData[]) {
    let startStage = null;
    if(timelineList.length > 0){
      startStage = this.addFirstStageForTimeLine(timelineList[0]);
    
      if (this.showAutoStages) {
        let list = [];
        list = timelineList.slice().reverse();
        list.push(startStage);
        return list;
      } else {
        let tempList = [];

        for (let timelineData of timelineList) {
          if(timelineData!=null){
            if (timelineData && (timelineData.assignedUserGroupCd || timelineData.assignedUserId)) {
              tempList.push(timelineData);
            }
          }
        }
        if(this.selectedFolder == this.FOLDER_OPTION_ASSIGNED_CLOSED || this.selectedFolder == this.FOLDER_OPTION_TEAM_CLOSED ){
          if(timelineList[timelineList.length - 1].stateCd != tempList[tempList.length - 1].stateCd && (timelineList[timelineList.length - 1].assignedUserGroupCd || timelineList[timelineList.length - 1].assignedUserId)){
            tempList.push(timelineList[timelineList.length - 1]);
          }
        }
        tempList = tempList.slice().reverse();
        tempList.push(startStage);
        return tempList;
      }
    }
  }

  addFirstStageForTimeLine(firstStage:TimelineStateAuditData){
    let firstTimeLineData = new TimelineStateAuditData();
    firstTimeLineData.stateCd = "Start";
    firstTimeLineData.startTimeLong = firstStage.startTimeLong;
    return firstTimeLineData;

  }
  tooltipDetails:any;
  stageDetails(stateInstanceId, timelineData) {
    if (timelineData && (timelineData.assignedUserGroupCd || timelineData.assignedUserId)) {
      this.tooltipContent = "";
      this.stateService.getTimelineStateInstanceId(stateInstanceId).subscribe(
        (response) => {
          if (response.length > 0) {
            this.tooltipDetails = response
            for (let index = 0; index < response.length; index++) {
              const element = response[index];
              this.tooltipContent += "<p>";
              this.tooltipContent += "<strong>" + element.activityLabel + "</strong>";
              if (element.assignedUserDisplayName != null) {
                this.tooltipContent += "<br>Assigned User: " + element.assignedUserDisplayName;
              }
              if (element.assignedUserGroupCd != null) {
                this.tooltipContent += "<br>Assigned Group: " + element.assignedUserGroupCd;
              }

              this.tooltipContent += "</p>";
            }
          }
        }
      );
    }else{
      this.tooltipContent = "This is a system generated Task";
    }
  }
  modalWindowData:any;
  openDetailsTimeline(template, timelineData){
    this.modalWindowData = timelineData;
    if(this.tooltipDetails.length>0){
      this.bsModalRef = this.modalService.show(template)
    }
  }


  closeModal() {
    this.bsModalRef.hide()
  }
  dhm(t){
    var cd = 24 * 60 * 60 * 1000,
        ch = 60 * 60 * 1000,
        d = Math.floor(t / cd),
        h = Math.floor( (t - d * cd) / ch),
        m = Math.round( (t - d * cd - h * ch) / 60000),
        pad = function(n){ return n < 10 ? '0' + n : n; };
  if( m === 60 ){
    h++;
    m = 0;
  }
  if( h === 24 ){
    d++;
    h = 0;
  }
  return [d, pad(h), pad(m)].join(':');
}
}
const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}