import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, Inject, OnChanges, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NavigationEnd, Router } from '@angular/router';
import * as Feather from 'feather-icons';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import * as SockJS from 'sockjs-client';
import { Application } from 'src/app/models/application.model';
import { User } from 'src/app/models/user.model';
import { ApplicationService } from 'src/app/service/application.service';
import { DataModelService } from 'src/app/service/datamodel.service';
import { EnvironmentData } from 'src/app/service/environments.service';
import { FetchUserService } from 'src/app/service/userhierarchy.service';
import * as Stomp from 'stompjs';
import { CommonSearchModel, NotificationPi } from '../../models/shared.model';
import { GraphObject } from '../../models/tasks.model';
import { AuthService } from '../../service/auth.service';
import { CommonService } from '../../service/common.service';
import { MultiTenantService } from '../../service/multi-tenant.service';
import { CommunicationService, DataSharingService, FileService, GraphService, NotificationService, UniversalUser, WhiteLabel } from '../../service/shared.service';
import { StateService } from '../../service/state.service';
import { LoaderService } from '../../service/loader.service';
import { ConversationService } from 'src/app/service/helper.service';
import { Subscription } from 'rxjs';
import { Agent } from 'src/app/models/conversation.model';
import * as JSZip from 'jszip';
import { DataModel } from 'src/app/models/datamodel.model';
import { MessagingService } from 'src/app/service/messaging.service';
import { DialerService } from '../../service/dialer.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit, OnChanges, OnDestroy {

  allProcessStages: any = {};
  selectedProcessStages: any[] = [];
  activeGraphObjectList: GraphObject[];
  userId: string;
  currentUser: User;
  notifications: NotificationPi[] = [];
  notificationCount: number = 0;
  displayBusinessDashboard: boolean = false;
  dataModelList: any[] = [];
  isWhiteLabel: boolean = false;
  profileImageObject: any = "assets/images/avatar.png";
  defaultAutoSuggestEmails: any = [];
  tenantList: any[];
  currentAccount = "";
  companyDetails = {};
  companyLogo: string;
  bsModal: BsModalRef;
  @ViewChild('dueBox', { static: true }) dueBox: ElementRef;
  @ViewChild('callBox', { static: true }) callBox: ElementRef;
  // websocket
  private stompClient: any = null;
  selectedApplication: Application;
  supportmessage: any;
  credits: any;
  agentList: Agent[];
  messageCategory: any[];
  messagePriority: any[];
  messageError: string = '';
  isBargeable: boolean = false;
  menuList: any[];
  quickAccess: any[];
  warning = {
    "header": "",
    "body": ""
  }
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '600',
    minHeight: '400',
    maxHeight: '600',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    uploadWithCredentials: false,                    //Image Upload Can we make this work@Parth
    uploadUrl: '',                                   //Your backend must return imageUrl.   | https://github.com/kolkov/angular-editor/issues/5  
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      [
        'undo',
        'redo',
        'strikeThrough',
        'subscript',
        'superscript',
        'indent',
        'outdent',
        // 'insertUnorderedList',
        // 'insertOrderedList',
        'heading',
        'fontName',
        'customClasses',
        'insertImage',
        'insertVideo',
        'toggleEditorMode',
        'link',
        'unlink',
        // 'insertHorizontalRule',
        // 'removeFormat',
        'fontSize',
        'textColor',
        'backgroundColor',
      ],
      []
    ]
  };
  private subscriptionFetchAgents: Subscription;
  dataFillModeFlag: boolean = false;
  searchTerm: string;
  menuSeenFirstTime: boolean = false;
  showPauseReason: boolean = false;
  pauseReason: string = null;
  dialerNotification;

  constructor(
    private router: Router,
    private commonService: CommonService,
    private authService: AuthService,
    private graphService: GraphService,
    private stateService: StateService,
    private communicationService: CommunicationService,
    private notificationService: NotificationService,
    private universalUser: UniversalUser,
    private dataModelService: DataModelService,
    private whiteLabel: WhiteLabel,
    private fileService: FileService,
    private environmentData: EnvironmentData,
    private snackBar: MatSnackBar,
    private fetchUserService: FetchUserService,
    private multiTenant: MultiTenantService,
    private applicationService: ApplicationService,
    private loaderService: LoaderService,
    @Inject(DOCUMENT) private document: Document,
    private modalService: BsModalService,
    private datasharingservice: DataSharingService,
    private conversationService: ConversationService,
    private messagingService: MessagingService,
    public dialerService: DialerService
  ) {
    this.supportmessage = {
      messages: [],
      title: "",
      description: ""
    };

    this.messageCategory = [
      { value: "PLATFORM", label: "Platform Queries" },
      { value: "PRODUCT", label: "Application Queries" },
      { value: "SALES", label: "Purchase Queries" },
      { value: "FEATURE_REQUEST", label: "New Feature Request" },
      { value: "BUG_REPORT", label: "Report a Bug" },
      { value: "OTHER", label: "Other Queries" }
    ];

    this.messagePriority = [
      { value: "LOW", label: "Low" },
      { value: "MEDIUM", label: "Medium" },
      { value: "HIGH", label: "High" },
      { value: "CRITICAL", label: "Critical" }
    ];
    this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        if (e.id > 3) {
          this.menuSeenFirstTime = true;
        } else {
          this.menuSeenFirstTime = false;
        }
      }
    });
  }
  pauseDialerPayload: any = {}
  ngOnInit() {
    //this.fetchGraphs()
    this.displayBusinessDashboard = this.universalUser.displayBusinessDashboard();
    this.userId = this.universalUser.getUser()._id;
    this.currentUser = this.universalUser.getUser();
    this.getSubscribedApplications();
    this.getDownloadFileObjectUrl();
    this.getDataModelList();
    this.getQuickAccessDataModels();
    this.getMenubarDataModels();
    this.getUserList();
    this.fetchAllAgents();
    this.initiateNotificationMechanism();
    this.fetNotificationCount();
    if (this.currentUser.companyAccountStage != null && this.datasharingservice.roadBlockSHown == false) {
      if (this.currentUser.companyAccountStage == "DUES_PENDING") {
        this.warning.header = "Pending Dues";
        this.warning.body = "Account has pending dues and services may be impacted for non resolution.<br>Please contact your AutomataPi account manager immediately for resolution";
        this.datasharingservice.roadBlockSHown = true;
        this.bsModal = this.modalService.show(this.dueBox, { class: 'modal-dialog modal-md modal-dialog-centered' });
      }
    }

    this.companyLogo = this.whiteLabel.getTheme();
    this.selectTenant();
    // console.log(this.currentUser)
    // this.commonService.fetchNotifications(this.userId);
    // this.commonService.notificationObservable.subscribe(
    //   response => { this.notifications = response; this.notificationCount = response.length },
    //   error => { }
    // )
    if (localStorage.getItem("isWhiteLabel") === "true") {
      this.isWhiteLabel = true;
    }
    this.multiTenant.getTenants().subscribe(
      (response) => {
        this.tenantList = response;
      },
      (error) => {
        console.log(error);
      },
    )
    if (this.currentUser.authorities.includes("ROLE_ADMIN") || this.currentUser.authorities.includes("ROLE_COMPANY_ADMIN")) {
      this.commonService.getCreditsRemaining().subscribe(
        (response) => {
          this.credits = response;
        },
        (error) => { }
      );
    }
    this.commonService.dataFillModeObservable.subscribe(
      (response) => {
        this.dataFillModeFlag = response;
        if (this.dataFillModeFlag == true) {
          this.toggleFullScreen();
        }
      }
    )
  }

  ngAfterViewInit() {
    Feather.replace();
  }

  //Applications
  getSubscribedApplications() {
    this.applicationService.getSelectedApplication().subscribe(
      (selectedApplication: Application) => {
        this.selectedApplication = selectedApplication;
      },
      error => {
        this.selectedApplication = null;
      }
    );
  }

  ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
    //  this.fetchNotifications();
  }

  ngOnDestroy(): void {
    this.disconnectFromWebSocket();
    if (this.subscriptionFetchAgents && !this.subscriptionFetchAgents.closed) {
      this.subscriptionFetchAgents.unsubscribe();
    }
  }

  connectToWebSocket() {
    // console.log("Initialize WebSocket Notification Connection");
    const envData = this.environmentData.getEnvData();
    const webSocketEndPoint: string = envData.rootUrl + "/flow/websocket/notification";
    let ws = new SockJS(webSocketEndPoint);
    this.stompClient = Stomp.over(ws);
    const _this = this;
    this.stompClient.connect({ username: this.universalUser.getUser().username }, function (frame) {
      _this.stompClient.subscribe("/user/queue/notification", (receivedEvent) => {
        // console.log("on message received ", receivedEvent);
        if (receivedEvent && receivedEvent.body) {
          const notification: NotificationPi = JSON.parse(receivedEvent.body);
          _this.notifications.unshift(notification);
          _this.notificationCount++;

        }

      });
      _this.stompClient.reconnect_delay = 2000;
    }, this.errorCallBack);
  }

  errorCallBack(error) {
    // console.log("errorCallBack -> " + error)
    setTimeout(() => {
      // console.log("try...");
      this.connectToWebSocket();
    }, 5000);
  }

  disconnectFromWebSocket() {
    if (this.stompClient !== null) {
      this.stompClient.disconnect();
    }
  }

  getDataModelList() {
    const commonsearchModel = new CommonSearchModel();
    commonsearchModel.searchParams = [{ 'statusCd': 'ACTIVE' }];
    commonsearchModel.returnFields = ['label', 'version', 'statusCd', 'name', 'subEntity', 'type'];
    this.dataModelService.getDataModelList(commonsearchModel)
      .subscribe(list => {
        if (list != null && list.length > 0) {
          for (let item of list) {
            if (!item.subEntity && item.type != "EmailIn") {

              if (item.type != 'Entity_Lookup_Reference') {
                this.dataModelList.push({ display: item.label, value: item.name, id: item._id, name: item.name, statusCd: item.statusCd })
              }
            }
          }
        }

      });
  }
  getQuickAccessDataModels() {
    this.dataModelService.dataModelQuickAccess().subscribe(
      (list) => {
        this.quickAccess = list;
      },
      (error) => {

      }
    );
  }
  getMenubarDataModels() {
    this.dataModelService.dataModelOnMenubar().subscribe(
      (list) => {
        this.menuList = list;
      },
      (error) => {

      }
    );
  }
  performAction(notification: NotificationPi) {
    this.markNotificationAsRead(notification);
  }

  markNotificationAsRead(notification: NotificationPi) {
    if (notification.associatedEntityId != null) {
      this.snackBar.open('Notification removed and marked as seen', "Close", {
        duration: 5000
      })
    }
    else {
      this.snackBar.open('Could not find any associated Entity with the Notification', "Close", {
        duration: 5000
      })
    }

    this.notificationService.markNotificationAsRead(notification._id).subscribe(data => {
      if (data["success"]) {
        notification.notificationSeen = true;
        this.notificationCount = this.notificationCount - 1;
        if (notification.entityType == "Task") {
          this.router.navigate(['/task'], { queryParams: { entityId: notification.associatedEntityId } });
          //this.router.navigate(['task',notification.associatedEntityId]);
        }
      }
    })
  }

  getUserList() {
    this.fetchUserService.fetchUsers()
      .subscribe(userList => {
        if (userList && userList.length > 0) {
          for (let user of userList) {
            this.defaultAutoSuggestEmails.push(user.email);
          }
          this.universalUser.setDefaultAutoSuggestEmails(this.defaultAutoSuggestEmails);
        }
      });
  }


  fetchGraphs(): void {
    let commonsearchModel = new CommonSearchModel();
    commonsearchModel.searchParams = [{ "statusCd": "ACTIVE" }, { "statusCd": "DRAFT" }];
    commonsearchModel.returnFields = ["machineType"];
    this.graphService.fetch(commonsearchModel)
      .subscribe(graphObjects => {
        this.activeGraphObjectList = graphObjects;
        if (this.activeGraphObjectList) {
          let graphs: string[] = []
          for (let graph of this.activeGraphObjectList) {
            graphs.push(graph.machineType);
          }
          this.getAllStages(graphs);
        }
      });
  }


  getAllStages(machineTypes: string[]) {
    if (machineTypes != null && machineTypes.length > 0) {
      this.stateService.getStages(machineTypes).subscribe(stages => {
        if (stages != null) {
          this.communicationService.sendStages(stages);
          this.allProcessStages = stages;
        }

      }, error => {

      });
    }
  }

  logout() {
    localStorage.removeItem("filterDetails");
    localStorage.removeItem("selectedFolderName");
    localStorage.removeItem("parentSelectedFolderName");
    this.authService.initiateLogoutSequence(this.router.url);
  }

  toggleTask() {
    this.commonService.toggleBlock = !this.commonService.toggleBlock;
  }


  menuFlag: boolean = false;//only in small media
  togglemenu() {
    this.menuFlag = !this.menuFlag;
  }

  toggleFullScreen() {
    let elem = document.documentElement;
    let methodToBeInvoked = elem.requestFullscreen;
    if (methodToBeInvoked) methodToBeInvoked.call(elem);

  }

  isEmptyObj(obj: any) {
    return Object.keys(obj).length === 0;
  }
  newActivity(model) {
    this.communicationService.setSelectedDataModelId(model.id);
    this.communicationService.setEntity(null);
    this.communicationService.setDataModelName(model.name);
    this.router.navigateByUrl('/add-activities');

  }

  profile() {
    this.router.navigateByUrl('/profile');
  }

  getLogoUrl() {
    if (this.whiteLabel.getWhiteLabel() && this.whiteLabel.getLogoUrl()) {
      return this.whiteLabel.getLogoUrl();
    } else if (this.companyLogo) {
      return this.companyLogo
    } else {
      return "https://www.automatapi.com/assets/images/logo.svg";
      // return "https://automatapi.com/assets/images/logo-small-black.png";
    }
  }

  getDownloadFileObjectUrl() {
    if (this.currentUser && this.currentUser.imageUrl) {
      if (this.currentUser.imageUrl.indexOf("/downloadData") == -1) {
        this.profileImageObject = this.currentUser.imageUrl;

      }
      else {
        this.fileService.download(this.currentUser.imageUrl, true).subscribe(
          objectUrl => {
            if (objectUrl) {
              this.profileImageObject = objectUrl.replace("data:text/html;", "data:image/" + this.getImageExtensionFromUrl(this.currentUser.imageUrl) + ";")
            }

          },
          error => {

          }
        );

      }
    }
    else {

    }
  }

  getImageExtensionFromUrl(url: string) {
    const folderList: string[] = url.split("/");
    const fileNameList: string[] = folderList[folderList.length - 1].split(".");
    return fileNameList[fileNameList.length - 1];
  }


  getDisplayMessage(notification: NotificationPi) {
    let displayMessage = "";
    if (notification.actionTaken == "taskReserved") {
      displayMessage = "<small>" + notification.entityName + " moved to your inbox</small>";
    }
    else if (notification.actionTaken == "taskAllocated") {
      displayMessage = "<small>" + notification.entityName + " allocated to you</small>";
    }
    else if (notification.actionTaken == "taskEscalated") {
      displayMessage = "<small>" + notification.entityName + " escalated to you</small>";
    }
    else {
      displayMessage ="<small>" +  notification.displayMessage + "</small>";
    }
    return displayMessage;
  }

  getIsoDate(date: any) {
    // console.log(date)
    let dt = new Date();
    // console.log(new Date(date))
    return new Date(date).toISOString();
  }

  isCustomDashboardAllow() {
    return this.universalUser.getUser() != null && (this.universalUser.getUser().authorities.includes("ROLE_COMPANY_ADMIN") ||
      this.universalUser.getUser().authorities.includes("ROLE_ADMIN") || this.universalUser.getUser().authorities.includes("BUSINESS_OWNER")
      || this.universalUser.getUser().authorities.includes("ROLE_COMPANY_ANALYST"));
  }


  goToTenant(tenant) {
    let currUser = this.universalUser.getUser();
    currUser._id = tenant.userId;
    currUser.companyId = tenant.companyId;
    this.currentAccount = tenant.companyName;
    this.universalUser.setUser(currUser);
    this.currentUser = currUser;
    this.multiTenant.setTenant(tenant.companyId).subscribe(
      (response) => {
        this.fetchUserService.getUser(tenant.userId).subscribe(
          (response1) => {
            this.getSubscribedApplications();
            this.redirectTo('/workspace2/listing')
          },
          (error) => { }
        )
      },
      (error) => { }
    );
  }
  redirectTo(uri: string) {
    this.router.navigateByUrl('/profile', { skipLocationChange: true }).then(() =>
      this.router.navigate([uri]));
  }
  loadcssfile(filename) {
    var fileref = document.createElement("link")
    fileref.setAttribute("rel", "stylesheet")
    fileref.setAttribute("type", "text/css")
    fileref.setAttribute("href", filename);
    fileref.setAttribute("id", "loadedCssFile");
    document.getElementsByTagName("head")[0].appendChild(fileref)
  }
  loadStyle(styleName: string) {
    const head = this.document.getElementsByTagName('head')[0];
    const style = this.document.createElement('link');
    style.id = 'client-theme';
    style.rel = 'stylesheet';
    style.href = `${styleName}`;

    head.appendChild(style);
  }


  addCss: boolean = false;
  addFont(fontName) {
    var fileref = document.createElement("link")
    fileref.setAttribute("rel", "stylesheet")
    fileref.setAttribute("href", "https://fonts.googleapis.com/css2?family=" + fontName + ":wght@400;700&display=swap")
    document.getElementsByTagName("head")[0].appendChild(fileref);
    this.addCss = true;
    let text = `body{font-family:'` + this.companyDetails["uiProperties"]["fontFamily"].replace("+", " ") + `',sans-serif !important}`;
    var styleref = document.createElement("style");
    let divName = ("id" + (new Date().getTime()));
    styleref.setAttribute("id", divName);
    document.getElementsByTagName("head")[0].appendChild(styleref);
    document.getElementById(divName).innerHTML = (text);
  }


  selectTenant() {
    this.multiTenant.getTenants().subscribe(
      (response) => {
        this.tenantList = response;
        let currUser = this.universalUser.getUser();
        let compId = currUser.currentCompanyId != null ? currUser.currentCompanyId : currUser.companyId;
        let pos = response.map(item => item.companyId).indexOf(compId);
        if (pos == -1) {
          this.currentAccount = response[0].companyName
        } else {
          this.currentAccount = response[pos].companyName
        }
      },
      (error) => {
        console.log(error);
      },
    )
  }

  modalOpen(templateRef: TemplateRef<any>) {
    this.bsModal = this.modalService.show(templateRef, { class: 'modal-dialog modal-md modal-dialog-centered' });
  }

  modalClose(templateRef: TemplateRef<any>) {
    this.messageError = '';
    this.bsModal.hide();
    setTimeout(() => {
      this.ticketAssigned = false;
    }, 2000);

  }
  ticketAssigned: boolean = false;
  ticketId: string = "";
  sendSupportMail() {
    if (this.supportmessage.title == "" && this.supportmessage.description == "") {
      this.messageError = "Please fill in your query";
    } else {
      this.messageError = '';
      // this.bsModal.hide();
      this.commonService.createSupportTicket(this.supportmessage).subscribe(
        (response) => {
          this.ticketId = response.ticket_id;
          this.ticketAssigned = true;
          // setTimeout(() => {
          //   this.snackBar.open('Mail sent to the administrator', "Close", {
          //     duration: 5000
          //   })
          // }, 1000);
        },
        (error) => {
          setTimeout(() => {
            this.snackBar.open('Something seems to be the issue.', "Close", {
              duration: 5000
            })
            this.bsModal.hide();

          }, 1000);
        },
      );
    }
  }

  shouldShowApplicationConfig() {
    return this.selectedApplication && this.universalUser.displayPiForms();
  }
  showWallet() {
    let showWall = false;
    let currUser = this.universalUser.getUser();
    let pos1 = currUser['authorities'].indexOf("BUSINESS_OWNER");
    let pos2 = currUser['authorities'].indexOf("ROLE_COMPANY_ADMIN");
    if (this.credits && this.credits.accountCreditsAllowed == true && this.credits.paidCustomer == false) {
      if (pos1 > -1 || pos2 > -1) {
        showWall = true;
      } else {
        showWall = false;
      }
    }
    return showWall;
  }

  fetchAllAgents() {
    this.subscriptionFetchAgents = this.conversationService.getAllAgents().subscribe(
      agentList => {
        if (agentList && agentList.length > 0) {
          this.agentList = agentList;
          for (const agent of agentList) {
            if (agent) {
              if (agent.uiComponent.isBargeable) {
                this.isBargeable = true;
                break
              }
            }
          }
        }
      }, error => {

      }
    );
  }
  showList: boolean = false
  toggleList() {
    this.showList = !this.showList
  }
  listActivity(dataModel: DataModel) {
    this.router.navigate(['/forms',dataModel.name,dataModel._id] );

  }
  addActivity(dataModel: DataModel) {
    for (let item of this.dataModelList) {
      if (item.value == dataModel.name) {
        this.communicationService.setSelectedDataModelId(item.id);
      }
    }
    this.communicationService.setEntity(null);
    this.communicationService.setDataModelName(dataModel.name);
    this.toggleList();
    this.getDataModelDetails(dataModel._id);
    this.searchTerm = ""
  }
  getDataModelDetails(dataModelid: string) {
    this.dataModelService.getDataModel(dataModelid)
      .subscribe(
        datamodeldetails => {
          this.redirectionToActivitiesComponent(datamodeldetails['showWizardMode'],datamodeldetails.name);
        }
      )
  }
  redirectionToActivitiesComponent(isWizardUI: boolean, datamodelName: string) {
    if (isWizardUI) {
      let tmp = '/form-detail-v2/' + datamodelName + '/new';
      this.router.navigateByUrl(tmp);
    }
    else {
      let tmp = '/form-detail/' + datamodelName + '/new';
      this.router.navigateByUrl(tmp);
    }
  }
  shortenName(str, charlength) {
    let opStr = ""
    if (str.split(" ").length > 1) {
      let strArr = str.split(" ");
      opStr = strArr[0].substring(0, +charlength)
      opStr = opStr.toUpperCase();
    } else {
      opStr = str.substring(0, +charlength).toUpperCase();
    }
    return opStr;
  }

  initiateNotificationMechanism() {
    //Notifiction type 
    this.notificationService.fetchNotificatioMode().subscribe(
      (response) => {
        switch (response.notiticationMode) {
          case 'FCM':
            this.registerFirebaseServiceWorker();
            break;
          case 'WEBSOCKET':
            this.connectToWebSocket();
            break;
          default:
            break;
        }

      },
      (error) => { }
    )
  }

  registerFirebaseServiceWorker() {
    navigator.serviceWorker.register('./firebase-messaging-sw.js')
      .then((registration) => {
        // Request permission and get token.....
        this.messagingService.requestPermission();
        this.subscribeToDialerNotifications();
        this.subscribeToDialerCallConnected();
        this.subscribeToDialerCallHangUp();
        this.subscribeToDialerCallWrapUp();
      });
  }

  subscribeToDialerNotifications() {
    this.dialerService.getDialerNotificationSubscription().subscribe(
      notification => {
        if (notification) {
          this.dialerNotification = notification;
          this.dialerNotification['show'] = true;
        }
      }
    )
  }

  subscribeToDialerCallConnected() {
    this.dialerService.getCallConnectedSubscription().subscribe(
      onCallConnected => {

      }
    );
  }

  subscribeToDialerCallHangUp() {
    this.dialerService.getCallHangUpSubscription().subscribe(
      onCallHangUp => {

      }
    );
  }

  subscribeToDialerCallWrapUp() {
    this.dialerService.getCallWrapUpSubscription().subscribe(
      onCallWrapUp => {
        this.dialerNotification = null;
      }
    );
  }


  navigateAsPerCallTarget(notification?: any) {
    const taskData = this.dialerService.getEntityIdAndMachineTypeFromLatestCallLog();
    if (taskData['entityId'] && taskData['machineType']) {
      this.router.navigate(['/task'], { queryParams: { entityId: taskData['entityId'], machineType: taskData['machineType'] } });
    }

    if (notification) {
      notification['show'] = false;
    }
  }

  pauseUnpauseAgentCalls() {
    const currentAgentStatus = this.dialerService.getDialerAgentStatus();

    if (currentAgentStatus == 'Paused') {
      this.dialerService.changeAgentStatus('unpause').subscribe(
        changedStatus => {

        }
      );
    } else {
      this.dialerService.changeAgentStatus('pause', this.pauseReason).subscribe(
        changedStatus => {
          this.pauseReason = null;
          this.bsModal.hide();
        },
        error => {
          this.pauseReason = null;
          this.bsModal.hide()
        }
      );
    }
  }

  manualWrapup() {
    this.dialerService.wrapUpCall().subscribe(
      response => {

      },
      error => { }
    )
  }
  onDocumentUpload(event, object, key) {
    // console.log(event)
    for (let index = 0; index < event.target.files.length; index++) {
      let uploadedFile = event.target.files[index];
      const fileInputForm = new FormData();
      const file: File = event.target.files[index];
      let fileNameArray = file.name.split(".");
      let fileName = "";
      if (fileNameArray.length > 2) {
        fileName = fileNameArray[0] + "." + fileNameArray[fileNameArray.length - 1]
      } else {
        fileName = file.name;
      }
      fileInputForm.append('file', file, fileName);
      fileInputForm.append("fileName", fileName);
      fileInputForm.append("functionInstanceName", "API");
      fileInputForm.append("entityType", "COMPLAINT");
      fileInputForm.append("entityRef", this.currentUser._id);
      let fileSize = file.size;
      let fileMb = fileSize / 1024 ** 2;
      if (fileMb >= 2) {
        this.messageError = "Please select a file less than 2MB.";

      } else {
        // this.messageError = "Success, your file is " + fileMb.toFixed(1) + "MB.";
        this.uploadFile(fileInputForm, object, key);
      }
    }

  }

  uploadFile(formData: FormData, object, key) {

    this.fileService.upload(formData)
    .subscribe(
      response => {
        if (response && response["url"] && response["fileName"]) {
          let tmp = {
            message: "Ticket Created",
            attachments: []
          }
          let attachment = {
            file_name: response.fileName,
            file_url: response.downloadFileUrl
          }
          tmp['attachments'].push(attachment);
          object[key].push(tmp)
        }
      }, (error) => {
        if (error && error.error && error.error.error && error.error.error.message) {
          this.snackBar.open(error.error.error.message, "Dismiss", {
            duration: 10000
          });
        }
      }
    );
  }
  fetchNotificationFlag:boolean = false;
  fetchGenralNotifications(){
    this.fetchNotificationFlag = true;
    this.commonService.fetchNotifications(this.userId);
    this.commonService.notificationObservable.subscribe(
      response => { this.notifications = response;  },
      error => { }
    )
  }
  getNotificationCount() {
    if (this.notificationCount < 0) {
      return 0;
    }
    else {
      return this.notificationCount;
    }
  }
  fetNotificationCount(){
    this.notificationService.fetchNotificationsCount()
    .subscribe(notifications => {
      this.notificationCount = notifications.count;
    });
  }
}