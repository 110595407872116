import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortentext'
})
export class ShortenNamePipe implements PipeTransform {

  transform(str: any, noOfChars: any=2,  ...args: any[]): any {
    if(!args) {
      return null;
    }
    if(!str) {
      return "-";
    }else{
      str = str.trim();
      let opStr = ""
      if (str.split(" ").length > 1) {
        if (str.split(" ").length == 2) { 
          let strArr = str.split(" ");
          for (let index = 0; index < 2 && index < +noOfChars; index++) {
            let el = strArr[index];
            opStr += el.substring(0, 1)
          }
          opStr = opStr.toUpperCase();
        }else{
          let strArr = str.split(" ");
          opStr = strArr[0].substring(0, 1) + strArr[strArr.length-1].substring(0, 1) 
        }
      } else {
        opStr = str.substring(0, +noOfChars).toUpperCase();
      }
      return opStr;
    }

   
    
  }

}

/**
 * 
 * shortenName(str, charlength) {
    
  }
 */