import { Component, OnInit, Input, EventEmitter, Output, ViewEncapsulation, ViewChild, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { EmailPersister } from 'src/app/models/helper.model';
import { Subscription } from 'rxjs';
import { EmailService } from 'src/app/service/helper.service';
import { State } from 'src/app/models/tasks.model';
import { DomSanitizer } from '@angular/platform-browser';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { AdhocEmailComponent } from 'src/app/shared/adhocemail/adhocemail.component';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { FileService } from 'src/app/service/shared.service';
@Component({
  selector: 'app-emailtrail',
  providers: [EmailService],
  templateUrl: './emailtrail.component.html',
  styleUrls: ['./emailtrail.component.scss']
})
export class EmailtrailComponent implements OnInit,OnChanges {

  @Input() currentSelectedTaskDetails: State;
  @Input() containerWidth: any;
  @Input() replyOption: boolean;
  @Output() closeEvent = new EventEmitter();
  @ViewChild('emailHolder', { static: false }) emailholder: ElementRef;
  @ViewChild('staticAlert', { static: false }) staticAlert: ElementRef;
  isExpanded:boolean = false;
  sourceEmailTrailList: EmailPersister[] = [];
  systemEmails:EmailPersister[] = [];
  private subscriptionEmail: Subscription;
  selectedState: State;
  len: number = 0;
  bsModalRef: BsModalRef;
  loaded:boolean = false;
  emailMsg = "Loading emails associated with the Case.";
  showSystemEmails: boolean = false;
  showSystemEmailsTogle:boolean = false;
  otherEmailsPresent:boolean = false;
  emailTexttoDisplay:any[]=[];
  emailPageSize:number = 5;
  emailPageNo:number = 0;
  loadMoreMessage = "Load more emails...."
  //   editorConfig: AngularEditorConfig = {
  //     editable: true,
  //       spellcheck: true,
  //       height: 'auto',
  //       minHeight: '0',
  //       maxHeight: 'auto',
  //       width: 'auto',
  //       minWidth: '0',
  //       translate: 'yes',
  //       enableToolbar: true,
  //       showToolbar: true,
  //       placeholder: 'Enter text here...',
  //       defaultParagraphSeparator: '',
  //       defaultFontName: '',
  //       defaultFontSize: '',
  //       fonts: [
  //         {class: 'arial', name: 'Arial'},
  //         {class: 'times-new-roman', name: 'Times New Roman'},
  //         {class: 'calibri', name: 'Calibri'},
  //         {class: 'comic-sans-ms', name: 'Comic Sans MS'}
  //       ],
  //       customClasses: [
  //       {
  //         name: 'quote',
  //         class: 'quote',
  //       },
  //       {
  //         name: 'redText',
  //         class: 'redText'
  //       },
  //       {
  //         name: 'titleText',
  //         class: 'titleText',
  //         tag: 'h1',
  //       },
  //     ],
  //     sanitize: true,
  //     toolbarPosition: 'top',
  //     toolbarHiddenButtons: [
  //       [
  //         'undo',
  //         'redo',
  //         'strikeThrough',
  //         'subscript',
  //         'superscript',
  //         'indent',
  //         'outdent',
  //         // 'insertUnorderedList',
  //         // 'insertOrderedList',
  //         'heading',
  //         'fontName',
  //         'customClasses',
  //         'insertImage',
  //         'insertVideo',
  //         'toggleEditorMode',
  //         'link',
  //         'unlink',
  //         'insertHorizontalRule',
  //         'removeFormat',
  //         'fontSize',
  //     'textColor',
  //     'backgroundColor',
  //       ],
  //       []
  //     ]
  // };



  // toolbarHiddenButtons: [
  //   [
  //     'bold',
  //     'italic',
  //     'underline',

  //     'justifyLeft',
  //     'justifyCenter',
  //     'justifyRight',
  //     'justifyFull',


  //   ],
  //   [


  //   ]
  // ]

  constructor(
    private modalService: BsModalService,
    private emailService: EmailService,
    private sanitizer: DomSanitizer,
    private snackbar: MatSnackBar,
    private fileService: FileService,

  ) {
    this.sourceEmailTrailList = [];
  }
  ngOnChanges(changes: SimpleChanges): void {
    // console.log(changes)
  }

  ngOnInit() {
    this.selectedState = this.currentSelectedTaskDetails;
    this.fetchEmailTrail();
  }


  loadMore(){
    this.emailPageNo += 1;
    this.fetchEmailTrail(null,true);
  }

  fetchReferenceEntityId(callback: (referenceEntityId: string) => void) {
    let referenceEntityId = this.selectedState.entityId;
    this.emailService.getFlowInstanceById(this.selectedState.stateMachineInstanceModelId).subscribe(data => {
        if (data["_id"] != null && data["subprocessFlag"] == true) {
            this.emailService.getFlowInstanceById(data["parentProcessId"]).subscribe(innerData => {
                referenceEntityId = innerData["entityId"];
                callback(referenceEntityId); // Call the callback with the updated referenceEntityId
            });
        } else {
            callback(referenceEntityId); // Call the callback if no nested call is made
        }
    });
  }
  emailHtml = [];
  fetchEmailTrail(pageNumber?:number,loadMoreEmails?:boolean) {
    let pageNo;
    if(pageNumber == 0){
      pageNo = pageNumber; 
    }
    else{
      pageNo = this.emailPageNo;
    }
    if(loadMoreEmails){
      this.loadMoreMessage = "Loading..."
    }

    this.fetchReferenceEntityId((referenceEntityId) => {
      this.subscriptionEmail = this.emailService.getEmailTrail(this.selectedState.entityId, referenceEntityId, pageNo, this.emailPageSize, { "emailTime": -1 })
        .subscribe(emailTrail => {
        if (emailTrail && emailTrail.length > 0) {
          for (let i = 0; i < emailTrail.length; i++) {
            let email = emailTrail[i];
            this.getHtmlContent(email)
            if(email.entityId === this.selectedState.entityId || email.entityId === referenceEntityId){
              if(email.emailType == "SYSTEM"){
                this.showSystemEmailsTogle = true;
                email["tag"] = "System email";
              }
              else{
                this.otherEmailsPresent = true;
              }
              const index = this.sourceEmailTrailList.findIndex(object => object._id === email._id);
              if(index == -1)
              {
                this.sourceEmailTrailList.push(email);
              }
              this.sanitizeHTML(email,i);
              this.sourceEmailTrailList = this.sourceEmailTrailList.sort((a, b) => { return b.emailTime['$date'] - a.emailTime['$date'] });
            }
          }
          this.showEmailTrail = true;
          if(!this.otherEmailsPresent){ 
            this.showSystemEmailsTogle = true;

            this.emailMsg ="There are only system emails present for this task please use the toogle to see them"
            this.snackbar.open("There are no new emails for the task", "Dismiss", {
              duration: 10000
            });
          }
          if(!this.showSystemEmails){
            this.removeSystemEmails();
          }
        }
        else{

          if(this.emailPageNo > 0 ){
            this.snackbar.open("There are no more emails associated with the task", "Dismiss", {
              duration: 10000
            });
          }
          else{
            this.showEmailTrail = true;
            this.emailMsg ="No Emails present for this task"
            this.snackbar.open("There are no more emails associated with the task", "Dismiss", {
              duration: 10000
            });
          }
        }
        this.loadMoreMessage = "Load more emails..."
      });
    })

  }

  toggleSystemEmails(){
  }

  removeSystemEmails(){
    this.systemEmails = [];
    for(let email of this.sourceEmailTrailList){
      if(email.emailType == "SYSTEM"){
        this.showEmailTrail = true;
        const index = this.sourceEmailTrailList.indexOf(email);
        this.systemEmails = this.sourceEmailTrailList.splice(index,1);
      }
    }
  }

  addSystemEmails(event){
    if(event.currentTarget.checked){
      this.sourceEmailTrailList = this.sourceEmailTrailList.concat(this.systemEmails).sort((a, b) => { return b.emailTime['$date'] - a.emailTime['$date'] });
    }
    else{
      this.removeSystemEmails()
    }
    
  }


  resize() {
    // let ratio = window.innerHeight / $('body').innerHeight();
    // console.log(this.emailholder)
    // if (window.innerWidth / $('body').innerWidth() < ratio) {
    //     ratio = window.innerWidth / $('body').innerWidth();
    // }
    // ratio -= .04;
    // $('body').css('-ms-zoom', ratio);
    // $('body').css('-moz-transform', 'scale(' + ratio + ')');
    // $('body').css('-o-transform', 'scale(' + ratio + ')');
    // $('body').css('-webkit-transform', 'scale(' + ratio + ')');
    // $('body').css('transform', 'scale(' + ratio + ')');
    // left = ($(window).innerWidth() - $('body').outerWidth() * ratio) / 2;
    // $('body').css('left', left);
  }
  closeSection() {
    this.closeEvent.emit();
  }
  showEmailTrail:boolean = false;

  getHtmlContent(email: EmailPersister, i?) {
    this.emailService.getEmailHtmlText(email._id).subscribe(emailFetched => {
      if (emailFetched && emailFetched.htmlText) {
        if(emailFetched.dataServicePayload && emailFetched.dataServicePayload.length > 0){
          for(let attachFileObj of emailFetched.dataServicePayload){
              if(emailFetched.htmlText.includes("cid:"+ attachFileObj["fileName"]))
              {
                this.getBase64FromUrl(attachFileObj["downloadFileUrl"],attachFileObj["fileName"],emailFetched,email);

              }
              else{
                this.processFirstEmail(emailFetched,email);
              }
            }
        }
        else{
          this.processFirstEmail(emailFetched,email);
        }
        
      }else{
        email['tmpDisplay'] = this.sanitizer.bypassSecurityTrustHtml(email.htmlText);
      }
    });
    
  }


  getBase64FromUrl(downloadFileUrl,fileName,element,email):string{
    this.fileService.download(downloadFileUrl, true).subscribe(
      objectUrl => {
        if (objectUrl) {
          var type = (objectUrl.split(';')[0]).replace("data:","");
          fetch(objectUrl)
          .then(res => res.blob())
          .then(blob => {
            this.fileToText(blob, (text) => {
              element.htmlText = element.htmlText.replace("cid:" + fileName,text);
              this.processFirstEmail(element,email);
            });
            
          })
        }
      }
    );
  return null;
  }

  processFirstEmail(emailFetched,email){
    this.showEmailTrail = true;
    var escape = document.createElement('textarea');
    if (emailFetched.htmlText.indexOf("<html") > -1 && emailFetched.htmlText.indexOf("<div") > -1 || emailFetched.htmlText.indexOf("<p") > -1 || emailFetched.htmlText.indexOf("<table") > -1) {

      email.htmlText = emailFetched.htmlText.replace(/(\n)/gm, '').replace(/(\r1|\t)/gm, ' ');
    } else {
      email.htmlText = emailFetched.htmlText.replace(/(\n)/gm, '&lt;br&gt;').replace(/(\r1|\t)/gm, ' ');
    }
    let html = email.htmlText.replace(/\<\!.*dtd\">/, '');
    escape.innerHTML = html;

    email['tmpDisplay'] = this.sanitizer.bypassSecurityTrustHtml(escape.textContent);
  }


  fileToText(blob, callback) {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => {
      callback(reader.result);
    };
  }



  sanitizeHTML(email:EmailPersister,i:number) {
    
    {
      if(i==0 && (email.htmlText == undefined || email.htmlText == null)){
        this.getHtmlContent(email,i);
      }
      else{
        email['tmpDisplay']= this.sanitizer.bypassSecurityTrustHtml(email.htmlText);
        // this.emailTexttoDisplay[i] = this.sanitizer.bypassSecurityTrustHtml(email.htmlText)
      }
    }
  }

 
  


  openModalWithComponent(email, mode?) {
    const config: ModalOptions = {
      backdrop: 'static',
      keyboard: false,
      animated: false,
      ignoreBackdropClick: true,
      initialState: {
        email: email,
        title: 'Case Email',
        emailTrail: this.sourceEmailTrailList,
        openMode: mode,
        replyOption: this.replyOption,
        stateInstance: this.currentSelectedTaskDetails
      },
      class: 'fullModalWidth emailModal'
    };
    this.bsModalRef = this.modalService.show(AdhocEmailComponent, config);
    this.bsModalRef.content.closeBtnName = 'Close';
    this.modalService.onHide.subscribe(() => {
      // console.log('email modal is closed');
      
    })
    this.bsModalRef.content.event.subscribe(res => {
      if (res == true && this.staticAlert && this.staticAlert.nativeElement) {
        this.staticAlert.nativeElement.classList.add('showAlert')
        setTimeout(() => this.staticAlert.nativeElement.classList.remove('showAlert'), 3000);
        this.fetchEmailTrail(0);
      }
    });
  }
  ngOnDestroy() {
    if (this.subscriptionEmail && !this.subscriptionEmail.closed) {
      this.subscriptionEmail.unsubscribe();
    }
  }
  getDateProper(date) {
    let d = new Date(date);
    if (d + '' != 'Invalid Date') {
      let minutes = d.getMinutes().toString().length == 1 ? '0'+d.getMinutes() : d.getMinutes();
      let hours = d.getHours().toString().length == 1 ? '0'+d.getHours() : d.getHours();
      let ampm = d.getHours() >= 12 ? 'pm' : 'am';
      let months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
      return d.getDate()+'/'+ (d.getMonth() + 1) +'/'+ d.getFullYear()+' ' + hours +':' + minutes + ampm;
    } else {
      return "-"
    }
  }
  giveBackStart(emailFrom:string){
    return emailFrom.substring(0,1).toUpperCase();
  }
}