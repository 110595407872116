import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { MatPaginator, MatSnackBar, MatSort, MatTable, MatTableDataSource } from '@angular/material';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AccessControlRequest, AccessControlResponse } from '../models/helper.model';
import { CommonSearchModel } from '../models/shared.model';
import { RecordSearchMap, DataModel } from '../models/datamodel.model'
import { DataModelService } from '../service/datamodel.service';
import { EntityService } from '../service/entity.service';
import { ExportService } from '../service/export.service';
import { AccessControlService } from '../service/helper.service';
import { CommunicationService, DataSharingService, UniversalUser } from '../service/shared.service';
import { StateService } from '../service/state.service';
import { PreferenceService } from '../service/user.service';
import { FileDetector } from 'protractor';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { RouteService } from '../service/shared.service'
import { Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-activities',
  templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.scss']
})
export class ActivitiesComponent implements OnInit, AfterViewInit, OnDestroy {


  userId: string;
  TABLINKS_ACTIVE = "block active";
  TABLINKS = "block";
  rows = 10;
  columns = 5;
  p: number = 1;
  progressBarFlag: boolean;
  pageNumber: any;
  fetchRecords: number;
  totalRecords: number;
  entityTabclass: any;
  entityList: any = [];
  entityListCard: any = [];
  selectedEntity: string;
  selectedDataModel: DataModel;
  selectedEntityMap: any;
  deleteEntityErrorMsg: string = null;
  allowDelete: boolean;
  scrollId: string;
  filterQuery: string;
  dataModelList: any[];
  dataModelId: string;
  showFilter: boolean = false;
  showEntityList: boolean = false;
  noResults: boolean = false;
  otherColumns: any[] = [];
  displayedColumns = [
    {name:'display', sortActivated: false, sortDirection : 1, label: 'Title'}, 
    {name:'count', sortActivated: false, sortDirection : 1, label: 'Count'}, 
    {name:'lastEntityUpdatedAtLong', sortActivated: false, sortDirection : 1, label: 'Records last updated on'}, 
    {name:'actions', sortActivated: false, sortDirection : 1, label: 'Actions'}
  ]
  columnList = [];
  private sort: MatSort;
  private paginator: MatPaginator;
  private table: MatTable<any>;
  notNeededFields: any = [];
  sortBy = 'name';
  sortOrder = 'asc';
  dropDownOpened: boolean = false;
  sortedDiaplayColumns = [];
  searchColumnList = [];
  recordSearchMapList: RecordSearchMap[] = [];
  defaultSearchMap: RecordSearchMap;
  entitySearchEnabled: boolean = false;
  entitySearchCriteria: any = {"criteria":{},"searchText":null,"sort":{}};
  selectedEntityLabel: string;
  businessKeyField: string = '';
  entityCount: number;
  pageNo: number = 1;
  bsModelDelete: BsModalRef;
  displayinCard = [];
  navigationSubscriber:Subscription;

  dropdownSettingsMultiple: IDropdownSettings = {};

  @ViewChild(MatSort, { static: false }) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }
  @ViewChild('table', { static: false }) set MatTable(tb: MatTable<any>) {
    this.table = tb;
  };
  @ViewChild(MatPaginator, { static: false }) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  ngAfterViewInit() {
    this.entityList.paginator = this.paginator;
    if (this.communicationService.formFilter) {
      this.recordSearchMapList = this.communicationService.formFilter;
      this.entitySearchCriteria = this.communicationService.entitySearchCriteria;
    }
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private communicationService: CommunicationService,
    private dataModelService: DataModelService,
    private entityService: EntityService,
    private accessControlService: AccessControlService,
    private universalUser: UniversalUser,

    @Inject(PreferenceService) private prefService: PreferenceService,
    private titleService: Title
  ) {
    this.titleService.setTitle("Forms - listing")
    this.entityList = [];
    this.dataModelList = [];
    this.selectedEntity = null;
    this.selectedDataModel = null;
    this.entityTabclass = {};
    this.notNeededFields = [];
    this.scrollId = null;
    this.filterQuery = '';
    this.rows = 10;
    this.columns = 5;
    this.dropdownSettingsMultiple = {
      singleSelection: false,
      idField: 'value',
      textField: 'label',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    this.defaultSearchMap = new RecordSearchMap();
    this.navigationSubscriber = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        if (this.route.snapshot.queryParamMap.get("datamodelName") != null) {
          this.selectedDataModel = null;
          this.initFunctions();
        } else {
          this.communicationService.setSelectedDataModelId(null);
          this.showEntityList = false;
          this.initFunctions();
        }
      }
    });
  }

  ngOnInit() {
    this.initFunctions();
  }
  initFunctions() {
    this.userId = this.universalUser.getUser()._id;
    this.pageNumber = 0;
    this.fetchRecords = 10;
    if (this.communicationService.formFilter) {
      this.recordSearchMapList = this.communicationService.formFilter;
      this.entitySearchCriteria = this.communicationService.entitySearchCriteria;
    }
    this.getDataModelList();
    this.recordSearchMapList = [];
  }
  goBack() {
    this.showEntityList = false;
    this.noResults = false;
    this.communicationService.setSelectedDataModelId(null);
    this.recordSearchMapList = [];
    this.pageNumber = 0;
  }

  ngOnDestroy() {
    if(this.navigationSubscriber){
      this.navigationSubscriber.unsubscribe();
    }
  }


  getDataModelList() {
    const commonsearchModel = new CommonSearchModel();
    commonsearchModel.searchParams = [{ 'statusCd': 'ACTIVE' }];
    commonsearchModel.returnFields = ['label', 'version', 'statusCd', 'name', 'subEntity', 'type',"priorityWiseCount","uiProperties"];
    commonsearchModel.sort = { "name":1}
    this.dataModelService.getDataModelListWiithOtherParams(commonsearchModel)
      .subscribe(list => {
        if (list != null && list.length > 0) {
          for (let item of list) {
            if (!item.subEntity && item.type != "EmailIn") {
              if (this.communicationService.getSelectedDataModelId() != null && this.communicationService.getSelectedDataModelId() == item._id) {
                this.selectedEntity = item.name;
                this.selectedDataModel = item;
                this.dataModelId = item._id;
              }
              // if (item.type != 'Entity_Lookup_Reference') {
              // this.dataModelList.push({ display: item.label, value: item.name, id: item._id, name: item.name, statusCd: item.statusCd })
              // }
              let dmPos = this.dataModelList.map(dmItem => dmItem.value).indexOf(item.name);
              // this.getEntityCount(item.name)
              if (dmPos == -1) {
                let tmp = {"high":null,"medium":null,"low":null}
                if(item.priorityWiseCount['HIGH'])
                    tmp.high = item.priorityWiseCount['HIGH'];
                if(item.priorityWiseCount['MEDIUM'])
                  tmp.medium = item.priorityWiseCount['MEDIUM'];
                if(item.priorityWiseCount['LOW'])
                  tmp.low = item.priorityWiseCount['LOW'];
                if(item.priorityWiseCount['High'])
                  tmp.high = item.priorityWiseCount['High'];
                if(item.priorityWiseCount['Medium'])
                  tmp.medium = item.priorityWiseCount['Medium'];
                if(item.priorityWiseCount['Low'])
                  tmp.low = item.priorityWiseCount['Low'];
                  
                this.dataModelList.push({
                  display: item.label,
                  priorityWiseCount:tmp,
                  lastEntityUpdatedAtLong:item['lastEntityUpdatedAtLong'],
                  value: item.name,
                  id: item._id,
                  name: item.name,
                  statusCd: item.statusCd,
                  entCount: item.entCount
                });
              }
              //this.setAccessOfUserForDataModelList();
            }
          }
          
        }
        if (this.dataModelList) {
          this.setAccessOfUserForDataModelList();
        }

      });
  }
 

  setAccessOfUserForDataModelList() {
    this.accessControlService.getUserAccessByComponentType("ENTITY")
      .subscribe(
        accessControlList => {
          if (!accessControlList || accessControlList.length == 0) {
            for (let dataModel of this.dataModelList) {
              this.setAccessControlParametersForDataModel(null, dataModel);
            }
            return;
          }
          for (let dataModel of this.dataModelList) {
            for (let accessControl of accessControlList) {
              if (accessControl.componentName == dataModel.name) {
                this.setAccessControlParametersForDataModel(accessControl, dataModel);
              }
            }
          }
        },
        error => {

        }
      );
  }

  setAccessControlParametersForDataModel(accessControl: AccessControlResponse, dataModel: DataModel) {
    if (!accessControl || accessControl.operationAccessList == null || accessControl.operationAccessList.length == 0) {
      dataModel.allowCreate = true;
      dataModel.allowUpdate = true;
      dataModel.allowDelete = true;
      return;
    }
    if (accessControl.operationAccessList) {
      for (let operation of accessControl.operationAccessList) {
        if (operation.operationName == "Create" && operation.enabled) {
          dataModel.allowCreate = true;
        }
        if (operation.operationName == "Update" && operation.enabled) {
          dataModel.allowUpdate = true;
        }
        if (operation.operationName == "Delete" && operation.enabled) {
          dataModel.allowDelete = true;
        }
        if (operation.operationName == "View" && operation.enabled) {
          dataModel.allowView = true;
        }
      }
    }
  }

  setAccessOfUserForDataModel(dataModel: DataModel) {
    let accessControlReq = new AccessControlRequest();
    accessControlReq.componentType = "ENTITY";
    accessControlReq.componentName = dataModel.name;
    accessControlReq.userId = this.userId;
    this.accessControlService.getUserAccess(accessControlReq)
      .subscribe(
        accessControl => {
          this.setAccessControlParametersForDataModel(accessControl, dataModel);
        });
  }
  setDataSourceAttributes() {
    this.entityList.sort = this.sort;
    if (this.paginator && this.sort) {
      this.applyFilter('');
    }
  }
  entityListCount:any[] = [];
  getEntityCount(entityName) {
    this.entityService.getEntityCount(entityName, this.entitySearchCriteria)
      .subscribe(
        response => {
          if (response && response.count != null) {
            let tmp = {}
            tmp['count'] = response.count;
            tmp['entityName'] = entityName;
            this.entityListCount.push(tmp)
          }
          
        },
        error => {

        }
      )
  }
  applyFilter(filterValue: string) {
    this.entityList.filter = filterValue.trim().toLowerCase();
  }
  changeSelection(entity: any) {
    // console.log(entity)
    // return;
    this.selectedEntity = entity.name;
    this.communicationService.setSelectedDataModelId(entity.id);
    this.communicationService.formFilter = null;
    this.communicationService.entitySearchCriteria = null;
    this.router.navigateByUrl('/forms/' + this.selectedEntity + "/"+ entity.id);
  }
  addActivity(datamodel: DataModel) {
    this.selectedEntity = datamodel.name;
    datamodel._id = datamodel.id;
    this.communicationService.setSelectedDataModelId(datamodel.id);
    this.selectedDataModel = datamodel;
    this.dataModelId = datamodel.id;
    this.communicationService.setEntity(null);
    this.communicationService.setDataModelName(this.selectedEntity);
    this.communicationService.setDataModel(datamodel)
    this.getDataModelDetails(this.dataModelId);
  }
  getDataModelDetails(dataModelid: string) {
    this.dataModelService.getDataModel(dataModelid)
      .subscribe(
        datamodeldetails => {
          this.redirectionToActivitiesComponent(datamodeldetails['showWizardMode']);
        }
      )
  }
  redirectionToActivitiesComponent(isWizardUI: boolean) {
    if (isWizardUI) {
      // this.router.navigateByUrl('/add-activities-v2');
      this.router.navigate(['/form-detail-v2',this.selectedEntity,'new']);
    }
    else {
      // this.router.navigateByUrl('/add-activities');
      this.router.navigate(['/form-detail',this.selectedEntity,'new']);
    }
  }
  capitalize = (s) => {
    if (typeof s !== 'string') return ''
    let tempStr = s.charAt(0).toUpperCase() + s.slice(1)
    return tempStr.split(/(?=[A-Z])/).join(" ")
  }
  getCurrentPage(){
    if(this.filterQuery.length > 1){
      this.p = 1
    }
    return this.noResults == false
  }
  getTotalCount(datamodel){
    let pos = this.entityListCount.map(itm=>itm.entityName).indexOf(datamodel.value);
    if (pos>-1) {
      return this.entityListCount[pos]['count']
    }else{
      return "0"
    }
  }
  sortColumn(column,direction){
    this.displayedColumns[column]
    let baseColumns = this.displayedColumns
    for (let index = 0; index < baseColumns.length; index++) {
      baseColumns[index].sortActivated = false;      
      if(baseColumns[index].name == column.name){
        baseColumns[index].sortActivated = true;
        baseColumns[index].sortDirection = direction;
      }
    }
   this.dataModelList.sort(function (x, y) {
      if (x[column.name] > y[column.name]) {
        return 1;
      } else if (x[column.name] < y[column.name]) {
        return -1;
      } else {
        return 0;
      }
    });
    
  }
}
