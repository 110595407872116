import { Component, OnInit, Output, EventEmitter, Input, OnChanges, ViewChild } from '@angular/core';

import * as moment from 'moment';
import { DaterangepickerComponent, DaterangepickerDirective } from 'ngx-daterangepicker-material';

@Component({
  selector: 'api-daterangepicker',
  templateUrl: './daterangepicker.component.html',
  styleUrls:['./daterangepicker.component.scss']
})
export class DateRangePickerComponent implements OnInit,OnChanges {
  @Input() showLabel:boolean;
  @Input() defaultNotShow: boolean;
  @Input() selected:any;
  @Output()
  dateEvent: EventEmitter<any> = new EventEmitter();

  @ViewChild(DaterangepickerDirective, { static: true }) pickerDirective: DaterangepickerDirective;
  picker: DaterangepickerComponent;

  // selected: any;
  alwaysShowCalendars: boolean;
  showRangeLabelOnInput: boolean;
  keepCalendarOpeningWithRange: boolean;
  maxDate: moment.Moment;
  minDate: moment.Moment;
  invalidDates: moment.Moment[] = [];
  tooltips = [
    {date: moment(), text: 'Today is just unselectable'},
    {date:  moment().add(2, 'days'), text: 'Yeeeees!!!'}
  ];
  inlineDateTime;
  ranges: any = {
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [
      moment()
        .subtract(1, 'month')
        .startOf('month'),
      moment()
        .subtract(1, 'month')
        .endOf('month')
    ],
    'Last 3 Month': [
      moment()
        .subtract(3, 'month')
        .startOf('month'),
      moment()
        .subtract(1, 'month')
        .endOf('month')
    ],
    'Last 6 Month': [
      moment()
        .subtract(6, 'month')
        .startOf('month'),
      moment()
        .subtract(1, 'month')
        .endOf('month')
    ]
  };

  constructor() {
  

    this.maxDate = moment().add(2,  'weeks');
    this.minDate = moment().subtract(3, 'days');
  
    this.alwaysShowCalendars = true;
    this.keepCalendarOpeningWithRange = true;
    this.showRangeLabelOnInput = true;
    
  }
  
  

rangeClicked(range) {
  // console.log('[rangeClicked] range is : ', range.dates[0]);
  let tmp = {
    startDate:moment(range.dates[0]),
    endDate: moment(range.dates[1])
  }
  this.dateEvent.emit(tmp);
}
datesUpdated(range) {
  // console.log('[datesUpdated] range is : ', range);
  this.dateEvent.emit(range);
}



ngOnInit() {
  this.picker = this.pickerDirective.picker;
  if (!this.defaultNotShow && !this.selected) {
    this.selected = {
      startDate:moment().subtract(6, 'days'),
      endDate: moment()
    };
  }
  
  
}

ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
  if (changes.defaultNotShow && changes.defaultNotShow.previousValue != undefined && 
      changes.defaultNotShow.currentValue!=undefined && changes.defaultNotShow.currentValue) {
      this.selected = null;
  }
}
  
open(e) {
  // console.log(e)
  this.pickerDirective.open(true);
}
isInvalidDate = (m: moment.Moment) =>  {
  return this.invalidDates.some(d => d.isSame(m, 'day') );
}
isTooltipDate = (m: moment.Moment) =>  {
  const tooltip = this.tooltips.find(tt => tt.date.isSame(m, 'day'));
  if (tooltip) {
    return tooltip.text;
  } else {
    return false;
  }
}


}
