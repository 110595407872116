import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { MatPaginator, MatSnackBar, MatSort, MatTable, MatTableDataSource } from '@angular/material';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AccessControlRequest, AccessControlResponse } from '../../models/helper.model';
import { CommonSearchModel } from '../../models/shared.model';
import { RecordSearchMap, DataModel, Entity } from '../../models/datamodel.model'
import { DataModelService } from '../../service/datamodel.service';
import { EntityService } from '../../service/entity.service';
import { ExportService } from '../../service/export.service';
import { AccessControlService } from '../../service/helper.service';
import { CommunicationService, DataSharingService, EntiySharingService, UniversalUser } from '../../service/shared.service';
import { StateService } from '../../service/state.service';
import { PreferenceService } from '../../service/user.service';
import { FileDetector } from 'protractor';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { RouteService } from '../../service/shared.service'
import { Subscription, Observable, Observer } from 'rxjs';
import { ItemsList } from '@ng-select/ng-select/lib/items-list';
import { Location } from '@angular/common';
import { UserPreferences } from 'src/app/models/userPreferences.model';
import { JoyrideService } from 'ngx-joyride';
import { ImportService } from 'src/app/service/import.service';


@Component({
  selector: 'app-form-listing',
  templateUrl: './form-listing.component.html',
  styleUrls: ['./form-listing.component.scss'],
})
export class FormListingComponent implements OnInit, AfterViewInit, OnDestroy {
  userId: string;
  TABLINKS_ACTIVE = "block active";
  TABLINKS = "block";
  rows = 10;
  columns = 5;
  p: number = 1;
  progressBarFlag: boolean;
  pageNumber: any;
  fetchRecords: number;
  totalRecords: number;
  entityTabclass: any;
  entityList: any = [];
  entityListCard: any = [];
  selectedEntity: string;
  selectedDataModel: DataModel;
  selectedEntityMap: any;
  deleteEntityErrorMsg: string = null;
  allowDelete: boolean;
  scrollId: string;
  filterQuery: string;
  dataModelList: any[];
  dataModelId: string;
  showFilter: boolean = true;
  showAdvancedFilter: boolean = false;
  showBulkFilter: boolean = false;
  showEntityList: boolean = false;
  noResults: boolean = false;
  configIssues: boolean = false;

  otherColumns: any[] = [];
  displayedColumns: Observable<any>;
  columnList = [];
  private sort: MatSort;
  private paginator: MatPaginator;
  private table: MatTable<any>;
  notNeededFields: any = [];
  sortBy = 'name';
  sortOrder = 'asc';
  dropDownOpened: boolean = false;
  sortedDiaplayColumns = [];
  searchColumnList = [];
  recordSearchMapList: RecordSearchMap[] = [];
  searchColRecord:RecordSearchMap = new RecordSearchMap();
  recordFilterList: RecordSearchMap[] = [];
  defaultSearchMap: RecordSearchMap;
  entitySearchEnabled: boolean = false;
  entitySearchCriteria: any = {};
  sortDetails:any={};
  selectedEntityLabel: string;
  businessKeyField: string = '';
  entityCount: number;
  pageNo: number = 1;
  bsModelDelete: BsModalRef;
  bsModalSaveFilter: BsModalRef;
  displayinCard = [];
  navigationSubscriber: Subscription;
  // User Preferences
  savedFilterDetails: any;
  savedFilterList: any[] = [];
  filterRecord:any;
  savedFilterUserPref: UserPreferences;
  extraColumns: any[] = [];
  count = 0;
  maxValue:any ;
  minValue:any ;
  maxFloatValue:any;
  minFloatValue:any;
  minLongValue:any;
  maxLongValue:any;
  extraDivToShow: string = '';
  statusArray = [
    { label: 'Open', value: 'Open' },
    { label: 'In Progress', value: 'In Progress' },
    { label: 'Resolved', value: 'Resolved' },
    { label: 'Closed', value: 'Closed' },
    { label: 'Re-Open', value: 'ReOpen' }
  ];
  priorityArray = [
    { label: 'Low', value: 'Low', img: 'Low.svg' },
    { label: 'Medium', value: 'Medium', img: 'Medium.svg' },
    { label: 'High', value: 'High', img: 'High.svg' },
    { label: 'Critical', value: 'Critical', img: 'Critical.svg' }
  ];

  dropdownSettingsMultiple: IDropdownSettings = {};

  @ViewChild(MatSort, { static: false }) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }
  @ViewChild('table', { static: false }) set MatTable(tb: MatTable<any>) {
    this.table = tb;
  };
  @ViewChild(MatPaginator, { static: false }) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  noRecordsFound: boolean = true;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private communicationService: CommunicationService,
    private dataModelService: DataModelService,
    private entityService: EntityService,
    private exportService: ExportService,
    private importService: ImportService,
    private accessControlService: AccessControlService,
    private universalUser: UniversalUser,
    private stateService: StateService,
    private snackBar: MatSnackBar,
    private modalService: BsModalService,
    private dataSharingService: DataSharingService,
    private routeService: RouteService,
    private _location: Location,
    private entiySharingService: EntiySharingService,
    @Inject(PreferenceService) private prefService: PreferenceService,
    private joyrideService:JoyrideService
  ) {
    this.entityList = [];
    this.dataModelList = [];
    this.selectedEntity = null;
    this.selectedDataModel = null;
    this.entityTabclass = {};
    this.notNeededFields = [];
    this.scrollId = null;
    this.filterQuery = '';
    this.rows = 10;
    this.columns = 5;
    this.dropdownSettingsMultiple = {
      singleSelection: false,
      idField: 'value',
      textField: 'label',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    this.defaultSearchMap = new RecordSearchMap();
    this.navigationSubscriber = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        this.otherColumns=[];
        if (this.route.snapshot.paramMap.get('dataModelName') != null) {
          this.selectedDataModel = null;
          this.count = 0;
          this.initFunctions();
        } else {
          this.showEntityList = false;
          this.initFunctions();
        }
      }
    });
  }

  ngOnInit() {
    this.initFunctions();
  }
  ngAfterViewInit() {
    if (this.communicationService.formFilter) {
      this.recordSearchMapList = this.communicationService.formFilter;
      this.entitySearchCriteria = this.communicationService.entitySearchCriteria;
    }
  }
  initFunctions() {
    this.count++;
    this.userId = this.universalUser.getUser()._id;
    this.pageNumber = 0;
    this.fetchRecords = 50;
    this.selectedEntity = this.route.snapshot.paramMap.get('dataModelName');
    this.dataModelId = this.route.snapshot.paramMap.get('dataModelId');
    this.getFilterPreferences();
    this.applyReset();
    if (this.count == 1) {
      this.communicationService.formFilter = null;
      this.communicationService.entitySearchCriteria = null;
      this.getSelectedDatamodel();
    }
  }
  goBack() {
    this._location.back();
  }

  ngOnDestroy() {
    if (this.navigationSubscriber) {
      this.navigationSubscriber.unsubscribe();
    }
  }
  applyReset(flag?) {
    this.entitySearchEnabled = false;
    this.entitySearchCriteria.searchText = "";
    this.entityList = [];
    this.recordSearchMapList = [];
    this.recordFilterList = [];
    this.minValue = undefined;
    this.maxValue = undefined;
    this.minFloatValue=undefined;
    this.maxFloatValue=undefined;
    this.minLongValue=undefined;
    this.maxLongValue=undefined;
  
    if (flag == true) {
      this.applySearchFilter() // THIS IS DUPLICATE CALL-------------------------->>>>>
    }
  }
  nameCircArray: any[] = [];
  availableGroupFilterOptions: any[] = [];
  availableStatusFilterOptions: any[] = [];
  availablePriorityFilterOptions: any[] = [];
  isStatusFilter: boolean = false;
  isPriorityFilter: boolean = false;
  showSavedFilterList: boolean = false;
  getSelectedDatamodel() {
    if (!this.selectedDataModel) {
      this.nameCircArray = [];
      this.availableGroupFilterOptions = [];
      this.availableStatusFilterOptions = [];
      this.availablePriorityFilterOptions = [];
      this.columnList = [];
      this.dataModelService.getDataModel(this.dataModelId)
        .subscribe(
          datamodel => {
            if (datamodel) {
              this.selectedEntityLabel = datamodel.label;
              this.selectedDataModel = datamodel;
              this.setAccessOfUserForDataModel(this.selectedDataModel);
              if(datamodel.fields && datamodel.fields.length > 0){
                this.sortedDiaplayColumns = datamodel.fields.sort((a, b) => { return a.sortOrder - b.sortOrder }).map(item => item.name);
                for (let field of datamodel.fields) {
                  if (field.hide || field.uiProperties.useDHTML || field.type == "MODEL" || field.type == "LOOKUP_REFERENCE") {
                    this.notNeededFields.push(field.name);
                  }
                  switch (field.type) {
                    case 'USER':
                      this.nameCircArray.push({
                        name: field.name,
                        label: field.label,
                        recordType: 'USER',
                        value: []
                      })
                      this.dataModelService.getValuesForListingFilter(this.entitySearchCriteria, this.selectedDataModel.name, 'USER').subscribe(
                        response => {
                          for (let index = 0; index < this.nameCircArray.length; index++) {
                            let element = this.nameCircArray[index];
                            let pos = response.map(itm => itm.name).indexOf(element.name);
                            if (pos > -1) {
                              element.value = response[pos].value
                            }

                          }
                        }
                      );
                      break;
                    case 'GROUP':
                      this.availableGroupFilterOptions.push({
                        name: field.name,
                        label: field.label,
                        recordType: 'GROUP',
                        value: []
                      })
                      this.dataModelService.getValuesForListingFilter(this.entitySearchCriteria, this.selectedDataModel.name, 'GROUP').subscribe(
                        response => {
                          for (let index = 0; index < this.availableGroupFilterOptions.length; index++) {
                            let element = this.availableGroupFilterOptions[index];
                            let pos = response.map(itm => itm.name).indexOf(element.name);
                            if (pos > -1) {
                              element.value = response[pos].value
                            }

                          }
                        }
                      );
                      break;
                    case 'STATUS':
                      this.availableStatusFilterOptions.push({
                        name: field.name,
                        label: field.label,
                        recordType: 'STATUS',
                        value: []
                      })
                      this.dataModelService.getValuesForListingFilter(this.entitySearchCriteria, this.selectedDataModel.name, 'STATUS').subscribe(
                        response => {
                          for (let index = 0; index < this.availableStatusFilterOptions.length; index++) {
                            let element = this.availableStatusFilterOptions[index];
                            let pos = response.map(itm => itm.name).indexOf(element.name);
                            if (pos > -1) {
                              element.value = response[pos].value
                            }

                          }
                        }
                      );
                      this.isStatusFilter = true
                      break;
                    case 'PRIORITY':
                      this.availablePriorityFilterOptions.push({
                        name: field.name,
                        label: field.label,
                        recordType: 'PRIORITY',
                        value: []
                      });
                      this.dataModelService.getValuesForListingFilter(this.entitySearchCriteria, this.selectedDataModel.name, 'PRIORITY').subscribe(
                        response => {
                          for (let index = 0; index < this.availablePriorityFilterOptions.length; index++) {
                            let element = this.availablePriorityFilterOptions[index];
                            let pos = response.map(itm => itm.name).indexOf(element.name);
                            if (pos > -1) {
                              element.value = response[pos].value
                            }

                          }
                        }
                      );
                      this.isPriorityFilter = true
                      break;
                    default:
                      break;
                  }
                }
                this.getIsSearchableList(datamodel.fields);
                // this.getCardDisplayParam(datamodel.fields);
                if (this.communicationService.formFilter) {
                  this.recordSearchMapList = this.communicationService.formFilter;
                } else {
                  let searchMap = new RecordSearchMap();
                  this.recordSearchMapList.push(searchMap);
                  for (let item of this.recordSearchMapList) {
                    if (item.recordType === "") {
                      item.recordType = "TEXT"
                    }
                  }
                  // 
                }
                this.getParameterizedEntitylList();
              }else{
                this.configIssues = true
              }
            }
          },
          error=>{
            if(error && error.error && error.error.error == "No DataModel found with provided id."){
              this.getDatamodelByName(this.selectedEntity);
             
            }
            // console.log(error.error.error,this.selectedEntity)
          }
      );
    }
  }
  getDatamodelByName(datamodelName){
    this.dataModelService.getDataModelByName(datamodelName).subscribe(
      (datamodel)=>{
        if (datamodel && datamodel.fields && datamodel.fields.length > 0) {
          this.selectedEntityLabel = datamodel.label;
          this.selectedDataModel = datamodel;
          this.setAccessOfUserForDataModel(this.selectedDataModel);
          if(datamodel.fields && datamodel.fields.length > 0){
            this.sortedDiaplayColumns = datamodel.fields.sort((a, b) => { return a.sortOrder - b.sortOrder }).map(item => item.name);
            for (let field of datamodel.fields) {
              if (field.hide || field.uiProperties.useDHTML || field.type == "MODEL" || field.type == "LOOKUP_REFERENCE") {
                this.notNeededFields.push(field.name);
              }
              switch (field.type) {
                case 'USER':
                  this.nameCircArray.push({
                    name: field.name,
                    label: field.label,
                    recordType: 'USER',
                    value: []
                  })
                  this.dataModelService.getValuesForListingFilter(this.entitySearchCriteria, this.selectedDataModel.name, 'USER').subscribe(
                    response => {
                      for (let index = 0; index < this.nameCircArray.length; index++) {
                        let element = this.nameCircArray[index];
                        let pos = response.map(itm => itm.name).indexOf(element.name);
                        if (pos > -1) {
                          element.value = response[pos].value
                        }

                      }
                    }
                  );
                  break;
                case 'GROUP':
                  this.availableGroupFilterOptions.push({
                    name: field.name,
                    label: field.label,
                    recordType: 'GROUP',
                    value: []
                  })
                  this.dataModelService.getValuesForListingFilter(this.entitySearchCriteria, this.selectedDataModel.name, 'GROUP').subscribe(
                    response => {
                      for (let index = 0; index < this.availableGroupFilterOptions.length; index++) {
                        let element = this.availableGroupFilterOptions[index];
                        let pos = response.map(itm => itm.name).indexOf(element.name);
                        if (pos > -1) {
                          element.value = response[pos].value
                        }

                      }
                    }
                  );
                  break;
                case 'STATUS':
                  this.availableStatusFilterOptions.push({
                    name: field.name,
                    label: field.label,
                    recordType: 'STATUS',
                    value: []
                  })
                  this.dataModelService.getValuesForListingFilter(this.entitySearchCriteria, this.selectedDataModel.name, 'STATUS').subscribe(
                    response => {
                      for (let index = 0; index < this.availableStatusFilterOptions.length; index++) {
                        let element = this.availableStatusFilterOptions[index];
                        let pos = response.map(itm => itm.name).indexOf(element.name);
                        if (pos > -1) {
                          element.value = response[pos].value
                        }

                      }
                    }
                  );
                  this.isStatusFilter = true
                  break;
                case 'PRIORITY':
                  this.availablePriorityFilterOptions.push({
                    name: field.name,
                    label: field.label,
                    recordType: 'PRIORITY',
                    value: []
                  });
                  this.dataModelService.getValuesForListingFilter(this.entitySearchCriteria, this.selectedDataModel.name, 'PRIORITY').subscribe(
                    response => {
                      for (let index = 0; index < this.availablePriorityFilterOptions.length; index++) {
                        let element = this.availablePriorityFilterOptions[index];
                        let pos = response.map(itm => itm.name).indexOf(element.name);
                        if (pos > -1) {
                          element.value = response[pos].value
                        }

                      }
                    }
                  );
                  this.isPriorityFilter = true
                  break;
                default:
                  break;
              }
            }
            this.getIsSearchableList(datamodel.fields);
            // this.getCardDisplayParam(datamodel.fields);
            if (this.communicationService.formFilter) {
              this.recordSearchMapList = this.communicationService.formFilter;
            } else {
              let searchMap = new RecordSearchMap();
              this.recordSearchMapList.push(searchMap);
              for (let item of this.recordSearchMapList) {
                if (item.recordType === "") {
                  item.recordType = "TEXT"
                }
              }
              // 
            }
            this.getParameterizedEntitylList();
          }else{
            this.configIssues = true
          }
        }
      },
      (error)=>{}
    )
  }
  getEntityCount() {
    this.entityService.getEntityCount(this.selectedEntity, this.entitySearchCriteria)
      .subscribe(
        response => {
          if (response && response.count != null) {
            this.entityCount = response.count;
          }
        },
        error => {

        }
      )
  }
  filterList: any = {};
  addRemoveFilters(value, key, recordType?) {
    if (!this.filterList[key]) {
      this.filterList[key] = [];
    }
    let pos = this.filterList[key].indexOf(value);
    if (pos == -1) {
      this.filterList[key].push(value);
    } else {
      this.filterList[key].splice(pos, 1);
    }
    this.recordFilterList = [];
    for (const key in this.filterList) { // Check against eachkey in filter
      if (Object.prototype.hasOwnProperty.call(this.filterList, key)) {
        if (this.filterList[key].length > 0) {
          let tmp = new RecordSearchMap()
          tmp.recordSearchColumn = key;
          tmp.recordColumnValue = this.filterList[key];
          tmp.recordType = recordType;
          this.recordFilterList.push(tmp);
        }
      }
    }
    this.applySearchFilter()
  }
  setDataSourceAttributes() {
    // this.entityList.sort = this.sort;
    // if (this.paginator && this.sort) {
    //   this.applyFilter('');
    // }
  }

  applyFilter(filterValue: string) {
    this.entityList.filter = filterValue.trim().toLowerCase();
  }

  onDocumentUpload(event) {
  this.importService.onDocumentUpload(event)
    .then(jsonData => {
      this.entityService.bulkSave(this.selectedEntity, jsonData);
      })
    .catch(error => {
      console.error(error);
      });
}

  exportAsExcel() {
    // this.exportService.exportTableExcel(this.table['_elementRef'].nativeElement, this.selectedEntity);
    // this.exportService.exportTableExcel(this.selectedEntity,this.entityCount);
    if (this.entityCount < 1001) {
      this.entityService.getParameterizedEntityList(this.selectedEntity, 0, this.entityCount, this.entitySearchCriteria).subscribe(
        list => {
          this.exportService.exportAllExcel(this.selectedEntity, list);
        }
      );
    } else {
      this.snackBar.open("Maximum of 1000 records can be exported. Please filter your results ", "Dismiss", {
        duration: 5000
      })
    }
  }
  
  getParameterizedEntitylList(append?) {
    let conditions = {}
    if (this.recordSearchMapList && this.recordSearchMapList.length > 0) {
      for (let col in this.recordSearchMapList) {
        let columnDetails = this.recordSearchMapList[col];
        if (!columnDetails.recordSearchColumn || columnDetails.recordSearchColumn.trim().length == 0) {
          continue;
        }

        if (columnDetails.recordType == "BOOLEAN" || columnDetails.recordType == "CHECKBOX") {
          conditions[columnDetails.recordSearchColumn] = JSON.parse(columnDetails.recordColumnValue);
        } else if (columnDetails.recordType == "DATETIME") {
          conditions[columnDetails.recordSearchColumn] = { "$gte": columnDetails.fromDate, "$lte": columnDetails.toDate };
        } else if (columnDetails.recordType == "DATE") {
          conditions[columnDetails.recordSearchColumn] = { "$gte": columnDetails.fromDate, "$lte": columnDetails.toDate };
        } else if ((columnDetails.recordType == "MULTI_SELECT" || columnDetails.recordType == "LOOKUP_REFERENCE") && columnDetails.recordColumnValue && columnDetails.recordColumnValue.length > 0) {
          const values: string[] = [];
          for (let value of columnDetails.recordColumnValue) {
            values.push(value.value);
          }
          conditions[columnDetails.recordSearchColumn] = { "$in": values };
        } else if (columnDetails.recordType == "INT") {
          conditions[columnDetails.recordSearchColumn] = { "$gte": columnDetails.minRange,"$lte": columnDetails.maxRange};
        } else if (columnDetails.recordType == "FLOAT") {
          conditions[columnDetails.recordSearchColumn] = { "$gte": columnDetails.minRange, "$lte": columnDetails.maxRange };
        } else if (columnDetails.recordType == "LONG") {
          conditions[columnDetails.recordSearchColumn] = { "$gte": columnDetails.minRange, "$lte": columnDetails.maxRange };
        } else {
          conditions[columnDetails.recordSearchColumn] = { "$regex": columnDetails.recordColumnValue, "$options": "i" };
        }
      }
    }
    if (this.recordFilterList && this.recordFilterList.length > 0) {
      for (let index = 0; index < this.recordFilterList.length; index++) {
        let columnDetails = this.recordFilterList[index];
        if (!columnDetails.recordSearchColumn || columnDetails.recordSearchColumn.trim().length == 0) {
          continue;
        }
        conditions[columnDetails.recordSearchColumn] = { "$in": columnDetails.recordColumnValue };
      }
    }
    this.entitySearchCriteria = { "criteria": conditions, searchText: this.entitySearchCriteria.searchText, sort:this.sortDetails };
    if (this.entitySearchCriteria.searchText == "") this.entitySearchCriteria.searchText = null
    this.entityService.getParameterizedEntityList(this.selectedEntity, this.pageNumber, this.fetchRecords, this.entitySearchCriteria).subscribe(
      list => {
        this.getEntityCount();
        if (list && list.length > 0) {
          this.noRecordsFound = false;
          if (append == 'append') {
            this.entityList = this.entityList.concat(list)
          } else {
            this.entityList = list;
            this.getColumnList(list)
          }

        }
        else {
          this.noRecordsFound = true;
          this.entityList.data = [];
          this.entityListCard = [];
          this.snackBar.open("records not found", "Dismiss", {
            duration: 5000
          })
        }
      }
    );
  }

  getColumnList(list) {
    if(this.otherColumns && this.otherColumns.length==0){
      this.otherColumns = [];
      if (list && list.length > 0) {
        let refRecord = list[0];
        let notNeeded = ['companyId', 'createdAt', 'createdBy', 'dueDate', 'updatedBy', 'machineType', 'statusCd', 'updatedAt', '_entityName', '_id', 'datamodelId', '_lookup_reference_id', '_lookup_reference_label', 'flowId', 'stageCd', 'updateByMachineType', 'customerId', 'lastKnownPage', 'pageProgress', 'pageFinished', '_carouselLastKnownIndex', '_carouselProgress', '_carouselFinished', '_lastKnownVideoDuration', '_videoFinished'];
        if (this.notNeededFields.length > 0) {
          notNeeded = notNeeded.concat(this.notNeededFields);
        }
        let entityFields = this.selectedDataModel.fields.map(item => item.name);
        for (let indexField = 0; indexField < entityFields.length; indexField++) {
          let key = entityFields[indexField];
          let pos = notNeeded.indexOf(key);
          if (pos == -1 && (this.selectedDataModel.fields[indexField]['type'] != 'TEXTAREA' || this.selectedDataModel.fields[indexField]['type'] != 'HTML_VIEWER' || this.selectedDataModel.fields[indexField]['businessKey'])) {
            this.otherColumns.push({
              "name": key,
              "label": this.capitalize(key),
              "value": true,
              "sortActivated": false,
              "sortDirection": 1,
              "showInList": this.selectedDataModel.fields[indexField].uiProperties.showInList ? true : false,
              "cardHeader": this.selectedDataModel.fields[indexField].uiProperties.cardHeader ? true : false,
              "type": this.selectedDataModel.fields[indexField]['type'],
              "inputSource": this.selectedDataModel.fields[indexField]['inputSource'],
              "otherFieldProperties": this.selectedDataModel.fields[indexField]
            });

          }
        }
        this.otherColumns.sort((a, b) => {
          if (a.otherFieldProperties.sortOrder > b.otherFieldProperties.sortOrder) {
            return 1;
          } else if (a.otherFieldProperties.sortOrder < b.otherFieldProperties.sortOrder) {
            return -1;
          } else {
            return 0;
          }
        });
        // this.otherColumns.sort((a, b) => {
        //   if(a.uiProperties.showInList && b.uiProperties.showInList){
        //     if (a.uiProperties.showInList > b.uiProperties.showInList) {
        //       return 1;
        //     } else if (a.uiProperties.showInList < b.uiProperties.showInList) {
        //       return -1;
        //     } else {
        //       return 0;
        //     }
        //   }else{
        //     return 0;
        //   }
        // });
        this.otherColumns.sort(function (x, y) {
          return (x.showInList === y.showInList) ? 0 : x.showInList ? -1 : 1;
        });
        this.otherColumns.sort(function (x, y) {
          return (x.businessKey === y.businessKey) ? 0 : x.businessKey ? -1 : 1;
        });
        this.otherColumns.sort(function (x, y) {
          return (x.cardHeader === y.cardHeader) ? 0 : x.cardHeader ? -1 : 1;
        });

        if (this.isPriorityFilter) {
          let pos = this.otherColumns.map(itm => itm.type).indexOf('PRIORITY');
          this.otherColumns.splice(pos, 1)
        }
        let splitForDisplay = JSON.parse(JSON.stringify(this.otherColumns))
        this.otherColumns = [];
        for (let i = 0; i < splitForDisplay.length; i++) {
          if (i < 5) {
            this.otherColumns.push(splitForDisplay[i]);
          } else {
            this.extraColumns.push(splitForDisplay[i])
          }
        }
        // let sorting = [];
        // for (let index = 0; index < this.otherColumns.length; index++) {
        //   const dispEl = this.otherColumns[index];

        //   if (this.otherColumns.indexOf(dispEl) > -1) {
        //     sorting[index] = this.otherColumns[this.otherColumns.indexOf(dispEl)];
        //     this.otherColumns.splice(this.otherColumns.indexOf(dispEl), 1)
        //   }
        //   this.otherColumns = this.otherColumns.filter(function (el) {
        //     return el != null;
        //   });
        // }
        this.getDisplayedColumns();
      }
    }
  }
  getDisplayedColumns() {
    let columnsToDisplay: any[] = [];
    let currUserPref = this.universalUser.getUserPref();
    if (currUserPref && currUserPref.map(item => item.identifier).indexOf(this.selectedEntity) > -1) {
      let currList = currUserPref[currUserPref.map(item => item.identifier).indexOf(this.selectedEntity)].preferences.columns;
    //   if (currList.length > 0) {
    //     columnsToDisplay = currList;
    //   } else {
    //     columnsToDisplay = this.otherColumns.map(item => item.name);
    //   }
    //   // code is to remove duplicate columns
    //   let tmp = columnsToDisplay.filter((value, index, self) => {
    //     return self.indexOf(value) === index;
    //   })
    //   columnsToDisplay = tmp;
    //   this.columnList = this.otherColumns;
    //   this.columnList.map(itm => {
    //     if (currList.length > 0) {
    //       let pos = currList.indexOf(itm.name);
    //       if (pos > -1) {
    //         itm.value = true
    //       } else {
    //         itm.value = false;
    //       }
    //     } else {
    //       itm.value = false;
    //     }

    //   })
    //   // for (let index = 0; index < columnsToDisplay.length; index++) {
    //   //   const element = columnsToDisplay[index];
    //   //   if (this.columnList.map(col => col.name).indexOf(element) > -1) {
    //   //     this.columnList[this.columnList.map(col => col.name).indexOf(element)]['value'] = true
    //   //   }
    //   // }
    } else {
      columnsToDisplay = this.otherColumns;
      // code is to remove duplicate columns
      let tmp = columnsToDisplay.filter((value, index, self) => {
        return self.indexOf(value) === index;
      })
      this.columnList = this.otherColumns;
      this.columnList.map(itm => {
        itm.value = true;
      })
      columnsToDisplay = tmp;
    }
    if (columnsToDisplay.map(item => item.name).indexOf('createdAt') == -1) {
      columnsToDisplay.push({ "name": 'createdAt', "label": 'Created At', "value": true, "sortActivated": false, "sortDirection": 1, "type": "DATE", "showInList":false, "cardHeader":false });
    }
    if (columnsToDisplay.map(item => item.name).indexOf('updatedAt') == -1) {
      columnsToDisplay.push({ "name": 'updatedAt', "label": 'Updated At', "value": true, "sortActivated": false, "sortDirection": 1, "type": "DATE", "showInList":false, "cardHeader":false });
    }
    // if(columnsToDisplay.map(item=>item.name).indexOf('statusCd')==-1){
    //   columnsToDisplay.push({"name": 'statusCd',"label": 'Status',"value": true,"sortActivated":false,"sortDirection":1,"type":"STAT"});
    // }
    if (columnsToDisplay.map(item => item.name).indexOf('actions') == -1) {
      columnsToDisplay.push({ "name": 'actions', "label": 'Actions', "value": true, "sortActivated": false, "sortDirection": 1, "type": "ACTION", "showInList":false, "cardHeader":false });
    }

    this.displayedColumns = new Observable<any>((observer: Observer<any>) => {
      observer.next(columnsToDisplay)
    });

  }
  getExtraColumns() {
    return this.extraColumns
  }
  // AccessControl
  setAccessOfUserForDataModelList() {
    this.accessControlService.getUserAccessByComponentType("ENTITY")
      .subscribe(
        accessControlList => {
          if (!accessControlList || accessControlList.length == 0) {
            for (let dataModel of this.dataModelList) {
              this.setAccessControlParametersForDataModel(null, dataModel);
            }
            return;
          }
          for (let dataModel of this.dataModelList) {
            for (let accessControl of accessControlList) {
              if (accessControl.componentName == dataModel.name) {
                this.setAccessControlParametersForDataModel(accessControl, dataModel);
              }
            }
          }
        },
        error => {

        }
      );
  }

  setAccessControlParametersForDataModel(accessControl: AccessControlResponse, dataModel: DataModel) {
    if (!accessControl || accessControl.operationAccessList == null || accessControl.operationAccessList.length == 0) {
      dataModel.allowCreate = true;
      dataModel.allowUpdate = true;
      dataModel.allowDelete = true;
      return;
    }
    if (accessControl.operationAccessList) {
      for (let operation of accessControl.operationAccessList) {
        if (operation.operationName == "Create" && operation.enabled) {
          dataModel.allowCreate = true;
        }
        if (operation.operationName == "Update" && operation.enabled) {
          dataModel.allowUpdate = true;
        }
        if (operation.operationName == "Delete" && operation.enabled) {
          dataModel.allowDelete = true;
        }
        if (operation.operationName == "View" && operation.enabled) {
          dataModel.allowView = true;
        }
      }
    }
  }

  setAccessOfUserForDataModel(dataModel: DataModel) {
    let accessControlReq = new AccessControlRequest();
    accessControlReq.componentType = "ENTITY";
    accessControlReq.componentName = dataModel.name;
    accessControlReq.userId = this.userId;
    this.accessControlService.getUserAccess(accessControlReq)
      .subscribe(
        accessControl => {
          this.setAccessControlParametersForDataModel(accessControl, dataModel);
        });
  }
  capitalize = (s) => {
    if (typeof s !== 'string') return ''
    let tempStr = s.charAt(0).toUpperCase() + s.slice(1)
    return tempStr.split(/(?<![A-Z])(?=[A-Z])/).join(" ");
  }
  formattedDate(dateVal) {
    let newDate = new Date(dateVal);

    let sMonth = this.padValue(newDate.getMonth() + 1);
    let sDay = this.padValue(newDate.getDate());
    let sYear = newDate.getFullYear();
    let sHour = newDate.getHours() + "";
    let sMinute = this.padValue(newDate.getMinutes());
    let sAMPM = "AM";

    var iHourCheck = parseInt(sHour);

    if (iHourCheck > 12) {
      sAMPM = "PM";
      sHour = +iHourCheck - 12 + "";
    }
    else if (iHourCheck === 0) {
      sHour = "12";
    }

    sHour = this.padValue(sHour);

    // return sMonth + "-" + sDay + "-" + sYear + " " + sHour + ":" + sMinute + " " + sAMPM;
    return sMonth + "-" + sDay + "-" + sYear;
  }
  padValue(value) {
    return (value < 10) ? "0" + value : value;
  }
  readableField(key, value) {
    if (key == 'createdAt') {
      return "<strong>Created On: </strong><br>" + this.formattedDate(value)
    } else {
      if (typeof value != 'string') {
        let str = "";
        if (value == 'undefined') {
          str = ("" + value + "")
        }
        else if (typeof value == 'number') {
          str = ("" + Math.round(value * 100) / 100 + "");
        }
        else {
          str = ("-")
        }
        return "<strong>" + this.capitalize(key) + ": </strong><br>" + str
      } else {
        if (typeof value == 'string' && value.indexOf("http") == -1) {
          let str = "";
          if (value.length > 20) {
            str = ("<div class='ellipsisExpand'>" + value + "</div>")
          } else {
            str = (value)
          }

          return "<strong>" + this.capitalize(key) + ": </strong><br>" + str
        } else {
          if (typeof value == 'string' && value.indexOf("<a") > -1) {

            return "<strong>" + this.capitalize(key) + ": </strong><br>" + value
          } else {
            let str = "";
            if (this.isValidDate(value) == true) {
              str = this.formattedDate(value);
            } else {

              if (value.length > 20) {
                str = ("<div class='ellipsisExpand'>" + value + "</div>")
              } else {
                str = (value)
              }
              return "<strong>" + this.capitalize(key) + ": </strong><br>" + str
            }
          }
        }
      }
    }
  }
  isValidDate(d) {
    var timestamp = Date.parse(d);

    if (isNaN(timestamp) == false) {
      return true
    } else {
      return false
    }
  }
  getHumanReadableValue(entity, column) {
    if (column.type == 'FILE') {
      
      if(entity[column.name]){
        if (entity[column.name] && entity[column.name].fileName) {
          return entity[column.name].fileName;
        } else if (entity[column.name].userFileName) {
          return entity[column.name].userFileName;
        }else if(column.otherFieldProperties.list){
          let Op="";
          for (let index = 0; index < entity[column.name].length; index++) {
            const element = entity[column.name][index];
            if(element.fileName){
              Op += element.fileName +",";
            }else if(element.userFileName){
              Op += element.userFileName +",";
            }else if(element.length>0){
              Op += element.map(item=>item.fileName).join(", ") + ", ";
            }
          }
          return Op;
        }
      }
      return JSON.stringify(entity[column.name])
    }else{
      return "-";
    }

  }
  chosenEntityList: any[] = [];
  checkIfChosen(entityId) {
    if (entityId == 'all') {
      return this.entityList.length == this.chosenEntityList.length;
    } else {
      if (this.chosenEntityList.indexOf(entityId) > -1) {
        return true;
      } else {
        return false;
      }
    }
  }
  chooseEntity(entityId) {
    if (entityId == 'all') {
      if (this.entityList.length == this.chosenEntityList.length) {
        this.chosenEntityList = [];
      } else {
        this.chosenEntityList = this.entityList.map(item => item._id);
      }
    } else {
      let pos = this.chosenEntityList.indexOf(entityId);
      if (pos == -1) {
        this.chosenEntityList.push(entityId);
      } else {
        this.chosenEntityList.splice(pos, 1);
      }
    }
  }
  addActivity(entityName?: string) {
    this.communicationService.setEntity(null);
    this.communicationService.setDataModelName(this.selectedEntity);
    this.getDataModelDetails(this.dataModelId);
  }
  viewEntity(entity) {
    if (entity["machineType"] != null && entity["machineType"] != undefined && entity["machineType"] != "") {
      this.getAssociatedTask(entity);
    }
    else {
      this.communicationService.setEntity(entity);
      this.communicationService.setSelectedDataModelId(this.dataModelId);
      this.communicationService.setDataModelName(this.selectedEntity);
      this.getDataModelDetails(this.dataModelId);
    }

  }

  duplicateEntity(entity: any) {
    this.communicationService.setDuplicateEntity(true);
    this.communicationService.setEntity(entity);
    this.communicationService.setSelectedDataModelId(this.dataModelId);
    this.communicationService.setDataModelName(this.selectedEntity);
    this.getDataModelDetails(this.dataModelId);
  }
  getDataModelDetails(dataModelid: string) {
    this.redirectionToActivitiesComponent(this.selectedDataModel['showWizardMode']);
    // this.dataModelService.getDataModel(dataModelid)
    //   .subscribe(
    //     datamodeldetails => {
    //       this.redirectionToActivitiesComponent(datamodeldetails['showWizardMode']);
    //     }
    //   )
  }
  redirectionToActivitiesComponent(isWizardUI: boolean) {
    let tmpEnt = JSON.parse(JSON.stringify(this.communicationService.getEntity()));
    if (tmpEnt == null) {
      if (isWizardUI) {
        this.router.navigate(['/form-detail-v2', this.selectedEntity, 'new']);
      }
      else {
        this.router.navigate(['/form-detail', this.selectedEntity, 'new']);
      }
    } else {
      if (isWizardUI) {
        this.router.navigate(['/form-detail-v2', tmpEnt._entityName, tmpEnt._id]);
      }
      else {
        this.router.navigate(['/form-detail', tmpEnt._entityName, tmpEnt._id]);
      }
    }
  }
  getAssociatedTask(entity) {

    this.stateService.getAssociatedStateForEntity(entity["_id"], entity["machineType"])
      .subscribe(stateInstanceIdResponse => {
        if (stateInstanceIdResponse.stateInstanceId != null) {
          entity["stateInstanceId"] = stateInstanceIdResponse.stateInstanceId;
        }
        this.communicationService.setEntity(entity);
        this.communicationService.setSelectedDataModelId(this.dataModelId);
        this.communicationService.setDataModelName(this.selectedEntity);

        this.getDataModelDetails(this.dataModelId);
      });
  }
  showExtraDiv(entityId) {
    if (this.extraDivToShow == entityId) { this.extraDivToShow = '' }
    else { this.extraDivToShow = entityId }
  }
  addSearchColumn() {
    let searchMap = new RecordSearchMap();
    this.recordSearchMapList.push(searchMap);
    for (let item of this.recordSearchMapList) {
      if (item.recordType === "") {
        item.recordType = "TEXT"
      }
    }
  }

  deleteSearchColumn(index) {
    this.recordSearchMapList.splice(index, 1);
  }
  getIsSearchableList(datamodel) {
    this.searchColumnList = [];
    this.searchColumnList.push({
      "name": "createdAt",
      "label": "CreatedAt",
      "type": "DATE",
      "inputSource": ""
    });
    const allowedSearchType = ["TEXT", "TEXTAREA", "FLOAT", "INT", "LONG", "DATE", "BOOLEAN", "SINGLE_SELECT", "MULTI_SELECT", "CHECKBOX", "DATETIME", "LOOKUP_REFERENCE"];
    for (let item of datamodel) {
      if ((item.uiProperties.isSearchable || item.businessKey) && allowedSearchType.includes(item.type)) {
        this.searchColumnList.push({
          "name": item.name,
          "label": this.capitalize(item.label),
          "type": item.type,
          "inputSource": item.inputSource,
          "inputSourceRoute": item.inputSourceRoute
        });
      }
    }
  }
  getCardDisplayParam(datamodel) {
    this.displayinCard = [];
    for (let item of datamodel) {
      if ((item.uiProperties.showInList || item.businessKey)) {
        let cardHeader = "";
        cardHeader = item.cardHeader;
        this.displayinCard.push({
          "name": item.name,
          "label": this.capitalize(item.label),
          "type": item.type,
          "inputSource": item.inputSource,
          "cardHeader": cardHeader
        });
        this.displayinCard.sort(function (x, y) {
          return (x.cardHeader === y.cardHeader) ? 0 : x.cardHeader ? -1 : 1;
        });
      }
    }
  }
  checkDatapointDetails(event, i) {
    let tempColumnName = event;
    for (let item of this.searchColumnList) {
      if (tempColumnName == item.name) {
        this.setInputSource(this.recordSearchMapList[i], item);

      }
    }
  }

  setInputSource(recordSearchMap: RecordSearchMap, item: any) {
    recordSearchMap.recordType = item.type;
    if (item.type == "BOOLEAN") {
      recordSearchMap.inputSource = ["null", true, false];
    }
    else if (item.type == "SINGLE_SELECT") {
      recordSearchMap.inputSource = item.inputSource;
    }
    else if (item.type == "MULTI_SELECT") {
      recordSearchMap.inputSource = [];
      if (item.inputSource) {
        for (let value of item.inputSource) {
          recordSearchMap.inputSource.push({ label: value, value: value });
        }
      }
    }
    else if (item.type == "CHECKBOX") {
      recordSearchMap.inputSource = ["null", true];
    }
    else if (item.type == "LOOKUP_REFERENCE") {
      this.progressBarFlag = true;
      if (this.selectedDataModel && this.selectedDataModel.fields) {
        for (let field of this.selectedDataModel.fields) {
          if (field.type && field.type == "LOOKUP_REFERENCE" && field.name == item.name && field.referenceModel) {
            this.entityService.getLookupRefIds(field.referenceModel)
              .subscribe(
                response => {
                  recordSearchMap.inputSource = []
                  if (response) {
                    for (let data of response) {
                      recordSearchMap.inputSource.push({ label: data.businessValue, value: data._id });
                    }
                  }

                }
              )
          }
        }
      }
    }
    if (item.inputSourceRoute) {
      this.populateInputSource(item.inputSourceRoute, this.recordSearchMapList, recordSearchMap)
    }
  }


  populateInputSource(routeCd: string, requestPayload: any, recordSearchMap: RecordSearchMap) {
    const payload = {
      "filterData": requestPayload
    }
    this.routeService.executeRoute(routeCd, payload)
      .subscribe(routeResponse => {
        if (routeResponse && routeResponse["payload"] && routeResponse["payload"]["_INPUT_SOURCE"]) {
          recordSearchMap.inputSource = recordSearchMap.inputSource.concat(routeResponse["payload"]["_INPUT_SOURCE"])
        }
      });
  }

  changedRange(event,i,type)
  {
    console.log(event)
    if(type=="INT")
    {
      
        this.recordSearchMapList[i].minRange = this.minValue;
        this.recordSearchMapList[i].maxRange = this.maxValue;
    }
    if(type=="FLOAT")
    {
        this.recordSearchMapList[i].minRange = this.minFloatValue;
        this.recordSearchMapList[i].maxRange = this.maxFloatValue;
    }
    if(type=="LONG")
    {
        this.recordSearchMapList[i].minRange = this.minLongValue;
        this.recordSearchMapList[i].maxRange = this.maxLongValue;
    }

  }

  onDateChanged(event, i) {
    if (event) {
      if (event.startDate != null && event.endDate != null) {
        this.recordSearchMapList[i].fromDate = new Date(event.startDate).toISOString();
        this.recordSearchMapList[i].toDate = new Date(event.endDate).toISOString();
      }
    }
  }
  onDateChangedCol(event,column,colIndex) {
    if (event) {
      if (event.startDate != null && event.endDate != null) {
        this.searchColRecord.fromDate = new Date(event.startDate).toISOString();
        this.searchColRecord.toDate = new Date(event.endDate).toISOString();
        this.searchColRecord.recordType = 'DATE';
        this.searchCol(column);
let CurrColId = 'colButton' + column.name+colIndex;
const elem = document.getElementById(CurrColId);
elem.dispatchEvent(new Event('click', { bubbles: true }))
      }
    }
  }
  applySearchFilter() {
    if (this.recordSearchMapList.length == 0) {
      this.recordSearchMapList.push(new RecordSearchMap())
    }
    if ((this.entitySearchCriteria.searchText && this.entitySearchCriteria.searchText.trim().length > 0) || (this.recordSearchMapList && this.recordSearchMapList.length > 0)) {
      let flag = true
      if (!this.entitySearchCriteria.searchText || this.entitySearchCriteria.searchText.trim().length == 0) {
        for (let col in this.recordSearchMapList) {
          let columnDetails = this.recordSearchMapList[col];
          if ((!columnDetails.recordColumnValue && !columnDetails.fromDate && !columnDetails.toDate) || !columnDetails.recordSearchColumn) {
            // flag = false;
          }
        }
      }
      this.communicationService.formFilter = this.recordSearchMapList;
      this.communicationService.entitySearchCriteria = this.entitySearchCriteria;
      if (flag) {
        this.entityList = [];
        this.entityListCard = [];
        this.entitySearchEnabled = true;
        this.getParameterizedEntitylList();
      }
      else {
        // this.getEntitylList();
      }
    }
  }
  checkIfPresent(value, type) {
    if (this.filterList && this.filterList[type]) {
      console.log(this.filterList, type, value)
      let pos = this.filterList[type].indexOf(value);
      if (pos > -1) return true;
      return false
    }
  }
  noMoreRecordsFlag: boolean = false;
  loadMore(e: any) {
    if(this.entityList.length == 0){
      // this.getParameterizedEntitylList()
    }else if ((this.entityList.length % 50) == 0) {
      this.pageNumber++;
      this.getParameterizedEntitylList('append')
    } else if (this.entityList.length % 50 < 49) {
      this.noMoreRecordsFlag = true;
      setTimeout(() => {
        this.noMoreRecordsFlag = false;
      }, 3000);
    }
  }
  

  private subscription: Subscription;
  updateStatus(event, selectedEntity) {
    const entity = new Entity();

    entity.fields = this.selectedDataModel.fields;
    entity.label = this.selectedDataModel.label;
    entity.name = this.selectedDataModel.name;
    entity.version = this.selectedDataModel.version;
    entity.process = this.selectedDataModel.process;
    let entityMap = selectedEntity;
    entityMap["statusCd"] = "DRAFT";
    this.subscription = this.entityService.saveEntity(entity, entityMap)
      .subscribe(
        savedEntity => {
          if (savedEntity) {
            this.snackBar.open(this.selectedDataModel.label.toString() + ' has been saved successfully', "Undo", {
              duration: 5000
            })
          }
        },
        error => {
          if (error.error.message) {
            this.snackBar.open(error.error.message, "Undo", {
              duration: 5000
            })
          }
          else {

            this.snackBar.open('Something went wrong please try again in sometime', "Undo", {
              duration: 5000
            })
          }
        }
      );
  }
  bulkUpdatePayload = {
    entityIdList:[],
    entityName:"",
    operationType:"",
    updatedFields:[],
    note:{}
  }
  onAddBulkNote:boolean = false;
  bulkChange(event,whatToChange){
    if(event){
      let pos = this.bulkUpdatePayload.updatedFields.map(item => item.fieldName).indexOf(whatToChange);
      if(pos == -1){
        let tmp = {
          fieldName:whatToChange,
          value:event.value
        }
        this.bulkUpdatePayload.updatedFields.push(tmp)
      }else{
        this.bulkUpdatePayload.updatedFields[pos]['value'] = event.value
      }
    }
    console.log(this.bulkUpdatePayload)
  }
  onUpdateBulk(){
    this.bulkUpdatePayload.entityIdList = this.chosenEntityList;
    this.bulkUpdatePayload.entityName = this.selectedDataModel.name;
    let bulkUpdateServiceCall = this.entityService.bulkUpdateEntities(this.bulkUpdatePayload);
    bulkUpdateServiceCall.subscribe(
      response=>{
        this.chosenEntityList=[];
        this.bulkUpdatePayload = {
          entityIdList:[],
          entityName:"",
          operationType:"",
          updatedFields:[],
          note:{}
        }
        this.applySearchFilter()
        this.snackBar.open("Entities Updated", "Dismiss", {
          duration: 5000
        })
      },
      error=>{
        console.log("Error",error)
      }
    );
    
  }
  onDeleteEntity(templateRef: TemplateRef<any>, entity: any) {
    this.selectedEntityMap = entity;
    if (entity.stateInstanceId) {
      this.deleteEntityErrorMsg = "Entity is attached to process. You can not delete it";
    }
    else {
      this.allowDelete = true;
      this.deleteEntityErrorMsg = "Are you sure you want to delete selected entity?";
    }
    this.bsModelDelete = this.modalService.show(templateRef, { class: 'modal-dialog modal-md modal-dialog-centered' });
  }
  onEntityDeleteCancel() {
    this.selectedEntityMap = null;
    this.bsModelDelete.hide();
  }

  onEntityDeleteConfrimation() {
    this.entityService.deleteEntityById(this.selectedEntity, this.selectedEntityMap._id)
      .subscribe(
        response => {
          const index = this.entityList.indexOf(this.selectedEntityMap);
          if (index != -1) {
            this.entityList.splice(index, 1);
          }
          this.bsModelDelete.hide();

        },
        error => {

        }
      )
  }
  sortColumn(columnName,direction){
    let baseColumns =[];
    this.sortDetails={}; //Remove this if in future we want multiple sortings change below code for changeing sortDirection
    this.displayedColumns.subscribe(
      response=>{baseColumns=response}
    );
    for (let index = 0; index < baseColumns.length; index++) {
      baseColumns[index].sortActivated = false;      
      if(baseColumns[index].name == columnName){
        baseColumns[index].sortActivated = true;
        baseColumns[index].sortDirection = +direction;
      }
    }
    this.sortDetails[columnName] = +direction;
    this.pageNumber = 0;
    this.getParameterizedEntitylList();
  }
  saveFilterModal(confirmSaveTemplate: TemplateRef<any>) {
    this.savedFilterDetails = {};
    this.savedFilterDetails.filterName = "";
    this.bsModalSaveFilter = this.modalService.show(confirmSaveTemplate, { class: 'modal-dialog modal-md modal-dialog-centered' })
  }

  downloadSample() { 
  if (this.selectedDataModel != null) {
        this.importService.downloadTemplate(this.selectedDataModel).then(() => {
        })
          .catch(error => {
            console.error('Error downloading template:', error);
          });
      
      }
  }

  uploadImportTemplate(importTemplate: TemplateRef<any>) {
    this.bsModalSaveFilter = this.modalService.show(importTemplate, { class: 'modal-dialog modal-md modal-dialog-centered' })
  }

  saveFilter() {
    let userPref = this.universalUser.getUserPref();
    if (userPref) {
      for (let i = 0; i < userPref.map(item => item.preferences).length; i++) {
        const element = userPref.map(item => item.preferences)[i];
      }
    }


    if (this.savedFilterDetails.filterName != "") {
      if (this.recordSearchMapList && this.recordSearchMapList.length > 0) {
        let conditions = {}
        for (let col in this.recordSearchMapList) {
          let columnDetails = this.recordSearchMapList[col];
          if (!columnDetails.recordSearchColumn || columnDetails.recordSearchColumn.trim().length == 0) {
            continue;
          }

          if (columnDetails.recordType == "BOOLEAN" || columnDetails.recordType == "CHECKBOX") {
            conditions[columnDetails.recordSearchColumn] = { filterValue: JSON.parse(columnDetails.recordColumnValue), colType: columnDetails.recordType };
          }
          else if (columnDetails.recordType == "DATETIME") {
            conditions[columnDetails.recordSearchColumn] = { filterValue: { "$gte": columnDetails.fromDate, "$lte": columnDetails.toDate }, colType: columnDetails.recordType };
          } else if (columnDetails.recordType == "DATE") {
            conditions[columnDetails.recordSearchColumn] = { filterValue: { "$gte": columnDetails.fromDate, "$lte": columnDetails.toDate }, colType: columnDetails.recordType };
          }
          else if ((columnDetails.recordType == "MULTI_SELECT" || columnDetails.recordType == "LOOKUP_REFERENCE") && columnDetails.recordColumnValue && columnDetails.recordColumnValue.length > 0) {
            const values: any[] = [];
            for (let value of columnDetails.recordColumnValue) {
              values.push({value:value.value, label: value.label});
            }
            conditions[columnDetails.recordSearchColumn] = { filterValue: { "$in": values }, colType: columnDetails.recordType, inputSource:JSON.stringify(columnDetails.inputSource)  };
          }
          else {
            conditions[columnDetails.recordSearchColumn] = { filterValue: { "$in": columnDetails.recordColumnValue}, colType: columnDetails.recordType };
          }
        }

        this.savedFilterDetails.searchText = !this.entitySearchCriteria.searchText ? null : this.entitySearchCriteria.searchText;
        this.savedFilterDetails = {
          filterName: this.savedFilterDetails.filterName,
          filterDetail: { "criteria": conditions, searchText: this.savedFilterDetails.searchText }
        };
        this.savedFilterList.push(this.savedFilterDetails);

        let toSaveList = []
        for (let index = 0; index < this.savedFilterList.length; index++) {
          let element = this.savedFilterList[index];
          toSaveList[index] = JSON.stringify(element) 
        }
        if(this.filterRecord){
          this.savedFilterUserPref = this.filterRecord[0];
          this.savedFilterUserPref.preferences.filterList = (toSaveList)
        }else{
          this.savedFilterUserPref = new UserPreferences()
          this.savedFilterUserPref.screen = "entities";
          this.savedFilterUserPref.component = "LISTING";
          this.savedFilterUserPref.identifier = this.dataModelId;
          this.savedFilterUserPref.preferences = { filterList: (toSaveList) };
        }
        this.getParameterizedEntitylList()
        this.prefService.saveUserPref(this.savedFilterUserPref)
          .subscribe(
            response => {
              this.savedFilterUserPref= response;
              for (let index = 0; index < response.preferences.filterList.length; index++) {
                const element = response.preferences.filterList[index];
                this.savedFilterUserPref.preferences.filterList[index] = JSON.parse(element)  
              }
              this.snackBar.open("Filter Saved", "Dismiss", {
                duration: 5000
              })
              this.bsModalSaveFilter.hide();
            },
            error => {

            }
          )
      }
    } else {
      this.savedFilterDetails.error = "Please Enter Filter Name."
    }
  }
  deleteFilter(filter,index){
    this.savedFilterList.splice(index,1);
    let toSaveList = []
    for (let index = 0; index < this.savedFilterList.length; index++) {
      let element = this.savedFilterList[index];
      toSaveList[index] = JSON.stringify(element) 
    }
    this.savedFilterUserPref = this.filterRecord[0];
    this.savedFilterUserPref.preferences.filterList = (toSaveList)
    this.prefService.saveUserPref(this.savedFilterUserPref)
    .subscribe(
      response => {
        this.savedFilterUserPref= response;
        for (let index = 0; index < response.preferences.filterList.length; index++) {
          const element = response.preferences.filterList[index];
          this.savedFilterUserPref.preferences.filterList[index] = JSON.parse(element)  
        }
        console.log(this.savedFilterUserPref)
        this.snackBar.open("Filter Saved", "Dismiss", {
          duration: 5000
        })
        this.bsModalSaveFilter.hide();
      },
      error => {

      }
    );
  }
  getFilterPreferences() {
    if (this.dataModelId) {
      this.prefService.getUserPrefByScreenAndComponentAndIdentifier('entities', 'LISTING', this.dataModelId).subscribe(
        (response) => {
          if(response && response.length>0){
            this.filterRecord = response;
            let preferences = response.map(item => item.preferences)
            this.savedFilterList = [];
            for (let index = 0; index < preferences.length; index++) {
              const element = preferences[index];
              if (element.filterList) {
                // this.savedFilterList = this.savedFilterList.concat(element.filterList)
                // this.savedFilterList.push(...JSON.parse(element.filterList))
                for (let i = 0; i < element.filterList.length; i++) {
                  const el = element.filterList[i];
                  this.savedFilterList[i] = JSON.parse(el)
                }
              }
            }
          }
        },
        (error) => { },
      );
    }
  }
  applySavedFilter(filter) {
    this.entitySearchCriteria.searchText = filter.filterDetail.searchText;
    let recordSearchMapList: RecordSearchMap[] = [];
    Object.keys(filter.filterDetail.criteria).map(col => {
    let recordSearchMap = new RecordSearchMap();
      let columnValue = filter.filterDetail.criteria[col];
      switch (columnValue.colType) {
        case "DATE":
          recordSearchMap.recordType = columnValue.colType;
          recordSearchMap.recordSearchColumn = col;
          recordSearchMap.recordColumnValue = null;
          recordSearchMap.fromDate = columnValue.filterValue.$gte;
          recordSearchMap.toDate = columnValue.filterValue.$lte;
          break;
        case "DATETIME":
          recordSearchMap.recordType = columnValue.colType;
          recordSearchMap.recordSearchColumn = col;
          recordSearchMap.recordColumnValue = null;
          recordSearchMap.fromDate = columnValue.filterValue.$gte;
          recordSearchMap.toDate = columnValue.filterValue.$lte;
          break;
        case 'BOOLEAN':
          recordSearchMap.recordType = columnValue.colType;
          recordSearchMap.recordSearchColumn = col;
          recordSearchMap.recordColumnValue = JSON.stringify(columnValue.filterValue);
          break;
        case 'CHECKBOX':
          recordSearchMap.recordType = columnValue.colType;
          recordSearchMap.recordSearchColumn = col;
          recordSearchMap.recordColumnValue = JSON.stringify(columnValue.filterValue);
          break;
        case 'MULTI_SELECT':
          let valMlti = [];
          for (let valI = 0; valI < columnValue.filterValue.$in.length; valI++) {
            const el = columnValue.filterValue.$in[valI];
            valMlti.push({
              value: el.value,
              label: el.label
            })
          }
          recordSearchMap.recordType = columnValue.colType;
          recordSearchMap.recordSearchColumn = col;
          recordSearchMap.recordColumnValue = valMlti;
          recordSearchMap.inputSource = JSON.parse(columnValue.inputSource)
          break;
        case 'LOOKUP_REFERENCE':
            let valLookUp = [];
            for (let valI = 0; valI < columnValue.filterValue.$in.length; valI++) {
              const el = columnValue.filterValue.$in[valI];
              valLookUp.push({
                value: el.value,
                label: el.label
              })
            }
            recordSearchMap.recordType = columnValue.colType;
            recordSearchMap.recordSearchColumn = col;
            recordSearchMap.recordColumnValue = valLookUp;
            recordSearchMap.inputSource = JSON.parse(columnValue.inputSource)
            break;
        default:
          recordSearchMap.recordType = columnValue.colType;
          recordSearchMap.recordSearchColumn = col;
          recordSearchMap.recordColumnValue = columnValue.filterValue;
          break;
      }
      recordSearchMapList.push(recordSearchMap)
    })
    if(recordSearchMapList.length>0)
    this.recordSearchMapList= recordSearchMapList;
    this.applySearchFilter()
  }
  dismissSaveFilter(){
    this.bsModalSaveFilter.hide();
  }
  startJoyRide(){
    let tmp = ['st1', 'st2'];
    if(this.nameCircArray.length>0){
      tmp.push('st3')
    }
    if(this.availableGroupFilterOptions.length>0){
      tmp.push('st4')
    }
    if(this.availableStatusFilterOptions.length>0){
      tmp.push('st5')
    }
    if(this.availablePriorityFilterOptions.length>0){
      tmp.push('st6')
    }
    if(this.savedFilterList.length>0){
      tmp.push('st7')
    }
    tmp = tmp.concat(['st8','st9','st10','st11'])
    this.joyrideService.startTour(
      { steps: tmp} // Your steps order
    );
  }
  searchCol(col){
    this.searchColRecord.recordSearchColumn = col.name; 
    // this.searchColRecord.recordType = "TEXT";
    let pos = (this.recordSearchMapList.map(item=>item.recordSearchColumn).indexOf(col.name));
    if(pos == -1){
      this.recordSearchMapList.push(JSON.parse(JSON.stringify(this.searchColRecord)));
    }else{
      this.recordSearchMapList[pos] = JSON.parse(JSON.stringify(this.searchColRecord));
    }
    this.searchColRecord = new RecordSearchMap();
    this.applySearchFilter();
  }
} 
