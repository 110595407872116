import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { User } from 'src/app/models/user.model';
import { Application } from '../models/application.model';
import { ApplicationService } from '../service/application.service';
import { AuthService } from '../service/auth.service';
import { CommonService } from '../service/common.service';
import { CommunicationService, FileService, UniversalUser } from '../service/shared.service';
import { FetchUserService } from '../service/userhierarchy.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  currentUser: User;
  profileImageObject: any = null;
  profilePhotoLoad: boolean = true;
  editMode: boolean = false;
  userTeam: any;
  passwordRegx = /^(?=.*\d)(?=.*[!@#$%^?`<>()-_+<.>()&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  displayRoles: string[] = [];
  credits: any;
  allApplications: Application[];
  resetPasswordMode: boolean;
  oldPassword: string;
  newPassword: string;
  showLoaderOverlay: boolean;

  constructor(
    private universalUser: UniversalUser,
    private commonService: CommonService,
    private fetchUsers: FetchUserService,
    private fileService: FileService,
    private authService: AuthService,
    private snackBar: MatSnackBar,
    private communicationService: CommunicationService,
    private applicationService: ApplicationService,
    private modalService: BsModalService,
    private route: ActivatedRoute,
    private titleService: Title,
    private sanitizer: DomSanitizer,
    private router:Router
  ) {
    this.titleService.setTitle("Profile");
  }

  ngOnInit() {
    this.currentUser = this.universalUser.getUser();
    this.fetchMasterRoles();
    this.getDownloadFileObjectUrl();
    this.currentUser.password = '';
    if (!this.currentUser.alertPermission) {
      this.currentUser.alertPermission = {
        "emailPermission": false,
        "mobilePermission": false,
        "appPermission": false,
        "desktopPermission": false
      };
    }
    this.fetchUsers.fetchChildUsers(this.currentUser._id).subscribe(
      (response) => {
        this.userTeam = response;
      },
      (error) => { }
    )
    if (this.currentUser.authorities.includes("ROLE_ADMIN") || this.currentUser.authorities.includes("ROLE_COMPANY_ADMIN")) {
      this.commonService.getCreditsRemaining().subscribe(
        (response) => {
          this.credits = response;
        },
        (error) => { }
      );
    }
    this.applicationService.getAllApplication().subscribe(
      (allApplications: Application[]) => {
        this.allApplications = allApplications;
      },
      error => {
        this.allApplications = null;
      }
    );

    this.route.queryParams.subscribe(
      params => {
        if (params.resetPass && params.resetPass == 'true') {
          this.resetPasswordMode = true;
        }
      }
    )
  }

  fetchMasterRoles() {
    if (this.currentUser == null || !this.currentUser.authorities || this.currentUser.authorities.length == 0)
      return;
    this.fetchUsers.getMasterRoles()
      .subscribe(
        response => {
          if (response) {
            for (let role of this.currentUser.authorities) {
              if (response[role])
                this.displayRoles.push(response[role]);
              else
                this.displayRoles.push(role);
            }
          }

        },
        error => {

        }
      )
  }

  onDocumentUpload(event) {
    for (let index = 0; index < event.target.files.length; index++) {
      const fileInputForm = new FormData();
      const file: File = event.target.files[index];
      fileInputForm.append('file', file, file.name);
      fileInputForm.append("fileName", file.name);
      fileInputForm.append("functionInstanceName", "API");
      fileInputForm.append("entityType", "PROFILEPIC");
      fileInputForm.append("entityRef", this.currentUser._id);
      this.uploadFile(fileInputForm);
    }

  }
  uploadFile(formData: FormData) {
    this.profilePhotoLoad = true;
    this.fileService.upload(formData)
      .subscribe(
        response => {
          if (response && response["url"] && response["fileName"]) {
            let doc: any = {};
            doc.url = response["url"];
            doc.fileName = response["fileName"];
            doc.downloadFileUrl = response["downloadFileUrl"];
            doc.fullDataUrl = response["fullDataUrl"];
            doc.fullFileUrl = response["fullFileUrl"];
            this.currentUser.imageUrl = doc.downloadFileUrl;
            this.updateProfile();
            this.getDownloadFileObjectUrl();
          }
        }, (error) => {
          if (error && error.error && error.error.error && error.error.error.message) {
            this.snackBar.open(error.error.error.message, "Dismiss", {
              duration: 10000
            });
          }else{
            this.snackBar.open(error, "Dismiss", {
              duration: 10000
            });
          }
          this.profilePhotoLoad = false;
        }
      );
  }

  getImageExtensionFromUrl(url: string) {
    const folderList: string[] = url.split("/");
    const fileNameList: string[] = folderList[folderList.length - 1].split(".");
    return fileNameList[fileNameList.length - 1];
  }

  getDownloadFileObjectUrl() {
    if (this.currentUser && this.currentUser.imageUrl) {
      if (this.currentUser.imageUrl.indexOf("/downloadData") == -1) {
        this.profileImageObject = this.currentUser.imageUrl;
        this.profilePhotoLoad = false;
      }
      else {
        this.fileService.download(this.currentUser.imageUrl, true).subscribe(
          objectUrl => {
            if (objectUrl) {
              this.profileImageObject = this.sanitizer.bypassSecurityTrustUrl(objectUrl);
            }
            this.profilePhotoLoad = false;
          },
          error => {
            this.profilePhotoLoad = false;
          }
        );

      }
    }
    else {
      this.profilePhotoLoad = false;
    }
  }


  validateUser(user: User) {
    if (!user) {
      this.snackBar.open("User cannot be null ", "Dismiss", {
        duration: 10000
      });
      return false;
    }

    if (this.editMode && (!user._id || user._id.length <= 0)) {
      this.snackBar.open("Id cannot be null or empty ", "Dismiss", {
        duration: 10000
      });
      return false;
    }

    if (!user.name || user.name.length <= 0) {
      this.snackBar.open("Name cannot be null or empty ", "Dismiss", {
        duration: 10000
      });
      return false;
    }

    if (!user.username || user.username.length <= 0) {
      this.snackBar.open("Username cannot be null or empty ", "Dismiss", {
        duration: 10000
      });
      return false;
    }

    if (!user.email || user.email.length <= 0) {
      this.snackBar.open("Email cannot be null or empty ", "Dismiss", {
        duration: 10000
      });
      return false;
    }

    if (user.password != null && user.password != undefined && user.password.length > 0) {
      if (!this.passwordRegx.test(user.password)) {
        this.snackBar.open("Password must contain atleast 8 characters, with atleast one upper case, one lower case, one special character and one number", "Dismiss", {
          duration: 10000
        });
        return false;
      }
    }

    return true;
  }
  currentTab: string = 'overview';
  loadTab(tabName) {
    this.currentTab = tabName;
    if (tabName == "overview") {

    }
    if (tabName == "design") {

    }
  }

  updateProfile() {
    if (!this.validateUser(this.currentUser)) {
      return;
    }
    this.showLoaderOverlay = true;
    this.authService.updateUserProfile(this.currentUser)
      .subscribe(
        response => {
          this.showLoaderOverlay = false;
          this.universalUser.setUser(this.currentUser, false);
          this.snackBar.open('Profile updated successfully', "Dismiss", {
            duration: 10000
          });
        },
        error => {
          this.showLoaderOverlay = false;
          if (error && error.error && error.error.message) {
            this.snackBar.open(error.error.message, "Dismiss", {
              duration: 10000
            });
          }

        }
      )
  }

  onResetPassword() {
    if (!this.newPassword) {
      this.snackBar.open("New Password can not be empty", "Dismiss", {
        duration: 10000
      });
      return
    }
    if (!this.oldPassword) {
      this.snackBar.open("Old Password can not be empty", "Dismiss", {
        duration: 10000
      });
      return
    }
    if (!this.isPasswordValid(this.newPassword)) {
      this.snackBar.open("Password must contain atleast 8 characters, with atleast one upper case, one lower case, one special character and one number", "Dismiss", {
        duration: 10000
      });
      return;
    }
    this.showLoaderOverlay = true;
    this.authService.resetPassword(this.oldPassword, this.newPassword)
      .subscribe(
        response => {
          console.log(response)
          this.universalUser.setUser(response, true)
          this.showLoaderOverlay = false;
          this.snackBar.open("Your password has been reset successfully. You will be signed out.", "Dismiss", {
            duration: 10000
          });
          localStorage.removeItem("filterDetails");
          localStorage.removeItem("selectedFolderName");
          localStorage.removeItem("parentSelectedFolderName");
          this.authService.initiateLogoutSequence(this.router.url);
          this.resetPasswordMode = false;
        },
        error => {
          this.showLoaderOverlay = false;
          if (error && error.error && error.error.message) {
            this.snackBar.open(error.error.message, "Dismiss", {
              duration: 10000
            });
          }
          else {
            this.snackBar.open("Something went wrong. Please try again.", "Dismiss", {
              duration: 10000
            });
          }
        }
      )
  }

  isPasswordValid(password) {
    return this.passwordRegx.test(password);
  }
}
