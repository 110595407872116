import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { USPSearchRequest, USPSearchResult, USPSearchResultData } from 'src/app/models/usp.model';
import { Observable, Observer, Subject } from 'rxjs';
import { USPService } from 'src/app/service/usp.service';

@Component({
  selector: 'app-usp-search',
  templateUrl: './usp-search.component.html',
  styleUrls: ['./usp-search.component.css']
})
export class USPSearchComponent implements OnInit {

  uspSearchRequest: USPSearchRequest;
  uspSearchResult: USPSearchResult;
  searchResultData: any;
  maxScore: number;
  entityName: string;
  entityNames: string[];
  underlayFlag: boolean = false;
  baseSearchFilters: any = {
    type: [{ name: 'entitySearch', label: "Form" }, { name: 'taskSearch', label: "Task" }],
    status: [{ name: 'ACTIVE', label: "Active" }, { name: 'CLOSED', label: "Closed" }]
  };

  searchType: string[] = ['entitySearch', 'taskSearch'];
  searchTermType: string = "";
  searchTermStat: string = "";
  constructor(
    private uspService: USPService,
    private router: Router
  ) {
    this.uspSearchRequest = new USPSearchRequest();
    this.uspSearchResult = new USPSearchResult();
    this.searchResultData = new Observable<any>((observer: Observer<any>) => {
      observer.next({
        'entitySearch': [],
        'taskSearch': []
      })
    });
    this.maxScore = 0;
    this.entityName = null;
  }
  ngOnInit() {
  }
  onUSPSearch() {
    this.maxScore = 0;
    if (this.uspSearchRequest.searchText && this.uspSearchRequest.searchText.length > 3) {
      this.getSearchResultWithFilter(false);
    }
  }
  getSearchResultWithFilter(isLoadMore?: boolean) {
    for (let index = 0; index < this.searchType.length; index++) {
      const element = this.searchType[index];
      this.uspService.search(this.uspSearchRequest, element)
        .subscribe(
          response => {
            console.log(this.uspSearchResult) 
            this.searchResultData[element] = [];
            for (let data of response) {
              
              this.searchResultData[element].push(data);
            }
          },
          error => {

          }
        )
    }

  }
  activateUnderlay(event) {
    this.uspSearchRequest.searchText="";
    this.searchResultData = {};
    this.underlayFlag = event
  }
  getResultData(item) {
    return (this.searchResultData[item])
  }
  navigatetoURL(sreachItem, type) {
    if (type != undefined) {
      this.activateUnderlay(false);
      switch (type) {
        case 'entitySearch':
          this.router.navigate([ '/add-activities' ], { queryParams: { 'entityId':sreachItem._id, "entityName":sreachItem.entityName } });
          break;
        case 'taskSearch':
          this.router.navigate([ '/task' ], { queryParams: { 'taskId':sreachItem._id } });
          break;
        default:
          break;
      }
      this.uspSearchRequest.searchText="";
    }
  }
  filterType(event){
    this.searchType = event.map(item=>item.name);
    if (this.searchType.length == 0) {
      this.searchType = ['entitySearch', 'taskSearch'];
    }
    this.getSearchResultWithFilter();
  }
  filterDetails(event,type){
    switch (type) {
      case 'status':
        this.uspSearchRequest.statusCdList =  event.map(item=>item.name);
        break;
    
      default:
        break;
    }
    this.getSearchResultWithFilter();
  }
  datesUpdated(range) {
    if (range.startDate != null && range.endDate != null) {
      this.uspSearchRequest.fromTime = range.startDate.startOf("day").format("YYYY-MM-DD HH:mm:ss");
      this.uspSearchRequest.toTime = range.endDate.format("YYYY-MM-DD HH:mm:ss");

    }
  }
  shortenName(str){
    let opStr =""
    if (str.assignedToUsername == null) {
      if (str.entityType == 'ENTITY') {
        opStr = str.entityLabel.substring(0,1).toUpperCase();
      } else {
        opStr="-"
      }
    } else {
      if(str.assignedToUsername.split(" ").length>1){
        let strArr = str.assignedToUsername.split(" ");
        opStr = strArr[0].substring(0,1) + strArr[1].substring(0,1)
        opStr = opStr.toUpperCase();
      }else{
        opStr = str.assignedToUsername.substring(0,2).toUpperCase();
      }
    }
    
    return opStr;
  }
}
